import * as React from "react";
import PublicAppBar from "../../../components/appbar/PublicAppBar";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type ForbiddenErrorProps = {
  //
};

const ForbiddenError: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth='lg'>
      <PublicAppBar />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h1">403</Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: "40px", marginBottom: 2 }}>{t("Oops! Forbidden")}</Typography>
        <Typography variant="body1">{t("You don't have permission to access this page.")}</Typography>
      </div>
    </Container>
  );
};

export default ForbiddenError;