// import SubDistrictJSON from '../../../services/REGION_THA/SubDistrict.json'
import SubDistrictJSON from '../../../../services/REGION_THA/SubDistrict.json'

export const fetchProvinces = async () => {
    try {
        const response = await fetch('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json');
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed to fetch all provinces name');
    }
};

export const fetchDistricts = async () => {
    try {
        const response = await fetch('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json');
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed to fetch districts');
    }
};

export const fetchSubDistricts = async () => {
    try {
        // const response = await fetch('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json');
        const data = SubDistrictJSON;
        return data;
    } catch (error) {
        throw new Error('Failed to fetch sub-districts');
    }
};