// React
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Utils
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { MuiOtpInput } from 'mui-one-time-password-input'

// Services
import { sendOTP, verifyOTP } from '../../../services/AuthService';

export function matchIsString(text: string) {
  const checkIsString = typeof text === 'string'
  return checkIsString
}

export function matchIsNumeric(text: string) {
  const isNumber = typeof text === 'number'
  const isString = matchIsString(text)
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text))
}

interface forgotpasswordProps {
  phonenumber: string;
  messageStatus: (status: boolean) => void;
}

const VerifyPassword: React.FC<forgotpasswordProps> = ({ phonenumber, messageStatus }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string>('');
  const [timeisActive, setTimeisActive] = useState<boolean>(true);
  const [timeLeft, setTimeLeft] = useState<number>(300);
  const [countOfFail, setCountOfFail] = useState<number>(2);
  const [msgOfFail, setMsgOfFail] = useState<boolean>(false);
  const [transactionFail, setTransactionFail] = useState<boolean>(false);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((time) => time - 1);
      if (timeLeft === 0) {
        setTimeisActive(false)
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleChange = (newValue: any) => {
    setOtp(newValue)
  }

  const handleComplete = (finalValue: string) => {
    setOtp(finalValue)
  }

  const validateChar = (value: string, index: number) => {
    return matchIsNumeric(value)
  }

  const handleResend = async () => {
    try {
      const data = {
        mobile_number: phonenumber
      }
      const response = await sendOTP(data)

      if (response.data?.success) {
        setTimeLeft(300)
        setTimeisActive(true)
        setCountOfFail(2)
        setMsgOfFail(false)
      }
    } catch (error) {
      navigate('/server', { replace: true });
    }
  }

  const onSubmitOTP = () => {
    const sendOTP = async () => {
      try {
        const data = {
          mobile_number: phonenumber,
          otp: otp
        }
        const response = await verifyOTP(data)

        if (response.data?.success) {

          let timeleft = 3;
          let downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
              clearInterval(downloadTimer);
              messageStatus(true)
            }
            timeleft -= 1;
          }, 1000);

          Swal.fire({
            icon: 'success',
            title: `<h5 style="margin: 0px">${t('Identity verification successful!')}</h5>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: `${t("OK!")}`,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              messageStatus(true)
            }
          })

        }
        else if (!timeisActive && !response.data?.success) {
          Swal.fire({
            icon: 'error',
            title: `<h5 style="margin: 0px">${t('Verify timeout')}</h5>`,
            html:
              `<p>${t('Your confirmation code (OTP) has been deleted. If you need to verify your identity, please click to [Resend].')}</p>` +
              `<p>${t('In order for us to send the 6-digit verification code (OTP) ')}<b>${t('to your phone number ')}${phonenumber}${t(' again.')}</b></p>`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: `${t("Resend")}`,
            cancelButtonText: `${t("Cancel")}`,
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setTimeisActive(true)
              handleResend();
            } else {
              navigate('/login', { replace: true });
            }
          })
        }
        else if (!response.data?.success) {
          if (countOfFail > 0) {
            Swal.fire({
              icon: 'error',
              title: `${t('Incorrect OTP code!')}`,
              html:
                `<p>${t('You can enter the confirmation code (OTP) only')} ${countOfFail} ${t('more times.')}</p>`,
              confirmButtonColor: '#43A047',
              confirmButtonText: `${t('OK!')}`,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                setCountOfFail(countOfFail - 1)
                setOtp('')
                setMsgOfFail(true)
              } else {
                navigate('/login', { replace: true });
              }
            })
          }
          else if (countOfFail === 0) {
            Swal.fire({
              icon: 'error',
              title: `${t('Authentication Failed') + '!'}`,
              html:
                `<p>${t('You are unable to proceed with the transaction.')}<br />${t('Please try again.')}</p>`,
              confirmButtonColor: '#43A047',
              confirmButtonText: `${t('OK!')}`,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                setTransactionFail(true)
              } else {
                navigate('/login', { replace: true });
              }
            })
          }
        }
      } catch (error) {
        navigate('/server', { replace: true });
      }
    }
    sendOTP();

  }

  return <div>
    <Box display='flex' flexDirection='column' marginTop={7}>
      <Typography alignSelf='center' sx={{ fontWeight: 'bold', fontSize: 20, letterSpacing: 0.5 }}>
        {t("Verification")}
      </Typography>
      <Box marginY={3} display='flex' flexDirection='column' gap={1}>
        <Typography alignSelf='center' sx={{ fontSize: 16, letterSpacing: 0.5 }}>
          {t("we have sent a 6 digit number")}
        </Typography>
        <Typography alignSelf='center' sx={{ fontWeight: 'bold', fontSize: 16, letterSpacing: 0.5 }}>
          {t("that send to ")} ******{phonenumber.slice(-4)}
        </Typography>
      </Box>
      {!transactionFail ? <>
        <Box marginY={3}>
          <MuiOtpInput
            autoFocus
            length={6}
            value={otp}
            onChange={handleChange}
            onComplete={handleComplete}
            validateChar={validateChar}
            TextFieldsProps={{
              color: 'info',
              InputProps: { inputMode: 'numeric', }
            }}
            sx={{
              display: 'flex',
              gap: { xs: 1, sm: 2 },
              maxWidth: 'fit-content',
              marginInline: 'auto',
              width: { xs: 325, sm: 400 },
            }}
          />
          {msgOfFail &&
            <Typography color="error" align="center" fontSize={13} sx={{ letterSpacing: 0.5, mt: 2 }}>
              {t("Incorrect OTP code (")} {`${countOfFail + 1}`} {t("more attempts allowed") + ")"}
            </Typography>
          }
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
          <Typography sx={{ fontSize: 16, letterSpacing: 0.5 }}>
            {t("Please enter the OTP code within ")}
          </Typography>
          {timeLeft > 0 ? <>
            <Typography sx={{ color: '#07bc0c', fontWeight: 'bold', fontSize: 16, letterSpacing: 0.5 }}>
              {`${Math.floor(timeLeft / 60)}`.padStart(2, '0')}:{`${timeLeft % 60}`.padStart(2, '0')}
            </Typography>
          </> : <>
            <Typography sx={{ color: '#c62828', fontWeight: 'bold', fontSize: 16, letterSpacing: 0.5 }}>
              {t("Timeout")}
            </Typography>
          </>}
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' gap={1} marginY={3}>
          <Typography sx={{ color: '#767676', fontSize: 15, letterSpacing: 0.5 }}>
            {t("Haven't received the verification code?")}
          </Typography>
          <Button color='info' sx={{ fontSize: 15, letterSpacing: 0.5, textTransform: 'capitalize' }}
            onClick={handleResend}
          >
            {t("Resend")}
          </Button>
        </Box>
        <Box display='flex' justifyContent='center' marginY={2}>
          <Button
            variant="contained"
            sx={{ width: { xs: '100%', sm: '40%' }, fontSize: 16 }}
            onClick={onSubmitOTP}
          >
            {t("Verify")}
          </Button>
        </Box>
      </> : <>
        <Box marginY={3}>
          <MuiOtpInput
            length={6}
            TextFieldsProps={{
              disabled: true,
              placeholder: '-'
            }}
            sx={{
              display: 'flex',
              gap: { xs: 1, sm: 2 },
              maxWidth: 'fit-content',
              marginInline: 'auto',
              width: { xs: 325, sm: 400 },
            }}
          />
          <Box display='flex' flexDirection='column' alignItems='center' gap={1} marginY={7}>
            <Typography color="error" sx={{ fontSize: { xs: 13, sm: 15 }, letterSpacing: 0.5, mb: 2 }}>
              {t("Identity verification failed. Please try the transaction again.")}
            </Typography>
            <Button
              variant="contained"
              sx={{ width: { xs: '100%', sm: '40%' }, fontSize: 16 }}
              component={Link} to={`/login`}
            >
              {t("return to Login")}
            </Button>
          </Box>
        </Box>
      </>}
    </Box>
  </div>;
};

export default VerifyPassword;
