// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    editMailingAddressDistrict,
    editMailingAddressVillageNo,
    editMailingAddressHouseNo,
    editMailingAddressProvince,
    editMailingAddressRoad,
    editMailingAddressAlley,
    editMailingAddressSubDistrict,
    editMailingAddressZipCode,
    editMailingSameAsLegalAddress
} from "../../../../redux/slices/jobAppSlice";

// MUI Components
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from "@mui/material";

// Utils
import { useTranslation } from "react-i18next";

// Styles
import theme from '../../../../styles/theme'

const MailingAddr: React.FC<any> = ({ errors, provinces, districts, subDistricts }) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const [dataErrorState, setDataErrorState] = useState<any>(errors)
    const { mailingAddressInfo } = useAppSelector((state) => state.jobapp);

    const [provincesOption, setProvincesOption] = useState<{
        external_code: number;
        province_code: number;
        label_th: string;
        label_en: string;
        is_active: boolean;
    }[]>([])
    const [districtsOption, setDistrictsOption] = useState([])
    const [subDistrictsOption, setSubDistrictsOption] = useState([])

    const [mailingHouseNo, setMailingHouseNo] = useState<string>("")
    const [mailingVillageNo, setMailingVillageNo] = useState<string>("")
    const [pmgAlley, setMailingAlley] = useState<string>("")
    const [mailingRoad, setMailingRoad] = useState<string>("")
    const [mailingSubDistrict, setMailingSubDistrict] = useState<any>(null)
    const [mailingDistrict, setMailingDistrict] = useState<any>(null)
    const [mailingProvince, setMailingProvince] = useState<any>(null)
    const [mailingZipCode, setMailingZipCode] = useState<string>("")

    const [loadingProvinceData, setLoadingProvinceData] = useState<boolean>(true)
    const [loadingDistrictData, setLoadingDistrictData] = useState<boolean>(true)
    const [loadingSubDistrictData, setLoadingSubDistrictData] = useState<boolean>(true)

    useEffect(() => {
        setProvincesOption(provinces)
    }, [provinces]);

    useEffect(() => {
        if (mailingProvince !== null) {
            const filterDistrict: any = districts.filter((district: any) => district.province_id === mailingProvince?.province_code)

            setDistrictsOption(filterDistrict)
        }
    }, [districts]);

    useEffect(() => {
        if (mailingDistrict !== null) {
            const filterSubDistrict: any = subDistricts.filter((subDistrict: any) => subDistrict.amphure_id === mailingDistrict?.id)

            setSubDistrictsOption(filterSubDistrict)
        }
    }, [subDistricts]);

    useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    useEffect(() => {
        if (mailingAddressInfo) {
            setMailingHouseNo(mailingAddressInfo.address_house_number || '')
            setMailingVillageNo(mailingAddressInfo.address_moo || '')
            setMailingAlley(mailingAddressInfo.address_lane || '')
            setMailingRoad(mailingAddressInfo.address_street || '')
            setMailingProvince(mailingAddressInfo.address_province || null)
            setTimeout(() => {
                setMailingDistrict(mailingAddressInfo.address_district || null)
            }, 100);
            setTimeout(() => {
                setMailingSubDistrict(mailingAddressInfo.address_sub_district || null)
            }, 200);
            setTimeout(() => {
                setMailingZipCode(mailingAddressInfo.address_postal_code || '')
                setLoadingProvinceData(false)
                setLoadingDistrictData(false)
                setLoadingSubDistrictData(false)
            }, 300);
        }

    }, [mailingAddressInfo])

    useEffect(() => {
        try {
            if (mailingProvince !== null) {
                const filterDistrict: any = districts.filter((district: any) => district.province_id === mailingProvince?.province_code)

                setDistrictsOption(filterDistrict)
            }

            setMailingDistrict(null)
            setSubDistrictsOption([])
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [mailingProvince])

    useEffect(() => {
        try {
            if (mailingDistrict !== null) {
                const filterSubDistrict: any = subDistricts.filter((subDistrict: any) => subDistrict.amphure_id === mailingDistrict?.id)

                setSubDistrictsOption(filterSubDistrict)
            }

            setMailingSubDistrict(null)
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [mailingDistrict])

    useEffect(() => {
        try {
            if (mailingSubDistrict === null) {
                setMailingZipCode("")
            }
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [mailingSubDistrict])

    const [mailingSameAsLegal, setMailingSameAsLegal] = useState<boolean>(false)

    useEffect(() => {
        setMailingSameAsLegal(mailingAddressInfo.same_as_legal_address)
        setDataErrorState({ ...dataErrorState, address_house_number: null, address_province: null, address_district: null, address_sub_district: null })
    }, [mailingAddressInfo])


    const handleMailingSameAsLegalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailingSameAsLegal(event.target.checked);
        dispatch(editMailingSameAsLegalAddress(event.target.checked))
    };

    return <div>
        <ThemeProvider theme={theme}>
            <Box component="form" noValidate sx={{ mt: 3 }}>
                <Box sx={{ mt: 6, mb: 3, display: "flex", alignItems: "center", gap: "32px" }}>
                    <Typography>{t("Mailing Address")}</Typography>

                    <FormControlLabel control={<Checkbox
                        checked={mailingSameAsLegal}
                        onChange={handleMailingSameAsLegalChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label={t("Same as legal address")} />

                </Box>
                {!mailingSameAsLegal && (<>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                error={dataErrorState.address_house_number ? true : false}
                                required
                                fullWidth
                                id="mailinghouseno."
                                label={t("House No.")}
                                placeholder={t("House No.")!}
                                value={loadingProvinceData ? "" : mailingHouseNo}
                                onChange={(e) => {
                                    setMailingHouseNo(e.target.value)
                                    dispatch(editMailingAddressHouseNo(e.target.value))

                                    setDataErrorState({ ...dataErrorState, address_house_number: null })
                                }}
                            />
                            <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                                {t(dataErrorState.address_house_number?.message)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                id="mailingmoo"
                                label={t("Moo")}
                                placeholder={t("Moo")!}
                                value={loadingProvinceData ? "" : mailingVillageNo}
                                onChange={(e) => {
                                    setMailingVillageNo(e.target.value)
                                    dispatch(editMailingAddressVillageNo(e.target.value))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                id="mailingalley"
                                label={t("Lane")}
                                placeholder={t("Lane")!}
                                value={loadingProvinceData ? "" : pmgAlley}
                                onChange={(e) => {
                                    setMailingAlley(e.target.value)
                                    dispatch(editMailingAddressAlley(e.target.value))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                id="mailingroad"
                                label={t("Street")}
                                placeholder={t("Street")!}
                                value={mailingRoad}
                                onChange={(e) => {
                                    setMailingRoad(e.target.value)
                                    dispatch(editMailingAddressRoad(e.target.value))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue: any) => {
                                        setMailingProvince(newValue);
                                        setMailingDistrict(null);
                                        setMailingSubDistrict(null);
                                        setMailingZipCode("");
                                        dispatch(editMailingAddressProvince(newValue))
                                        dispatch(editMailingAddressDistrict(null))
                                        dispatch(editMailingAddressSubDistrict(null))
                                        dispatch(editMailingAddressZipCode(""))

                                        setDataErrorState({ ...dataErrorState, address_province: null })
                                    }}
                                    disableClearable
                                    limitTags={1}
                                    id="provinces"
                                    options={provincesOption}
                                    getOptionLabel={(option) => i18n.language === 'th' ? option.label_th : option.label_en}
                                    isOptionEqualToValue={(option, value) => option.province_code === value.province_code}
                                    value={loadingProvinceData ? null : mailingProvince}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props}>
                                                <div>
                                                    {i18n.language === 'th' ? option.label_th : option.label_en}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            required
                                            label={t("Province")}
                                            placeholder={t("Province")!}
                                            error={dataErrorState.address_province ? true : false}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingProvinceData ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                            <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                                {t(dataErrorState.address_province?.message)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue: any) => {
                                        setMailingDistrict(newValue);
                                        setMailingSubDistrict(null);
                                        setMailingZipCode("");
                                        dispatch(editMailingAddressDistrict(newValue))
                                        dispatch(editMailingAddressSubDistrict(null))
                                        dispatch(editMailingAddressZipCode(""))

                                        setDataErrorState({ ...dataErrorState, address_district: null })
                                    }}
                                    disableClearable
                                    limitTags={1}
                                    id="districts"
                                    options={districtsOption}
                                    getOptionLabel={(option) => i18n.language === 'th' ? option.name_th : option.name_en}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    noOptionsText={t("กรุณาเลือกจังหวัด")}
                                    value={loadingDistrictData ? null : mailingDistrict}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props}>
                                                <div>
                                                    {i18n.language === 'th' ? option.name_th : option.name_en}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            required
                                            label={t("District")}
                                            placeholder={t("District")!}
                                            error={dataErrorState.address_district ? true : false}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingDistrictData ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                            <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                                {t(dataErrorState.address_district?.message)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue: any) => {
                                        setMailingSubDistrict(newValue);
                                        setMailingZipCode(newValue?.zip_code || "");
                                        dispatch(editMailingAddressSubDistrict(newValue))
                                        dispatch(editMailingAddressZipCode(newValue?.zip_code || ""))

                                        setDataErrorState({ ...dataErrorState, address_sub_district: null })
                                    }}
                                    disableClearable
                                    limitTags={1}
                                    id="sub_districts"
                                    options={subDistrictsOption}
                                    getOptionLabel={(option) => i18n.language === 'th' ? option.name_th : option.name_en}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    noOptionsText={t("กรุณาเลือกอำเภอ")}
                                    value={loadingSubDistrictData ? null : mailingSubDistrict}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props}>
                                                <div>
                                                    {i18n.language === 'th' ? option.name_th : option.name_en}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            required
                                            label={t("Sub District")}
                                            placeholder={t("Sub District")!}
                                            error={dataErrorState.address_sub_district ? true : false}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingSubDistrictData ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                            <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                                {t(dataErrorState.address_sub_district?.message)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} display='flex' alignItems='center'>
                            <Typography sx={{ mt: 0 }}>{t("Postal Code")} : {mailingZipCode === '' ? <></> : mailingZipCode}</Typography>
                        </Grid>
                    </Grid>
                </>)}
            </Box>
        </ThemeProvider>
    </div>;
};

export default MailingAddr;
