import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { deleteItem, getItem } from './StorageService';
import { TOKEN as AUTH_TOKEN_KEY, apiUrl } from '../Constants';
import { decodeJWTToken } from '../utils/decode-jwt';
// Define the base URL for your API
const BASE_URL = apiUrl;
// Create an instance of Axios with the base URL
const api: AxiosInstance = axios.create({
    baseURL: BASE_URL,
});
// Set the default headers for the requests
api.defaults.headers.common['Content-Type'] = 'application/json';

// Interceptor for adding the token to requests
api.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem(AUTH_TOKEN_KEY); // Retrieve the token from wherever you have stored it
        if (token && !config.disableToken) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor for handling token expiration or authentication errors
api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            console.error('Unauthorized access or token expired');
            const decodedToken = decodeJWTToken(getItem(AUTH_TOKEN_KEY));

            if (decodedToken && decodedToken.isLoggedIn) {
                window.location.assign('/login?token_expired=true');
                deleteItem(AUTH_TOKEN_KEY);
                sessionStorage.removeItem('all_selected');
                sessionStorage.removeItem('selected_position');
            }

        }
        return error.response;
    }
);

export default api;
