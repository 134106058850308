import jwtDecode from "jwt-decode";


export interface JWTPayload {
    isLoggedIn: boolean;
    type: string;
    value: string;
    exp: number;
}

export function decodeJWTToken(token: string | null): JWTPayload | null {

    if (!token) {
        return null;
    }
    
    if (!token) {
        return null;
    } else {
        const decoded = jwtDecode(token);
        if (!decoded) {
            return null;
        }
        try {
            return decoded as JWTPayload;
        } catch (err) {
            return null;
        }
    }

}