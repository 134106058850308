import { apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/auth';

interface RegisterDataType {
    prefix: string;
    first_name_th: string;
    last_name_th: string;
    first_name_en: string;
    last_name_en: string;
    date_of_birth: string;
    id_card_number: string;
    telephone: string;
    email: string | null;
    password: string
}

interface LoginDataType {
    username: string;
    password: string;
}

interface resetPasswordType {
    username: string;
    new_password: string;
}

interface sendOtpType {
    mobile_number: string;
}

interface verifyOtpType {
    mobile_number: string,
    otp: string;
}

export function localRegister(data: RegisterDataType) {
    return api.post(`${BASE_URL}/register`, data);
}

export function localLogin(data: LoginDataType) {
    return api.post(`${BASE_URL}/login`, data);
}

export function resetPassword(data: resetPasswordType) {
    return api.put(`${BASE_URL}/reset-password`, data);
}

export function sendOTP(data: sendOtpType) {
    return api.get(`${BASE_URL}/send-otp?mobile_number=${data.mobile_number}`);
}

export function verifyOTP(data: verifyOtpType) {
    return api.post(`${BASE_URL}/otp-verification`, data);
}