import React from 'react';
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import Swal from 'sweetalert2';
// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { fetchDocumentByUser } from '../../../../redux/slices/documentsSlice';

import {
  MultipleFileUpload,
  MultipleFileUploadStatusItem,
} from '@patternfly/react-core';
import "@patternfly/react-core/dist/styles/base.css";
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import './index.scss'; 

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import {Stack } from '@mui/material';
// Services
import {
  sendFilesDocuments,
  deleteDocuments
} from "../../../../services/UserService";
// Types
import {
  TokenPayload
} from "../../../../@types";
interface readFile {
  fileName: string;
  data?: string;
  loadResult?: 'danger' | 'success';
  loadError?: DOMException;
}

interface documentFile {
  id: string;
  name: string;
  type: string;
  dmsFileId: string;
}

interface Props {
  handleNextUpload: any;
}


const MultipleFileUploadBasic: React.FC<Props> = ({ handleNextUpload }) => {
  // const MultipleFileUploadBasic: React.FunctionComponent = ({ handleNextUpload:any }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token') || ''
  const decoded: TokenPayload = jwtDecode(token)
  const [userFiles, setUserFiles] = React.useState<File[]>([]);
  const [currentFiles, setCurrentFiles] = React.useState<File[]>([]);
  const [readFileData, setReadFileData] = React.useState<readFile[]>([]);
  const [showStatus, setShowStatus] = React.useState(false);
  const [statusIcon, setStatusIcon] = React.useState('inProgress');
  const [documentData, setDocumentData] = React.useState<readFile[]>([]);
  const [loading, setLoading] = React.useState(false);
  const UserId = decoded?.UserId ? decoded.UserId : "";
  const { document } = useAppSelector(state => state.documentDMS);
  const dispatch = useAppDispatch();

  if (!showStatus && currentFiles.length > 0) {
    setShowStatus(true);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchDocumentByUser())
    }
    fetchData()
  }, [dispatch]);


  React.useEffect(() => {
      if (document) {
        const documentItems = document.filter((item: any) => item.type === "EMPLOYMENT_REFERENCE")
          setDocumentData(documentItems)
      }
  }, [document]);


  React.useEffect(() => {
    if (readFileData.length < currentFiles.length) {
      setStatusIcon('inProgress');
    } else if (readFileData.every((file) => file.loadResult === 'success')) {
      setStatusIcon('success');
    } else {
      setStatusIcon('danger');
    }
  }, [readFileData, currentFiles]);

  
  const removeFiles = (namesOfFilesToRemove: string[]) => {
    const newCurrentFiles = userFiles.filter(
      (currentFile) => !namesOfFilesToRemove.some((fileName) => fileName === currentFile.name)
    );
    setUserFiles(newCurrentFiles) //
    setCurrentFiles(newCurrentFiles);

    const newReadFiles = readFileData.filter(
      (readFile) => !namesOfFilesToRemove.some((fileName) => fileName === readFile.fileName)
    );

    setReadFileData(newReadFiles);
  };


  const handleReadSuccess = (data: string, file: File) => {
    setReadFileData((prevReadFiles) => [...prevReadFiles, { data, fileName: file.name, loadResult: 'success' }]);
  };

  const handleReadFail = (error: DOMException, file: File) => {
    setReadFileData((prevReadFiles) => [
      ...prevReadFiles,
      { loadError: error, fileName: file.name, loadResult: 'danger' }
    ]);
  };


  const filterBySize = (file:any) => {
    return file[0].size <= 1e+7;
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    let idstokeep = [0, 2]; 
    let droppedFile = Array.prototype.slice.call(files).splice(idstokeep[0], idstokeep[1]);
    const checkSize = filterBySize(files)

    if (checkSize === true) {
      const currentFileNames = userFiles.map((file) => file.name);
      const reUploads = droppedFile.filter((file) => currentFileNames.includes(file.name));

    Promise.resolve()
      .then(() => removeFiles(reUploads.map((file) => file.name)))
      .then(() => setUserFiles((prevFiles) => {
        handleNextUpload(false)
       return [...prevFiles, ...droppedFile]
      }));   
      
    } else {
      Swal.fire({
        icon: 'error',
        title: `${t('Error')}`,
        text: `${t("text_check_image_hight_2mb")}`,
        confirmButtonText: `${t('ok')}`,
    });
    
    }
      
      
  };
 

  const handleImageUpload = async (event:any) => {
    event.preventDefault();
    setLoading(true)

    const formData = new FormData();
    const selectedJobs = sessionStorage.getItem('all_selected')
    const arrayJobs = JSON.parse(selectedJobs!)

    formData.append("uploadType", 'employmentRef');
    formData.append("businessPlace", arrayJobs[0]?.site_id);
    formData.append("company", arrayJobs[0]?.company);

    for(let item in userFiles){
          formData.append("employmentRefs", userFiles[item]);
    }
    
    if(userFiles && userFiles.length > 0){
      const resFilesDoc = await sendFilesDocuments(formData, UserId)
        if (resFilesDoc && resFilesDoc.status === 200) {
            await dispatch(fetchDocumentByUser())
            setUserFiles([])
            setLoading(false)
            handleNextUpload(true)
          Swal.fire({
            icon: "success",
            title: `${t('Update status successful!')}` ,
            showConfirmButton: false,
            timer: 2000
          });
        } else if(resFilesDoc.status === 500) {
          setLoading(false)
          handleNextUpload(true)
          // Handle error
          Swal.fire({
            icon: 'warning',
            text: `${t('text_err_limit_to_upload_file')}`,
            confirmButtonText: `${t('ok')}`,
        });
        }
        else{
          setLoading(false)
          const currentFile = userFiles && userFiles.filter((file) => file?.type === 'image/png' || file?.type === 'image/jpg' )
          Swal.fire({
              icon: 'warning',
              text: `${t('text_err_400_to_upload_file')} [${resFilesDoc.status}]`,
              confirmButtonText: `${t('ok')}`,
            }).then((result) => {
              if (result.isConfirmed) {
                if(currentFile.length <= 0){
                  handleNextUpload(true)
                }
                setUserFiles(currentFile)
              }
            })
          
        }
      }else{
        setLoading(false)
        Swal.fire({
          icon: 'warning',
          text: `${t('text_choose_image_before_click_upload')}`,
          confirmButtonText: `${t('ok')}`,
        })
      }
  
  }

  const removeDocument = async (namesOfFilesToRemove: documentFile) => {
    setLoading(true)
    if(namesOfFilesToRemove && namesOfFilesToRemove?.id && namesOfFilesToRemove?.dmsFileId){
      const newCurrentFiles = await  deleteDocuments(namesOfFilesToRemove.dmsFileId ,UserId)
      if(newCurrentFiles){
        await dispatch(fetchDocumentByUser())
        setLoading(false)
      }
    }
  };


  return (
   
    <div>
    <Typography sx={{ mt: { xs: 3, sm: 6 }, mb: 3 }}>{t("text_2_file_Upload")}</Typography>
     <Grid container spacing={2}>
        <Grid item lg={6} sm={12} xs={12}>     
          <form className="form-file-upload" id="form-file-upload"  onSubmit={handleImageUpload}>
          <div className="div-file-upload">
            <label id="label-file-upload" className={`label-file-upload ${ userFiles.length === 2 || documentData.length === 2 ? 'disabled': ''}`}  >
            <div>
            <div className={`custom-file-upload ${ userFiles.length === 2 || documentData.length === 2 ? 'disabled': ''}`} >
              <CloudUploadIcon className="upload-icon" />
              {t("text_files_Choose")}
              <input
                disabled = {userFiles.length === 2 ? true: false} 
                type="file" 
                id="fileInput"
                className="file-input" 
                accept="image/png, image/jpeg"
                onChange={(event) => { handleFileSelection(event)}} />
            </div>
          </div> 
        </label>
        </div>
        <button type="submit" className="custom-file-upload" >{t("text_files_Upload")}</button>
        </form>
        </Grid>
        <Grid className="grid-file-upload" item lg={6} sm={12} xs={12}>
          <MultipleFileUpload >
            {userFiles.map((file) => (
              <MultipleFileUploadStatusItem
              file={file}
              key={file.name}
              onClearClick={() => removeFiles([file.name])}
              onReadSuccess={handleReadSuccess}
              onReadFail={handleReadFail}
              fileIcon ={<UploadFileIcon color="secondary"/>}
              />
            ))}
      </MultipleFileUpload>
      <MultipleFileUpload  >
                  {documentData.map((file:any,index:number) => (
                  <div key={index}>
                    <MultipleFileUploadStatusItem
                      className='file-upload-item'
                      file={file}
                      fileName={file.name}
                      key={file.name} 
                      onClearClick={() => removeDocument(file)}
                      onReadSuccess={handleReadSuccess}
                      onReadFail={handleReadFail}
                      progressValue={100}
                      fileIcon ={<UploadFileIcon color="secondary"/>}
                    />
                  </div>
                    ))}
              </MultipleFileUpload>
              {loading &&(
              <Stack alignItems="center">
                  <CircularProgress />
              </Stack>
              )}
      </Grid>
    </Grid>
    </div>
  );
};

export default MultipleFileUploadBasic;
