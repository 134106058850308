// React
import { useEffect, useState } from "react";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";

// Components
import ConfirmationDialogPDPA from "./consentPDPA"

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from "@mui/material";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

// Utils
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { setCookie } from "../../../cookie";
import { newAdapter } from "../../../utils";
import Swal from "sweetalert2";
import * as Yup from 'yup';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import theme from "../../../styles/theme";
import { green } from "@mui/material/colors";
import '../../../styles/signup.scss'

// Services
import { localRegister } from "../../../services/AuthService";
import { checkUsername} from "../../../services/UserService";
import { getAllActivePrefix } from "../../../services/MasterDataService";

export default function Register() {
    const defaultTheme = createTheme({
        typography: {
            fontFamily: 'Sarabun'
        }
    });

    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [successRegister, setSuccessRegister] = useState<boolean>(false);
    const [prefix, setPrefix] = useState<string>('');
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [pdpaConsent, setPdpaConsent] = useState<boolean>(false);
    const [dateOfBirth, setDateOfBirth] = useState<any>(null);
    const [openPDPA, setOpenPDPA] = useState<boolean>(true)
    const [dateErrorState, setDateErrorState] = useState<boolean>(false)
    const [ageStatus, setAgeStatus] = useState<boolean>(false);
    const [prefixList, setPrefixList] = useState<any>([]);
    const [statusValidate, setStatusValidate] = useState<boolean>(true);


    const handlepdpaConsentDialog = () => {
        setOpenPDPA(false)
        setPdpaConsent(true)
    }

    const handlePrefixChange = (event: SelectChangeEvent) => {
        setPrefix(event.target.value as string);
        errors.prefix = undefined;
    };
    
    const SignUpSchema = Yup.object().shape({
        prefix: Yup.string().required(`${t("Prefix is required")}`),
        first_name_th: Yup.string().required(`${t("First name (Thai language) is require")}`),
        last_name_th: Yup.string().required(`${t("Last name (Thai language) is require")}`),
        first_name_en: Yup.string().required(`${t("First name (English language) is require")}`).matches(/^[A-Za-z\s-]*$/, `${t("Enter only English letters")}`),
        last_name_en: Yup.string().required(`${t("Last name (English language) is require")}`).matches(/^[A-Za-z\s-]*$/, `${t("Enter only English letters")}`),
        email: Yup.string().email(`${t("Please enter Valid email")}`),
        password: Yup.string()
            .required(`${t("Password is required")}`)
            .min(8, `${t("Password must be at least 8 characters")}`)
            .matches(
                /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                `${t("Password must include a number and letter")}`
            ),
        confirm_password: Yup.string()
            .required(`${t("Confirm Password is required")}`)
            .min(8, `${t("Password must be at least 8 characters")}`)
            .oneOf([Yup.ref('password')], `${t("Passwords don't match")}`),
        phone_number: Yup.string()
        .required(`${t("This field is required phone number")}`)
        .min(10, `${t("Phone number must be 10 digits")}`)
        .max(10, `${t("Phone number must be 10 digits")}`),
        id_card_number: Yup.string()
        .required(`${t("ID Card number is required")}`)
        .min(13, `${t("ID Card number must be 13 digit")}`)
        .max(13, `${t("ID Card number must be 13 digit")}`)
    });

   
  
    const isNumeric = (value: string): boolean => {
      return /^-?\d+$/.test(value);
    };
   
    const validateThaiCitizenID = async (id: string) => {
        // Remove any non-digit characters from the ID
        id = id.replace(/\D/g, '');
      
        // Check if the ID has the correct length (13 digits)
        if (id.length !== 13) {
          return false;
        }
      
        // Convert the ID to an array of digits
        const digits = id.split('').map(Number);
      
        // Calculate the checksum
        let sum = 0;
        for (let i = 0; i < 12; i++) {
          sum += digits[i] * (13 - i);
        }
      
        // Check if the last digit matches the checksum
        const checksum = (11 - (sum % 11)) % 10;

        return digits[12] === checksum;
      }

      const checkIDCard = async (id:string) => {
        
        const validateStatus = await validateThaiCitizenID(id)
        if(validateStatus && isNumeric(id)){
            checkMemberIdCard(id); 
            setStatusValidate(true)
        }else{
            setStatusValidate(false)
            Swal.fire({
                icon: "error",
                text: `${t("text_error_validate_idCard")}`,
              });
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SignUpSchema)
    });

    const onSubmit = (user: any) => {

        /* validation of dopa id-card */
        if (!statusValidate) {
            toast.error( `${t("text_error_validate_idCard")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (dateOfBirth === null) {
            setDateErrorState(true)
            toast.error(`${t("Date of birth is required")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (dateOfBirth !== null && ageStatus === true) {
            setDateErrorState(true)
            toast.error(`${t("text_error_age_application")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }else if (dateOfBirth !== null){
            setDateErrorState(false) 
        }

        Swal.fire({
            title: `${t("Please check the information before confirming")}` ,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${t("Confirm")}`,
            cancelButtonText:`${t("Back Review Information")}`,
          }).then((result) => {
            if (result.isConfirmed) {
              
        try {
            setRegisterLoading(true);

            const register = async () => {
                const data = {
                    prefix: user.prefix,
                    first_name_th: user.first_name_th,
                    last_name_th: user.last_name_th,
                    first_name_en: user.first_name_en.length > 0 ? user.first_name_en : null,
                    last_name_en: user.last_name_en.length > 0 ? user.last_name_en : null,
                    date_of_birth: dateOfBirth,
                    id_card_number: user.id_card_number,
                    telephone: user.phone_number,
                    email: user.email.length > 0 ? user.email : null,
                    password: user.password,
                }

                const response = await localRegister(data);

                if (response.data?.success) {
                    setRegisterLoading(false);
                    setSuccessRegister(true);

                    const parsedToken = jwtDecode(response.data?.access_token) as { exp: number }
                    const tokenExpiryTime = new Date(parsedToken.exp * 1000)
                    setCookie(response.data?.access_token, tokenExpiryTime)

                    let timeleft = 3;
                    let downloadTimer = setInterval(function () {
                        if (timeleft <= 0) {
                            clearInterval(downloadTimer);
                            if (searchParams.get("continue") !== null) {
                                window.location.assign(searchParams.get("continue") as string);
                            } else {
                                const position = JSON.parse(sessionStorage.getItem('selected_position') || '[]');
                                if(position?.length > 0){
                                    window.location.assign('/appform');
                                }else{
                                    window.location.assign('/jobs');
                                }
                            }
                        }
                        timeleft -= 1;
                    }, 1000);

                    Swal.fire({
                        title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                        text: `${t("You will be redirected in")} ${timeleft} ${t("seconds")}`,
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        timer: 3000,
                        timerProgressBar: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (searchParams.get("continue") !== null) {
                                window.location.assign(searchParams.get("continue") as string);
                            } else {
                                const position = JSON.parse(sessionStorage.getItem('selected_position') || '[]');
                              
                                if(position?.length > 0){
                                    window.location.assign('/appform');
                                }else{
                                    window.location.assign('/jobs');
                                }
                            }
                        }
                    })
                } else {
                    setRegisterLoading(false);

                    toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }

            register();
        } catch (error) {
            navigate('/servererror', { replace: true })
        } finally {
            setRegisterLoading(false);
        }
            }
          });

    }

    const checkMemberIdCard = async (idCard:string) => {
        try {
            const response = await checkUsername(idCard);
            if (response.data?.success) {
            Swal.fire({
                icon: "warning",
                text: `${idCard} ${t("text_err_id_mrmber_old")}`,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    sessionStorage.setItem('nextStep', 'appform');
                    window.location.assign(`/login/${idCard}`);
                }
            });
                    }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
       
        const fetchActivePrefix = async () => {
            try {
                const response = await getAllActivePrefix();

                if (response.data?.success) {
                    setPrefixList(response.data?.marital_statuses);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchActivePrefix();
    }, [])

    useEffect(() => {
        const token = localStorage.access_token;

        if (token) {
            try {
                const user: any = jwtDecode(token);

                const adminAndHRRolesType = ['Super Admin', 'HR Recruiter'];

                if (adminAndHRRolesType.includes(user.RoleType)) {
                    navigate('/admin');
                } else {
                    navigate('/jobs');
                }
            } catch (error) {
                navigate('/login');
                localStorage.removeItem('access_token')
            }
        }

    }, [navigate])

    useEffect(() => {
        if (successRegister === false) {
            const unloadCallback = (event: any) => {
                event.preventDefault();
                event.returnValue = "";
                return "";
            };

            window.addEventListener("beforeunload", unloadCallback);
            return () => window.removeEventListener("beforeunload", unloadCallback);
        } else {
            return () => { };
        }
    }, [successRegister]);

    useEffect(() => {
        const calculateAge = async (dateOfBirth:Date) => {
            const birth = new Date(dateOfBirth);
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            let age = now.getFullYear() - birth.getFullYear();
            const monthDiff = now.getMonth() - birth.getMonth();
            const dateDiff = now.getDate() - birth.getDate();

        // If the current date hasn't occurred yet this year, subtract 1 from the age.
        if (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)) {
            age--;
        }

        if (isNaN(age)) {
            return '-';
        }

        const statusAge = age < 18 ? true : false

        setAgeStatus(statusAge)

        };

        if (dateOfBirth != null) {
            calculateAge(dateOfBirth!);
        }
    }, [dateOfBirth]);

    return (
        <>
            <ToastContainer style={{ width: "400px" }} />

            <ThemeProvider theme={defaultTheme}>
                {
                    pdpaConsent ? (
                        <>
                            <Container component="main" sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: 3, sm: 0 } }}>
                                <Box
                                    sx={{
                                        marginTop: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: 900,
                                        width: '100%'
                                    }}
                                >
                                    <Box sx={{ display: { sm: 'none' }, alignSelf: 'center' }}>
                                        <img src='https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png' alt='Betagro Logo' width='70px' />
                                    </Box>
                                    <Typography sx={{ fontSize: { xs: 22, sm: 25 }, marginBottom: 3 }}>
                                        {t("Create an account")}
                                    </Typography>
                                    <Box component="form" noValidate sx={{ mt: 5 }}>
                                            <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="idcardnumber"
                                                    label={t("ID card number")}
                                                    placeholder={t("ID card number")!}
                                                    {...register('id_card_number')}
                                                    error={errors.id_card_number || statusValidate === false ? true : false}
                                                    inputProps={{ maxLength: 13 }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const inputText = e.target.value;
                                                        // if (/^\d*$/.test(inputText)) {
                                                            if(inputText && inputText.length === 13){
                                                                checkIDCard(inputText)
                                                                // checkMemberIdCard(inputText);
                                                            }
                                                        // }
                                                    }}
                                                    helperText={
                                                       statusValidate === false ? `${t("text_error_validate_idCard")}` : null
                                                    }
                                                />
                                                <FormHelperText sx={{ ml: 2, mt: 1, color: 'gray', fontSize: 12 }}>
                                                    {`${t("Input as number")} ${t("such as")} 1234567890123---`}
                                                </FormHelperText>
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.id_card_number?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="phonenumber"
                                                    label={t("Phone Number")}
                                                    placeholder={t("Phone Number")!}
                                                    {...register('phone_number')}
                                                    error={errors.phone_number ? true : false}
                                                    // inputProps={{ maxLength: 10 }}
                                                    InputProps={{
                                                        type: "number",
                                                        sx: {
                                                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                            display: 'none'
                                                            },
                                                            '& input[type=number]': {
                                                                MozAppearance: 'textfield'
                                                            },
                                                        }
                                                    }}
                                                />
                                                <FormHelperText sx={{ ml: 2, mt: 1, color: 'gray', fontSize: 12 }}>
                                                    {`${t("Input as number")} ${t("such as")} 0812345678`}
                                                </FormHelperText>
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.phone_number?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="prefix" error={errors.prefix ? true : false}>{t("Prefix") + ' *'}</InputLabel>
                                                    <Select
                                                        labelId="prefix"
                                                        id="prefix"
                                                        value={prefixList.length > 0 ? prefix : ''}
                                                        label={t("Prefix") + ' *'}
                                                        {...register('prefix')}
                                                        onChange={handlePrefixChange}
                                                        error={errors.prefix ? true : false}
                                                    >
                                                         {
                                                             prefixList.map((item: { external_code: string, label: string }) => {
                                                            return <MenuItem key={item.external_code} value={item.external_code}>{t(`${item.label}`)} </MenuItem>
                                                        })
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.prefix?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField
                                                    autoComplete="given-name"
                                                    required
                                                    fullWidth
                                                    id="firstName"
                                                    label={t("First name (Thai language)")}
                                                    placeholder={t("First name (Thai language)")!}
                                                    {...register('first_name_th')}
                                                    error={errors.first_name_th ? true : false}
                                                />
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.first_name_th?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="lastName"
                                                    label={t("Last name (Thai language)")}
                                                    placeholder={t("Last name (Thai language)")!}
                                                    autoComplete="family-name"
                                                    {...register('last_name_th')}
                                                    error={errors.last_name_th ? true : false}
                                                />
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.last_name_th?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="firstNameEN"
                                                    label={t("First name (English language)")}
                                                    placeholder={t("First name (English language)")!}
                                                    autoComplete="family-name"
                                                    {...register('first_name_en')}
                                                    error={errors.first_name_en ? true : false}
                                                />
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.first_name_en?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="lastNameEN"
                                                    label={t("Last name (English language)")}
                                                    placeholder={t("Last name (English language)")!}
                                                    autoComplete="family-name"
                                                    {...register('last_name_en')}
                                                    error={errors.last_name_en ? true : false}
                                                />
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.last_name_en?.message}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th' >
                                                    <MobileDatePicker
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        value={dateOfBirth}
                                                        label={t("Date of birth") + " *"}
                                                        format="DD/MM/YYYY"
                                                        disableFuture
                                                        closeOnSelect
                                                        localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
                                                        dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
                                                        onChange={(newValue) => {
                                                            try {
                                                                if (newValue !== null) {
                                                                    setDateOfBirth(newValue);
                                                                    setDateErrorState(false)
                                                                }
                                                            } catch (error) { }
                                                        }}
                                                        
                                                        slotProps={{
                                                            textField: {
                                                                error: dateErrorState === true ? true : false,
                                                                helperText: dateOfBirth===null && dateErrorState === true ? `${t("Date of birth is required")}` : null
                                                            },
                                                        }}
                                                    />
                                                    <FormHelperText sx={{ ml: 2, mt: 1, color: 'gray', fontSize: 12 }}>
                                                        {t("Input as number ( Date / Month / Year B.C. )")}
                                                    </FormHelperText>
                                                </LocalizationProvider>
                                                {ageStatus  && dateOfBirth != null &&
                                                    (<Typography color="error" align="left" sx={{ ml: 2, mt: 1, fontSize: 12 }}>
                                                        {t( "text_error_age_application")}
                                                    </Typography>
                                                    )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    id="email"
                                                    label={t("Email Address")}
                                                    placeholder={t("Email Address")!}
                                                    autoComplete="email"
                                                    {...register('email')}
                                                    error={errors.email ? true : false}
                                                />
                                                <FormHelperText sx={{ ml: 2, mt: 1, color: 'gray', fontSize: 12 }}>
                                                    {`${t("Such as")} email@mail.com`}
                                                </FormHelperText>
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.email?.message}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label={t("Create Password")}
                                                    placeholder={t("Password")!}
                                                    type="password"
                                                    id="password"
                                                    autoComplete="new-password"
                                                    {...register('password')}
                                                    error={errors.password ? true : false}
                                                />
                                                <FormHelperText id="password-helper-text" sx={{ ml: 2, mt: 1, color: 'gray', fontSize: 12 }}>
                                                    {t("Password length must be greater than 8 characters.")} <br />
                                                    {t("Including at least one number and one letter.")}
                                                </FormHelperText>
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.password?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label={t("Confirm Password")}
                                                    placeholder={t("Confirm Password")!}
                                                    type="password"
                                                    id="confirmPassword"
                                                    {...register('confirm_password')}
                                                    error={errors.confirm_password ? true : false}
                                                />
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                                                    {errors.confirm_password?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Container maxWidth="xs">
                                            <ThemeProvider theme={theme}>
                                                <Box sx={{ marginTop: 3, position: 'relative' }}>
                                                    <Button
                                                        disabled={registerLoading}
                                                        fullWidth
                                                        type="submit"
                                                        size="large"
                                                        variant="contained"
                                                        sx={{
                                                            mt: 2,
                                                            mb: 2,
                                                            color: registerLoading ? 'lightgray' : 'white',
                                                        }}
                                                        onClick={
                                                            // handleButtonClick()
                                                            handleSubmit(onSubmit)
                                                        }
                                                    >
                                                        {t("Sign Up")}
                                                    </Button>
                                                    {registerLoading && (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                color: green[500],
                                                                position: 'absolute',
                                                                top: '30%',
                                                                left: '47%',
                                                                zIndex: 999,
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </ThemeProvider>
                                        </Container>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <Link component={ReactRouterLink} to="/login" variant="body2" underline='none' sx={{ color: '#1565c0', pb: 3 }}>
                                                    {t("Already have an account? Sign in")}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Container>
                        </>
                    ) : (
                        <>
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ConfirmationDialogPDPA
                                    id="pdpa-consent"
                                    keepMounted
                                    open={openPDPA}
                                    onClose={handlepdpaConsentDialog}
                                    onCancel={() => navigate('/login')}
                                />
                            </Box>
                        </>
                    )
                }
            </ThemeProvider>
        </>
    );
}