// React
import { useEffect, useState } from 'react'
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams
} from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

// MUI Icons
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';

// Utils
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../../../styles/status.scss'
import { green } from '@mui/material/colors';

// Types
import { Status, TokenPayload } from '../../../@types';

// Services
import { editStatus, getStatusById } from '../../../services/StatusService';

const EditStatus = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const userRoleType = useAppSelector((state) => state.user.roletype);
  const [foundStatus, setFoundStatus] = useState<boolean>(false);

  const havePermission = userRoleType === 'Super Admin' ? true : false;

  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const allSuccessLoading = !loadingStatus;

  if (!havePermission) {
    navigate('/forbidden', { replace: true });
  }

  const [statusData, setStatusData] = useState<Status>({
    status_name: '',
    show_on_hr: '',
    show_on_applicant: '',
    is_active: true,
    color: '',
  });

  useEffect(() => {
    if (havePermission) {
      const fetchStatus = async () => {
        try {
          const status_id = id as string;

          const response = await getStatusById(status_id);

          if (response) {
            if (!response.data?.success) {
              Swal.fire({
                title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#43A047',
                confirmButtonText: 'OK!',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.assign('/admin/masterdata/status');
                }
              });
              return;
            }

            const statusData = response.data?.status;

            setStatusData((prevStatusData) => ({
              ...prevStatusData,
              is_active: statusData.is_active,
              status_name: statusData.status_name,
              show_on_hr: statusData.show_on_hr,
              show_on_applicant: statusData.show_on_applicant,
              color: statusData.color,
            }))
          }

        } catch (error) {
          navigate('/servererror', { replace: true });
        } finally {
          setLoadingStatus(false);
          setFoundStatus(true)
        }
      };

      fetchStatus();
    }
  }, [id])

  const formValidationSchema = yup.object().shape({
    status_name: yup.string().required(`${t("Status name is required")}`),
    show_on_hr: yup.string().required(`${t("Status Name for show on HR is required")}`),
    show_on_applicant: yup.string().required(`${t("Status Name for show on Applicant is required")}`),
    color: yup.string().required(`${t("Status color is required")}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidationSchema)
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setStatusData((prevData) => ({
        ...prevData,
        is_active: event.target.checked,
      }));
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setStatusData((prevData) => ({
        ...prevData,
        color: event.target.value,
      }));
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  }

  const onSubmit = () => {
    const postData = async () => {
      try {
        const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);
        setEditLoading(true);

        const updateData = {
          status_name: statusData.status_name,
          show_on_hr: statusData.show_on_hr,
          show_on_applicant: statusData.show_on_applicant,
          is_active: statusData.is_active,
          color: statusData.color,
          modified_by: parsedToken.Username
        }

        const status_id = id as string;
        const response = await editStatus(status_id, updateData);

        if (response) {
          setEditLoading(false);
          if (!response.data?.success) {
            toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (response.data?.success) {
            Swal.fire({
              title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#43A047',
              confirmButtonText: 'OK!',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/admin/masterdata/status');
              }
            })
          }
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    }

    postData();

  }

  return (
    <>
      <ToastContainer style={{ width: "400px" }} />

      {
        allSuccessLoading ? (<>
          {
            havePermission ? (
              foundStatus ? (
                <>
                  <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                    <Box display='flex' gap={3} sx={{ mb: 3 }}>
                      <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                        textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                          color: '#1976d2',
                        }
                      }}>
                        <ChevronLeftIcon fontSize='small' />
                        <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                      </Box>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link
                          underline="hover"
                          sx={{ display: 'flex', alignItems: 'center' }}
                          color="inherit"
                          component={ReactRouterLink}
                          to="/admin"
                        >
                          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                          {t("Dashboard")}
                        </Link>
                        <Link
                          underline="hover"
                          sx={{ display: 'flex', alignItems: 'center' }}
                          color="inherit"
                          component={ReactRouterLink}
                          to={'/admin/masterdata/status'}
                        >
                          <VerifiedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                          {t("Status")}
                        </Link>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                          color="text.primary"
                        >
                          <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                          {t("Edit Status")}
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                      {t("Edit Status")}
                    </Typography>
                  </Box>

                  <Box component="form" noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="statusName"
                          label={t("Status Name")}
                          {...register('status_name')}
                          error={errors.status_name ? true : false}
                          value={statusData.status_name}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setStatusData((prevData) => ({ ...prevData, status_name: event.target.value }));
                            errors.status_name = undefined
                          }}
                        />
                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                          {errors.status_name?.message}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="showOnHr"
                          label={t("Status Name for show on HR")}
                          {...register('show_on_hr')}
                          error={errors.show_on_hr ? true : false}
                          value={statusData.show_on_hr}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setStatusData((prevData) => ({ ...prevData, show_on_hr: event.target.value }));
                            errors.show_on_hr = undefined
                          }}
                        />
                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                          {errors.show_on_hr?.message}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="showOnApplicant"
                          label={t("Status Name for show on Applicant")}
                          {...register('show_on_applicant')}
                          error={errors.show_on_applicant ? true : false}
                          value={statusData.show_on_applicant}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setStatusData((prevData) => ({ ...prevData, show_on_applicant: event.target.value }));
                            errors.show_on_applicant = undefined
                          }}
                        />
                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                          {errors.show_on_applicant?.message}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <FormControlLabel
                          label={t("Active")}
                          control={
                            <Checkbox
                              checked={statusData.is_active}
                              onChange={handleChange}
                              inputProps={{ 'aria-label': 'Active' }}
                            />
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Typography>
                          {t("Status Colors")}
                        </Typography>

                        <Stack direction="row" spacing={0.5} sx={{ mt: 1 }} useFlexGap flexWrap="wrap">
                          <FormControl
                            defaultValue={statusData.color}
                            id='color'
                            {...register('color')}
                            sx={{ mr: 3 }}
                          >
                            <Stack direction="row" spacing={0.5} sx={{ mt: 1 }}>
                              <label className='blue-container' >
                                <input
                                  id='color'
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={'#03A9F4'}
                                  checked={statusData.color === '#03A9F4'}
                                />
                                <span id='color' className='blue-status' style={{ margin: 2 }}></span>
                              </label>

                              <label className='orange-container'>
                                <input
                                  id='color'
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={'#FFA726'}
                                  checked={statusData.color === '#FFA726'}
                                />
                                <span id='color' className='orange-status' style={{ margin: 2 }}></span>
                              </label>

                              <label className='green-container'>
                                <input
                                  id='color'
                                  type="checkbox"
                                  value={'#49AF4D'}
                                  checked={statusData.color === '#49AF4D'}
                                  onChange={handleCheck}
                                />
                                <span className='green-status' style={{ margin: 2 }}></span>
                              </label>

                              <label className='red-container'>
                                <input
                                  id='color'
                                  type="checkbox"
                                  value={'#F44336'}
                                  checked={statusData.color === '#F44336'}
                                  onChange={handleCheck}
                                />
                                <span className='red-status' style={{ margin: 2 }}></span>
                              </label>
                            </Stack>
                          </FormControl>

                          {(statusData.show_on_applicant === '' && statusData.show_on_hr === '') ?
                            (
                              <Box>
                                <Chip
                                  variant="outlined"
                                  sx={{
                                    color: 'gray',
                                    my: 1.5,
                                    mr: 3
                                  }}
                                  size='medium'
                                  label={t("Example")}
                                />

                                <Chip
                                  variant="outlined"
                                  sx={{
                                    color: 'gray',
                                    my: 1.5,
                                  }}
                                  size='medium'
                                  label={t("Example")}
                                />
                              </Box>
                            ) : (
                              <Box>
                                <Chip
                                  variant="outlined"
                                  sx={{
                                    bgcolor: statusData.color,
                                    borderColor: statusData.color,
                                    color: 'white',
                                    my: 1.5,
                                    mr: 3
                                  }}
                                  size='medium'
                                  label={statusData.show_on_applicant}
                                />

                                <Chip
                                  variant="outlined"
                                  sx={{
                                    bgcolor: statusData.color,
                                    borderColor: statusData.color,
                                    color: 'white',
                                    my: 1.5,
                                  }}
                                  size='medium'
                                  label={statusData.show_on_hr}
                                />
                              </Box>
                            )
                          }
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{ mt: 5, mb: 2, color: 'white' }}
                          onClick={handleSubmit(onSubmit)}
                          disabled={editLoading}
                        >
                          {t("Edit")}
                          {editLoading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                                position: 'absolute',
                                zIndex: 999,
                              }}
                            />
                          )}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          sx={{ mt: 5, mb: 2 }}
                          component={ReactRouterLink}
                          to="/admin/masterdata/status"
                        >
                          {t("Cancel")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (<>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t('No Data')}
                  </Typography>
                </Box>
              </>)
            ) : null
          }
        </>) : <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
            <CircularProgress />
          </Box>
        </>
      }
    </>
  )
}

export default EditStatus