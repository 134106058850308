import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

interface SubDistrict {
    id: number;
    name_th: string;
    name_en: string;
    geography_id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
}

interface District {
    id: number;
    name_th: string;
    name_en: string;
    province_id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
}

interface Province {
    external_code: string;
    province_code: string;
    label_th: string;
    label_en: string;
    is_active: boolean;
}

interface JobAppState {
    personalInfo: {
        prefix: string,
        first_name_th: string,
        last_name_th: string,
        first_name_en: string,
        last_name_en: string,
        nationality: string,
        religion: string,
        marital_status: string,
        gender: string,
        weight: string,
        height: string,
        date_of_birth: Date | null,
        id_card_number: string,
        password: string,
        newpassword:string,
        military_status: string,
        work_experience: string,
    },
    contactInfo: {
        telephone: string,
        email: string,
        line_id: string,
        emergency_name: string,
        emergency_phone: string,
        emergency_relation: string,
    },
    educationInfo: {
        education_level: string,
        education_level_other: string,
        education_school: string,
    },
    legalAddressInfo: {
        address_house_number: string,
        address_moo: string,
        address_lane: string,
        address_street: string,
        address_province: Province | null,
        address_district: District | null,
        address_sub_district: SubDistrict | null,
        address_postal_code: string,
    },
    mailingAddressInfo: {
        address_house_number: string,
        address_moo: string,
        address_lane: string,
        address_street: string,
        address_province: Province | null,
        address_district: District | null,
        address_sub_district: SubDistrict | null,
        address_postal_code: string,
        same_as_legal_address: boolean,
    },
    jobInfo: {
        start_date_of_work: Date | null;
    },
}

const tokenData = localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!) : ''

const initialState: JobAppState = {
    personalInfo: {
        prefix: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).Prefix : '',
        first_name_th: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).FNameTH : '',
        last_name_th: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).LNameTH : '',
        first_name_en: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).FNameEN : '',
        last_name_en: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).LNameEN : '',
        nationality: 'THA',
        religion: 'BDH',
        marital_status: '',
        gender:  tokenData && tokenData?.Prefix ?  (tokenData.Prefix === 'Miss' || tokenData.Prefix === 'Mrs' || tokenData.Prefix.indexOf('ญ') > 0 ) ? 'F' : 'M' : '', //fix Prefix
        weight: '',
        height: '',
        date_of_birth: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).DateOfBirth !== null ? jwtDecode<any>(localStorage.getItem('access_token')!).DateOfBirth : null : null,
        id_card_number: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).IDCardNumber : '',
        password:'',
        newpassword:'',
        military_status: '',
        work_experience: '',
    },
    contactInfo: {
        telephone: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).Telephone : '',
        email: localStorage.getItem('access_token') ? jwtDecode<any>(localStorage.getItem('access_token')!).Email !== null ? jwtDecode<any>(localStorage.getItem('access_token')!).Email : '' : '',
        line_id: '',
        emergency_name: '',
        emergency_phone: '',
        emergency_relation: '',
    },
    educationInfo: {
        education_level: '',
        education_level_other: '',
        education_school: '',
    },
    legalAddressInfo: {
        address_house_number: '',
        address_moo: '',
        address_lane: '',
        address_street: '',
        address_province: null,
        address_district: null,
        address_sub_district: null,
        address_postal_code: '',
    },
    mailingAddressInfo: {
        address_house_number: '',
        address_moo: '',
        address_lane: '',
        address_street: '',
        address_province: null,
        address_district: null,
        address_sub_district: null,
        address_postal_code: '',
        same_as_legal_address: false,
    },
    jobInfo: {
        start_date_of_work: null,
    },
};

// Reducer
const jobAppSlice = createSlice({
    name: 'jobapp',
    initialState,
    reducers: {
        editPrefix: (state, action) => {
            state.personalInfo.prefix = action.payload;
        },
        editFirstNameTH: (state, action) => {
            state.personalInfo.first_name_th = action.payload;
        },
        editLastNameTH: (state, action) => {
            state.personalInfo.last_name_th = action.payload;
        },
        editFirstNameEN: (state, action) => {
            state.personalInfo.first_name_en = action.payload;
        },
        editLastNameEN: (state, action) => {
            state.personalInfo.last_name_en = action.payload;
        },
        editNationality: (state, action) => {
            state.personalInfo.nationality = action.payload;
        },
        editReligion: (state, action) => {
            state.personalInfo.religion = action.payload;
        },
        editMaritalStatus: (state, action) => {
            state.personalInfo.marital_status = action.payload;
        },
        editGender: (state, action) => {
            state.personalInfo.gender = action.payload;
        },
        editWeight: (state, action) => {
            state.personalInfo.weight = action.payload;
        },
        editHeight: (state, action) => {
            state.personalInfo.height = action.payload;
        },
        editDateOfBirth: (state, action) => {
            state.personalInfo.date_of_birth = action.payload;
        },
        editIdCardNumber: (state, action) => {
            state.personalInfo.id_card_number = action.payload;
        },
        editPassWord: (state, action) => {
            state.personalInfo.password = action.payload;
        },
        editComfirmPassword: (state, action) => {
            state.personalInfo.newpassword = action.payload;
        },
        editPhoneNumber: (state, action) => {
            state.contactInfo.telephone = action.payload;
        },
        editEmail: (state, action) => {
            state.contactInfo.email = action.payload;
        },
        editLineID: (state, action) => {
            state.contactInfo.line_id = action.payload;
        },
        editEmergencyPerson: (state, action) => {
            state.contactInfo.emergency_name = action.payload;
        },
        editEmergencyPhone: (state, action) => {
            state.contactInfo.emergency_phone = action.payload;
        },
        editRelationship: (state, action) => {
            state.contactInfo.emergency_relation = action.payload;
        },
        editeducation_level: (state, action) => {
            state.educationInfo.education_level = action.payload;
        },
        editeducation_levelOther: (state, action) => {
            state.educationInfo.education_level_other = action.payload;
        },
        editSchool: (state, action) => {
            state.educationInfo.education_school = action.payload;
        },
        editMilitaryStatus: (state, action) => {
            state.personalInfo.military_status = action.payload;
        },
        editLegalAddressHouseNo: (state, action) => {
            state.legalAddressInfo.address_house_number = action.payload;
        },
        editLegalAddressVillageNo: (state, action) => {
            state.legalAddressInfo.address_moo = action.payload;
        },
        editLegalAddressAlley: (state, action) => {
            state.legalAddressInfo.address_lane = action.payload;
        },
        editLegalAddressRoad: (state, action) => {
            state.legalAddressInfo.address_street = action.payload;
        },
        editLegalAddressProvince: (state, action) => {
            state.legalAddressInfo.address_province = action.payload;
        },
        editLegalAddressDistrict: (state, action) => {
            state.legalAddressInfo.address_district = action.payload;
        },
        editLegalAddressSubDistrict: (state, action) => {
            state.legalAddressInfo.address_sub_district = action.payload;
        },
        editLegalAddressZipCode: (state, action) => {
            state.legalAddressInfo.address_postal_code = action.payload;
        },
        editMailingAddressHouseNo: (state, action) => {
            state.mailingAddressInfo.address_house_number = action.payload;
        },
        editMailingAddressVillageNo: (state, action) => {
            state.mailingAddressInfo.address_moo = action.payload;
        },
        editMailingAddressAlley: (state, action) => {
            state.mailingAddressInfo.address_lane = action.payload;
        },
        editMailingAddressRoad: (state, action) => {
            state.mailingAddressInfo.address_street = action.payload;
        },
        editMailingAddressProvince: (state, action) => {
            state.mailingAddressInfo.address_province = action.payload;
        },
        editMailingAddressDistrict: (state, action) => {
            state.mailingAddressInfo.address_district = action.payload;
        },
        editMailingAddressSubDistrict: (state, action) => {
            state.mailingAddressInfo.address_sub_district = action.payload;
        },
        editMailingAddressZipCode: (state, action) => {
            state.mailingAddressInfo.address_postal_code = action.payload;
        },
        editMailingSameAsLegalAddress: (state, action) => {
            state.mailingAddressInfo.same_as_legal_address = action.payload;
        },
        editStartDateOfWork: (state, action) => {
            state.jobInfo.start_date_of_work = action.payload;
        },
        editWorkExperience: (state, action) => {
            state.personalInfo.work_experience = action.payload;
        }
    },
});

export const {
    editPrefix,
    editFirstNameTH,
    editLastNameTH,
    editFirstNameEN,
    editLastNameEN,
    editNationality,
    editReligion,
    editMaritalStatus,
    editGender,
    editWeight,
    editHeight,
    editDateOfBirth,
    editIdCardNumber,
    editPassWord,
    editComfirmPassword,
    editPhoneNumber,
    editEmail,
    editLineID,
    editEmergencyPerson,
    editEmergencyPhone,
    editRelationship,
    editeducation_level,
    editeducation_levelOther,
    editSchool,
    editMilitaryStatus,
    editLegalAddressHouseNo,
    editLegalAddressVillageNo,
    editLegalAddressAlley,
    editLegalAddressRoad,
    editLegalAddressProvince,
    editLegalAddressDistrict,
    editLegalAddressSubDistrict,
    editLegalAddressZipCode,
    editMailingAddressHouseNo,
    editMailingAddressVillageNo,
    editMailingAddressAlley,
    editMailingAddressRoad,
    editMailingAddressProvince,
    editMailingAddressDistrict,
    editMailingAddressSubDistrict,
    editMailingAddressZipCode,
    editMailingSameAsLegalAddress,
    editStartDateOfWork,
    editWorkExperience
} = jobAppSlice.actions;
export default jobAppSlice;