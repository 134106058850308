// React
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

// MUI Components
import { Autocomplete, Badge, Box, Button, Card, Checkbox, Chip, CircularProgress, ClickAwayListener, Fade, FormControl, Grid, Icon, IconButton, Paper, Popper, PopperPlacementType, TextField, Tooltip, Typography } from '@mui/material';
import { PickersShortcutsItem } from '@mui/x-date-pickers';
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";

// @MUI Icons
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// Types
import { Site } from '../../../@types';

// Utils
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

// Services
import { getAverageDashboardData } from '../../../services/DashboardService';
import { getAllSites } from '../../../services/SiteService';

const AverageDashboard = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [siteData, setSiteData] = useState<Site[]>([]);

    const [averageDashboardData, setAverageDashboardData] = useState<any>(null);
    const [selectedSites, setSelectedSites] = useState<Site[]>([]);
    const [loadingSites, setLoadingSites] = useState<boolean>(true);
    const [loadingAverageDashboard, setLoadingAverageDashboard] = useState<boolean>(true);
    const [countFilterAverageDashboardSelected, setCountFilterAverageDashboardSelected] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [placement, setPlacement] = useState<PopperPlacementType>();
    const [filterAverageDashboardOpen, setFilterAverageDashboardOpen] = useState<boolean>(false);

    const handleFilterAverageDashboardOpenClick =
        (newPlacement: PopperPlacementType) =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
                setFilterAverageDashboardOpen((prev) => placement !== newPlacement || !prev);
                setPlacement(newPlacement);
            };

    useEffect(() => {
        const fetchAverageDashboard = async () => {
            setLoadingAverageDashboard(true);

            const response = await getAverageDashboardData({
                site_ids: selectedSites?.map((site: Site) => site.site_id).length !== 0 ? selectedSites?.map((site: Site) => site.site_id) : undefined,
            });

            if (response.data?.success) {
                setLoadingAverageDashboard(false);
                setAverageDashboardData(response.data?.data);
            }
        };

        fetchAverageDashboard();
    }, [selectedSites]);

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data?.success) {
                    setSiteData(response.data?.sites);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        const siteSorting = async () => {
            try {
                siteData.sort((a, b) => a.site_id.localeCompare(b.site_id));
                setTimeout(() => {
                    setLoadingSites(false);
                }, 500);
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        fetchSites();
        siteSorting();

    }, []);

    useEffect(() => {
        let count = 0;
        if (selectedSites && selectedSites.length > 0) count++;
        setCountFilterAverageDashboardSelected(count);
    }, [selectedSites])

    const handleClickAwayAverageDashboardFilter = () => {
        setFilterAverageDashboardOpen(false);
    };

    const styleCard = {
        borderRadius: '0.5rem', height: '100%', p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        // ':hover': {
        //   boxShadow: 4, cursor: 'pointer', transition: 'all 0.2s ease-in-out',
        // },
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1, marginTop: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("Information of average")}
                </Typography>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: 'fit-content(100%) 2fr' },
                    gridTemplateRows: { xs: 'fit-content(100%) repeat(1, 1fr)' },
                }}>
                    <Box sx={{ gridRow: { xs: 2, sm: 1 }, gridColumn: { sm: 3 } }}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '50% 50%', sm: 'repeat(1, 1fr)' } }}>
                            <Box sx={{
                                py: 1, display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end' }, '&:hover': {
                                    cursor: 'pointer',
                                }, gridColumn: 1
                            }}>
                                <Button onClick={handleFilterAverageDashboardOpenClick('bottom-end')}>
                                    <Badge badgeContent={countFilterAverageDashboardSelected} color="primary">
                                        <Icon >filter_list</Icon>
                                    </Badge>
                                    <Typography sx={{ userSelect: 'none' }}>
                                        {t("Filter")}
                                    </Typography>
                                </Button>

                                <Popper open={filterAverageDashboardOpen} anchorEl={anchorEl} placement={placement} transition>
                                    {({ TransitionProps }) => (
                                        <ClickAwayListener onClickAway={handleClickAwayAverageDashboardFilter}>
                                            <Fade {...TransitionProps} timeout={50}>
                                                <Paper sx={{ width: '400px', display: 'flex', flexDirection: 'column', py: 1, px: 3, border: '1px solid #e0e0e0' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Box sx={{ width: '100%' }}>
                                                            <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterBySite")}</Typography>
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    onChange={(_, newValue: Site[]) => {
                                                                        setSelectedSites(newValue);
                                                                    }}
                                                                    multiple
                                                                    disableCloseOnSelect
                                                                    limitTags={1}
                                                                    id="site"
                                                                    options={
                                                                        siteData
                                                                    }
                                                                    size="small"
                                                                    loading={loadingSites}
                                                                    loadingText={`${t("Loading")}...`}
                                                                    getOptionLabel={(option) => option.site_name}
                                                                    value={selectedSites}
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox
                                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option.site_name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label={loadingSites ? `${t("Loading")}...` : t("Site")}
                                                                            placeholder={t("Site")!}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {loadingSites ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }} />
                                                                    )}
                                                                    renderTags={(value: any, getTagProps) =>
                                                                        value.map((option: Site, index: number) => (
                                                                            <Chip
                                                                                variant="outlined"
                                                                                label={t(option.site_name)}
                                                                                {...getTagProps({ index })}
                                                                            />
                                                                        ))
                                                                    }
                                                                    noOptionsText={t("No options found")}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Tooltip title={t("Clear")}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ height: 'fit-content', ml: 1 }}
                                                                onClick={() => {
                                                                    setSelectedSites([]);
                                                                }}
                                                            >
                                                                <Icon>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                                        <Button
                                                            sx={{
                                                                fontSize: 13,
                                                            }}
                                                            onClick={() => {
                                                                setSelectedSites([]);
                                                                setFilterAverageDashboardOpen(false);
                                                            }}
                                                        >
                                                            {t('RemoveAllFilters')}
                                                        </Button>
                                                    </Box>
                                                </Paper>
                                            </Fade>
                                        </ClickAwayListener>
                                    )}
                                </Popper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <PeopleAltIcon sx={{ color: '#42A5F5', fontSize: '2rem', backgroundColor: '#E3F2FD', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average numbers of applicant per position')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_person_apply_per_job}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('people/position')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <PeopleAltIcon sx={{ color: '#42A5F5', fontSize: '2rem', backgroundColor: '#E3F2FD', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average numbers of interview per position')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_interview_amount_per_job}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('people/position')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <PeopleAltIcon sx={{ color: '#42A5F5', fontSize: '2rem', backgroundColor: '#E3F2FD', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average numbers of passed interview per position')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_interview_pass_per_job}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('people/position')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <AccessTimeFilledIcon sx={{ color: '#3EE845', fontSize: '2rem', backgroundColor: '#e4f2e4', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average time taken from job application to interview results')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_apply_to_result_status_duration}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('day')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <AccessTimeFilledIcon sx={{ color: '#FFA726', fontSize: '2rem', backgroundColor: '#faefe1', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average time in status')} "{t('Applied')}"
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_apply_status_duration}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('day')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <AccessTimeFilledIcon sx={{ color: '#FFA726', fontSize: '2rem', backgroundColor: '#faefe1', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average time in status')} "{t('Under Considering')}"
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_considered_status_duration}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('day')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <AccessTimeFilledIcon sx={{ color: '#FFA726', fontSize: '2rem', backgroundColor: '#faefe1', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Average time in status')} "{t('Interviewing')}"
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingAverageDashboard ? '...' : averageDashboardData?.average_interview_status_duration}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('day')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default AverageDashboard