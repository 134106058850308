import { PickersShortcutsItem } from "@mui/x-date-pickers";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";
import dayjs, { Dayjs } from "dayjs";

const pastShortcutsItemsTH: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'วันนี้',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            return [start_today, end_today];
        },
    },
    {
        label: 'เมื่อวานนี้',
        getValue: () => {
            const today = dayjs();
            const yesterday = today.subtract(1, 'day');
            return [yesterday.startOf('day'), yesterday.endOf('day')];
        },
    },
    {
        label: 'สัปดาห์นี้',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'สัปดาห์ที่แล้ว',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: '7 วันที่ผ่านมา',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            const prevWeek = start_today.subtract(7, 'day');
            return [prevWeek, end_today];
        },
    },
    {
        label: '30 วันที่ผ่านมา',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            const prevMonth = start_today.subtract(30, 'day');
            return [prevMonth, end_today];
        },
    },
    {
        label: 'เดือนนี้',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'เดือนที่แล้ว',
        getValue: () => {
            const today = dayjs();
            const prevMonth = today.subtract(1, 'month');
            return [prevMonth.startOf('month'), prevMonth.endOf('month')];
        },
    },
];

const pastShortcutsItemsEN: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'Today',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            return [start_today, end_today];
        },
    },
    {
        label: 'Yesterday',
        getValue: () => {
            const today = dayjs();
            const yesterday = today.subtract(1, 'day');
            return [yesterday.startOf('day'), yesterday.endOf('day')];
        },
    },
    {
        label: 'This week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Last week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 days',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            const prevWeek = start_today.subtract(7, 'day');
            return [prevWeek, end_today];
        },
    },
    {
        label: 'Last 30 days',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            const prevMonth = start_today.subtract(30, 'day');
            return [prevMonth, end_today];
        },
    },
    {
        label: 'This month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Last month',
        getValue: () => {
            const today = dayjs();
            const prevMonth = today.subtract(1, 'month');
            return [prevMonth.startOf('month'), prevMonth.endOf('month')];
        },
    },
];

const futureShortcutsItemsTH: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'วันนี้',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            return [start_today, end_today];
        },
    },
    {
        label: 'พรุ่งนี้',
        getValue: () => {
            const today = dayjs();
            const tomorrow = today.add(1, 'day');
            return [tomorrow.startOf('day'), tomorrow.endOf('day')];
        },
    },
    {
        label: 'สัปดาห์นี้',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'สัปดาห์หน้า',
        getValue: () => {
            const today = dayjs();
            const nextWeek = today.add(7, 'day');
            return [nextWeek.startOf('week'), nextWeek.endOf('week')];
        },
    },
    {
        label: '7 วันหน้า',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const nextWeek = start_today.add(7, 'day');
            return [start_today, nextWeek.endOf('day')];
        },
    },
    {
        label: '30 วันหน้า',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const nextMonth = start_today.add(30, 'day');
            return [start_today, nextMonth.endOf('day')];
        },
    },
    {
        label: 'เดือนนี้',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'เดือนหน้า',
        getValue: () => {
            const today = dayjs();
            const nextMonth = today.add(1, 'month');
            return [nextMonth.startOf('month'), nextMonth.endOf('month')];
        },
    },
];

const futureShortcutsItemsEN: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'Today',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const end_today = dayjs().endOf('day');
            return [start_today, end_today];
        },
    },
    {
        label: 'Tomorrow',
        getValue: () => {
            const today = dayjs();
            const tomorrow = today.add(1, 'day');
            return [tomorrow.startOf('day'), tomorrow.endOf('day')];
        },
    },
    {
        label: 'This week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
        },
    },
    {
        label: 'Next week',
        getValue: () => {
            const today = dayjs();
            const nextWeek = today.add(7, 'day');
            return [nextWeek.startOf('week'), nextWeek.endOf('week')];
        },
    },
    {
        label: 'Next 7 days',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const nextWeek = start_today.add(7, 'day');
            return [start_today, nextWeek.endOf('day')];
        },
    },
    {
        label: 'Next 30 days',
        getValue: () => {
            const start_today = dayjs().startOf('day');
            const nextMonth = start_today.add(30, 'day');
            return [start_today, nextMonth.endOf('day')];
        },
    },
    {
        label: 'This month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Next month',
        getValue: () => {
            const today = dayjs();
            const nextMonth = today.add(1, 'month');
            return [nextMonth.startOf('month'), nextMonth.endOf('month')];
        },
    },
];

export const pastShortcutsItems = {
    th: pastShortcutsItemsTH,
    en: pastShortcutsItemsEN
}

export const futureShortcutsItems = {
    th: futureShortcutsItemsTH,
    en: futureShortcutsItemsEN
}