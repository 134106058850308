// React
import React from "react";

// Components
import JobDashboard from "./JobDashboard";
import InterviewDashboard from "./InterviewDashboard";
import AverageDashboard from "./AverageDashboard";
import NewApplyDailyGraphDashboard from "./NewApplyDailyGraphDashboard";
import InterviewGraphDashboard from "./InterviewGraphDashboard";

// MUI Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Utils
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  return <div>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
        {t("Dashboard")}
      </Typography>
    </Box>

    <Box sx={{ width: '100%', marginTop: 2, paddingX: 3 }}>
      <JobDashboard />
      <InterviewDashboard />
      <AverageDashboard />
      <NewApplyDailyGraphDashboard />
      <InterviewGraphDashboard />
    </Box>
  </div>;
};

export default Dashboard;
