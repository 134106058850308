// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import VerifyPassword from './VerifyOTP';
import CreateNewPassword from './CreateNewPassword';

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from "@mui/material"

// Utils
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

// Styles
import theme from '../../../styles/theme';

// Services
import { checkIfCorrectUserMobile, checkIfUserExist } from '../../../services/UserService';
import { sendOTP } from '../../../services/AuthService';

const ForgotPassword: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [inputIdCardNumber, setInputIdCardNumber] = useState<string>('');
  const [idCardNumberBlank, setIdCardNumberBlank] = useState<boolean>(false);
  const [idCardNumberIsExist, setIdCardNumberIsExist] = useState<boolean>(false);
  const [foundUser, setFoundUser] = useState<boolean>(false);

  const [phonenumber, setPhonenumber] = useState<string>('');
  const [phonenumberBlank, setPhonenumberBlank] = useState<boolean>(false);
  const [phonenumberIsExist, setPhonenumberIsExist] = useState<boolean>(false);

  const [otpStatus, setOtpStatus] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  const onSubmit_IdCardNumber = async () => {
    if (inputIdCardNumber.length === 0) {
      setFoundUser(false)
      setIdCardNumberBlank(true)
      setIdCardNumberIsExist(false)
      return null;
    }

    if (idCardNumberBlank === false && inputIdCardNumber !== '') {
      try {
        const data = {
          username: inputIdCardNumber
        }
        const response = await checkIfUserExist(data);

        if (response.data?.success && response.data?.isExist) {
          setFoundUser(true)
          setIdCardNumberIsExist(false)
        }
        else if (response.data?.success && !response.data?.isExist) {
          setFoundUser(false)
          setIdCardNumberIsExist(true)
        }
        else {
          setFoundUser(false)
          toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        navigate('/server', { replace: true });
      }
    }
  };

  const handleIdCardNumChange = (event: any) => {
    setInputIdCardNumber(event.target.value);

    if (idCardNumberBlank) {
      setIdCardNumberBlank(false)
    }
    else if (idCardNumberIsExist) {
      setIdCardNumberIsExist(false)
    }
  };

  const handleTelephoneChange = (event: any) => {
    setPhonenumber(event.target.value)
    if (phonenumberBlank) {
      setPhonenumberBlank(false)
    }
    else if (phonenumberIsExist) {
      setPhonenumberIsExist(false)
    }
  }

  const onSubmitPhonenumber = async () => {
    if (phonenumber.length === 0) {
      setPhonenumberBlank(true)
      setPhonenumberIsExist(false)
      return null;
    }

    const sendPhoneNumber = async () => {
      try {
        const data = {
          mobile_number: phonenumber,
        }
        const response = await sendOTP(data);

        if (response.data?.success) {
          setOtpStatus(true)
        }

      } catch (error) {
        navigate('/server', { replace: true });
      }
    }

    if (phonenumberBlank === false && phonenumber !== '') {
      try {
        const data = {
          mobile_number: phonenumber,
          username: inputIdCardNumber,
        }
        const response = await checkIfCorrectUserMobile(data);

        if (response.data?.success) {
          setPhonenumberIsExist(false)
          sendPhoneNumber();
        }
        else if (!response.data?.success) {
          setPhonenumberIsExist(true)
        }
      } catch (error) {
        navigate('/server', { replace: true });
      }
    }
  }

  return <div>
    <ThemeProvider theme={theme}>
      <ToastContainer style={{ width: "400px" }} />
      {!otpStatus ? (
        <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box marginY={7} sx={{ display: 'flex', flexDirection: 'column', width: '400px', }}>
            <Typography component="h1" variant="h6" align="left" sx={{ fontWeight: 'bold', letterSpacing: 0.5 }}>
              {t("Reset your password")}
            </Typography>
            {!foundUser && (<>
              <Typography align="left" sx={{ fontSize: 15, letterSpacing: 0.5 }} my={1} >
                {t("Enter your identification card number to reset your password.")}
              </Typography>
              <Box component="form" sx={{ mt: 1 }}>
                <TextField
                  autoFocus
                  fullWidth
                  color="info"
                  id="username"
                  margin="normal"
                  label={t("ID card number")}
                  placeholder={t("ID card number")!}
                  onChange={handleIdCardNumChange}
                  inputProps={{ maxLength: 13 }}
                  error={idCardNumberBlank || idCardNumberIsExist ? true : false}
                />
                {idCardNumberBlank && (
                  <Typography color="error" align="left" fontSize={14} sx={{ letterSpacing: 0.5 }}>
                    {t("ID card number is required")}
                  </Typography>
                )}
                {idCardNumberIsExist && (
                  <Typography color="error" align="left" fontSize={14} sx={{ letterSpacing: 0.5 }}>
                    {t("Not found a user with this id card number.")}
                  </Typography>
                )}
              </Box>
              <Box>
                <Button fullWidth size="medium" variant="contained" sx={{ mt: 6, minWidth: 170, }}
                  onClick={onSubmit_IdCardNumber}
                >
                  {t("Next")}
                </Button>
                <Typography marginTop={3} fontSize={14}>
                  <Link href="login" underline='none' sx={{ color: '#1565c0', fontSize: 15 }}>
                    {t("Remember password? Back to Sign in.")}
                  </Link>
                </Typography>
              </Box>
            </>)}

            {foundUser && !otpStatus && (<>
              <Typography align="left" sx={{ fontSize: 15, letterSpacing: 0.5 }} my={1} >
                {t("Enter your phone number to verify your identity")}
              </Typography>
              <Box component="form" sx={{ mt: 1 }}>
                <TextField
                  autoFocus
                  fullWidth
                  color="info"
                  error={phonenumberBlank || phonenumberIsExist ? true : false}
                  id="phone-number"
                  label={t("Phone Number")}
                  placeholder={t("Phone Number")!}
                  size="medium"
                  margin="normal"
                  onChange={handleTelephoneChange}
                />
                {phonenumberBlank && (
                  <Typography color="error" align="left" fontSize={14} sx={{ letterSpacing: 0.5 }}>
                    {t("Phone number is required")}
                  </Typography>
                )}
                {phonenumberIsExist && (
                  <Typography color="error" align="left" fontSize={14} sx={{ letterSpacing: 0.5 }}>
                    {t("Incorrect mobile number")}
                  </Typography>
                )}
              </Box>
              <Box>
                <Button fullWidth size="medium" variant="contained" sx={{ mt: 6, minWidth: 170, }}
                  onClick={onSubmitPhonenumber}
                >
                  {t("Next")}
                </Button>
                <Typography marginTop={3} fontSize={14}>
                  <Link href="login" underline='none' sx={{ color: '#1565c0', fontSize: 15 }}>
                    {t("Remember password? Back to Sign in.")}
                  </Link>
                </Typography>
              </Box>
            </>)}
          </Box>
        </Container>
      ) : (
        otpStatus && !resetPassword && (
          <VerifyPassword phonenumber={phonenumber} messageStatus={setResetPassword} />
        )
      )}
      {
        resetPassword && (<CreateNewPassword idCardNumber={inputIdCardNumber} />)
      }
    </ThemeProvider>
  </div>;
}

export default ForgotPassword;