import { Box, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import AdminAppbar from "../appbar/AdminAppbar";
import AdminSidebar from "../../pages/admin/AdminSidebar";

const AdminLayout = () => {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AdminAppbar />
                <AdminSidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </>
    )
}

export default AdminLayout