// React
import React, { useState, useEffect } from 'react'
import {
    Link,
    useNavigate,
    useSearchParams
} from 'react-router-dom';

// Hooks and Redux'
import { useAppSelector } from '../../../redux/hooks';
import useDebounce from '../../../hooks/useDebounce';

// Components 
import JobCard from './JobCard';
import usePagination from '../../../components/common/Pagination';

// MUI Components
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

import {
    GridCellParams,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueGetterParams
} from '@mui/x-data-grid';

import {
    DataGridPro,
    enUS
} from '@mui/x-data-grid-pro';

// MUI Icons
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';

// Utils
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import dayjs from 'dayjs';

// Styles
import theme from '../../../styles/theme';

// Types
import { thTHGrid } from '../../../@types/DataGridLocaleText';
import { Job, Site } from '../../../@types';

// Services
import { queryJobs } from '../../../services/JobService';
import { getAllSites } from '../../../services/SiteService';

const JobLists = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { rolesite } = useAppSelector(state => state.user)
    const [jobData, setJobData] = useState<Job[]>([])
    const [siteData, setSiteData] = useState<Site[]>([]);
    const [selectedSites, setSelectedSites] = useState<Site | null>(null);
    const [loadingSite, setLoadingSite] = useState<boolean>(true);
    const [searchLoading, setSearchLoading] = useState<boolean>(true);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [jobDisplayType, setJobDisplayType] = useState<string>('table');

    let [page, setPage] = useState(1)
    const PER_PAGE = 6
    const count = Math.ceil(jobData.length / PER_PAGE)
    const DATA = usePagination(jobData, PER_PAGE)

    const handleChange = (e: React.ChangeEvent<unknown>, p: number) => {
        setPage(p)
        DATA.jump(p)
    }

    useEffect(() => {
        if (searchParams.has('display')) {
            setJobDisplayType(searchParams.get('display')!);
        } else {
            setJobDisplayType('table');
            navigate('/admin/jobs?display=table', { replace: true });
        }
    }, [searchParams])

    const handleJobDisplayType = (
        _: React.MouseEvent<HTMLElement>,
        newDisplayType: string | null,
    ) => {
        if (newDisplayType !== null) {
            setJobDisplayType(newDisplayType);
            navigate(`/admin/jobs?display=${newDisplayType}`, { replace: true });
        }
    };

    const JobsContent = jobData?.length
        ? DATA.currentData().map((job: Job) => <JobCard key={job.id} jobData={job} />)
        : null

    const debouncedSearchQuery = useDebounce(searchQuery, 500);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'edit',
            headerName: `${t('Edit')}`,
            headerAlign: 'center',
            width: 50,
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Link to={`/admin/jobs/edit/${params.row.id}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
                        <Tooltip title={t("Edit")}>
                            <Icon fontSize="medium">edit</Icon>
                        </Tooltip>
                    </Link>
                );
            },
        },
        {
            field: 'view',
            headerName: `${t('View')}`,
            headerAlign: 'center',
            width: 50,
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Link to={`/admin/jobs/detail/${params.row.id}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
                        <Tooltip title={t("View")}>
                            <Icon fontSize="medium">visibility</Icon>
                        </Tooltip>
                    </Link>
                );
            }
        },
        {
            field: 'job_name', headerName: `${t("Position")}`, width: 150,
            valueGetter: (params: GridValueGetterParams) => params.row.job_name
        },
        {
            field: 'company', headerName: `${t("Job Company")}`, width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.company
        },
        {
            field: 'num_openings', headerName: `${t("Number of openings")}`, width: 100,
            valueGetter: (params: GridValueGetterParams) => params.row.num_openings
        },
        {
            field: 'start_date', headerName: `${t("Start Date")}`, width: 150, type: 'string',
            valueGetter: (params: GridValueGetterParams) => {
                const date = dayjs(params.row.start_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
                const time = dayjs(params.row.start_date).format('HH:mm')
                return date + ' ' + time
            },
            renderCell: (params: GridCellParams) => {
                const date = dayjs(params.row.start_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
                const time = dayjs(params.row.start_date).format('HH:mm')
                return date + ' ' + time
            },
        },
        {
            field: 'end_date', headerName: `${t("End Date")}`, width: 150, type: 'string',
            valueGetter: (params: GridValueGetterParams) => {
                const date = dayjs(params.row.end_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
                const time = dayjs(params.row.end_date).format('HH:mm')
                return date + ' ' + time
            },
            renderCell: (params: GridCellParams) => {
                const date = dayjs(params.row.end_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
                const time = dayjs(params.row.end_date).format('HH:mm')
                return date + ' ' + time
            },
        },
        {
            field: 'contract_signer', headerName: `${t("Contract Signer")}`, width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.contract_signer
        },
        {
            field: 'hr_name', headerName: `${t("HR Recruiter Name")}`, width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.hr_name
        },
        {
            field: 'site', headerName: `${t("Site")}`, width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.Site.site_name
        },
        {
            field: 'is_active',
            headerName: `${t("Status")}`,
            width: 150,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params: GridCellParams) =>
                params.row.is_active === true ? (
                    <Chip variant="outlined" color="success" label={t("Job's opening")} size="small" />
                ) : (
                    <Chip variant="outlined" color="error" label={t("Job's closed")} size="small" />
                ),
            valueGetter: (params: GridValueGetterParams) => params.row.is_active
        },
        {
            field: 'created_date', headerName: `${t("Created date")}`, width: 150, type: 'string',
            valueGetter: (params: GridValueGetterParams) => {
                const date = dayjs(params.row.created_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
                const time = dayjs(params.row.created_date).format('HH:mm')
                return date + ' ' + time
            },
            renderCell: (params: GridCellParams) => {
                const date = dayjs(params.row.created_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
                const time = dayjs(params.row.created_date).format('HH:mm')
                return date + ' ' + time
            },
        }
    ];

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                setSearchLoading(true);

                const queryData = {
                    rolesite: rolesite,
                    is_active: true,
                    site_id: selectedSites?.site_id,
                    search: debouncedSearchQuery,
                }
                const response = await queryJobs(queryData);

                if (response.data?.success) {
                    const jobsData = response.data?.jobs;
                    setJobData(jobsData);
                }

            } catch (error) {
                navigate('/servererror', { replace: true });
            } finally {
                setTimeout(() => {
                    setSearchLoading(false);
                    setIsSearching(false);
                }, 500);
            }
        };

        if (debouncedSearchQuery !== '') {
            setIsSearching(true);
            fetchJobs();
        } else {
            setSearchQuery('');
            fetchJobs();
        }
    }, [debouncedSearchQuery, selectedSites, jobDisplayType]);

    useEffect(() => {
        if (searchQuery !== '') {
            setIsSearching(true);
            setJobData([]);
        }
    }, [searchQuery])

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data?.success) {
                    const sitesData = response.data?.sites;
                    setSiteData(sitesData.filter((site: Site) => rolesite.includes(site.site_id)));
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        const siteSorting = async () => {
            try {
                siteData.sort((a, b) => a.site_id.localeCompare(b.site_id));
                setTimeout(() => {
                    setLoadingSite(false);
                }, 500);
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        fetchSites();
        siteSorting();

    }, [navigate]);

    return (
        <div>
            <ToastContainer />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("JobsList")}
                </Typography>
            </Box>

            <Box sx={{ width: '100%', marginTop: 2, paddingX: 3 }}>
                <Box>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={9}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    label={t("Search")}
                                    placeholder={t("Search by Job position's name, Company's name")!}
                                    value={searchQuery}
                                    onChange={((event) => {
                                        setSearchQuery(event.target.value);
                                        DATA.start()
                                        setPage(1)
                                    })}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon fontSize="medium">search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: searchQuery.length > 0 && (
                                            <InputAdornment position="end">
                                                <IconButton size="small" edge="end" onClick={() => setSearchQuery('')}>
                                                    <Icon>clear</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} display='flex' justifyContent='center'>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue: Site | null) => {
                                        setSelectedSites(newValue);
                                    }}
                                    limitTags={1}
                                    id="site"
                                    options={
                                        siteData
                                    }
                                    getOptionLabel={(option) => option.site_name}
                                    value={selectedSites}
                                    renderOption={(props, option, { inputValue, selected }) => {
                                        const matches = match(option.site_name, inputValue, { insideWords: true });
                                        const parts = parse(option.site_name, matches);

                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part: { highlight: boolean; text: string }, index: number) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontWeight: part.highlight ? 700 : 400,
                                                            }}
                                                        >

                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label={loadingSite ? `${t("Loading")}...` : t("Site")}
                                            placeholder={loadingSite ? `${t("Loading")}...` : t("Site")!}
                                        />
                                    )}
                                    loading={loadingSite}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ marginTop: 3 }}>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: 'fit-content(100%) 2fr' },
                        gridTemplateRows: { xs: 'fit-content(100%) repeat(1, 1fr)' },
                        marginBottom: 2,
                    }}>
                        <Box sx={{ gridRow: 1, gridColumn: 1, display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: { xs: 18, sm: 20 } }}>
                                {t("Jobs Available")} {searchLoading || isSearching ? '...' : jobData?.length} {t("PositionSuffix")}
                            </Typography>
                        </Box>
                        <Box sx={{ gridRow: { xs: 2, sm: 1 }, gridColumn: { sm: 3 }, mt: { xs: 1 } }}>
                            <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '50% 50%', sm: 'repeat(1, 1fr)' } }}>
                                <Stack sx={{ gridColumn: 2, justifyContent: { xs: 'flex-end' } }} direction="row" spacing={4}>
                                    <ToggleButtonGroup
                                        value={jobDisplayType}
                                        exclusive
                                        onChange={handleJobDisplayType}
                                        aria-label="job display type"
                                    >
                                        <ToggleButton value="grid" aria-label="grid">
                                            <Tooltip title="Grid">
                                                <GridViewIcon />
                                            </Tooltip>
                                        </ToggleButton>

                                        <ToggleButton value="table" aria-label="table">
                                            <Tooltip title="Table">
                                                <TableViewIcon />
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </Box>

                        </Box>
                    </Box>

                    {
                        isSearching && !searchLoading ?
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                                <Typography align='center'>{t("Searching")}...</Typography>
                            </Box>
                            : null
                    }

                    {
                        searchLoading ?
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                                <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box>
                            </Box>
                            : <>
                                {
                                    !isSearching && !searchLoading ?
                                        jobData.length > 0 ?
                                            jobDisplayType === 'grid' ?
                                                <>
                                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} >
                                                        {JobsContent}
                                                    </Grid>
                                                </>
                                                : <>
                                                    <Box display='inline-grid' sx={{ width: '100%' }}>
                                                        <DataGridPro
                                                            getRowId={(row) => row.id}
                                                            rows={jobData}
                                                            columns={columns}
                                                            pagination
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { page: 0, pageSize: 10 },
                                                                },
                                                            }}
                                                            pageSizeOptions={[10, 20, 30, 40, 50]}
                                                            disableRowSelectionOnClick
                                                            sx={{
                                                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                                                    outline: 'none !important',
                                                                },
                                                                fontSize: 13,
                                                            }}
                                                            slots={{
                                                                toolbar: CustomToolbar,
                                                            }}
                                                            slotProps={{
                                                                filterPanel: {
                                                                    filterFormProps: {
                                                                        logicOperatorInputProps: {
                                                                            variant: 'outlined',
                                                                            size: 'small',
                                                                        },
                                                                        columnInputProps: {
                                                                            variant: 'outlined',
                                                                            size: 'small',
                                                                            sx: { mt: 'auto' },
                                                                        },
                                                                        operatorInputProps: {
                                                                            variant: 'outlined',
                                                                            size: 'small',
                                                                            sx: { mt: 'auto' },
                                                                        },
                                                                        valueInputProps: {
                                                                            InputComponentProps: {
                                                                                variant: 'outlined',
                                                                                size: 'small',
                                                                            },
                                                                        },
                                                                        deleteIconProps: {
                                                                            sx: {
                                                                                '& .MuiSvgIcon-root': { color: '#d32f2f' },
                                                                            },
                                                                        },
                                                                    },
                                                                    sx: {
                                                                        '& .MuiDataGrid-filterForm': { p: 2 },
                                                                        '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                                                                            backgroundColor: () =>
                                                                                theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                                                                        },
                                                                        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                                                                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                                                                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                                                                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                                                                    },
                                                                },
                                                            }}
                                                            localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                                                            unstable_headerFilters
                                                            getRowHeight={() => 50}
                                                        />
                                                    </Box>
                                                </>
                                            :
                                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                                                <Typography align='center'>{t("No data")}</Typography>
                                            </Box>
                                        : null
                                }

                                {
                                    jobDisplayType === 'grid' ?
                                        <Grid container direction="row" justifyContent="center" sx={{ pb: 2, pt: 3 }}>
                                            <Pagination
                                                count={count}
                                                page={page}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        : null
                                }
                            </>
                    }
                </Box>
            </Box>

        </div >
    )
}

export default JobLists