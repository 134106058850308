import * as React from "react";
import {
  Box, Icon, CircularProgress, Container, ThemeProvider, Typography, Button, Card,
  CardContent, Stack, Checkbox, FormControl, Paper, TextField, InputAdornment, List, IconButton,
  Divider, Pagination, Drawer, Grid, Tooltip, Autocomplete
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridValueGetterParams, enUS } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import useDebounce from '../../../hooks/useDebounce';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import { thTHGrid } from "../../../@types/DataGridLocaleText";
import { Job, Site } from '../../../@types';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import usePagination from "../../../components/common/Pagination";

//MUI ICON
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { checkDupJobApply, getJobById, queryJobs } from "../../../services/JobService";
import { getAllSites } from "../../../services/SiteService";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ConfirmationDialogPDPA from "../../auth/sign_up/consentPDPA";

type JobsListProps = {
};

type Anchor = "top";

const JobsList: React.FC<JobsListProps> = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  var [allJobs, setAllJobs] = useState<Job[]>([]);
  var [selectData, setSelectData] = useState<Job[]>([]);
  var [allJobId, setAllJobId] = useState<String[]>([])
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  //FOR FILTERS
  const { userid } = useAppSelector(state => state.user)
  const [selectedSites, setSelectedSites] = useState<Site | null>(null);
  const [siteData, setSiteData] = useState<Site[]>([]);
  const [siteLoading, setSiteLoading] = useState<boolean>(true);
  const [isAllJobsLoading, setIsAllJobsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  // const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [searchLoading, setSearchLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [state, setState] = useState({ top: false })
  // const [pdpaConsent, setPdpaConsent] = useState<boolean>(false);
  const [openPDPA, setOpenPDPA] = useState<boolean>(false)

  const allSuccessLoading = !siteLoading && !isAllJobsLoading
  let jobID = ''

  useEffect(() => {
    try {
      if (localStorage.getItem('access_token') !== null) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      setIsLoggedIn(false);
    }
  }, [])

  //PAGINATION
  let [page, setPage] = useState(1)
  const PER_PAGE = 10
  const count = Math.ceil(allJobs.length / PER_PAGE)
  const DATA = usePagination(allJobs, PER_PAGE)

  //PDPA
  const handlepdpaConsentDialog = () => {
    onSubmit()
    // setPdpaConsent(true)
    // setOpenPDPA(true)
  }

  const handleChange = (e: any, p: any) => {
    setPage(p)
    DATA.jump(p)
  }
  //CHANGE FORMAT DATE
  const monthTH: string[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  const monthEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  function changeFormat(date: Date) {
    const parse_date: Date = new Date(date)
    let formatted_date: string = "";
    i18n.language === 'th' ?
      formatted_date = parse_date.getDate() + ' ' + monthTH[parse_date.getMonth()] + ' ' + (parse_date.getFullYear() + 543) :
      formatted_date = parse_date.getDate() + ' ' + monthEN[parse_date.getMonth()] + ' ' + parse_date.getFullYear()
    return (formatted_date)
  }
  //CHECK SELECT JOB
  async function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const checkdupjobapply = async () => {
      try {
        jobID = event.target.value;
        const checked = event.target.checked;

        const data = {
          job_id: event.target.value,
          user_id: userid,
        }

        const response = await checkDupJobApply(data);

        if (!response.data?.success) {
          Swal.fire({
            icon: 'error',
            title: `<h5 style="margin: 0px">${t('Cannot select this job position')}</h5>`,
            html: `<p>${t('You have already applied for this position.')}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#43A047',
            confirmButtonText: `${t('OK!')}`,
            allowOutsideClick: false,
          })
          return true;
        } else if (response.data?.success) {
          if (checked) {
            allJobId.push(jobID)

            const fetchSelectJobs = async () => {
              try {
                const response = await getJobById(jobID);
                const data = response.data?.job;

                data.check = true
                selectData = [...selectData, data]
                setSelectData(selectData)

                let totalSelectedJobs = JSON.parse(sessionStorage.getItem('all_selected') ?? '[]');
                totalSelectedJobs = selectData
                sessionStorage.setItem('all_selected', JSON.stringify(totalSelectedJobs));

                setTimeout(() => {
                  setIsAllJobsLoading(false);
                }, 1000);
              } catch (error) {
                console.error(error);
              }
            };
            fetchSelectJobs();
          }
          else if (selectData !== null || !event.target.checked) {
            const data: any = selectData.filter(item => item.id !== jobID)
            setSelectData(data)
            allJobId = allJobId.filter(item => item !== jobID)

            let totalSelectedJobs = JSON.parse(sessionStorage.getItem('all_selected') ?? '[]');
            totalSelectedJobs = data
            sessionStorage.setItem('all_selected', JSON.stringify(totalSelectedJobs));
          }
          let formsAnswer = JSON.parse(sessionStorage.getItem('selected_position') ?? '[]');
          formsAnswer = allJobId
          sessionStorage.setItem('selected_position', JSON.stringify(formsAnswer));
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    checkdupjobapply();
  }

  selectData = JSON.parse(sessionStorage.getItem('all_selected') || '[]');
  allJobId = JSON.parse(sessionStorage.getItem('selected_position') || '[]');

  //COLUMN DATAGRID OF SELECTED POSITION
  const columns: GridColDef[] = [
    {
      field: 'delete',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              const id = params.row.id
              allJobId.find((value, i) => value === id ? allJobId[i] = '' : null)
              let newArr: String[] = allJobId.filter(value => value !== '')
              setAllJobId(newArr)

              const data: any = selectData.filter(item => item.id !== id)
              setSelectData(data)

              let selected_id: any = []
              data.map((job: any) => {
                return selected_id.push(job.id)
              })

              let formsAnswer = JSON.parse(sessionStorage.getItem('selected_position') ?? '[]');
              formsAnswer = selected_id
              sessionStorage.setItem('selected_position', JSON.stringify(formsAnswer));
              let totalSelectedJobs = JSON.parse(sessionStorage.getItem('all_selected') ?? '[]');
              totalSelectedJobs = data
              sessionStorage.setItem('all_selected', JSON.stringify(totalSelectedJobs));
            }}
          >
            <Tooltip title={t("Delete this job position")}>
              <DeleteIcon color='error' />
            </Tooltip>
          </IconButton>
        )
      },
    },
    {
      field: 'site',
      headerName: `${t('Workplace')}`,
      width: 170,
      valueGetter: (params: GridValueGetterParams) => params.row.Site.site_name
    },
    {
      field: 'job_name',
      headerName: `${t('Position')}`,
      width: 150,
    },
    {
      field: 'date',
      headerName: `${t('Application period')}`,
      width: 250,
      valueGetter: (params: GridValueGetterParams) =>
        `${changeFormat(params.row.start_date || '')} - ${changeFormat(params.row.end_date || '')}`,
    },
    {
      field: 'num_openings',
      headerName: `${t('Job opening')}`,
      width: 80,
    },
  ];

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setSearchLoading(true);

        const data = {
          is_active: true,
          site_id: selectedSites?.site_id,
          search: debouncedSearchQuery,
        }

        const response = await queryJobs(data);

        if (response.data?.success) {
          setAllJobs(response.data?.jobs);
          setTimeout(() => {
            // setIsLoaded(false);
            setIsAllJobsLoading(false);
          }, 1000);
        }

      } catch (error) {
        navigate('/servererror', { replace: true });
      } finally {
        setTimeout(() => {
          setSearchLoading(false);
          setIsSearching(false);
        }, 500);
      }
    };

    if (debouncedSearchQuery !== '') {
      setIsSearching(true);
      fetchJobs();
    } else {
      setSearchQuery('');
      fetchJobs();
    }
  }, [debouncedSearchQuery, navigate, selectedSites]);

  useEffect(() => {
    if (searchQuery !== '') {
      setIsSearching(true);
      setAllJobs([]);
    }
  }, [searchQuery])

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await getAllSites();

        if (response.data?.success) {
          const Data = response.data?.sites;

          const siteActive = Data.filter((site: any) => site.is_active === true)
          // const siteActive = Data.map((site: any) => {
          //   if (site.is_active === true) {
          //     return site
          //   }
          // })

          setSiteData(siteActive)
          setTimeout(() => {
            setSiteLoading(false)
          }, 500);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    fetchSites();
  }, [navigate]);

  //FILTER IN RESPONSIVE
  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }
    setState({ ...state, [anchor]: open });
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" ? "auto" : 250 }}
      role="mailingation"
    >
      <List>
        <Typography gutterBottom sx={{ fontSize: 16, p: 3 }}>
          {t('Filter')}
        </Typography>
        <Box>
          <Typography sx={{ pl: 3, pb: 1, fontSize: 14 }}>
            {t('Search')}
          </Typography>
          <Grid item xs={12} sm={12} sx={{ pl: 3, pr: 3 }}>
            <FormControl sx={{ width: '100%', pb: 3 }}>
              <TextField
                variant="outlined"
                placeholder={t("Position, Company")!}
                value={searchQuery}
                onChange={((event) => {
                  setSearchQuery(event.target.value)
                  DATA.start()
                  setPage(1)
                })}
                InputProps={{
                  endAdornment: searchQuery.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton size="small" edge="end" onClick={() => setSearchQuery('')}>
                        <Icon>clear</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Typography sx={{ pl: 3, pb: 1, fontSize: 15 }}>
            {t('Workplace')}
          </Typography>
          <Grid item xs={12} sm={12} sx={{ pl: 3, pr: 3 }}>
            <FormControl sx={{ width: '100%', pb: 3 }}>
              <Autocomplete
                onChange={(event, newValue: Site | null) => {
                  setSelectedSites(newValue);
                }}
                limitTags={1}
                id="site"
                options={
                  siteData
                }
                getOptionLabel={(option) => option.site_name}
                value={selectedSites}
                renderOption={(props, option, { inputValue, selected }) => {
                  const matches = match(option.site_name, inputValue, { insideWords: true });
                  const parts = parse(option.site_name, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part: any, index: any) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    placeholder={siteLoading ? `${t("Loading")}...` : t("Site")!}
                  />
                )}
                loading={siteLoading}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} sx={{ pl: 3, pr: 3, pb: 2 }}>
            <Button variant="contained" sx={{ width: "100%" }} onClick={toggleDrawer(anchor, false)}>
              {t('Apply')}
            </Button>
          </Grid>
        </Box>
      </List>
      <Divider />
    </Box>
  )

  const onSubmit = () => {

    if (selectData.length !== 0) {
      Swal.fire({
        // icon: 'warning',
        icon: 'warning',
        title: `<h5 style="margin: 0px">${t('Are you sure you want to proceed with the transaction?')}</h5>`,
        html: `<p>${t('Please verify the information before proceeding.')}</p>`,
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: `${t('Confirm')}`,
        cancelButtonText: `${t('Cancel')}`,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if(!isLoggedIn){
            window.location.assign('/register')
          }else{
            setOpenPDPA(true)
            // window.location.assign('/appform') 
          }
         
          // window.location.assign('/register') //Jobs
        }
      })
    }
    else {
      Swal.fire({
        title: `<h5 style="margin: 0px">${t('Cannot proceed with the transaction')}</h5>`,
        html: `<p>${t('Please select the position you want to apply for.')}</p>`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#43A047',
        confirmButtonText: `${t('OK!')}`,
        allowOutsideClick: false,
      })
      return null;
    }
  }

  function ApplyJob(id: any) {
    const checkdupjobapply = async () => {
      try {
        jobID = id;
        const checked = true;
        const data = {
          job_id: id,
          user_id: userid,
        }
        const response = await checkDupJobApply(data);

        if (!response.data?.success) {
          Swal.fire({
            icon: 'error',
            title: `<h5 style="margin: 0px">${t('Cannot select this job position')}</h5>`,
            html: `<p>${t('You have already applied for this position.')}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#43A047',
            confirmButtonText: `${t('OK!')}`,
            allowOutsideClick: false,
          })
          return true;
        } else if (response.data?.success) {
          if (checked) {
            allJobId.push(jobID)

            const fetchSelectJobs = async () => {
              try {
                const response = await getJobById(jobID);
                const data = response.data?.job;

                data.check = true
                selectData = [...selectData, data]
                setSelectData(selectData)

                const selectJob: any = []
                selectJob.push(data)
                sessionStorage.setItem('all_selected', JSON.stringify(selectJob));
                onSubmit() //step follow proceed --> pdpa 
                // window.location.assign('/appform')
                setTimeout(() => {
                  setIsAllJobsLoading(false);
                }, 1000);
              } catch (error) {
                console.error(error);
              }
            };
            fetchSelectJobs();
          }

          const selectID: any = []
          selectID.push(jobID)
          sessionStorage.setItem('selected_position', JSON.stringify(selectID));
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };
    checkdupjobapply();
  }

  return <><Container maxWidth='lg' sx={{ display: 'flex' }}>
    <Box sx={{ height: '340px', display: { xs: "none", md: "flex" } }}>
      <Paper sx={{ m: 2, borderRadius: 2, pb: 1 }}>
        <Typography gutterBottom sx={{ fontSize: 17, p: 3 }}>
          {t('Filter')}
        </Typography>
        <Box>
          <Typography sx={{ pl: 3, pb: 1, fontSize: 15 }}>
            {t('Search')}
          </Typography>
          <Grid item xs={12} sm={4} sx={{ pl: 3, pr: 3 }}>
            <FormControl sx={{ width: '100%', pb: 3 }}>
              <TextField
                variant="outlined"
                placeholder={t("Position, Company")!}
                value={searchQuery}
                onChange={((event) => {
                  setSearchQuery(event.target.value)
                  DATA.start()
                  setPage(1)
                })}
                InputProps={{
                  endAdornment: searchQuery.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton size="small" edge="end" onClick={() => { setSearchQuery('') }}>
                        <Icon>clear</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Box>
        <Box>
          <Typography sx={{ pl: 3, pb: 1, fontSize: 15 }}>
            {t('Workplace')}
          </Typography>
          <Grid item xs={12} sm={4} sx={{ pl: 3, pr: 3 }}>
            <FormControl sx={{ width: '100%', pb: 3 }}>
              <Autocomplete
                onChange={(event, newValue: Site | null) => {
                  setSelectedSites(newValue);
                }}
                limitTags={1}
                id="site"
                options={
                  siteData
                }
                getOptionLabel={(option) => option.site_name}
                value={selectedSites}
                renderOption={(props, option, { inputValue, selected }) => {
                  const matches = match(option.site_name, inputValue, { insideWords: true });
                  const parts = parse(option.site_name, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part: any, index: any) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    placeholder={siteLoading ? `${t("Loading")}...` : t("Site")!}
                  />
                )}
                loading={siteLoading}
              />
            </FormControl>
          </Grid>
        </Box>
      </Paper>
    </Box>

    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, }}>
        {
          !allSuccessLoading ? <Box display='flex' justifyContent='center'><CircularProgress color="success" size={20} sx={{ mr: 1 }} />
            <Typography align='center'>{t("Loading")}...</Typography>
          </Box> : (
            <>
              <Grid container direction="row" justifyContent="flex-end">
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  {(["top"] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        startIcon={<FilterAltOutlinedIcon />}
                        onClick={toggleDrawer(anchor, true)}
                        sx={{ fontSize: '12px' }}
                      >
                        {t('Filter')}
                      </Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </Box>
                <Stack direction="row" sx={{ alignItems: 'flex-end', mb: 2 }} useFlexGap flexWrap="wrap">
                  <Typography variant="subtitle2" sx={{ display: 'inline-flex', fontSize: { md: '14px', xs: '12px' } }}>
                    <CheckCircleOutlineIcon sx={{ color: '#4CAF50', mr: 1, fontSize: '20px' }} />
                    {`${selectData.length}`} {t('selected')}
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ ml: 2, width: { md: 100, xs: 90 }, fontSize: { md: '13px', xs: '11px' } }}
                    onClick={handlepdpaConsentDialog}
                  > {t('Apply job')}
                  </Button>
                </Stack>
              </Grid>

              {(selectData.length === 0) ? (
                <>
                  <Typography align='center' sx={{ mt: 2, color: 'red', fontSize: { md: '16px', xs: '14px' } }}>
                    {t("You haven't selected a job position")}
                  </Typography>
                  <Typography align='center' sx={{ mb: 3, mt: 1, color: 'red', fontSize: { md: '16px', xs: '14px' } }}>
                    {t("Please check")} <CheckCircleOutlineIcon sx={{ mr: 1, fontSize: '15px', alignItems: 'center' }} />
                    {t("for choose job position")}
                  </Typography>
                </>
              ) : (<>
                <Box display='inline-grid' sx={{ width: '100%' }}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={selectData}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 30, 40, 50]}
                    getRowHeight={() => 'auto'}
                    disableRowSelectionOnClick
                    sx={{
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none !important',
                      },
                      fontSize: 13,
                    }}
                    localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                  />
                </Box>
              </>)
              }
              <Typography variant="h6" component="h1" gutterBottom sx={{ mb: 2, mt: 2, fontSize: { md: '18px', xs: '16px' } }}>
                {t('All Open Jobs')} ( {`${allJobs.length}`} {t("Openings")} )
              </Typography>

              {!isAllJobsLoading && !isSearching && !searchLoading && <div>
                {allJobs.length > 0 ? (<>
                  {isSearching === false && DATA.currentData().map((job: any) => (
                    <div key={job.id}>
                      <Card variant="outlined" sx={{ mb: 2, width: "100%", borderRadius: 3 }}>
                        {allJobId.includes(job.id) === true ? (
                          <Grid container justifyContent="flex-end" sx={{ pr: 1, mb: 1 }} height={15}>
                            {isLoggedIn &&
                              <IconButton>
                                <Tooltip title={t("Unselect this job position")}>
                                  <Checkbox
                                    checked={true}
                                    icon={<CheckCircleOutlineIcon />}
                                    checkedIcon={<CheckCircleIcon sx={{ color: '#4CAF50' }} />}
                                    onChange={handleCheck}
                                    value={job.id}
                                  />
                                </Tooltip>
                              </IconButton>
                            }
                          </Grid>

                        ) : (
                          <Grid container justifyContent="flex-end" sx={{ pr: 1, mb: 1 }} height={15}>
                            <IconButton>
                              <Tooltip title={t("Select this job position")}>
                                <Checkbox
                                  checked={false}
                                  icon={<CheckCircleOutlineIcon />}
                                  checkedIcon={<CheckCircleIcon sx={{ color: '#4CAF50' }} />}
                                  onChange={handleCheck}
                                  value={job.id}
                                />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        )}
                        <CardContent sx={{ flexGrow: 1, height: 'auto' }}>
                          <Box sx={{
                            display: { xs: 'flex', sm: 'grid' },
                            flexDirection: { xs: 'row' },
                            gridTemplateColumns: { xs: 'false', sm: 'fit-content(100%) repeat(1, 1fr)' },
                            columnGap: 2
                          }}>
                            <Box sx={{ display: { xs: 'none', sm: 'grid' }, gridColumn: { sm: 1 }, alignSelf: 'flex-start' }}>
                              <div>
                                <img
                                  src="https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png"
                                  alt="Betagro Logo"
                                  style={{ display: "block", width: "80px" }}
                                />
                              </div>
                            </Box>
                            <Box sx={{ display: { xs: 'grid', sm: 'none' }, gridColumn: { sm: 1 }, alignSelf: 'flex-start' }}>
                              <div>
                                <img
                                  src="https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png"
                                  alt="Betagro Logo"
                                  style={{ display: "block", width: "50px" }}
                                />
                              </div>
                            </Box>
                            <Box sx={{
                              display: 'grid', columnGap: { sm: 3 }, rowGap: 1,
                              gridTemplateRows: 'fit-content(100%) repeat(1, 1fr)',
                              gridTemplateColumns: { xs: 'false', sm: 'max-content auto' },
                            }}>
                              <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{ gridRow: 1, gridColumn: { sm: 1 }, mb: 1 }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '17px', sm: '18px' } }}>
                                  {job.job_name}
                                </Typography>
                              </Box>

                              <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{ gridRow: 2, gridColumn: { sm: 1 } }}>
                                <BusinessRoundedIcon color='warning' fontSize='small' />
                                <Typography sx={{ fontSize: { xs: '13px', sm: '15px' }, ml: 1 }}>
                                  {job.company}
                                </Typography>
                              </Box>

                              <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{ gridRow: { xs: 3, sm: 2 }, gridColumn: { sm: 2 } }}>
                                <LocationOnRoundedIcon fontSize='small' sx={{ color: '#FF9824' }} />
                                <Typography sx={{ fontSize: { xs: '13px', sm: '15px' }, ml: 1, }}>
                                  {job.Site.site_name}
                                </Typography>
                              </Box>

                              <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{ gridRow: { xs: 4, sm: 3 }, gridColumn: { sm: 1 } }}>
                                <InsertInvitationIcon fontSize='small' sx={{ color: "#4CAF50" }} />
                                <Typography sx={{ ml: 1, fontSize: { xs: '13px', sm: '15px' } }}>
                                  {`${changeFormat(job.start_date)}`} - {`${changeFormat(job.end_date)}`}
                                </Typography>
                              </Box>

                              <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{ gridRow: { xs: 5, sm: 3 }, gridColumn: { sm: 2 } }}>
                                <PeopleRoundedIcon fontSize='small' sx={{ color: "#04A9F4" }} />
                                <Typography sx={{ ml: 1, fontSize: { xs: '13px', sm: '15px' } }}>
                                  {job.num_openings} {t("Openings")}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box display='flex' justifyContent='flex-end' alignItems='flex-end' marginTop={3}>
                            <Button
                              type="submit"
                              variant='contained'
                              disableElevation
                              sx={{
                                fontSize: { md: '13px', xs: '10px' }, pb: 1, mr: 1, backgroundColor: '#F2F8F2', color: '#43A047',
                                "&:hover": {
                                  backgroundColor: '#43A047',
                                  color: 'white'
                                },
                              }}
                              onClick={() => {
                                ApplyJob(job.id)
                                // onSubmit()
                              }}
                            >
                              {t("Apply job")}
                            </Button>

                            <Button
                              variant='contained'
                              disableElevation
                              sx={{
                                fontSize: { md: '13px', xs: '10px' }, pb: 1, backgroundColor: '#F2F8F2', color: '#43A047',
                                "&:hover": {
                                  backgroundColor: '#43A047',
                                  color: 'white'
                                },
                              }}
                              component={Link}
                              to={`/jobs/detail/${job.id}`}
                            >
                              {t("View More")}
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                  <Grid container direction="row" justifyContent="center" sx={{ pb: 2 }}>
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                </>)
                  : (
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                      <Typography align='center'>{t("There are no open job positions")}</Typography>
                    </Box>)}
              </div>
              }
            </>
          )}
        {
          searchLoading && allSuccessLoading ?
            <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress color="success" size={20} sx={{ mr: 1 }} />
              <Typography align='center'>{t("Loading")}...</Typography>
            </Box>
            :
            <>
              {
                isSearching ?
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Typography align='center'>{t("Searching")}...</Typography>
                  </Box>
                  : null
              }
            </>
        }
      </Box>
    </ThemeProvider>
  </Container>
    {/* <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> */}
    <ConfirmationDialogPDPA
      id="pdpa-consent"
      keepMounted
      open={openPDPA}
      onClose={() => {
        setOpenPDPA(false)
        window.location.assign('/appform') 
        // onSubmit()
      }}
      onCancel={() => {
        setOpenPDPA(false)
      }}
    />
    {/* </Box> */}
  </>
};

export default JobsList;
