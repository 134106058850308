import JobsList from "./UserJobsList";
import { Container } from "@mui/material";

type JobsLayoutProps = {
};

const JobsLayout: React.FC<JobsLayoutProps> = () => {
    return <div>
        <Container maxWidth='lg' sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <JobsList />
        </Container>
    </div>;
};

export default JobsLayout;
