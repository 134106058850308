import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/roles';

export function getAllRoles() {
    return api.get(`${BASE_URL}`);
}

export function getRoleById(id: string) {
    return api.get(`${BASE_URL}/${id}`);
}

export function createRole(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}`, data, config);
}

export function editRole(id: string, data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.put(`${BASE_URL}/${id}`, data, config);
}