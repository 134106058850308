// React
import { useEffect, useState } from "react";
// Hooks and Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import {
    editDateOfBirth,
    editFirstNameTH,
    editFirstNameEN,
    editGender,
    editHeight,
    editIdCardNumber,
    editLastNameTH,
    editLastNameEN,
    editPrefix,
    editWeight,
    editNationality,
    editReligion,
    editMaritalStatus
} from "../../../../redux/slices/jobAppSlice";

// MUI Components
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

// Utils
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { newAdapter } from "../../../../utils";

// Services
import {
    getAllActiveMaritalStatus,
    getAllActiveReligion,
    getAllActivePrefix
} from "../../../../services/MasterDataService";

const PersonalInfo: React.FC<any> = ({ errors }) => {
    const { t, i18n } = useTranslation();

    const { personalInfo, } = useAppSelector(state => state.jobapp);
    const { isLoggedIn } = useAppSelector(state => state.user);
    const dispatch = useDispatch();
    const [dataErrorState, setDataErrorState] = useState<any>(errors)

    useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    const [prefix, setPrefix] = useState('');
    const [firstNameTH, setFirstNameTH] = useState('');
    const [lastNameTH, setLastNameTH] = useState('');
    const [firstNameEN, setFirstNameEN] = useState('');
    const [lastNameEN, setLastNameEN] = useState('');
    const [nationality, setNationality] = useState('THA');
    const [gender, setGender] = useState('');
    const [religion, setReligion] = useState('BDH');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState<any>(null);
    const [idCardNumber, setIdCardNumber] = useState('');
    const [password, setPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');

    const [religionList, setReligionList] = useState<any>([]);
    const [maritalStatusList, setMaritalStatusList] = useState<any>([]);
    const [prefixList, setPrefixList] = useState<any>([]);

    useEffect(() => {
        if (personalInfo) {
            setPrefix(personalInfo.prefix || '');
            setFirstNameTH(personalInfo.first_name_th || '');
            setLastNameTH(personalInfo.last_name_th || '');
            setFirstNameEN(personalInfo.first_name_en || '');
            setLastNameEN(personalInfo.last_name_en || '');
            setNationality(personalInfo.nationality || 'THA');
            setGender(personalInfo.gender || '');
            setReligion(personalInfo.religion || 'BDH');
            setMaritalStatus(personalInfo.marital_status || '');
            setWeight(personalInfo.weight || '');
            setHeight(personalInfo.height || '');
            setDateOfBirth(personalInfo.date_of_birth ? dayjs(personalInfo.date_of_birth) : null);
            setIdCardNumber(personalInfo.id_card_number || '');
            setPassword(personalInfo.password);
            setNewPassword(personalInfo.newpassword);
        }
    }, [personalInfo, password, newpassword]);

    useEffect(() => {
        const fetchAllActiveReligion = async () => {
            try {
                const response = await getAllActiveReligion();

                if (response.data?.success) {
                    setReligionList(response.data?.religions);
                }
            } catch (error) {
                console.log(error);
            }
        }

        const fetchAllActiveMaritalStatus = async () => {
            try {
                const response = await getAllActiveMaritalStatus();

                if (response.data?.success) {
                    setMaritalStatusList(response.data?.marital_statuses);
                }
            } catch (error) {
                console.log(error);
            }
        }

        const fetchActivePrefix = async () => {
            try {
                const response = await getAllActivePrefix();

                if (response.data?.success) {
                    setPrefixList(response.data?.marital_statuses);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchActivePrefix();
        fetchAllActiveReligion();
        fetchAllActiveMaritalStatus();
    }, [])

    const handleGenderChange = (event: SelectChangeEvent) => {
        setGender(event.target.value as string);
        dispatch(editGender(event.target.value as string));

        if (dataErrorState.gender !== null) {
            setDataErrorState({ ...dataErrorState, gender: null })
        }
    };

    const handleNationalityChange = (event: SelectChangeEvent) => {
        setNationality(event.target.value as string);
        dispatch(editNationality(event.target.value as string));

        if (dataErrorState.nationality !== null) {
            setDataErrorState({ ...dataErrorState, nationality: null })
        }
    };

    const handleReligionChange = (event: SelectChangeEvent) => {
        setReligion(event.target.value as string);
        dispatch(editReligion(event.target.value as string));

        if (dataErrorState.religion !== null) {
            setDataErrorState({ ...dataErrorState, religion: null })
        }
    };

    const handleMaritalStatusChange = (event: SelectChangeEvent) => {
        setMaritalStatus(event.target.value as string);
        dispatch(editMaritalStatus(event.target.value as string));

        if (dataErrorState.marital_status !== null) {
            setDataErrorState({ ...dataErrorState, marital_status: null })
        }
    };

    const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    return <div>
        <Typography sx={{ mt: { xs: 3, sm: 6 }, mb: 3 }}>{t("Personal Information")}</Typography>
     
        <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                    <InputLabel id="prefix" error={dataErrorState.prefix ? true : false}>{t("Prefix") + ' *'}</InputLabel>
                    <Select
                        labelId="prefix"
                        required
                        error={dataErrorState.prefix ? true : false}
                        sx={{
                            backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                            }
                        }}
                        disabled={isLoggedIn ? true : false}
                        id="prefix"
                        value={prefix}
                        label={t("Prefix") + ' *'}
                        onChange={(e) => {
                            setPrefix(e.target.value);
                            setGender(e.target.value === 'Mr' ? 'M' : 'F');
                            dispatch(editPrefix(e.target.value));
                            dispatch(editGender(e.target.value === 'Mr' ? 'M' : 'F'));

                            if (dataErrorState.prefix) {
                                setDataErrorState({ ...dataErrorState, prefix: null, gender: null })
                            }
                        }}
                       
                    >
                         {
                            prefixList.map((item: { external_code: string, label: string }) => {
                           return <MenuItem key={item.external_code} value={item.external_code}>{t(`${item.label}`)} </MenuItem>
                       })
                       }
                        {/* <MenuItem value='Mr'>{t("personal_data.gender.mr_label")}</MenuItem>
                        <MenuItem value='Mrs'>{t("personal_data.gender.mrs_label")}</MenuItem>
                        <MenuItem value='Miss'>{t("personal_data.gender.miss_label")}</MenuItem> */}
                    </Select>
                    {
                        dataErrorState.prefix ?
                            <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                                {t(dataErrorState.prefix?.message)}
                            </Typography> : null
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    error={dataErrorState.first_name_th ? true : false}
                    required
                    sx={{
                        backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={isLoggedIn ? true : false}
                    fullWidth
                    id="firstName"
                    label={t("Thai name")}
                    placeholder={t("Thai name")!}
                    autoComplete="given-name"
                    value={firstNameTH}
                    onChange={(e) => {
                        setFirstNameTH(e.target.value);
                        dispatch(editFirstNameTH(e.target.value));

                        if (dataErrorState.first_name_th) {
                            setDataErrorState({ ...dataErrorState, first_name_th: null })
                        }
                    }}
                />
                {
                    dataErrorState.first_name_th ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.first_name_th?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    error={dataErrorState.last_name_th ? true : false}
                    required
                    sx={{
                        backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={isLoggedIn ? true : false}
                    fullWidth
                    id="lastNameTH"
                    label={t("Thai last name")}
                    placeholder={t("Thai last name")!}
                    autoComplete="family-name"
                    value={lastNameTH}
                    onChange={(e) => {
                        setLastNameTH(e.target.value);
                        dispatch(editLastNameTH(e.target.value));

                        if (dataErrorState.last_name_th) {
                            setDataErrorState({ ...dataErrorState, last_name_th: null })
                        }
                    }}
                />
                {
                    dataErrorState.last_name_th ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.last_name_th?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    error={dataErrorState.id_card_number  ? true : false}
                    required
                    sx={{
                        backgroundColor: personalInfo.id_card_number === null ? '' : isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: personalInfo.id_card_number === null ? '' : isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={personalInfo.id_card_number === null ? false : isLoggedIn ? true : false}
                    fullWidth
                    id="idcardnumber"
                    label={t("ID card number")}
                    placeholder={t("ID card number")!}
                    value={idCardNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputText = e.target.value;
                        if (/^\d*$/.test(inputText)) {
                            setIdCardNumber(inputText);
                            dispatch(editIdCardNumber(inputText));
                            if (dataErrorState.id_card_number) {
                                setDataErrorState({ ...dataErrorState, id_card_number: null })
                            }
                        }
                    }}
                    inputProps={{ maxLength: 13 }}
                />
                {
                    dataErrorState.id_card_number ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.id_card_number?.message)}
                        </Typography> : null
                }
                <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 1234567890123`}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    error={dataErrorState.first_name_en ? true : false}
                    required
                    sx={{
                        backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={isLoggedIn ? true : false}
                    fullWidth
                    id="firstNameEN"
                    label={t("English name")}
                    placeholder={t("English name")!}
                    autoComplete="given-name"
                    value={firstNameEN}
                    onChange={(e) => {
                        setFirstNameEN(e.target.value);
                        dispatch(editFirstNameEN(e.target.value));

                        if (dataErrorState.first_name_en) {
                            setDataErrorState({ ...dataErrorState, first_name_en: null })
                        }
                    }}
                />
                {
                    dataErrorState.first_name_en ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.first_name_en?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    error={dataErrorState.last_name_en ? true : false}
                    fullWidth
                    required
                    sx={{
                        backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={isLoggedIn ? true : false}
                    id="lastNameEN"
                    label={t("English last name")}
                    placeholder={t("English last name")!}
                    autoComplete="given-name"
                    value={lastNameEN}
                    onChange={(e) => {
                        setLastNameEN(e.target.value);
                        dispatch(editLastNameEN(e.target.value));

                        if (dataErrorState.last_name_en) {
                            setDataErrorState({ ...dataErrorState, last_name_en: null })
                        }
                    }}
                />
                {
                    dataErrorState.last_name_en ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.last_name_en?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                    <InputLabel id="gender" error={dataErrorState.gender ? true : false}>{t("Gender") + ' *'}</InputLabel>
                    <Select
                        error={dataErrorState.gender ? true : false}
                        required
                        labelId="gender"
                        id="gender"
                        label={t("Gender") + ' *'}
                        onChange={handleGenderChange}
                        value={gender}
                    >
                        <MenuItem autoFocus value='M'>{t("Male")}</MenuItem>
                        <MenuItem autoFocus value='F'>{t("Female")}</MenuItem>
                        {/* {
                            gender === 'Mr' ?
                                <MenuItem value='M'>{t("Male")}</MenuItem> :
                                (prefix === 'Mrs' || prefix === 'Miss') ?
                                    <MenuItem value='F'>{t("Female")}</MenuItem> :
                                    <MenuItem value=''>{t("กรุณาเลือกคำนำหน้าก่อน")}</MenuItem>
                        } */}
                    </Select>
                </FormControl>
                {
                    dataErrorState.gender ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.gender?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    error={dataErrorState.weight ? true : false}
                    required
                    fullWidth
                    sx={{ width: '100%' }}
                    id="weight"
                    label={`${t("Weight")} (${t("kg")})`}
                    placeholder={`${t("Weight")} (${t("kg")})`}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={weight}
                    onChange={(e) => {
                        const inputText = e.target.value;
                        if (/^[0-9.]*$/.test(inputText)) {
                            setWeight(e.target.value)
                            dispatch(editWeight(e.target.value));

                            if (dataErrorState.weight) {
                                setDataErrorState({ ...dataErrorState, weight: null })
                            }
                        }
                    }}
                    onKeyDown={blockInvalidChar}
                />
                {
                    dataErrorState.weight ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.weight?.message)}
                        </Typography> : null
                }
                <FormHelperText sx={{ marginLeft: 1 }}>{t("Input as number")}</FormHelperText>

            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    error={dataErrorState.height ? true : false}
                    required
                    fullWidth
                    sx={{ width: '100%' }}
                    id="height"
                    label={`${t("Height")} (${t("cm")})`}
                    placeholder={`${t("Height")} (${t("cm")})`}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={height}
                    onChange={(e) => {
                        const inputText = e.target.value;
                        if (/^[0-9.]*$/.test(inputText)) {
                            setHeight(e.target.value)
                            dispatch(editHeight(e.target.value));

                            if (dataErrorState.height) {
                                setDataErrorState({ ...dataErrorState, height: null })
                            }
                        }
                    }}
                    onKeyDown={blockInvalidChar}
                />
                {
                    dataErrorState.height ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.height?.message)}
                        </Typography> : null
                }
                <FormHelperText sx={{ marginLeft: 1 }}>{t("Input as number")}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                    <InputLabel id="nationality" error={dataErrorState.nationality ? true : false}>{t("Nationality") + ' *'}</InputLabel>
                    <Select
                        error={dataErrorState.nationality ? true : false}
                        required
                        labelId="nationality"
                        id="nationality"
                        label={t("Nationality") + ' *'}
                        onChange={handleNationalityChange}
                        value={nationality}
                    >
                        <MenuItem value="THA">{t("Thai")}</MenuItem>
                    </Select>
                </FormControl>
                {
                    dataErrorState.nationality ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.nationality?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="religion" error={dataErrorState.religion ? true : false}>{t("Religion") + ' *'}</InputLabel>
                    <Select
                        error={dataErrorState.religion ? true : false}
                        required
                        labelId="religion"
                        id="religion"
                        label={t("Religion") + ' *'}
                        onChange={handleReligionChange}
                        value={religionList.length > 0 ? religion : ''}
                    >
                        {
                            religionList.map((item: { external_code: string, label_th?: string, label_en?: string }) => {
                                return <MenuItem key={item.external_code} value={item.external_code}>{i18n.language === 'th' ? item.label_th : item.label_en}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                {
                    dataErrorState.religion ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.religion?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="marital_status" error={dataErrorState.marital_status ? true : false}>{t("MaritalStatus") + ' *'}</InputLabel>
                    <Select
                        error={dataErrorState.marital_status ? true : false}
                        required
                        labelId="marital_status"
                        id="marital_status"
                        label={t("MaritalStatus") + ' *'}
                        onChange={handleMaritalStatusChange}
                        value={maritalStatus}
                    >
                        {
                            maritalStatusList.map((item: { external_code: string, label: string }) => {
                                return <MenuItem key={item.external_code} value={item.external_code}>{t(item.label)}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                {
                    dataErrorState.marital_status ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.marital_status?.message)}
                        </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th' >
                    <MobileDatePicker
                        sx={{
                            width: '100%',
                            backgroundColor: personalInfo.date_of_birth === null ? '' : isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                WebkitTextFillColor: personalInfo.date_of_birth === null ? '' : isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                            }
                        }}
                        closeOnSelect
                        disableFuture
                        disabled={personalInfo.date_of_birth === null ? false : isLoggedIn ? true : false}
                        value={dateOfBirth}
                        label={t("Date of birth") + " *"}
                        format="DD/MM/YYYY"
                        localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
                        dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
                        onAccept ={(newValue) => {
                            try {
                                if (newValue !== null) {
                                    setDateOfBirth(newValue.toISOString());
                                    dispatch(editDateOfBirth(newValue.toISOString()));

                                    if (dataErrorState.date_of_birth) {
                                        setDataErrorState({ ...dataErrorState, date_of_birth: null })
                                    }
                                }
                            } catch (error) { }
                        }}
                        // onChange={(newValue) => {
                        //     try {
                        //         if (newValue !== null) {
                        //             setDateOfBirth(newValue.toISOString());
                        //             dispatch(editDateOfBirth(newValue.toISOString()));

                        //             if (dataErrorState.date_of_birth) {
                        //                 setDataErrorState({ ...dataErrorState, date_of_birth: null })
                        //             }
                        //         }
                        //     } catch (error) { }
                        // }}
                        slotProps={{
                            textField: {
                                error: dataErrorState.date_of_birth ? true : false,
                            },
                        }}
                    />
                    {
                        dataErrorState.date_of_birth ?
                            <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                                {t(dataErrorState.date_of_birth?.message)}
                            </Typography> : null
                    }
                    <FormHelperText sx={{ marginLeft: 1 }}>{t("Input as number ( Date / Month / Year B.C. )")}</FormHelperText>
                </LocalizationProvider>
            </Grid>
            {/* <Grid item xs={12} sm={3}>
                <TextField
                    error={dataErrorState.id_card_number ? true : false}
                    required
                    sx={{
                        backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={isLoggedIn ? true : false}
                    fullWidth
                    id="idcardnumber"
                    label={t("ID card number")}
                    placeholder={t("ID card number")!}
                    value={idCardNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputText = e.target.value;
                        if (/^\d*$/.test(inputText)) {
                            setIdCardNumber(inputText);
                            dispatch(editIdCardNumber(inputText));
                            if(inputText && inputText.length === 13){
                                checkMemberIdCard(inputText);
                            }
                            if (dataErrorState.id_card_number) {
                                setDataErrorState({ ...dataErrorState, id_card_number: null })
                            }
                        }
                    }}
                    inputProps={{ maxLength: 13 }}
                />
                {
                    dataErrorState.id_card_number ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.id_card_number?.message)}
                        </Typography> : null
                }
                <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 1234567890123`}</FormHelperText>
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    label={t("Create Password")}
                    placeholder={t("Password")!}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputText = e.target.value;
                        if (/^\d*$/.test(inputText)) {
                            setPassword(inputText);
                            dispatch(editPassWord(inputText));

                            if (dataErrorState.password) {
                                setDataErrorState({ ...dataErrorState, password: null })
                            }
                        }
                    }}
                    // {...register('password')}
                    error={errors.password ? true : false}
                />
                <FormHelperText id="password-helper-text" sx={{ ml: 2, mt: 1, color: 'gray', fontSize: 12 }}>
                    {t("Password length must be greater than 8 characters.")} <br />
                    {t("Including at least one number and one letter.")}
                </FormHelperText>
                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                    {errors.password?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    label={t("Confirm Password")}
                    placeholder={t("Confirm Password")!}
                    type="password"
                    id="confirmPassword"
                    value={newpassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputText = e.target.value;
                        if (/^\d*$/.test(inputText)) {
                            setNewPassword(inputText);
                            dispatch(editComfirmPassword(inputText));

                            if (dataErrorState.password) {
                                setDataErrorState({ ...dataErrorState, password: null })
                            }
                        }
                    }}
                    // {...register('confirm_password')}
                    error={errors.confirm_password ? true : false}
                />
                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13} fontFamily='Sarabun'>
                    {errors.confirm_password?.message}
                </Typography>
            </Grid> */}
        </Grid>
    </div>;
};

export default PersonalInfo;
