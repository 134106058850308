import { Dayjs } from "dayjs";
import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/dashboard';

interface JobsDashboardType {
    created_date_range?: [Dayjs | null, Dayjs | null];
    site_ids?: string[];
}

interface InterviewsDashboardType {
    interview_date_range?: [Dayjs | null, Dayjs | null];
    job_apply_date_range?: [Dayjs | null, Dayjs | null];
    site_ids?: string[];
    job_ids?: string[];
}

interface AverageDashboardType {
    site_ids?: string[];
}

interface NewApplyDailyGraphDashboardType {
    site_ids?: string[];
}

interface InterviewCountPassAndRejectGraphDashboardType {
    interview_date_range?: [Dayjs | null, Dayjs | null];
    site_ids?: string[];
    job_ids?: string[];
}

export function getAllDashboardData(data: JobsDashboardType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/dashboard-data`, data, config);
}

export function getJobsDashboardData(data: JobsDashboardType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/dashboard-data/jobs`, data, config);
}

export function getInterviewsDashboardData(data: InterviewsDashboardType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/dashboard-data/interviews`, data, config);
}

export function getAverageDashboardData(data: AverageDashboardType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/dashboard-data/average`, data, config);
}

export function getNewApplyDailyGraphDashboardData(data: NewApplyDailyGraphDashboardType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/dashboard-data/new-daily-job-apply`, data, config);
}

export function getInterviewCountPassAndRejectGraphDashboardData(data: InterviewCountPassAndRejectGraphDashboardType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/dashboard-data/interview-count-pass-reject`, data, config);
}