// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    editLegalAddressDistrict,
    editLegalAddressVillageNo,
    editLegalAddressHouseNo,
    editLegalAddressProvince,
    editLegalAddressRoad,
    editLegalAddressAlley,
    editLegalAddressSubDistrict,
    editLegalAddressZipCode
} from "../../../../redux/slices/jobAppSlice";

// MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from "@mui/material";

// Utils
import { useTranslation } from "react-i18next";

// Styles
import theme from '../../../../styles/theme'

const LegalAddr: React.FC<any> = ({ errors, provinces, districts, subDistricts }) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const [dataErrorState, setDataErrorState] = useState<any>(errors)

    const { legalAddressInfo } = useAppSelector((state) => state.jobapp);

    const [provincesOption, setProvincesOption] = useState<{
        external_code: number;
        province_code: number;
        label_th: string;
        label_en: string;
        is_active: boolean;
    }[]>([])

    const [districtsOption, setDistrictsOption] = useState([])
    const [subDistrictsOption, setSubDistrictsOption] = useState([])

    const [legalHouseNo, setLegalHouseNo] = useState<string>("")
    const [legalVillageNo, setLegalVillageNo] = useState<string>("")
    const [pmgAlley, setLegalAlley] = useState<string>("")
    const [legalRoad, setLegalRoad] = useState<string>("")
    const [legalSubDistrict, setLegalSubDistrict] = useState<any>(null)
    const [legalDistrict, setLegalDistrict] = useState<any>(null)
    const [legalProvince, setLegalProvince] = useState<any>(null)
    const [legalZipCode, setLegalZipCode] = useState<string>("")

    const [loadingProvinceData, setLoadingProvinceData] = useState<boolean>(true)
    const [loadingDistrictData, setLoadingDistrictData] = useState<boolean>(true)
    const [loadingSubDistrictData, setLoadingSubDistrictData] = useState<boolean>(true)

    useEffect(() => {
        setProvincesOption(provinces)
    }, [provinces]);

    useEffect(() => {
        if (legalProvince !== null) {
            const filterDistrict: any = districts.filter((district: any) => district.province_id === legalProvince?.province_code)

            setDistrictsOption(filterDistrict);
        }
    }, [districts, legalProvince]);

    useEffect(() => {
        if (legalDistrict !== null) {
            const filterSubDistrict: any = subDistricts.filter((subDistrict: any) => subDistrict.amphure_id === legalDistrict?.id)

            setSubDistrictsOption(filterSubDistrict);
        }
    }, [subDistricts, legalDistrict]);

    useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    useEffect(() => {
        if (legalAddressInfo) {
            setLegalHouseNo(legalAddressInfo.address_house_number || '')
            setLegalVillageNo(legalAddressInfo.address_moo || '')
            setLegalAlley(legalAddressInfo.address_lane || '')
            setLegalRoad(legalAddressInfo.address_street || '')
            setLegalProvince(legalAddressInfo.address_province || null)
            setTimeout(() => {
                setLegalDistrict(legalAddressInfo.address_district || null)
            }, 100);
            setTimeout(() => {
                setLegalSubDistrict(legalAddressInfo.address_sub_district || null)
            }, 300);
            setTimeout(() => {
                setLegalZipCode(legalAddressInfo.address_postal_code || '')
                setLoadingProvinceData(false)
                setLoadingDistrictData(false)
                setLoadingSubDistrictData(false)
            }, 500);
        }

    }, [legalAddressInfo])

    useEffect(() => {
        try {
            if (legalProvince !== null) {
                const filterDistrict: any = districts.filter((district: any) => district.province_id === legalProvince?.province_code)

                setDistrictsOption(filterDistrict)
            }

            setLegalDistrict(null)
            setSubDistrictsOption([])
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [legalProvince])

    useEffect(() => {
        try {
            if (legalDistrict !== null) {
                const filterSubDistrict: any = subDistricts.filter((subDistrict: any) => subDistrict.amphure_id === legalDistrict?.id)

                setSubDistrictsOption(filterSubDistrict)
            }

            setLegalSubDistrict(null)
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [legalDistrict])

    useEffect(() => {
        try {
            if (legalSubDistrict === null) {
                setLegalZipCode("")
            }
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [legalSubDistrict])

    return <div>
        <ThemeProvider theme={theme}>
            <Box component="form" noValidate sx={{ mt: 3 }}>
                <Typography sx={{ mt: 6, mb: 3 }}>{t("Legal Address")}</Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            error={dataErrorState.address_house_number ? true : false}
                            required
                            fullWidth
                            id="legalhouseno."
                            label={t("House No.")}
                            placeholder={t("House No.")!}
                            value={loadingProvinceData ? "" : legalHouseNo}
                            onChange={(e) => {
                                setLegalHouseNo(e.target.value)
                                dispatch(editLegalAddressHouseNo(e.target.value))

                                setDataErrorState({ ...dataErrorState, address_house_number: null })
                            }}
                        />
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.address_house_number?.message)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            fullWidth
                            id="legalmoo"
                            label={t("Moo")}
                            placeholder={t("Moo")!}
                            value={loadingProvinceData ? "" : legalVillageNo}
                            onChange={(e) => {
                                setLegalVillageNo(e.target.value)
                                dispatch(editLegalAddressVillageNo(e.target.value))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            id="legalalley"
                            label={t("Lane")}
                            placeholder={t("Lane")!}
                            value={loadingProvinceData ? "" : pmgAlley}
                            onChange={(e) => {
                                setLegalAlley(e.target.value)
                                dispatch(editLegalAddressAlley(e.target.value))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            id="legalroad"
                            label={t("Street")}
                            placeholder={t("Street")!}
                            value={loadingProvinceData ? "" : legalRoad}
                            onChange={(e) => {
                                setLegalRoad(e.target.value)
                                dispatch(editLegalAddressRoad(e.target.value))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                onChange={(event, newValue: any) => {
                                    setLegalProvince(newValue);
                                    setLegalDistrict(null);
                                    setLegalSubDistrict(null);
                                    setLegalZipCode("");
                                    dispatch(editLegalAddressProvince(newValue))
                                    dispatch(editLegalAddressDistrict(null))
                                    dispatch(editLegalAddressSubDistrict(null))
                                    dispatch(editLegalAddressZipCode(""))

                                    setDataErrorState({ ...dataErrorState, address_province: null })
                                }}
                                disableClearable
                                limitTags={1}
                                id="provinces"
                                options={provincesOption}
                                getOptionLabel={(option) => i18n.language === 'th' ? option.label_th : option.label_en}
                                isOptionEqualToValue={(option, value) => option.province_code === value.province_code}
                                value={loadingProvinceData ? null : legalProvince}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props}>
                                            <div>
                                                {i18n.language === 'th' ? option.label_th : option.label_en}
                                            </div>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        required
                                        label={t("Province")}
                                        placeholder={t("Province")!}
                                        error={dataErrorState.address_province ? true : false}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingProvinceData ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.address_province?.message)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                onChange={(event, newValue: any) => {
                                    setLegalDistrict(newValue);
                                    setLegalSubDistrict(null);
                                    setLegalZipCode("");
                                    dispatch(editLegalAddressDistrict(newValue))
                                    dispatch(editLegalAddressSubDistrict(null))
                                    dispatch(editLegalAddressZipCode(""))

                                    setDataErrorState({ ...dataErrorState, address_district: null })
                                }}
                                disableClearable
                                limitTags={1}
                                id="districts"
                                options={districtsOption}
                                getOptionLabel={(option) => i18n.language === 'th' ? option.name_th : option.name_en}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText={t("กรุณาเลือกจังหวัด")}
                                value={loadingDistrictData ? null : legalDistrict}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props}>
                                            <div>
                                                {i18n.language === 'th' ? option.name_th : option.name_en}
                                            </div>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        required
                                        label={t("District")}
                                        placeholder={t("District")!}
                                        error={dataErrorState.address_district ? true : false}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingDistrictData ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.address_district?.message)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                onChange={(event, newValue: any) => {
                                    setLegalSubDistrict(newValue);
                                    setLegalZipCode(newValue?.zip_code || "");
                                    dispatch(editLegalAddressSubDistrict(newValue))
                                    dispatch(editLegalAddressZipCode(newValue?.zip_code || ""))

                                    setDataErrorState({ ...dataErrorState, address_sub_district: null })
                                }}
                                disableClearable
                                limitTags={1}
                                id="sub_districts"
                                options={subDistrictsOption}
                                getOptionLabel={(option) => i18n.language === 'th' ? option.name_th : option.name_en}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText={t("กรุณาเลือกอำเภอ")}
                                value={loadingSubDistrictData ? null : legalSubDistrict}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props}>
                                            <div>
                                                {i18n.language === 'th' ? option.name_th : option.name_en}
                                            </div>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        required
                                        label={t("Sub District")}
                                        placeholder={t("Sub District")!}
                                        error={dataErrorState.address_sub_district ? true : false}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingSubDistrictData ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.address_sub_district?.message)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} display='flex' alignItems='center'>
                        <Typography sx={{ mt: 0 }}>{t("Postal Code")} : {legalZipCode === '' ? <></> : legalZipCode}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    </div>;
};

export default LegalAddr;
