import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/ec';

interface getECDataBody {
    site_ids: string[];
    job_ids: string[];
    ec_ids: string[];
}

interface editECExportedStatusBody {
    ec_ids: string[];
}

export function getECDataById(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.get(`${BASE_URL}/get-ec-data/${data}`, config);
}

export function createECData(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}`, data, config);
}

export function editECData(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.put(`${BASE_URL}`, data, config);
}

export function getECData(data: getECDataBody) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    return api.post(`${BASE_URL}/get-ec-data`, data, config);
}

export function getECExport() {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}/export`,  config);
}

export function editECExportedStatus(data: editECExportedStatusBody) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.put(`${BASE_URL}/edit-exported-status`, data, config);
}

export function checkUserEC(idCard: string) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    return api.post(`${BASE_URL}/check-ec-status`, {id: idCard}, config);
}

export function UpdateStatusEC(id: string) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    return api.post(`${BASE_URL}/update-ec-status`, {status_id: id}, config);
}