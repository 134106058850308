// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import LegalAddr from "./LegalAddr";
import MailingAddr from "./MailingAddr";

// Services
import { fetchDistricts, fetchSubDistricts } from "./fetchGeoData";
import ProvinceJSON from '../../../../services/REGION_THA/Province.json'

interface ErrorType {
    prefixOther?: { message?: string };
    firstName?: { message?: string };
    lastName?: { message?: string };
    gender?: { message?: string };
    weight?: { message?: string };
    height?: { message?: string };
    dateOfBirth?: { message?: string };
    idCardNumber?: { message?: string };
    phoneNumber?: { message?: string };
}


type AddressInfoProps = {
    legalErrors: ErrorType;
    mailingErros: ErrorType;
};

const AddressInfo: React.FC<AddressInfoProps> = ({ legalErrors, mailingErros }) => {
    const navigate = useNavigate()

    const [districts, setDistricts] = useState([])
    // const [subDistricts, setSubDistricts] = useState([])
    const [subDistricts, setSubDistricts] = useState<{
        id: number;
        zip_code: number;
        name_th: string;
        name_en: string;
        amphure_id: number;
    }[]>([])
    useEffect(() => {
        const fetchDistrictsData = async () => {
            try {
                const districtsData = await fetchDistricts()
                setDistricts(districtsData)
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        const fetchSubDistrictsData = async () => {
            try {
                const subDistrictsData = await fetchSubDistricts()
                setSubDistricts(subDistrictsData)
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        fetchDistrictsData()
        fetchSubDistrictsData()
    }, [])

    return <div>
        <LegalAddr errors={legalErrors} provinces={ProvinceJSON} districts={districts} subDistricts={subDistricts} />
        <MailingAddr errors={mailingErros} provinces={ProvinceJSON} districts={districts} subDistricts={subDistricts} />
    </div>;
};

export default AddressInfo;
