import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/users';

interface checkIfUserExistType {
    username: string;
}

export function getAllUsers() {
    return api.get(`${BASE_URL}`);
}

export function getUserById(id: string) {
    return api.get(`${BASE_URL}/${id}`);
}

export function createUser(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}`, data, config);
}

export function editUser(id: string, data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.put(`${BASE_URL}/${id}`, data, config);
}

export function getUserByUsername(username: string) {
    return api.post(`${BASE_URL}/username`, { username });
}

export function checkIfUserExist(data: checkIfUserExistType) {
    return api.get(`${BASE_URL}/check-if-user-exists?username=${data.username}`);
}

export function checkIfCorrectUserMobile(data: any) {
    return api.post(`${BASE_URL}/check-if-correct-user-mobile`, data);
}

export function getUserData(data: any) {
    return api.post(`${BASE_URL}/data`, data);
}

export function queryUsers(data: any) {
    return api.get(`${BASE_URL}/query?search=${data.search}&site=${data.site}&status=${data.status}&type=${data.type}`);
}

export function editUserById(id: string, data: any) {
    return api.put(`${BASE_URL}/${id}`, data);
}

export function checkUsername(username: string) {
    return api.post(`${BASE_URL}/check-username`, { username });
}

export function sendFilesDocuments(formData: any, userId:string) {

    const config = {
        headers: { Authorization: `Bearer ${TOKEN}`,
                    'Content-Type': 'multipart/form-data'
                } 
    };
    return api.post(`${BASE_URL}/${userId}/documents`, formData, config);
}

export function getAllDocuments(userId:string, type:string) {

    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` } 
    };
    return api.get(`${BASE_URL}/documents/user/${userId}`, config);
}

export function deleteDocuments(documentId:string, userId:string) {

    const config = {
        headers: { Authorization: `Bearer ${TOKEN}`} 
    };
    return api.delete(`${BASE_URL}/${userId}/documents/${documentId}`, config);
}

export function getDocumentsfile (fileId:string) {

    const config = {
        headers: { Authorization: `Bearer ${TOKEN}`} 
    };
    return api.get(`${BASE_URL}/documents/file/${fileId}`, config);
}