// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";

const getPublicEnv = async (): Promise<{
    datadog_rum_env: string;
}> => {
    const url = `${window.location.origin}/v1/api/public-variable-frontend`;
    const res = await axios.get(url);
    return res.data as { datadog_rum_env: string };
};

export const datadogMonitoring = (): void => {
    getPublicEnv()
        .then((publicEnvData) => {
            
            if (publicEnvData.datadog_rum_env === "local" || publicEnvData.datadog_rum_env === undefined || publicEnvData.datadog_rum_env === "") return;

            datadogRum.init({
                allowedTracingUrls: [`${window.location.origin}/v1`],
                applicationId: '732491a0-4155-444b-91b8-e3fe5fe6aab5',
                clientToken: 'pubf00be46d415b235d72cc332d289b675b',
                defaultPrivacyLevel: "mask-user-input",
                env: publicEnvData.datadog_rum_env,
                service: "hrjl1",
                sessionReplaySampleRate: 20,
                sessionSampleRate: 100,
                site: "ap1.datadoghq.com",
                trackFrustrations: true,
                trackLongTasks: true,
                trackResources: true,
                trackUserInteractions: true,
                version: process.env.REACT_APP_COMMIT_ID,
            });

            datadogRum.startSessionReplayRecording();
        })
        .catch((error) => {
            console.error("Error fetching public environment data:", error);
        });
};
