import { GridLocaleText } from '@mui/x-data-grid-pro';

export const thTHGrid: Partial<GridLocaleText> = {
    // Root
    noRowsLabel: 'ไม่มีข้อมูล',
    noResultsOverlayLabel: 'ไม่พบผลลัพธ์',

    // Density selector toolbar button text
    toolbarDensity: 'ความสูงของตาราง',
    toolbarDensityLabel: 'ความสูงของตาราง',
    toolbarDensityCompact: 'แคบ',
    toolbarDensityStandard: 'ปกติ',
    toolbarDensityComfortable: 'กว้าง',

    // Columns selector toolbar button text
    toolbarColumns: 'คอลัมน์',
    toolbarColumnsLabel: 'คอลัมน์',

    // Filters toolbar button text
    toolbarFilters: 'ตัวกรอง',
    toolbarFiltersLabel: 'ตัวกรอง',
    toolbarFiltersTooltipHide: 'ซ่อนตัวกรอง',
    toolbarFiltersTooltipShow: 'แสดงตัวกรอง',
    toolbarFiltersTooltipActive: (count) => `${count} ตัวกรองที่ใช้งานอยู่`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'ค้นหา...',
    toolbarQuickFilterLabel: 'ค้นหา',
    toolbarQuickFilterDeleteIconLabel: 'ลบ',

    // Export selector toolbar button text
    toolbarExport: 'ส่งออก',
    toolbarExportLabel: 'ส่งออก',
    toolbarExportCSV: 'ส่งออกเป็น CSV',
    toolbarExportPrint: 'พิมพ์',
    toolbarExportExcel: 'ส่งออกเป็น Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'ค้นหาคอลัมน์',
    columnsPanelTextFieldPlaceholder: 'คอลัมน์',
    columnsPanelDragIconLabel: 'เรียงลำดับคอลัมน์',
    columnsPanelShowAllButton: 'แสดงทั้งหมด',
    columnsPanelHideAllButton: 'ซ่อนทั้งหมด',
    
    // Filter panel text
    filterPanelAddFilter: 'เพิ่มตัวกรอง',
    filterPanelRemoveAll: 'ลบทั้งหมด',
    filterPanelDeleteIconLabel: 'ลบ',
    filterPanelLogicOperator: 'ตัวดำเนินการ',
    filterPanelOperator: 'ตัวดำเนินการ',
    filterPanelOperatorAnd: 'และ',
    filterPanelOperatorOr: 'หรือ',
    filterPanelColumns: 'คอลัมน์',
    filterPanelInputLabel: 'ค่า',
    filterPanelInputPlaceholder: 'ค้นหาค่า',

    // Filter operators text
    filterOperatorContains: 'ประกอบด้วย',
    filterOperatorEquals: 'เท่ากับ',
    filterOperatorStartsWith: 'ขึ้นต้นด้วย',
    filterOperatorEndsWith: 'ลงท้ายด้วย',
    filterOperatorIs: 'คือ',
    filterOperatorNot: 'ไม่ใช่',
    filterOperatorAfter: 'หลังจาก',
    filterOperatorOnOrAfter: 'หลังจากหรือเท่ากับ',
    filterOperatorBefore: 'ก่อน',
    filterOperatorOnOrBefore: 'ก่อนหรือเท่ากับ',
    filterOperatorIsEmpty: 'ค่าว่าง',
    filterOperatorIsNotEmpty: 'ค่าไม่ว่าง',
    filterOperatorIsAnyOf: 'เป็นอันหนึ่งใน',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',

    // Header filter operators text
    headerFilterOperatorContains: 'ประกอบด้วย',
    headerFilterOperatorEquals: 'เท่ากับ',
    headerFilterOperatorStartsWith: 'ขึ้นต้นด้วย',
    headerFilterOperatorEndsWith: 'ลงท้ายด้วย',
    headerFilterOperatorIs: 'คือ',
    headerFilterOperatorNot: 'ไม่ใช่',
    headerFilterOperatorAfter: 'หลังจาก',
    headerFilterOperatorOnOrAfter: 'หลังจากหรือเท่ากับ',
    headerFilterOperatorBefore: 'ก่อน',
    headerFilterOperatorOnOrBefore: 'ก่อนหรือเท่ากับ',
    headerFilterOperatorIsEmpty: 'ค่าว่าง',
    headerFilterOperatorIsNotEmpty: 'ค่าไม่ว่าง',
    headerFilterOperatorIsAnyOf: 'เป็นอันหนึ่งใน',
    'headerFilterOperator=': 'เท่ากับ',
    'headerFilterOperator!=': 'ไม่เท่ากับ',
    'headerFilterOperator>': 'มากกว่า',
    'headerFilterOperator>=': 'มากกว่าหรือเท่ากับ',
    'headerFilterOperator<': 'น้อยกว่า',
    'headerFilterOperator<=': 'น้อยกว่าหรือเท่ากับ',

    // Filter values text
    filterValueAny: 'อะไรก็ได้',
    filterValueTrue: 'จริง',
    filterValueFalse: 'เท็จ',

    // Column menu text
    columnMenuLabel: 'เมนู',
    columnMenuShowColumns: 'แสดงคอลัมน์',
    columnMenuManageColumns: 'จัดการคอลัมน์',
    columnMenuFilter: 'ตัวกรอง',
    columnMenuHideColumn: 'ซ่อนคอลัมน์',
    columnMenuUnsort: 'ยกเลิกการเรียงลำดับ',
    columnMenuSortAsc: 'เรียงลำดับจากน้อยไปมาก',
    columnMenuSortDesc: 'เรียงลำดับจากมากไปน้อย',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count} ตัวกรองที่ใช้งานอยู่`,
    columnHeaderFiltersLabel: 'แสดงตัวกรอง',
    columnHeaderSortIconLabel: 'เรียงลำดับ',

    // Rows selected footer text
    footerRowSelected: (count) => `${count} แถวที่เลือก`,

    // Total row amount footer text
    footerTotalRows: 'รวม',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'เลือก',
    checkboxSelectionSelectAllRows: 'เลือกทั้งหมด',
    checkboxSelectionUnselectAllRows: 'ยกเลิกการเลือกทั้งหมด',
    checkboxSelectionSelectRow: 'เลือกแถว',
    checkboxSelectionUnselectRow: 'ยกเลิกการเลือกแถว',

    // Boolean cell text
    booleanCellTrueLabel: 'จริง',
    booleanCellFalseLabel: 'เท็จ',

    // Actions cell more text
    actionsCellMore: 'เพิ่มเติม',

    // Column pinning text
    pinToLeft: 'ปักหมุดทางซ้าย',
    pinToRight: 'ปักหมุดทางขวา',
    unpin: 'ยกเลิกการปักหมุด',

    // Tree Data
    treeDataGroupingHeaderName: 'การจัดกลุ่ม',
    treeDataExpand: 'ขยาย',
    treeDataCollapse: 'ยุบ',

    // Grouping columns
    groupingColumnHeaderName: 'การจัดกลุ่ม',
    groupColumn: (name) => `${name} คอลัมน์ที่จัดกลุ่ม`,
    unGroupColumn: (name) => `${name} ยกเลิกการจัดกลุ่ม`,

    // Master/detail
    detailPanelToggle: 'เปิด/ปิดรายละเอียด',
    expandDetailPanel: 'ขยาย',
    collapseDetailPanel: 'ยุบ',

    // Row reordering text
    rowReorderingHeaderName: 'การจัดเรียงลำดับ',

    // Aggregation
    aggregationMenuItemHeader: 'รวม',
    aggregationFunctionLabelSum: 'ผลรวม',
    aggregationFunctionLabelAvg: 'ค่าเฉลี่ย',
    aggregationFunctionLabelMin: 'ค่าต่ำสุด',
    aggregationFunctionLabelMax: 'ค่าสูงสุด',
    aggregationFunctionLabelSize: 'ขนาด',
    MuiTablePagination: {
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} จากทั้งหมด ${count}`,
        labelRowsPerPage: 'จำนวนแถวต่อหน้า:',
    },
}