// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  editPrefix,
  editFirstNameTH,
  editLastNameTH,
  editFirstNameEN,
  editLastNameEN,
  editGender,
  editReligion,
  editMaritalStatus,
  editWeight,
  editHeight,
  editDateOfBirth,
  editIdCardNumber,
  editPhoneNumber,
  editEmail,
  editLineID,
  editEmergencyPerson,
  editEmergencyPhone,
  editRelationship,
  editeducation_level,
  editeducation_levelOther,
  editSchool,
  editMilitaryStatus,
  editLegalAddressHouseNo,
  editLegalAddressVillageNo,
  editLegalAddressAlley,
  editLegalAddressRoad,
  editLegalAddressProvince,
  editLegalAddressDistrict,
  editLegalAddressSubDistrict,
  editLegalAddressZipCode,
  editMailingAddressHouseNo,
  editMailingAddressVillageNo,
  editMailingAddressAlley,
  editMailingAddressRoad,
  editMailingAddressProvince,
  editMailingAddressDistrict,
  editMailingAddressSubDistrict,
  editMailingAddressZipCode,
  editMailingSameAsLegalAddress,
  editStartDateOfWork,
  editWorkExperience,
  editNationality
} from "../../../redux/slices/jobAppSlice";

// Components
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import ContactInfo from "./PersonalInfo/ContactInfo";
import MilitaryInfo from "./PersonalInfo/MilitaryInfo";
import EducationInfo from "./PersonalInfo/EducationInfo";
import JobsPositionInfo from "./JobsPositionInfo/JobsPositionInfo";
import AddressInfo from "./AddressInfo/AddressInfo";
import ReviewInfo from "./ReviewInfo/ReviewInfo";
import MultipleFileUploadBasic from "./PersonalInfo/FileUploadInfo";

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { styled, StepConnector, StepLabel, stepConnectorClasses, ThemeProvider } from '@mui/material';

// Utils
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import 'dayjs/locale/en-gb';
import Swal from 'sweetalert2';
import jwtDecode from "jwt-decode";

// Styles
import theme from '../../../styles/theme'
import { green } from "@mui/material/colors";

// Services
import { fetchDistricts, fetchSubDistricts } from "./AddressInfo/fetchGeoData";
import { createAppForm, getLatestSubmittedAppForms } from "../../../services/AppFormService";
import ProvinceJSON from '../../../services/REGION_THA/Province.json'

// Validations
import {
  validateContactInfo,
  validateEducationInfo,
  validateMilitaryInfo,
  validateLegalAddressInfo,
  validatePersonalInfo,
  validateMailingAddressInfo,
  validateJobInfo
} from "./AppFormValidation";

const AppFormPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const { userid, isLoggedIn } = useAppSelector((state) => state.user)
  const { personalInfo, contactInfo, educationInfo, legalAddressInfo, mailingAddressInfo, jobInfo } = useAppSelector((state) => state.jobapp)

  const [activeStep, setActiveStep] = useState<number>(0);
  const [personalInfoErrors, setPersonalInfoErrors] = useState({});
  const [contactInfoErrors, setContactInfoErrors] = useState({});
  const [educationInfoErrors, setEducationInfoErrors] = useState({});
  const [militaryInfoErrors, setMilitaryInfoErrors] = useState({});
  const [legalAddressInfoErrors, setLegalAddressInfoErrors] = useState({});
  const [mailingAddressInfoErrors, setMailingAddressInfoErrors] = useState({});
  const [jobInfoErrors, setJobInfoErrors] = useState({});
  const [uploadFiles, setUploadFiles] = useState<boolean>(true);

  const [sendFormLoading, setSendFormLoading] = useState<boolean>(false);

  useEffect(() => {
    const position = JSON.parse(sessionStorage.getItem('selected_position') || '[]');
    if(position.length < 1){
      Swal.fire({
        icon: 'error',
        title: `<h5 style="margin: 0px">${t('Cannot select this job position')}</h5>`,
        html: `<p>${t('You have already applied for this position.')}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#43A047',
        confirmButtonText: `${t('OK!')}`,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.assign('/jobs');
        }
      })

    }else{
    const getLatestFormAnswers = async () => {
      const data = {
        user_id: userid,
      }
      const response = await getLatestSubmittedAppForms(data);

      if (response.data?.success) {
        const latestFormAnswers: any = response.data.latestAppForm;

        if (latestFormAnswers) {
          Swal.fire({
            title: `<h5 style="margin: 0px; font-size: 25px;">${t("We found your previous application data")}</h5>`,
            html: `<p>${t('Do you want to use the old application data or not?')}<br>${t('The latest entered data will be used instead of current data.')}</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: `${t('Yes')}`,
            cancelButtonColor: '#d33',
            cancelButtonText: `${t('No')}`,
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const initialFormFunction = async () => {
                dispatch(editPrefix(latestFormAnswers.prefix || ""));
                dispatch(editFirstNameTH(latestFormAnswers.first_name_th || ""));
                dispatch(editLastNameTH(latestFormAnswers.last_name_th || ""));
                dispatch(editFirstNameEN(latestFormAnswers.first_name_en || ""));
                dispatch(editLastNameEN(latestFormAnswers.last_name_en || ""));
                dispatch(editGender(latestFormAnswers.gender || ""));
                dispatch(editReligion(latestFormAnswers.religion || ""));
                dispatch(editMaritalStatus(latestFormAnswers.marital_status || ""));
                dispatch(editNationality(latestFormAnswers.nationality || ""));
                dispatch(editHeight(latestFormAnswers.height || ""));
                dispatch(editWeight(latestFormAnswers.weight || ""));
                dispatch(editDateOfBirth(latestFormAnswers.date_of_birth || ""));
                dispatch(editIdCardNumber(latestFormAnswers.id_card_number || ""));
                dispatch(editPhoneNumber(latestFormAnswers.telephone || ""));
                dispatch(editEmail(latestFormAnswers.email || ""));
                dispatch(editLineID(latestFormAnswers.line_id || ""));
                dispatch(editEmergencyPerson(latestFormAnswers.emergency_name || ""));
                dispatch(editEmergencyPhone(latestFormAnswers.emergency_phone || ""));
                dispatch(editRelationship(latestFormAnswers.emergency_relation || ""));
                dispatch(editeducation_level(latestFormAnswers.education_level || ""));
                dispatch(editSchool(latestFormAnswers.education_school || ""));
                dispatch(editMilitaryStatus(latestFormAnswers.military_status || ""));
                dispatch(editWorkExperience(latestFormAnswers.work_experience || ""));
                dispatch(editStartDateOfWork(latestFormAnswers.start_date || ""));

                const legal_address = latestFormAnswers.AppFormAddress.find((address: any) => address.address_type === "LEGAL");
                const mailing_address = latestFormAnswers.AppFormAddress.find((address: any) => address.address_type === "MAILING");

                const highestEdu = latestFormAnswers.education_level || "";
                const highestEduOption = ["ไม่มีวุฒิการศึกษา", "ประถมศึกษา", "มัธยมต้น", "มัธยมปลาย", "ปวช,", "ปวส."]
                if (!highestEduOption.includes(highestEdu)) {
                  dispatch(editeducation_level("อื่น ๆ"));
                  dispatch(editeducation_levelOther(highestEdu));
                }

                const provinceData = ProvinceJSON;
                const districtData = await fetchDistricts();
                const subDistectData = await fetchSubDistricts();

                const legalProvince = provinceData.find((province: any) => province.external_code === parseInt(legal_address.address_province));
                const legalDistrict = districtData.find((district: any) => district.name_th === legal_address.address_district);
                const legalSubDistrict = subDistectData.find((subDistrict: any) => subDistrict.name_th === legal_address.address_sub_district);

                dispatch(editLegalAddressProvince(legalProvince || ""));
                dispatch(editLegalAddressDistrict(legalDistrict || ""));
                dispatch(editLegalAddressSubDistrict(legalSubDistrict || ""));
                dispatch(editLegalAddressHouseNo(legal_address.address_house_number || ""));
                dispatch(editLegalAddressVillageNo(legal_address.address_moo || ""));
                dispatch(editLegalAddressAlley(legal_address.address_lane || ""));
                dispatch(editLegalAddressRoad(legal_address.address_street || ""));
                dispatch(editLegalAddressZipCode(parseInt(legal_address.address_postal_code) || ""));
                dispatch(editMailingSameAsLegalAddress(true));

                if (mailing_address) {
                  const mailingProvince = provinceData.find((province: any) => province.external_code === parseInt(mailing_address.address_province));
                  const mailingDistrict = districtData.find((district: any) => district.name_th === mailing_address.address_district);
                  const mailingSubDistrict = subDistectData.find((subDistrict: any) => subDistrict.name_th === mailing_address.address_sub_district);

                  dispatch(editMailingAddressProvince(mailingProvince || ""));
                  dispatch(editMailingAddressDistrict(mailingDistrict || ""));
                  dispatch(editMailingAddressSubDistrict(mailingSubDistrict || ""));
                  dispatch(editMailingAddressHouseNo(mailing_address.address_house_number || ""));
                  dispatch(editMailingAddressVillageNo(mailing_address.address_moo || ""));
                  dispatch(editMailingAddressAlley(mailing_address.address_lane || ""));
                  dispatch(editMailingAddressRoad(mailing_address.address_street || ""));
                  dispatch(editMailingAddressZipCode(parseInt(mailing_address.address_postal_code) || ""));
                  dispatch(editMailingSameAsLegalAddress(false));
                }

                Swal.fire({
                  title: `${t('Success')}`,
                  text: `${t('Successfully loaded previous application data')}`,
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: theme.palette.primary.main,
                  confirmButtonText: `${t('ok')}`,
                  reverseButtons: true,
                  timer: 5000,
                  timerProgressBar: true,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.scrollTo(0, 0);
                  }
                })

              }

              initialFormFunction();

            }
          })
        }
      }
    }
    getLatestFormAnswers();
  }

  }, [dispatch, t, userid])

  const steps = ['Personal Information', 'Address Information', 'Job Application', 'Upload Documents', 'Review Information'];
  // const steps = ['Personal Information', 'Address Information', 'Job Application', 'Review Information'];

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const handleNext = () => {
    if (activeStep === 0) {
      const newPersonalInfoErrors = validatePersonalInfo(personalInfo);
      setPersonalInfoErrors(newPersonalInfoErrors!);

      const newContactInfoErrors = validateContactInfo(contactInfo);
      setContactInfoErrors(newContactInfoErrors);

      const newEducationInfoErrors = validateEducationInfo(educationInfo);
      setEducationInfoErrors(newEducationInfoErrors);

      const newMilitaryInfoErrors = validateMilitaryInfo({
        military_status: personalInfo.military_status,
      });
      setMilitaryInfoErrors(newMilitaryInfoErrors);

      if (Object.keys(newPersonalInfoErrors!).length === 0 && Object.keys(newContactInfoErrors!).length === 0 && Object.keys(newEducationInfoErrors!).length === 0 && Object.keys(newMilitaryInfoErrors!).length === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
      } else {
        Swal.fire({
          title: `<h5 style="margin: 0px">${t('Please fill in the information completely')}</h5>`,
          html: `<p>${t('Please fill in the information completely and accurately')}<br> ${t('before proceeding to the next step.')}</p>`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: `${t("Confirm")}`,
        })
      }

    } else if (activeStep === 1) {
      const newLegalAddressInfoErrors = validateLegalAddressInfo(legalAddressInfo);
      setLegalAddressInfoErrors(newLegalAddressInfoErrors!);

      const newMailingAddressInfoErrors = validateMailingAddressInfo(mailingAddressInfo);
      setMailingAddressInfoErrors(newMailingAddressInfoErrors!);

      if (Object.keys(newLegalAddressInfoErrors!).length === 0 && Object.keys(newMailingAddressInfoErrors!).length === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
      } else {
        Swal.fire({
          title: `<h5 style="margin: 0px">${t('Please fill in the information completely')}</h5>`,
          html: `<p>${t('Please fill in the information completely and accurately')}<br> ${t('before proceeding to the next step.')}</p>`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: `${t("Confirm")}`,
        })
      }
    } else if (activeStep === 2) {
      let selectedPosition = JSON.parse(sessionStorage.getItem('selected_position')!);

      const newJobInfoErrors = validateJobInfo({
        start_date_of_work: jobInfo.start_date_of_work,
      });
      setJobInfoErrors(newJobInfoErrors);

      if (selectedPosition.length === 0) {
        Swal.fire({
          title: `<h5 style="margin: 0px">${t('No job positions found')}</h5>`,
          html: `<p>${t('Please choose a job position you would like to apply for,')}<br>${t('at least one position, before proceeding to the next steps.')}</p>`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: theme.palette.primary.main,
          confirmButtonText: `${t("Confirm")}`,
        })
      }
      else {
        if (Object.keys(newJobInfoErrors!).length === 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          window.scrollTo(0, 0);
        } else {
          Swal.fire({
            title: `<h5 style="margin: 0px">${t('Please fill in the information completely')}</h5>`,
            html: `<p>${t('Please fill in the information completely and accurately')}<br> ${t('before proceeding to the next step.')}</p>`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: `${t("Confirm")}`,
          })
        }
      }
    } else if (activeStep >= 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo(0, 0);
    } 
  };

  const handleBack = () => {
    setUploadFiles(true)
    setPersonalInfoErrors({});
    setContactInfoErrors({});
    setEducationInfoErrors({});
    setMilitaryInfoErrors({});
    setLegalAddressInfoErrors({});
    setMailingAddressInfoErrors({});
    setJobInfoErrors({});

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = () => {
    try {
      const sendForm = async () => {
        const data = {
          user_id: userid,
          personalInfo,
          contactInfo,
          educationInfo,
          legalAddressInfo,
          mailingAddressInfo,
          selectedPosition: JSON.parse(sessionStorage.getItem('selected_position')!),
          created_by: isLoggedIn ? jwtDecode<any>(localStorage.getItem('access_token')!).Username : null,
          jobInfo
        }

        const response = await createAppForm(data);
        return response;
      }

      Swal.fire({
        title: `<h5 style="margin: 0px">${t('Confirm Application Submission ?')}</h5>`,
        html: `<p>${t("The information entered will be sent to the officer for verification.")}</p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.main,
        confirmButtonText: `${t('Send')}`,
        cancelButtonColor: '#d33',
        cancelButtonText: `${t('Cancel')}`,
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          setSendFormLoading(true);

          const response = await sendForm();

          if (response.data?.success) {
            setSendFormLoading(false);

            sessionStorage.removeItem('selected_position');
            sessionStorage.removeItem('all_selected');

            let timeleft = 10;
            let downloadTimer = setInterval(function () {
              if (timeleft <= 0) {
                clearInterval(downloadTimer);
                if (isLoggedIn) {
                  window.location.assign('/jobs_status?submitted_status=success');
                } else {
                  window.location.assign('/');
                }
              }
              timeleft -= 1;
            }, 1000);

            Swal.fire({
              title: `<h5 style="margin: 0px">${t('Successfully submitted application')}</h5>`,
              html: `<p>${t("The application information has been submitted to the officer.")}</p>` +
                `<p>${t("Please wait for a callback at the provided telephone number")}</p>`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: theme.palette.primary.main,
              confirmButtonText: `${t('ok')}`,
              reverseButtons: true,
              timer: 10000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (isLoggedIn) {
                  window.location.assign('/jobs_status?submitted_status=success');
                } else {
                  window.location.assign('/');
                }
              }
            })
          } else {
            Swal.fire({
              title: `<h5 style="margin: 0px">${t('Failed to submit application')}</h5>`,
              html: `<p>${t("Please try again later.")}</p>`,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: theme.palette.primary.main,
              confirmButtonText: `${t('ok')}`,
              reverseButtons: true,
            })
          }
        }
      });

    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  };

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${activeStep + 1} / ${steps.length}`}</Typography>
        </Box>
      </Box>
    );
  }

  const handleNextUpload = (statusUpload: boolean) => {
   setUploadFiles(statusUpload)
  };
  
  const handleNextStepUpload = () => {
  if(uploadFiles=== false){
    Swal.fire({
      icon: 'warning',
      text: `${t('text_step_upload_file')}`,
      confirmButtonText: `${t('ok')}`,
  });
  }
};

  return <div>
    <ToastContainer style={{ width: "400px" }} />

    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ marginTop: 4, display: { xs: 'flex', sm: 'none' }, gap: { xs: 4 } }}>
            {
              activeStep !== steps.length && (<>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgressWithLabel value={(activeStep + 1) * 100 / 5} size={50} />
                </Box>
              </>)
            }
            <Typography variant="h6">
              {t(steps[activeStep])}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 4, display: { xs: 'none', sm: 'block' } }}>
            <Stepper activeStep={activeStep} connector={<QontoConnector />} alternativeLabel>
              {
                steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{t(label)}</StepLabel>
                    </Step>
                  );
                })
              }
            </Stepper>
          </Box>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              
              {activeStep === 0 ? (<React.Fragment>
                <ThemeProvider theme={theme}>
                  <Box component="form" noValidate sx={{ mt: { xs: 0, sm: 3 } }}>
                    <PersonalInfo errors={personalInfoErrors} />
                    <ContactInfo errors={contactInfoErrors} />
                    <EducationInfo errors={educationInfoErrors} />
                    <MilitaryInfo errors={militaryInfoErrors} />
                  </Box>
                </ThemeProvider>
              </React.Fragment>) : null}
              {activeStep === 1 ? (<React.Fragment>
                <AddressInfo legalErrors={legalAddressInfoErrors} mailingErros={mailingAddressInfoErrors} />
              </React.Fragment>) : null}
              {activeStep === 2 ? (<React.Fragment>
                <JobsPositionInfo errors={jobInfoErrors} />
              </React.Fragment>) : null}
              {activeStep === 3 ? (<React.Fragment>
                <MultipleFileUploadBasic handleNextUpload={handleNextUpload} />
              </React.Fragment>) : null}
             {/* <Box sx={{ textAlign: 'center', mt: 8 }}><Typography>ยังไม่สามารถอัปโหลดไฟล์ได้</Typography></Box> : null}  */}
              {activeStep === 4 ? (<React.Fragment>
                <ReviewInfo />
              </React.Fragment>) : null}
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2, mt: 3, mb: 3 }}>

                {/* <Button onClick={handleClear} sx={{ color: 'error.main', mr: 2 }}>{t("Clear")}</Button> */}

                <Box sx={{ flex: '1 1 auto' }} />

                <Box sx={{ display: 'flex', gap: "16px" }}>
                  {
                    activeStep > 0 ? (
                      <Button onClick={handleBack} sx={{ mr: 1 }}>{t("Back")}</Button>
                    ) : null
                  }

                  {
                    activeStep < steps.length - 1 ? (
                      <Button onClick={() => uploadFiles ? handleNext() :handleNextStepUpload()}>
                          {t("Next")}
                      </Button>
                       ) : null
                  }
                  {
                    activeStep === steps.length - 1 ? (
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={sendFormLoading}
                        onClick={handleSubmit}
                      >
                        {t("Submit")}
                        {sendFormLoading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              zIndex: 999,
                            }}
                          />
                        )}
                      </Button>
                    ) : null
                  }
                </Box>

              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  </div>;
};

export default AppFormPage;
