import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/interviews';

export function getAllInterviews() {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.get(`${BASE_URL}`, config);
}

export function getAllActiveInterviewsByUserId(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/all-active-by-userid`, data, config);
}