interface PersonalInfoType {
    prefix: string;
    first_name_th: string;
    last_name_th: string;
    first_name_en: string;
    last_name_en: string;
    nationality: string;
    religion: string;
    marital_status: string;
    gender: string;
    weight: string;
    height: string;
    date_of_birth: Date | null;
    id_card_number: string;
}

interface ContactInfoType {
    telephone: string;
    email?: string;
    lineID?: string;
    emergencyPerson?: string;
    emergencyPhone?: string;
    emergencyRelationship?: string;
}

interface EducationInfoType {
    education_level: string;
    education_level_other: string;
    education_school: string;
}

interface MilitaryInfoType {
    military_status: string;
}

interface AddressType {
    address_house_number: string;
    address_moo: string;
    address_lane: string;
    address_street: string;
    address_province: Province | null;
    address_district: District | null;
    address_sub_district: SubDistrict | null;
    address_postal_code: string;
    same_as_legal_address?: boolean;
}

interface JobInfoType {
    start_date_of_work: Date | null;
}

interface ErrorType {
    prefix?: { message?: string };
    first_name_th?: { message?: string };
    last_name_th?: { message?: string };
    first_name_en?: { message?: string };
    last_name_en?: { message?: string };
    nationality?: { message?: string };
    religion?: { message?: string };
    marital_status?: { message?: string };
    gender?: { message?: string };
    weight?: { message?: string };
    height?: { message?: string };
    date_of_birth?: { message?: string };
    id_card_number?: { message?: string };
    telephone?: { message?: string };
    education_level?: { message?: string };
    education_level_other?: { message?: string };
    education_school?: { message?: string };
    military_status?: { message?: string };
    address_house_number?: { message?: string };
    address_moo?: { message?: string };
    address_lane?: { message?: string };
    address_street?: { message?: string };
    address_province?: { message?: string };
    address_district?: { message?: string };
    address_sub_district?: { message?: string };
    address_postal_code?: { message?: string };
    start_date_of_work?: { message?: string };
}

interface SubDistrict {
    id: number;
    name_th: string;
    name_en: string;
    geography_id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
}

interface District {
    id: number;
    name_th: string;
    name_en: string;
    province_id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
}

interface Province {
    external_code: string;
    province_code: string;
    label_th: string;
    label_en: string;
    is_active: boolean;
}

export const validatePersonalInfo = (personalInfo: PersonalInfoType) => {
    const errors: ErrorType = {};
    let haveError = false;
    
    if (personalInfo.prefix === '') {
        errors.prefix = { message: 'Prefix is required' };
        haveError = true;
    }

    if (personalInfo.first_name_th === '') {
        errors.first_name_th = { message: 'Thai first name is required' };
        haveError = true;
    }

    if (personalInfo.last_name_th === '') {
        errors.last_name_th = { message: 'Thai last name is required' };
        haveError = true;
    }

    if (personalInfo.first_name_en === '') {
        errors.first_name_en = { message: 'English first name is required' };
        haveError = true;
    }

    if (personalInfo.last_name_en === '') {
        errors.last_name_en = { message: 'English last Name is required' };
        haveError = true;
    }

    if (personalInfo.nationality === '') {
        errors.nationality = { message: 'Nationality is required' };
        haveError = true;
    }

    if (personalInfo.religion === '') {
        errors.religion = { message: 'Religion is required' };
        haveError = true;
    }

    if (personalInfo.marital_status === '') {
        errors.marital_status = { message: 'Marital status is required' };
        haveError = true;
    }

    if (personalInfo.gender === '') {
        errors.gender = { message: 'Gender is required' };
        haveError = true;
    }

    if (personalInfo.weight === '') {
        errors.weight = { message: 'Weight is required' };
        haveError = true;
    }

    if (personalInfo.height === '') {
        errors.height = { message: 'Height is required' };
        haveError = true;
    }

    if (personalInfo.date_of_birth === null) {
        errors.date_of_birth = { message: 'Date of birth is required' };
        haveError = true;
    }

    if (personalInfo.id_card_number === '' || personalInfo.id_card_number === null ) {
        errors.id_card_number = { message: 'ID card number is required' };
        haveError = true;
    } else if (personalInfo.id_card_number && personalInfo.id_card_number.length !== 13) {
        errors.id_card_number = { message: 'ID card number must be 13 digits' };
        haveError = true;
    }

    if (!haveError) {
        return {};
    }

    return errors;
};

export const validateContactInfo = (contactInfo: ContactInfoType) => {
    const errors: ErrorType = {};
    let haveError = false;

    if (contactInfo.telephone === '') {
        errors.telephone = { message: 'Phone number is required' };
        haveError = true;
    } else if (contactInfo.telephone.length !== 10) {
        errors.telephone = { message: 'Phone number must be 10 digits' };
        haveError = true;
    }

    if (!haveError) {
        return {};
    }

    return errors;
}

export const validateEducationInfo = (educationInfo: EducationInfoType) => {
    const errors: ErrorType = {};
    let haveError = false;

    if (educationInfo.education_level === '') {
        errors.education_level = { message: 'Education level is required' };
        haveError = true;
    }

    if (educationInfo.education_level === 'อื่น ๆ' && educationInfo.education_level_other === '') {
        errors.education_level_other = { message: 'Education level other is required' };
        haveError = true;
    }

    if (!haveError) {
        return {};
    }

    return errors;
}

export const validateMilitaryInfo = (militaryInfo: MilitaryInfoType) => {
    const errors: ErrorType = {};
    let haveError = false;

    if (militaryInfo.military_status === '') {
        errors.military_status = { message: 'Military status is required' };
        haveError = true;
    }

    if (!haveError) {
        return {};
    }

    return errors;
}

export const validateLegalAddressInfo = (addressInfo: AddressType) => {
    const errors: ErrorType = {};
    let haveError = false;

    if (addressInfo.address_house_number === '') {
        errors.address_house_number = { message: 'House number is required' };
        haveError = true;
    }

    if (addressInfo.address_province === null) {
        errors.address_province = { message: 'Province is required' };
        haveError = true;
    }

    if (addressInfo.address_district === null) {
        errors.address_district = { message: 'District is required' };
        haveError = true;
    }

    if (addressInfo.address_sub_district === null) {
        errors.address_sub_district = { message: 'Sub District is required' };
        haveError = true;
    }

    if (addressInfo.address_postal_code === '') {
        errors.address_postal_code = { message: 'Zip code is required' };
        haveError = true;
    }

    if (!haveError) {
        return {};
    }

    return errors;
}

export const validateMailingAddressInfo = (addressInfo: AddressType) => {
    const errors: ErrorType = {};
    let haveError = false;

    if (addressInfo.same_as_legal_address) {
        return {};
    }

    if (addressInfo.address_house_number === '') {
        errors.address_house_number = { message: 'House number is required' };
        haveError = true;
    }

    if (addressInfo.address_province === null) {
        errors.address_province = { message: 'Province is required' };
        haveError = true;
    }

    if (addressInfo.address_district === null) {
        errors.address_district = { message: 'District is required' };
        haveError = true;
    }

    if (addressInfo.address_sub_district === null) {
        errors.address_sub_district = { message: 'Sub District is required' };
        haveError = true;
    }

    if (addressInfo.address_postal_code === '') {
        errors.address_postal_code = { message: 'Zip code is required' };
        haveError = true;
    }


    if (!haveError) {
        return {};
    }

    return errors;
}

export const validateJobInfo = (jobInfo: JobInfoType) => {
    const errors: ErrorType = {};
    let haveError = false;

    if (jobInfo.start_date_of_work === null) {
        errors.start_date_of_work = { message: 'Start date of work is required' };
        haveError = true;
    }

    if (!haveError) {
        return {};
    }

    return errors;
};