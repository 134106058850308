import React, { useState, useEffect } from 'react'
import { useParams, Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, CircularProgress, Link } from '@mui/material';
import Swal from 'sweetalert2';
import { useAppSelector } from '../../../redux/hooks';

// @MUI Icons
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { checkDupJobApply, getJobById } from "../../../services/JobService";
import theme from "../../../styles/theme";
import ConfirmationDialogPDPA from "../../auth/sign_up/consentPDPA";

interface Job {
    id: string
    job_id: string;
    job_name: string;
    num_openings: number;
    Site: {
        site_id: string;
        site_name: string;
    },
    company: string;
    contract_signer: string;
    start_date: Date;
    end_date: Date;
    job_description: { jobTitle: string, jobDetials: string }[];
    is_active: boolean;
    hr_name: string;
    hr_email: string;
    hr_phone: string;
}

const DetailOpenJob = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
 //FOR FILTERS
    const { userid } = useAppSelector(state => state.user)
    const [loadingJob, setLoadingJob] = useState(true);
    const allSuccessLoading = !loadingJob;
    const [jobData, setJobData] = useState<Job>({
        job_id: "",
        job_name: "",
        num_openings: 0,
        Site: {
            site_id: '',
            site_name: ''
        },
        company: "",
        contract_signer: "",
        start_date: new Date(),
        end_date: new Date(),
        hr_name: "",
        hr_email: "",
        hr_phone: "",
        is_active: true,
        id: "",
        job_description: [{ jobTitle: "", jobDetials: "" }],
    })
    
    var [selectData, setSelectData] = useState<Job[]>([]);
    var [allJobId, setAllJobId] = useState<String[]>([])
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [openPDPA, setOpenPDPA] = useState<boolean>(false)
    let jobID = ''

    useEffect(() => {
        try {
          if (localStorage.getItem('access_token') !== null) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        } catch (error) {
          setIsLoggedIn(false);
        }
      }, [])

    function ApplyJob(id: any) {
        const checkdupjobapply = async () => {
          try {
            jobID = id;
            const checked = true;
            const data = {
              job_id: id,
              user_id: userid,
            }
            const response = await checkDupJobApply(data);
    
            if (!response.data?.success) {
              Swal.fire({
                icon: 'error',
                title: `<h5 style="margin: 0px">${t('Cannot select this job position')}</h5>`,
                html: `<p>${t('You have already applied for this position.')}</p>`,
                showCancelButton: false,
                confirmButtonColor: '#43A047',
                confirmButtonText: `${t('OK!')}`,
                allowOutsideClick: false,
              })
              return true;
            } else if (response.data?.success) {
              if (checked) {
                allJobId.push(jobID)
    
                const fetchSelectJobs = async () => {
                  try {
                    const response = await getJobById(jobID);
                    const data = response.data?.job;
    
                    data.check = true
                    selectData = [...selectData, data]
                    setSelectData(selectData)
    
                    const selectJob: any = []
                    selectJob.push(data)
                    sessionStorage.setItem('all_selected', JSON.stringify(selectJob));
                    onSubmit() 

                  } catch (error) {
                    console.error(error);
                  }
                };
                fetchSelectJobs();
              }
    
              const selectID: any = []
              selectID.push(jobID)
              sessionStorage.setItem('selected_position', JSON.stringify(selectID));
            }
          } catch (error) {
            navigate('/servererror', { replace: true });
          }
        };
        checkdupjobapply();
      }

      const onSubmit = () => {
        if (selectData.length !== 0) {
          Swal.fire({
            icon: 'warning',
            title: `<h5 style="margin: 0px">${t('Are you sure you want to proceed with the transaction?')}</h5>`,
            html: `<p>${t('Please verify the information before proceeding.')}</p>`,
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: `${t('Confirm')}`,
            cancelButtonText: `${t('Cancel')}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              if(!isLoggedIn){
                 window.location.assign('/register')
              }else{
                setOpenPDPA(true)
                // window.location.assign('/appform') 
              }
            }
          })
        }
        else {
          Swal.fire({
            title: `<h5 style="margin: 0px">${t('Cannot proceed with the transaction')}</h5>`,
            html: `<p>${t('Please select the position you want to apply for.')}</p>`,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#43A047',
            confirmButtonText: `${t('OK!')}`,
            allowOutsideClick: false,
          })
          return null;
        }       
      }

    useEffect(() => {
        const fetchJobById = async () => {
            try {

                const response = await getJobById(id);

                if (!response.data?.success) {
                    navigate('/admin/jobs', { replace: true });
                    return;
                }
                const jobData = response.data?.job;
                setJobData(jobData);

                const jobDescription = JSON.parse(jobData.job_description)
                setJobData({
                    ...jobData,
                    job_description: jobDescription
                })
                
                setLoadingJob(false);
            } catch (error: any) {
                navigate('/servererror', { replace: true, state: { error: error.message } });
            }
        };

        fetchJobById();
    }, []);

    //CHANGE FORMAT DATE
    const monthTH: string[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    const monthEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    function changeFormat(date: Date) {
        const parse_date: Date = new Date(date)
        let formatted_date: string = "";
        i18n.language === 'th' ?
        formatted_date = parse_date.getDate() + ' ' + monthTH[parse_date.getMonth()] + ' ' + (parse_date.getFullYear() + 543) :
        formatted_date = parse_date.getDate() + ' ' + monthEN[parse_date.getMonth()] + ' ' + parse_date.getFullYear()
        return (formatted_date)
    }

    let allJobTopic: string[] = [];
    if (jobData.job_id !== "") {
        for (let i = 0; i < jobData.job_description.length; i++) {
            Object.values(jobData.job_description[i]).forEach((_, index) => {
                allJobTopic.push(Object.values(jobData.job_description[i])[index])
            })
        }
    }

    return <div>
        <div>
        {
            allSuccessLoading ?
                <>
                    <Box sx={{ mt: 2, ml: { sm: 4 } }}>
                        <Box display='flex' gap={3} sx={{ mb: 3 }}>
                            <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                                textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                    color: '#1976d2',
                                }
                            }}>
                                <ChevronLeftIcon fontSize='small' />
                                <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                            </Box>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    component={ReactRouterLink}
                                    to="/"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    {t("Home")}
                                </Link>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    <WorkIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    {jobData.job_name}
                                </Typography>
                            </Breadcrumbs>
                        </Box>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography gutterBottom variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
                                {jobData.job_name}
                            </Typography>
                          
                            <Button variant='contained' sx={{ width: 120 }} 
                            onClick={() => {
                                ApplyJob(jobData.id)
                              }}
                              >{t("Apply job")} </Button>
                        </Grid>
                        <Grid container sx={{ my: 2 }}>
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ mr: 4, mb: 1 }}
                                >
                                    <BusinessRoundedIcon fontSize="small" color='warning' />
                                    <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                        {jobData.company}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ mr: 4, mb: 1 }}
                                >
                                    <LocationOnRoundedIcon fontSize='small' sx={{ color: '#FF9824' }} />
                                    <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                        {jobData.Site.site_name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ mr: 4, mb: 1 }}
                                >
                                    <InsertInvitationIcon fontSize='small' sx={{ color: '#4CAF50' }} />
                                    <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                        {`${changeFormat(jobData.start_date)}`} - {`${changeFormat(jobData.end_date)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ mr: 4 }}
                                >
                                    <PeopleRoundedIcon fontSize='small' sx={{ color: '#04A9F4' }} />
                                    <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                        {jobData.num_openings} {t("Openings")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Typography>
                            {t("Workplace")} dd : {jobData.Site.site_name}
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {t("Company")} : {jobData.company}
                        </Typography>

                        {allJobTopic.map((data, index) => {
                            let dataDetails: string[] = [];
                            if ((index % 2) !== 0) {
                                dataDetails = data.split('\n')
                            }
                            return <div key={index}>
                                {dataDetails.length !== 0 ? (
                                    <>
                                        {dataDetails.map((detail, i) => {
                                            return (
                                                <Typography key={i} variant="body1" component="div">
                                                    {detail}
                                                </Typography>
                                            );
                                        })}
                                    </>
                                ) :
                                    (
                                        <Typography gutterBottom variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                                            {data}
                                        </Typography>
                                    )}
                            </div>;

                        })}

                        <Grid container direction="column" sx={{ my: 3 }}>
                            <Typography gutterBottom variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                                {t("Contract HR Recruiter")}
                            </Typography>
                            <Typography variant="body1" component="div" >
                                {jobData.hr_name}
                            </Typography>
                            <Typography variant="body1" component="div" >
                                {t("Email") + " :"} {jobData.hr_email}
                            </Typography>
                            <Typography variant="body1" component="div" >
                                {t("Tel") + " :"} {jobData.hr_phone}
                            </Typography>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mb: 4 }}
                        >
                            <Button variant='contained' sx={{ width: 180 }} component={ReactRouterLink} to={"/jobs"}>{t('Home')} </Button>
                        </Grid>

                    </Box>
                </>
                : <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}  >
                        <CircularProgress />
                    </Box>
                </>
        }
    </div >;
    <div>
        <ConfirmationDialogPDPA
            id="pdpa-consent"
            keepMounted
            open={openPDPA}
            onClose={() => {
            setOpenPDPA(false)
            window.location.assign('/appform') 
            // onSubmit()
            }}
            onCancel={() => {
            setOpenPDPA(false)
            }}
        />
    </div>
    

    </div>
}

export default DetailOpenJob