import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, CssBaseline, IconButton, ListItemIcon, Menu, MenuItem, useTheme, Collapse, Link, ListItemButton, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import GreatBritainFlag from "../../assets/images/Great-Britain-Flag.png"
import ThailandFlag from "../../assets/images/Thailand-Flag.png"
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { eraseCookie } from '../../cookie';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { editIsLoggedIn } from '../../redux/slices/userSlice';
import '../../styles/Admin.scss';
import Icon from '@mui/material/Icon';
import Swal from 'sweetalert2';
import { getMenuOfRole } from '../../services/MenuService';

const drawerWidth = 240;

interface RootObject {
    menu_id: string;
    menu_name: string;
    parent_id?: any;
    icon?: any;
    children: Child[];
    is_active: boolean;
}

interface Child {
    children_id: string;
    menu_name: string;
    route_name?: string;
    icon: string;
    is_active: boolean;
}

function AdminAppbar() {
    const theme = useTheme();
    const location = useLocation();
    const path = location.pathname.replace(/\/$/, '');
    const pathParts = path.split("/");
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const { fNameTH, lNameTH, fNameEN, lNameEN, rolename } = useAppSelector((state) => state.user);
    const { roleid } = useAppSelector(state => state.user)

    const [ChangeLang, setChangeLang] = useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const [activeMenu, setActiveMenu] = useState<{ [key: string]: boolean }>({});
    const [opened, setOpened] = useState<{ [key: string]: boolean }>({});
    const [menusData, setMenusData] = useState<RootObject[]>([])

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language') === null ? (
            localStorage.setItem('language', 'th')!
        ) : localStorage.getItem('language')!)
    }, [i18n])

    const onClickLanguageChangeENtoTH = () => {
        i18n.changeLanguage("en");
        localStorage.setItem('language', 'en')
    };
    const onClickLanguageChangeTHtoEN = () => {
        i18n.changeLanguage("th");
        localStorage.setItem('language', 'th')
    };

    const openLang = Boolean(ChangeLang);
    const handleClickLang = (event: React.MouseEvent<HTMLElement>) => {
        setChangeLang(event.currentTarget);
    };
    const handleCloseLang = () => {
        setChangeLang(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const toggleOpen = (menu_name: string) => {
        setOpened((prevOpened) => ({
            ...prevOpened,
            [menu_name]: !prevOpened[menu_name],
        }));
    };

    const handleActiveMenu = (menu_name: string) => {
        // set all previous active menu to false
        Object.keys(activeMenu).forEach((key) => {
            activeMenu[key] = false;
        });

        // set current menu to true
        setActiveMenu((prevActive) => ({
            ...prevActive,
            [menu_name]: true,
        }));
    };

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const data = {
                    roleId: roleid
                }

                const response = await getMenuOfRole(data);

                if (response.data?.success) {
                    setMenusData(response.data?.menus);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        fetchMenus();
    }, [])

    useEffect(() => {
        if (pathParts[2] === 'masterdata') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'MasterData': true,
            }));
        } else if (pathParts[2] === 'jobs') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'JobHiring': true,
            }));
        } else if (pathParts[2] === 'application-form') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'ApplicationForm': true,
            }));
        } else if (pathParts[2] === 'interview') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'Interview': true,
            }));
        }
    }, [])

    const drawer = (
        <div>
            <Toolbar />
            <Box sx={{ overflow: 'auto', paddingX: 2 }}>
                <List>
                    {
                        menusData.length > 0 && menusData.map((menu, index) => (
                            <div key={index}>
                                <Box onClick={() => toggleOpen(menu.menu_name)} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '210px' }}>
                                    <Typography variant="h5" sx={{ fontSize: '12px', marginLeft: 2, marginTop: 3, marginBottom: 1, fontWeight: 'bold', userSelect: 'none', textTransform: 'uppercase' }} >
                                        {t(menu.menu_name)}
                                    </Typography>
                                    <Box>
                                        {opened[menu.menu_name] ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                                    </Box>
                                </Box>
                                <Collapse in={opened[menu.menu_name]} timeout="auto" unmountOnExit>
                                    {
                                        menu.children.length > 0 ? (
                                            <>
                                                {
                                                    menu.children.map((child, childIndex) => (
                                                        <Link key={childIndex}
                                                            component={ReactRouterLink}
                                                            to={`${child.route_name}`}
                                                            color="inherit"
                                                            underline="none"
                                                            onClick={() => handleActiveMenu(child.menu_name)}
                                                        >
                                                            <ListItemButton sx={{ pl: 2, marginBottom: 0.5, borderRadius: 1, backgroundColor: activeMenu[child.menu_name] ? theme.palette.primary.main : 'inherit', "&:hover": { backgroundColor: theme.palette.primary.main } }}>
                                                                <ListItemIcon sx={{ minWidth: 0 }}>
                                                                    {
                                                                        <Icon sx={{ color: activeMenu[child.menu_name] ? 'white' : 'inherit' }}>{child.icon}</Icon>
                                                                    }
                                                                </ListItemIcon>
                                                                <Typography sx={{ pl: 1, fontSize: 16, color: activeMenu[child.menu_name] ? 'white' : 'inherit' }}>
                                                                    {t(child.menu_name)}
                                                                </Typography>
                                                            </ListItemButton>
                                                        </Link>
                                                    ))
                                                }
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Collapse>
                            </div>
                        ))
                    }
                </List>
            </Box>
        </div>
    )

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        try {
            Swal.fire({
                title: 'ออกจากระบบ',
                text: "คุณต้องการออกจากระบบหรือไม่?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่, ออกจากระบบ',
                cancelButtonText: 'ยกเลิก',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    const logout = async () => {
                        try {
                            eraseCookie();
                            sessionStorage.removeItem('get_latest_form');
                            sessionStorage.removeItem('selected_position');
                            sessionStorage.removeItem('all_selected');
                            sessionStorage.removeItem('id_card_number');
                            dispatch(editIsLoggedIn(false));
                            window.location.assign(`/login`);
                        } catch (error) {
                            navigate('/servererror', { replace: true });
                        }
                    };

                    logout();
                }
            })
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    };

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: theme.palette.primary.main, boxShadow: 'none', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <Icon sx={{ color: 'white' }}>menu</Icon>
                    </IconButton>
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", sm: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth
                            }
                        }}
                    >
                        {drawer}
                    </Drawer>

                    <div style={{ marginRight: '16px', backgroundColor: 'white', padding: 1, borderRadius: 4 }}>
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png"
                            alt="Betagro Logo"
                            style={{ display: 'block', width: '40px' }}
                        />
                    </div>
                    <Typography
                        component="h1"
                        variant="h6"
                        noWrap
                        sx={{ flexGrow: 1, fontWeight: 'bold', color: 'white', }}
                        align='left'
                    >
                        HR Hiring Management
                    </Typography>

                    <Stack direction="row" spacing={2}>
                        <IconButton
                            id="language-button"
                            aria-controls={open ? "lang-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickLang}
                            sx={{ color: 'white', position: 'relative' }}
                        >
                            <Icon>language</Icon>
                            {
                                i18n.language === 'en' ? <img src={GreatBritainFlag} alt='Great Britain flag' width='24px' style={{ position: 'absolute', bottom: 4, right: 2 }} /> :
                                    <img src={ThailandFlag} alt='Thailand flag' width='24px' style={{ position: 'absolute', bottom: 4, right: 2 }} />
                            }
                        </IconButton>
                        <Menu
                            id="lang-menu"
                            anchorEl={ChangeLang}
                            open={openLang}
                            onClose={handleCloseLang}
                            onClick={handleCloseLang}
                            MenuListProps={{
                                "aria-labelledby": "language-button"
                            }}
                        >
                            <MenuItem onClick={onClickLanguageChangeTHtoEN}><img src={ThailandFlag} alt='Thailand flag' width='24px' style={{ marginRight: '8px' }} />ไทย</MenuItem>
                            <MenuItem onClick={onClickLanguageChangeENtoTH}><img src={GreatBritainFlag} alt='Great Britain flag' width='24px' style={{ marginRight: '8px' }} />English</MenuItem>
                        </Menu>

                        <IconButton
                            onClick={handleClick}
                            size="medium"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ backgroundColor: '#e1e8eb', color: 'blue', }} {...stringAvatar(`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`)} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                        >
                            {/* <MenuItem onClick={() => {
                                dispatch(logOut());
                            }}> */}
                            <Box px={4} pt={1} pb={2} display='flex' gap={2} alignItems='center'>
                                <Avatar sx={{ backgroundColor: '#e1e8eb', color: 'blue' }} {...stringAvatar(`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`)} />
                                <Box>
                                    <Typography sx={{ fontWeight: 600 }}>{`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`}</Typography>
                                    <Typography>{`${t("Role")}: ${rolename}`}</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            {/* <MenuItem
                                // onClick={}
                                sx={{
                                    py: 1.2, px: 4
                                }}
                            >
                                <ListItemIcon>
                                    <Icon fontSize="medium">account_box</Icon>
                                </ListItemIcon>
                                {t("Profile")}
                            </MenuItem> */}
                            <MenuItem
                                onClick={handleLogout}
                                sx={{
                                    py: 1.2, px: 4
                                }}
                            >
                                <ListItemIcon>
                                    <Icon fontSize="medium">logout</Icon>
                                </ListItemIcon>
                                {t("Logout")}
                            </MenuItem>
                        </Menu>
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    )
}
export default AdminAppbar;