// @React
import React, { useEffect, useState } from 'react';

// @MUI Components
import { Box, Grid, ThemeProvider, Typography, CircularProgress, Card, CardContent, Pagination } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import theme from "../../../styles/theme";
import usePagination from '../../../components/common/Pagination';

// @Utils
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';

// @React-Router-Dom
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// import other pages
import JobStatusTable from './JobStatusTable';
import FileUploadStatus from '../AppFormPage/PersonalInfo/FileUploadStatus';
import JobsStatusCompleteTable from './JobsStatusCompleteTable';
import JobScheduleCard from './JobScheduleCard';
import {  getAllAppFormDataByUserId } from '../../../services/AppFormService';
import { useAppSelector } from '../../../redux/hooks';

function otherProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface DecodedToken {
  UserId: string;
}

const JobsStatus: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isLoggedIn } = useAppSelector(state => state.user);

  const [inprogress_jobAppform, setInprogress_jobAppform] = useState<any[]>([]);
  const [complete_jobAppform, setComplete_jobAppform] = useState<any[]>([]);
  const [jobSchedule, setJobSchedule] = useState<any[]>([]);
  const [jobSuccess, setSuccessJob] = useState<any[]>([]);

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [valueOfTap, setValueOfTap] = useState<number>(0);

  const [approveJob, setApproveJob] = useState<boolean>(false);

  const handleTapsChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueOfTap(newValue);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
      return;
    }

    const fetchAllAppFormById = async () => {
      try {
        const parsedToken: DecodedToken = jwtDecode(localStorage.access_token);

        const data = {
          user_id: parsedToken.UserId
        }

        const response = await getAllAppFormDataByUserId(data);

        if (response.data?.success) {
          const appformData = response.data?.appForms;

          const findAppForm_inprogress: any = appformData.filter((value: any) => value.Status.id < 4);
          setInprogress_jobAppform(findAppForm_inprogress);

          const findAppform_complete: any = appformData.filter((value: any) => value.Status.id > 3);
          setComplete_jobAppform(findAppform_complete)

          const isJobSchedule: any = appformData.filter((value: any) => value.Status.id === 3)
          setJobSchedule(isJobSchedule)

          const isApproveJob: boolean = appformData.findIndex((value: any) => value.Status.id === 4) >= 0 ? true :false
          setApproveJob(isApproveJob)

          const successJob: any = appformData.filter((value: any) => value.Status.id === 4);
          setSuccessJob(successJob)

          if (searchParams.get('content_type') === 'completedTable') {
            setValueOfTap(1)
            navigate('/jobs_status?content_type=completedTable', { replace: true });
          } else {
            navigate('/jobs_status?content_type=inprogressTable', { replace: true });
          }

        } else {
          navigate('/jobs_status', { replace: true });
        }

      } catch (error) {
        navigate('/servererror', { replace: true });
        console.log(error);
      } finally {
        setTimeout(() => {
          setLoadingData(false);
        }, 500);
      }
    };
    fetchAllAppFormById();
  }, [navigate]);

  const countJob = (value: number): string => {
    let numberOfJobs: string = "";
    if (!inprogress_jobAppform.length && value === 0) {
      return numberOfJobs;
    }
    else if (!complete_jobAppform.length && value === 1) {
      return numberOfJobs;
    }
    if (value === 0) {
      numberOfJobs = "(" + inprogress_jobAppform.length.toString() + " " + `${t("Position") + ")"}`
    }
    else if (value === 1) {
      numberOfJobs = "(" + complete_jobAppform.length.toString() + " " + `${t("Position") + ")"}`
    }
    return numberOfJobs;
  }

  //PAGINATION
  let [page, setPage] = useState(1)
  const PER_PAGE = 2
  const count = Math.ceil(jobSchedule.length / PER_PAGE)
  const DATA = usePagination(jobSchedule, PER_PAGE)

  const handleChange = (e: any, p: any) => {
    setPage(p)
    DATA.jump(p)
  }

  return <div>
    <ThemeProvider theme={theme}>
      <Box>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 4, mb: 1, fontWeight: 'bold', fontSize: { xs: '17px', sm: '20px' }, letterSpacing: 0.5 }}>
            {t("Your job application status")}
          </Typography>
        </Grid>
      </Box>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueOfTap} onChange={handleTapsChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            }
          }}
          sx={{
            ".Mui-selected": {
              color: theme.palette.primary.main,
              fontSize: { xs: 14, sm: 15 }
            },
          }}
        >
          <Tab
            label={t("in progress") + ` ${countJob(0)}`}
            {...otherProps(0)}
            sx={{ letterSpacing: 0.5, fontSize: { xs: 13, sm: 14 } }}
            component={Link} to='/jobs_status?content_type=inprogressTable'
          />
          <Tab
            label={t("operation completed") + ` ${countJob(1)}`}
            {...otherProps(1)}
            sx={{ letterSpacing: 0.5, fontSize: { xs: 13, sm: 14 } }}
            component={Link} to='/jobs_status?content_type=completedTable'
          />
        { approveJob && (<Tab
            label={t("text_attachment_application_pass")} 
            {...otherProps(2)}
            sx={{ letterSpacing: 0.5, fontSize: { xs: 13, sm: 14 } }}
            component={Link} to='/jobs_status?content_type=uploadTable'
          />
        )}
        </Tabs>
      </Box>
      <Box sx={{ width: '100%', my: 4 }}>
        {!loadingData ? (<>
          {valueOfTap === 0 ? (
            <JobStatusTable value={valueOfTap} index={0} data={inprogress_jobAppform} />
          ) : (
            <JobsStatusCompleteTable value={valueOfTap} index={1} data={complete_jobAppform} />
          )}
          {valueOfTap === 2 ? (<React.Fragment>
                <FileUploadStatus data={jobSuccess} />
              </React.Fragment>) : null}
              
          {valueOfTap === 0 && jobSchedule.length !== 0 ? (<>
            <Box>
              <Grid item xs={12} sm={9}>
                <Typography sx={{ mt: 4, mb: 2, fontWeight: 'bold', fontSize: { xs: '17px', sm: '20px' }, letterSpacing: 0.5 }} >
                  {t("Schedule an interview appointment")}
                </Typography>
              </Grid>
            </Box>
            <Box sx={{ minWidth: 275 }}>
              {DATA.currentData().map((data: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Card variant="outlined" sx={{ mb: 2 }}>
                      <JobScheduleCard scheduleData={data} />
                    </Card>
                  </React.Fragment>
                );
              })}
            </Box>
            <Grid container direction="row" justifyContent="center" sx={{ pb: 2 }}>
              <Pagination
                count={count}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          </>) : (
            (inprogress_jobAppform.length && valueOfTap === 0) ? (<>
              <Box>
                <Grid item xs={12} sm={9}>
                  <Typography sx={{ mt: 4, mb: 2, fontWeight: 'bold', fontSize: { xs: '17px', sm: '20px' }, letterSpacing: 0.5 }} >
                    {t("Schedule an interview appointment")}
                  </Typography>
                </Grid>
              </Box>
              <Card variant="outlined" sx={{ minWidth: 275, mb: 10, p: 2 }}>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', sm: '16px' },
                      display: 'flex', justifyContent: 'center', alignItems: 'center',
                      letterSpacing: 0.5
                    }}>
                    {t("No interview appointment scheduled")}
                  </Typography>
                </CardContent>
              </Card>
            </>) : null
          )}
        </>) : (
          <Box display='flex' justifyContent='center' gap={2}>
            <CircularProgress color="success" size={20} />
            <Typography align='center'>{t("Loading")}...</Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  </div>;
};

export default JobsStatus;
