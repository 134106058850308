// React
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";
import useDebounce from "../../../hooks/useDebounce";

// MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
    GridCellParams,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton
} from "@mui/x-data-grid";

import { DataGridPro, enUS } from "@mui/x-data-grid-pro";

// Utils
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useTranslation } from "react-i18next";

// Types
import theme from "../../../styles/theme";
import { thTHGrid } from "../../../@types/DataGridLocaleText";
import { FilterOption, Site } from "../../../@types";

// Services
import { querySites } from "../../../services/SiteService";

const Sites = () => {
    const { t, i18n } = useTranslation();
    const userRoleType = useAppSelector(state => state.user.roletype);
    const navigate = useNavigate();

    const [siteLoading, setSiteLoading] = useState<boolean>(true);
    const [siteData, setSiteData] = useState<Site[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<FilterOption>({ "label": `${t("All")}`, "value": null });
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const allSuccessLoading = !siteLoading;

    useEffect(() => {
        const fetchSites = async () => {
            try {
                setSiteLoading(true);

                const queryData = `search=${debouncedSearchQuery}&status=${selectedStatus.value}`;
                const response = await querySites(queryData);
                const data = await response.data;

                if (data.success) {
                    setSiteData(data.sites);
                } else {
                    setSiteData([]);
                }

            } catch (error) {
                navigate('/servererror', { replace: true });
            } finally {
                setTimeout(() => {
                    setSiteLoading(false);
                    setIsSearching(false);
                }, 500);
            }
        };

        if (debouncedSearchQuery !== '') {
            setIsSearching(true);
            fetchSites();
        } else {
            setSearchQuery('');
            fetchSites();
        }
    }, [debouncedSearchQuery, navigate, selectedStatus]);

    useEffect(() => {
        if (searchQuery !== '') {
            setIsSearching(true);
            setSiteData([]);
        }
    }, [searchQuery])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'edit',
            headerName: `${t('Edit')}`,
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            width: userRoleType === 'Super Admin' ? 80 : 0,
            renderCell: (params) => {
                if (userRoleType === 'Super Admin') {
                    return (
                        <Link
                            to={`/admin/masterdata/sites/edit/${params.row.id}`}
                            style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}
                        >
                            <Tooltip title={t("Edit")}>
                                <Icon fontSize="medium">edit</Icon>
                            </Tooltip>
                        </Link >
                    )
                }
            },
        },
        { field: 'site_id', headerName: `${t("Site Id")}`, width: 150, valueGetter: (params: GridCellParams) => params.row.site_id },
        { field: 'site_name', headerName: `${t("Site Name")}`, width: 500, valueGetter: (params: GridCellParams) => params.row.site_name },
        {
            field: 'is_active',
            headerName: `${t("Status")}`,
            width: 100,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                params.row.is_active === true ? (
                    <Chip variant="outlined" color="success" label={`${t("Active")}`} size="small" />
                ) : (
                    <Chip variant="outlined" color="error" label={`${t("Inactive")}`} size="small" />
                ),
        },
    ];

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("SitesPageTitle")}
                </Typography>
                {
                    userRoleType === 'Super Admin' && (
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{
                                backgroundColor: theme.palette.button.main,
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#0288d1',
                                },
                                minWidth: 'fit-content',
                                minHeight: '42px',
                            }}
                            component={Link}
                            to="/admin/masterdata/sites/create"
                        >
                            {t("Create New Site")}
                        </Button>
                    )
                }
            </Box>

            <Box>
                <Grid container spacing={3} sx={{ marginTop: 1, paddingX: 3 }}>
                    <Grid item xs={12} sm={10}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label={t("Search")}
                                placeholder={t("Search by Site id, site name")!}
                                value={searchQuery}
                                onChange={((event) => setSearchQuery(event.target.value))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchQuery.length > 0 && (
                                        <InputAdornment position="end">
                                            <IconButton size="small" edge="end" onClick={() => setSearchQuery('')}>
                                                <Icon>clear</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                                onChange={(event, newValue: FilterOption) => {
                                    setSelectedStatus(newValue);
                                }}
                                limitTags={2}
                                options={[{ "label": `${t("All")}`, "value": null }, { "label": `${t("Active")}`, "value": true }, { "label": `${t("Inactive")}`, "value": false }]}
                                isOptionEqualToValue={(option: FilterOption, value: FilterOption) => option.value === value.value}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.label, inputValue, { insideWords: true });
                                    const parts = parse(option.label, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part: { text: string, highlight: boolean }, index: number) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                                value={selectedStatus}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField {...params} label={t("Status")} placeholder={t("Status")!} />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ width: '100%', paddingX: 3, mt: 3 }}>
                {isSearching && allSuccessLoading && <Typography align='center'>{t("Searching")}...</Typography>}
                {!allSuccessLoading ? (
                    <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box>
                ) : siteData.length !== 0 ? (
                    <Box display='inline-grid' sx={{ width: '100%' }}>
                        <DataGridPro
                            getRowId={(row) => row.site_id}
                            rows={siteData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pagination
                            pageSizeOptions={[10, 20, 30, 40, 50]}
                            getRowHeight={() => 50}
                            disableRowSelectionOnClick
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                    outline: "none !important",
                                },
                                fontSize: 13,
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            slotProps={{
                                filterPanel: {
                                    filterFormProps: {
                                        logicOperatorInputProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                        },
                                        columnInputProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                            sx: { mt: 'auto' },
                                        },
                                        operatorInputProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                            sx: { mt: 'auto' },
                                        },
                                        valueInputProps: {
                                            InputComponentProps: {
                                                variant: 'outlined',
                                                size: 'small',
                                            },
                                        },
                                        deleteIconProps: {
                                            sx: {
                                                '& .MuiSvgIcon-root': { color: '#d32f2f' },
                                            },
                                        },
                                    },
                                    sx: {
                                        '& .MuiDataGrid-filterForm': { p: 2 },
                                        '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                                            backgroundColor: () =>
                                                theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                                        },
                                        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                                    },
                                },
                            }}
                            localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                            unstable_headerFilters
                        />
                    </Box>
                ) : !isSearching ? (
                    <Typography align='center'>{t("No data")}</Typography>
                ) : null}
            </Box>
        </>
    );
};

export default Sites;
