// React
import React, { useEffect, useState } from 'react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from '../../../redux/hooks';

// MUI Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// MUI Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// Utils
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import jwtDecode from 'jwt-decode';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import { green } from '@mui/material/colors';

// Types
import RoleType from '../../../@types/RoleType';

// Services
import { getAllSites } from '../../../services/SiteService';
import { createRole } from '../../../services/RoleService';
import { getAllMenus } from '../../../services/MenuService';
import { Menu, Site, TokenPayload } from '../../../@types';

function CreateRole() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const userRoleType = useAppSelector((state) => state.user.roletype);

  const havePermission = userRoleType === RoleType.Super_Admin ? true : false;

  if (!havePermission) {
    navigate('/forbidden', { replace: true });
  }

  const [roleName, setRoleName] = useState<string>('')
  const [active, setActive] = useState<boolean>(true)
  const [selectedSites, setSelectedSites] = useState<Site[]>([]);
  const [siteData, setSiteData] = useState<Site[]>([]);
  const [selectedRoleType, setSelectedRoleType] = useState<string>('');
  const [selectedMenus, setSelectedMenus] = useState<Menu[]>([]);
  const [allMenuData, setAllMenuData] = useState<Menu[]>([]);
  const [menusData, setMenusData] = useState<Menu[]>([]);
  const [loadingSites, setLoadingSites] = useState<boolean>(true);
  const [loadingMenus, setLoadingMenus] = useState<boolean>(true);
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await getAllSites();

        if (response.data?.success) {
          const sitesData = response.data.sites;
          setSiteData(sitesData.filter((site: Site) => site.is_active === true));
          setLoadingSites(false);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };
    fetchSites();
  }, [navigate]);

  useEffect(() => {
    try {
      siteData.sort((a: Site, b: Site) => a.site_id.localeCompare(b.site_id))
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  }, [siteData]);

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const response = await getAllMenus();

        if (response.data?.success) {
          const menusData = response.data?.menus;
          setAllMenuData(menusData.sort((a: Menu, b: Menu) => a.menu_id.localeCompare(b.menu_id)));
          setMenusData(allMenuData);
          setLoadingMenus(false);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };
    fetchMenus();
  }, [navigate]);

  useEffect(() => {
    if (selectedRoleType === RoleType.HR_Recruiter) {
      setMenusData(allMenuData.filter((menu: Menu) => menu.parent_id !== '600'));
      const defaultSelectedMenus = allMenuData.filter((menu: Menu) => menu.parent_id !== '600' && menu.parent_id !== '100' && menu.parent_id !== null);
      setSelectedMenus(defaultSelectedMenus);
    } else if (selectedRoleType === RoleType.Super_Admin) {
      setMenusData(allMenuData);
      const defaultSelectedMenus = allMenuData.filter((menu: Menu) => menu.parent_id !== '100' && menu.parent_id !== null);
      setSelectedMenus(defaultSelectedMenus);
    }
  }, [selectedRoleType]);

  const formValidationSchema = yup.object().shape({
    rolename: yup.string().required(`${t("Role name is required")}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidationSchema)
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const onSubmit = (user: any) => {
    const postData = async () => {
      try {
        const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);
        const sites = (selectedSites as Site[]).map((site) => site.site_id);
        const menus = selectedMenus.map((menu: any) => menu.menu_id);
        const parentMenus = selectedMenus.map((menu: Menu) => menu.parent_id);
        const distinctParentMenus = Array.from(new Set(parentMenus));

        const selectedMenusCreate = [...menus, ...distinctParentMenus]

        selectedMenusCreate.push('100');
        selectedMenusCreate.push('101');

        selectedMenusCreate.sort((a: string, b: string) => a.localeCompare(b))

        setCreateLoading(true);

        const updatedData = {
          role_name: roleName,
          is_active: active,
          role_type: selectedRoleType,
          site_id: sites,
          created_by: parsedToken.Username,
          menu: selectedMenusCreate
        }

        const response = await createRole(updatedData);

        if (response) {
          setCreateLoading(false);
          if (!response.data?.success) {
            toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (response.data?.success) {
            Swal.fire({
              title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#43A047',
              confirmButtonText: 'OK!',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.assign('/admin/masterdata/roles')
              }
            })
          }
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    }

    postData();
  };

  return (
    <>
      <ToastContainer style={{ width: "400px" }} />

      {
        havePermission ?
          <>
            <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
              <Box display='flex' gap={3} sx={{ mb: 3 }}>
                <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                  textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                    color: '#1976d2',
                  }
                }}>
                  <ChevronLeftIcon fontSize='small' />
                  <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    component={ReactRouterLink}
                    to="/admin"
                  >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t("Dashboard")}
                  </Link>
                  <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    component={ReactRouterLink}
                    to={'/admin/masterdata/roles'}
                  >
                    <AssignmentIndIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t("RoleMenu")}
                  </Link>
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    <AddCircleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t("Create New Role")}
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                {t("Create New Role")}
              </Typography>
            </Box>

            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="roleName"
                    label={t("Role Name")}
                    {...register('rolename')}
                    error={errors.rolename ? true : false}
                    value={roleName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setRoleName(event.target.value);
                      errors.rolename = undefined
                    }}
                  />
                  <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                    {errors.rolename?.message}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="roleType">{t("Role Type")}</InputLabel>
                    <Select
                      id="roleType"
                      label={t("Role Type")}
                      placeholder={t("Role Type")!}
                      value={selectedRoleType}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectedRoleType(event.target.value);
                      }}
                    >
                      <MenuItem value={RoleType.HR_Recruiter}>{t(RoleType.HR_Recruiter)}</MenuItem>
                      <MenuItem value={RoleType.Super_Admin}>{t(RoleType.Super_Admin)}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    onChange={(event, newValue: any) => {
                      setSelectedMenus(newValue);
                    }}
                    disableCloseOnSelect
                    isOptionEqualToValue={(option: Menu, value: Menu) => option.menu_id === value.menu_id}
                    id="menu"
                    options={menusData.filter((menu: Menu) => menu.parent_id !== null && menu.parent_id !== '100')}
                    groupBy={(option: Menu) => {
                      return option.parent_id;
                    }}
                    renderTags={(value: any, getTagProps) =>
                      value.map((option: Menu, index: number) => (
                        <Chip
                          variant="outlined"
                          icon={<Icon fontSize='small'>{option.icon}</Icon>}
                          label={t(option.menu_name)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderGroup={(params) => (
                      menusData.map((menu: Menu) => {
                        if (menu.menu_id === params.group) {
                          return (
                            <div key={menu.menu_id}>
                              <Typography variant="inherit" color="primary" align="left" pl={2} pt={1} fontSize={13}>
                                {t(menu.menu_name)}
                              </Typography>
                              {params.children}
                            </div>
                          )
                        }
                      })
                    )}
                    value={selectedMenus}
                    getOptionLabel={(option: Menu) => t(option.menu_name)}
                    renderOption={(props, option: Menu, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {t(option.menu_name)}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={loadingMenus ? `${t("Loading")}...` : t("Menu")}
                        placeholder={t("Menu")!}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingMenus ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    loading={loadingMenus}
                    loadingText={`${t("Loading")}...`}
                    noOptionsText={selectedMenus.length === 0 ? t("Please select role type first") : t("No options found")}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    onChange={(event, newValue: any) => {
                      setSelectedSites(newValue);
                    }}
                    limitTags={2}
                    disableCloseOnSelect
                    id="site"
                    options={siteData}
                    getOptionLabel={(option) => option.site_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.site_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params}
                        label={loadingSites ? `${t("Loading")}...` : t("Site")}
                        placeholder={t("Site")!}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSites ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }} />
                    )}
                    loading={loadingSites}
                    loadingText={`${t("Loading")}...`}
                    renderTags={(value: any, getTagProps) =>
                      value.map((option: Site, index: number) => (
                        <Chip
                          variant="outlined"
                          label={t(option.site_name)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    noOptionsText={t("No options found")}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    label={t("Active")}
                    control={
                      <Checkbox
                        checked={active}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'Active' }}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ mt: 5, mb: 2, color: 'white' }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={createLoading}
                  >
                    {t("Create")}
                    {createLoading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          zIndex: 999,
                        }}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 5, mb: 2 }}
                    component={ReactRouterLink}
                    to="/admin/masterdata/roles"
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </> : null
      }

    </>
  )
}

export default CreateRole