import React, { useEffect, useState } from 'react'
import { Box, ThemeProvider, Typography, Link, Chip, CardActions } from "@mui/material";
import theme from "../../../styles/theme";
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import Skeleton from '@mui/material/Skeleton';

// mui icon
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import { getAllActiveInterviewsByUserId } from '../../../services/InterviewService';

interface AppointmentData {
  id: string;
  Job: {
    job_name: string;
    Site: {
      site_name: string;
    };
    company: string;
    hr_name: string;
    hr_email: string;
    hr_phone: string;
  }
  Status: {
    id: string;
    status_name: string;
    show_on_applicant: string;
    color: string;
  }
  Interview: [
    {
      id: string;
      interview_date: Date;
    }
  ]
}

interface DecodedToken {
  UserId: string;
}

interface Props {
  scheduleData: AppointmentData;
}

const JobScheduleCard: React.FC<Props> = ({ scheduleData }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [today, setToday] = useState<string>(dayjs(new Date()).format('YYYY-MM-DD').toString());
  const monthTH: string[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  const monthEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const parsedToken: DecodedToken = jwtDecode(localStorage.access_token);
        const data = {
          user_id: parsedToken.UserId,
        }

        const response = await getAllActiveInterviewsByUserId(data);
        
        if (response.data?.success) {
          setTimeout(() => {
            setLoadingData(false);
          }, 500);
        }

      } catch (error) {
        navigate('/servererror', { replace: true });
        console.log(error);
      } 
    };
    fetchInterviews();
  }, [navigate]);

  const formatOfDate = (date: Date): string => {
    let dateToken: string[] = dayjs(date).format('D-MM-YYYY').split("-")
    let fullDate = "";
    i18n.language === 'th' ? fullDate = dateToken[0] + " " + monthTH[parseInt(dateToken[1])-1] + " " + (parseInt(dateToken[2]) + 543).toString() :
      fullDate = dateToken[0] + " " + monthEN[parseInt(dateToken[1])-1] + " " + dateToken[2]
    return fullDate + " " + dayjs(date).format('HH:mm')
  }
  const checkDateIsPast = (date: Date): boolean => {
    return dayjs(today).isAfter(dayjs(dayjs(date).format('YYYY-MM-DD')))
  }
  const checkDateIsToday = (today: string, date: Date): boolean => {
    return today === dayjs(date).format('YYYY-MM-DD')
  }

  return <div>
    {!loadingData ? (
      <CardContent >
        <Box sx={{ display: {xs: 'flex', sm: 'grid'}, flexDirection: { xs: 'row'},
          gridTemplateColumns: { xs: 'false', sm: 'fit-content(100%) repeat(1, 1fr)' },
        }}>
          <EventRoundedIcon color='info' 
            sx={{ fontSize: { xs: 40, sm: 55 }, p: { xs: 0 ,sm: 1 }, 
              gridRow: { sm: '1' }, gridColumn: { sm: '1' } 
          }}/>
          <Box sx={{ display: 'grid', columnGap: { sm: 4 }, pl: 1,
            gridTemplateRows: { xs: 'repeat(1, 1fr)', sm: 'false' },
            gridTemplateColumns: { xs: 'false', sm: 'fit-content(100%) repeat(4, 1fr)' },
          }}>
            { i18n.language === 'th' ? (
            <Typography sx={{ fontWeight: 'bold', letterSpacing: 0.5, fontSize: { xs: '16px', sm:'17px' },
              gridRow: { sm: '1' }, gridColumn: { sm: '1' },
              mt: { xs: 0, sm: 2 },
            }}>
                {t("ตำแหน่ง ")} {scheduleData.Job.job_name}
            </Typography>
            ) : (
              <Typography sx={{ fontWeight: 'bold', letterSpacing: 0.5, fontSize: { xs: '16px', sm:'17px' },
                gridRow: { sm: '1' }, gridColumn: { sm: '1' },
                mt: { xs: 0, sm: 2 },
              }}>
                  {scheduleData.Job.job_name}
              </Typography>
            )}
            
            { checkDateIsToday(today, scheduleData.Interview[0].interview_date) ? (
              <>
              <Chip 
                variant="outlined" 
                color="success"
                sx={{ p: 1, mt: { xs: 1, sm: 1.5 },
                gridRow: { sm: '1' }, gridColumn: { xs: '1', sm: '2' }, }}
                label={formatOfDate(scheduleData.Interview[0].interview_date)}
              />
              </>
            ) : (
              (checkDateIsPast(scheduleData.Interview[0].interview_date) && !checkDateIsToday(today, scheduleData.Interview[0].interview_date)) ? (
                <Chip 
                  variant="outlined"
                  color="warning"
                  sx={{ p: 1, mt: { xs: 1, sm: 1.5 }, 
                  gridRow: { sm: '1' }, gridColumn: { xs: '1', sm: '2' }, }}
                  label={t("waiting for the interview results")}
                />
              ) : (
                <Chip 
                  variant="outlined"
                  sx={{ p: 1, mt: { xs: 1, sm: 1.5 }, 
                  gridRow: { sm: '1' }, gridColumn: { xs: '1', sm: '2' }, }}
                  label={formatOfDate(scheduleData.Interview[0].interview_date)}
                />
              )
            )}
            
            <Box display='flex' flexDirection='row' alignItems='center'
              sx={{ mt: 2, gridRow: { xs: '3', sm: '2' }, gridColumn: { xs: 'span 2', sm: 'span 3' }, 
            }}>
              <LocationOnRoundedIcon color='warning' fontSize='small' sx={{ alignSelf: 'flex-start' }}/>
              <Typography sx={{ fontSize: '15px', ml: 1 }}>
                {scheduleData.Job.Site.site_name}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row' alignItems='center'
              sx={{ mt: 1, gridRow: { xs: '4', sm: '3' }, gridColumn: { xs: 'span 2', sm: 'span 2' } 
            }}>
              <ContactPageRoundedIcon color='info' fontSize='small' />
              <Typography sx={{ fontSize: '15px', ml: 1 }}>
                {t("Contact Information for HR Recruiter")}
              </Typography>   
            </Box>
            <Box sx={{ ml: 3.5, mt: 1, gridColumn: '1' }}>
              <Typography sx={{ fontSize: '15px', }}>
                {scheduleData.Job.hr_name}
              </Typography>
              <Typography sx={{ fontSize: '15px', mt: 0.5 }}>
                {t("Email") + " : "} {scheduleData.Job.hr_email}
              </Typography>
              <Typography sx={{ fontSize: '15px', mt: 0.5 }}>
                {t("Phone Number") + " : "} {scheduleData.Job.hr_phone}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' alignItems='flex-end' sx={{ mt: { xs: 2, sm: 0} }}>
          <Link
            variant="body2"
            color='#9e9e9e'
            component={ReactRouterLink}
            to={`/jobs_status/appformdetail/${scheduleData.id}?content_type=inprogressTable`}
            sx={{ mt: { xs: 1 } }}
          >
            {t("Application Details")}
          </Link>
        </Box>
      </CardContent>
      ) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Skeleton variant="rectangular" width='100%' height={200} />
      </Box>
    )}  
  </div>;
};

export default JobScheduleCard;
