import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchRole, fetchUser } from "../../redux/slices/userSlice";

interface User {
    Role: string,
    RoleType: string
}

const ProtectedRoute: React.FC<{
    children: JSX.Element;
    allowedRoles?: string[];
    requiredMenus?: string;
}> = ({ children, allowedRoles, requiredMenus }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { isLoggedIn, token, menu } = useAppSelector(state => state.user);
    const [checkedRole, setCheckedRole] = React.useState(false);
    const [checkedMenu, setCheckedMenu] = React.useState(false);

    const checkUserToken = async () => {
        let userData: User = { Role: '', RoleType: '' };
        if (!isLoggedIn) {
            navigate(`/login?continue=${location.pathname}`);
            return;
        }

        if (!allowedRoles && !requiredMenus) {
            setCheckedRole(true);
            setCheckedMenu(true);
            return;
        }

        try {

            if (token) {
                userData = jwtDecode<any>(token);
    
                const userRoleType = userData.RoleType;
                
                if (allowedRoles && allowedRoles.length > 0) {
                    // if (!allowedRoles.includes(userRoleType)) {
                    //     navigate('/forbidden');
                    // }
                }
    
                setCheckedRole(true);
            }
        } catch (error) {
            navigate(`/login?continue=${location.pathname}`);
        }

        if (requiredMenus && requiredMenus.length > 0) {
            if (!menu.includes(requiredMenus)) {
                navigate('/forbidden');
            }
        }

        setCheckedMenu(true);
    }

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchRole())
            await checkUserToken();
        }
        fetchData()

    }, [dispatch]);

    return isLoggedIn && checkedRole && checkedMenu ? children : null;
}

export default ProtectedRoute;
