// React
import { useEffect, useState } from "react";
import {
    Link as ReactRouterLink,
    useNavigate,
    useParams
} from "react-router-dom";

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

// MUI Icons
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import FactoryIcon from '@mui/icons-material/Factory';

// Utils
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

// Styles
import { green } from "@mui/material/colors";

// Types
import { TokenPayload, SiteData } from "../../../@types";

// Services
import { editSite, getSiteById } from "../../../services/SiteService";

const EditSite = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const userRoleType = useAppSelector((state) => state.user.roletype);
    const havePermission = userRoleType === 'Super Admin' ? true : false;

    const [siteData, setSiteData] = useState<SiteData>({
        site_id: '',
        site_name: '',
        is_active: true,
        modified_by: '',
    });

    const [siteLoading, setSiteLoading] = useState<boolean>(true);
    const [editLoading, setEditLoading] = useState<boolean>(false);

    if (!havePermission) {
        navigate('/forbidden', { replace: true });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSiteData((prevState: SiteData) => ({
            ...prevState,
            is_active: event.target.checked
        }));
    };

    useEffect(() => {
        const getSite = async () => {
            try {
                const siteId = id as string;
                const response = await getSiteById(siteId);
                const siteData = await response.data?.site;

                if (response.data?.success === false) {
                    Swal.fire({
                        title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#43A047',
                        confirmButtonText: 'OK!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign('/admin/masterdata/sites')
                        }
                    });
                    return;
                }

                setSiteData((prevState: SiteData) => ({
                    ...prevState,
                    site_id: siteData.site_id,
                    site_name: siteData.site_name,
                    is_active: siteData.is_active,
                }));
                setSiteLoading(false);

            } catch (error) {
                console.log(error);

                navigate('/servererror', { replace: true });
            }
        };

        getSite();
    }, [navigate]);

    const formValidationSchema = Yup.object().shape({
        // site_id: Yup.string().required(`${t("Site id is required")}`),
        site_name: Yup.string().required(`${t("Site name is required")}`),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formValidationSchema)
    });

    const onSubmit = (site: SiteData) => {
        const postSite = async () => {
            try {
                const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);
                setEditLoading(true);

                const editData = {
                    site_id: siteData.site_id,
                    site_name: site.site_name,
                    is_active: siteData.is_active,
                    modified_by: parsedToken.Username,
                }
                const response = await editSite(id as string, editData);

                if (response) {
                    setEditLoading(false);
                    const responseData = await response.data;
                    if (!response.data?.success) {
                        toast.error(i18n.language === 'th' ? responseData.message_th : responseData.message_en, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else if (response.data?.success) {
                        Swal.fire({
                            title: i18n.language === 'th' ? responseData.message_th : responseData.message_en,
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#43A047',
                            confirmButtonText: 'OK!',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/admin/masterdata/sites', { replace: true });
                            }
                        })
                    }
                }

            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        postSite();
    };

    return (
        <>
            <ToastContainer style={{ width: "400px" }} />

            {
                !siteLoading ? <>
                    {
                        havePermission ?
                            <>
                                <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                                    <Box display='flex' gap={3} sx={{ mb: 3 }}>
                                        <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                                            textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                                color: '#1976d2',
                                            }
                                        }}>
                                            <ChevronLeftIcon fontSize='small' />
                                            <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                                        </Box>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                component={ReactRouterLink}
                                                to="/admin"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                {t("Dashboard")}
                                            </Link>
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                component={ReactRouterLink}
                                                to={'/admin/masterdata/sites'}
                                            >
                                                <FactoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                {t("Site")}
                                            </Link>
                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="text.primary"
                                            >
                                                <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                {t("Edit Site")}
                                            </Typography>
                                        </Breadcrumbs>
                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                                        {t("Edit Site")}
                                    </Typography>
                                </Box>

                                <Box component="form" noValidate sx={{ mt: 3 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                id="siteId"
                                                label={t("Site Id")}
                                                // {...register('site_id')}
                                                // error={errors.site_id ? true : false}
                                                value={siteData?.site_id}
                                                // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                //     setSiteData({ ...siteData, site_id: event.target.value });
                                                //     errors.site_id = undefined
                                                // }}
                                            />
                                            {/* <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                                {errors.site_id?.message}
                                            </Typography> */}
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="siteName"
                                                label={t("Site Name")}
                                                {...register('site_name')}
                                                error={errors.site_name ? true : false}
                                                value={siteData.site_name}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setSiteData({ ...siteData, site_name: event.target.value });
                                                    errors.site_name = undefined
                                                }}
                                            />
                                            <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                                {errors.site_name?.message}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel
                                                label={t("Active")}
                                                control={
                                                    <Checkbox
                                                        checked={siteData.is_active}
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'Active' }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                sx={{ mt: 5, mb: 2, color: 'white' }}
                                                onClick={handleSubmit(onSubmit)}
                                                disabled={editLoading}
                                            >
                                                {t("Edit")}
                                                {editLoading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: green[500],
                                                            position: 'absolute',
                                                            zIndex: 999,
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mt: 5, mb: 2 }}
                                                component={ReactRouterLink}
                                                to="/admin/masterdata/sites"
                                            >
                                                {t("Cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </> : null
                    }
                </> : <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                        <CircularProgress />
                    </Box>
                </>
            }
        </>
    )
};

export default EditSite;
