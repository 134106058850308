import React from 'react';
import { Box, IconButton, Typography, Chip, } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DataGrid, GridCellParams, GridColDef, enUS } from '@mui/x-data-grid';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { thTHGrid } from "../../../@types/DataGridLocaleText";
import dayjs from 'dayjs';

interface statusCompleteTableProps {
  index: number;
  value: number;
  data: any[];
}

const JobsStatusCompleteTable: React.FC<statusCompleteTableProps> = (props) => {
  const { index, value, data, ...other } = props;
  const { t, i18n } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'viewmore',
      headerName: '',
      width: 70,
      renderCell: (params) => {
        return (
          <Tooltip title={t("View more details")}>
            <IconButton color='info' sx={{ borderRadius: 2 }}
              component={ReactRouterLink}
              to={`/jobs_status/appformdetail/${params.row.id}?content_type=completedTable`}
            >
              <VisibilityRoundedIcon />
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'position_name',
      headerName: `${t("Position")}`,
      width: 230,
      renderCell: (params: GridCellParams) => {
        let jobPosition = params.row.Job.job_name

        return (
          <Typography sx={{ fontSize: 14 }}>
            {jobPosition}
          </Typography>
        );
      }
    },
    {
      field: 'Site',
      headerName: `${t("Site")}`,
      width: 200,
      renderCell: (params: GridCellParams) => {
        let site = params.row.Job.Site.site_name;

        return (
          <Typography sx={{ fontSize: 14, }}>
            {site}
          </Typography>
        );
      }
    },
    {
      field: 'status_name',
      headerName: `${t("Status")}`,
      width: 250,
      renderCell: (params: GridCellParams) => {
        let status_applicant = params.row.Status.show_on_applicant
        let statusColor = params.row.Status.color

        return (
          <Chip
            variant="outlined"
            sx={{
              bgcolor: statusColor,
              borderColor: statusColor,
              fontWeights: 'normal',
              color: 'white',
              my: 2,
            }}
            size='medium'
            label={status_applicant}
          />
        );
      }
    },
    {
      field: 'created_date',
      headerName: `${t("Apply Date")}`,
      width: 150,
      renderCell: (params: GridCellParams) => {
        let created_date = dayjs(params.row.created_date).format('MM/DD/BBBB HH:mm')

        return (
          <Typography sx={{ fontSize: 14, }}>
            {created_date}
          </Typography>
        );
      }
    },
    {
      field: 'modified_date',
      headerName: `${t("Last Modified Date")}`,
      width: 150,
      renderCell: (params: GridCellParams) => {
        if (params.row.modified_date) {
          let modified_date = dayjs(params.row.modified_date).format('MM/DD/BBBB HH:mm') ?? '-'

          return (
            <Typography sx={{ fontSize: 14 }}>
              {modified_date}
            </Typography>
          );
        } else {
          return (
            <Typography sx={{ fontSize: 14 }}>-</Typography>
          )
        }
      }
    },
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {data.length ? (
        <DataGrid
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 }
            },
          }}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          rows={data}
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none !important',
            },
            fontSize: 15,
          }}
          localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
        />
      ) : (
        <Box display='flex' flexDirection='column' justifyContent='center' marginY={7} gap={2}>
          <Typography align='center' sx={{ fontSize: { xs: '14px', sm: '16px' }, letterSpacing: 0.5 }}>
            {t("No completed job positions found")}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default JobsStatusCompleteTable;
