// React
import * as React from "react";

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    editeducation_level,
    editeducation_levelOther,
    editSchool
} from "../../../../redux/slices/jobAppSlice";

// MUI Components
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Utils
import { useTranslation } from "react-i18next";

const EducationInfo: React.FC<any> = ({ errors }) => {
    const { t } = useTranslation();

    const { educationInfo } = useAppSelector(state => state.jobapp);
    const dispatch = useAppDispatch();

    const [highestEdu, setHighestEdu] = React.useState('');
    const [highestEduOther, setHighestEduOther] = React.useState('');
    const [school, setSchool] = React.useState('');
    const [highestEduSelectOther, setHighestEduSelectOther] = React.useState(false);

    const [dataErrorState, setDataErrorState] = React.useState<any>(errors)

    React.useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    React.useEffect(() => {
        setHighestEdu(educationInfo.education_level);
        setHighestEduOther(educationInfo.education_level_other);
        setSchool(educationInfo.education_school);
    }, [educationInfo]);

    React.useEffect(() => {
        setHighestEdu(educationInfo.education_level);

        if (highestEdu === 'อื่น ๆ') {
            setHighestEduSelectOther(true);
            setHighestEduOther(highestEduOther);
        }

    }, [highestEdu, highestEduOther]);

    React.useEffect(() => {
        if (highestEdu !== 'อื่น ๆ') {
            setHighestEduSelectOther(false);
        }
    }, [setHighestEduSelectOther]);

    const handleHighestEduChange = (event: SelectChangeEvent) => {
        if (event.target.value === 'อื่น ๆ') {
            setHighestEduSelectOther(true);
            setHighestEdu('อื่น ๆ');
            dispatch(editeducation_level('อื่น ๆ'));
        } else {
            setHighestEduSelectOther(false);
            setHighestEdu(event.target.value as string);
            dispatch(editeducation_level(event.target.value as string));
        }

        if (dataErrorState.education_level) {
            setDataErrorState({ ...dataErrorState, education_level: null })
        }
    };

    return <div>
        <Typography sx={{ mt: 6, mb: 3 }}>{t("Education Information")}</Typography>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={highestEduSelectOther ? 2 : 4}>
                <FormControl fullWidth>
                    <InputLabel id="hightestedu" error={dataErrorState.education_level ? true : false}>{t("Education Level") + ' *'}</InputLabel>
                    <Select
                        error={dataErrorState.education_level ? true : false}
                        required
                        labelId="hightestedu"
                        id="hightestedu"
                        value={highestEdu}
                        label={t("Education Level") + ' *'}
                        onChange={handleHighestEduChange}
                    >
                        <MenuItem value='ไม่มีวุฒิการศึกษา'>{t("No educational qualification")}</MenuItem>
                        <MenuItem value='ประถมศึกษา'>{t("Primary School")}</MenuItem>
                        <MenuItem value='มัธยมต้น'>{t("Junior High School")}</MenuItem>
                        <MenuItem value='มัธยมปลาย'>{t("Senior High School")}</MenuItem>
                        <MenuItem value='ปวช.'>{t("Vocational Certificate")}</MenuItem>
                        <MenuItem value='ปวส.'>{t("Certificate of Technical Vocation")}</MenuItem>
                        <MenuItem value='อื่น ๆ' onClick={() => {
                            setHighestEduSelectOther(true)
                            dispatch(editeducation_level('อื่น ๆ'));
                        }
                        }>{t("Other")}</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                    {t(dataErrorState.education_level?.message)}
                </Typography>
            </Grid>
            {highestEduSelectOther && <Grid item xs={12} sm={4}>
                <TextField
                    error={dataErrorState.education_level_other ? true : false}
                    fullWidth
                    id="hightestedu"
                    label={t("Input Education Level") + ' *'}
                    placeholder={t("Input Education Level")!}
                    type="text"
                    value={highestEduOther}
                    onChange={(e) => {
                        setHighestEduOther(e.target.value);
                        dispatch(editeducation_levelOther(e.target.value));

                        if (dataErrorState.education_level_other) {
                            setDataErrorState({ ...dataErrorState, education_level_other: null })
                        }
                    }}
                />
                <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                    {t(dataErrorState.education_level_other?.message)}
                </Typography>
            </Grid>}
            <Grid item xs={12} sm={highestEduSelectOther ? 6 : 8}>

                {
                    highestEdu !== 'ไม่มีวุฒิการศึกษา' ?
                        <TextField
                            fullWidth
                            id="school"
                            label={t("School")}
                            placeholder={t("School")!}
                            type="text"
                            value={school}
                            onChange={(e) => {
                                setSchool(e.target.value);
                                dispatch(editSchool(e.target.value));
                            }}
                        /> : null
                }
            </Grid>
        </Grid>
    </div>;
};

export default EducationInfo;
