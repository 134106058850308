// React
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from '../../../redux/hooks';

// MUI Components
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

import {
  GridCellParams,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid';

import {
  DataGridPro,
  enUS
} from '@mui/x-data-grid-pro';

// Utils
import { useTranslation } from 'react-i18next';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// Styles
import theme from '../../../styles/theme';

// Types
import { thTHGrid } from '../../../@types/DataGridLocaleText';
import RoleType from '../../../@types/RoleType';

// Services
import { getAllRoles } from '../../../services/RoleService';
import { getAllSites } from '../../../services/SiteService';
import {
  FilterOption,
  Role,
  Site
} from '../../../@types';

const Roles = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const userRoleType = useAppSelector(state => state.user.roletype);
  const [roleData, setRoleData] = useState<Role[]>([]);
  const [siteData, setSiteData] = useState<Site[]>([]);
  const [roleLoading, setRoleLoading] = useState<boolean>(true);
  const [siteLoading, setSiteLoading] = useState<boolean>(true);
  const [filterRoleData, setFilterRoleData] = useState<Role[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<FilterOption>({ "label": `${t("All")}`, "value": null });
  const [selectedRoleType, setSelectedRoleType] = useState<FilterOption>({ "label": `${t("All")}`, "value": "All" });
  const [selectedSites, setSelectedSites] = useState<Site | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const allSuccessLoading = !roleLoading && !siteLoading;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getAllRoles();

        if (response.data?.success) {
          const rolesData = response.data?.roles;

          setRoleData(rolesData);
          setFilterRoleData(rolesData);
          setSelectedStatus({ "label": `${t("All")}`, "value": null })
          setSelectedRoleType({ "label": `${t("All")}`, "value": "All" })
          setTimeout(() => {
            setRoleLoading(false)
          }, 500);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };
    fetchRoles();
  }, [navigate, t]);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await getAllSites();

        if (response.data?.success) {
          const sitesData = response.data?.sites;

          setSiteData(sitesData);
          setTimeout(() => {
            setSiteLoading(false)
          }, 500);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    fetchSites();
  }, [navigate]);

  useEffect(() => {
    try {
      siteData.sort((a, b) => a.site_id.localeCompare(b.site_id))
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  }, [siteData, navigate]);

  useEffect(() => {
    try {
      let filteredData = roleData.filter((row) =>
        row.role_name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (selectedSites) {
        filteredData = filteredData.filter((role) =>
          role.site_id?.includes(selectedSites.site_name)
        );
      }

      if (selectedStatus?.value !== null) {
        filteredData = filteredData.filter((role) => role.is_active === selectedStatus.value);
      }

      if (selectedRoleType?.value !== 'All') {
        filteredData = filteredData.filter((role) => role.role_type === selectedRoleType.value);
      }

      setFilterRoleData(filteredData);
    } catch (error) {
      navigate('/servererror', { replace: true });
    }

  }, [searchQuery, selectedRoleType, selectedStatus, selectedSites, roleData, navigate]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: `${t('Edit')}`,
      headerAlign: 'center',
      align: 'center',
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      width: userRoleType === RoleType.Super_Admin ? 80 : 0,
      renderCell: (params) => {
        if (userRoleType === RoleType.Super_Admin) {
          return (
            <Link
              to={`/admin/masterdata/roles/edit/${params.row.role_id}`}
              style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}
            >
              <Tooltip title={t("Edit")}>
                <Icon fontSize="medium">edit</Icon>
              </Tooltip>
            </Link >
          )
        }
      },
    },
    {
      field: 'role_name',
      headerName: `${t('Role Name')}`,
      width: 220,
      valueGetter: (params: GridCellParams) => params.row.role_name
    },
    {
      field: 'site_name',
      headerName: `${t('Sites')}`,
      width: 250,
      renderCell: (params: GridCellParams) => {
        let siteData = [...params.row.site_id];
        siteData.sort()

        return (
          <div style={{ paddingBottom: 16, paddingTop: 16, lineHeight: 1.5 }}>
            {
              siteData.length > 0 ? siteData.map((site: string, index: number) => (
                <React.Fragment key={index}>
                  {site}
                  {index !== siteData.length - 1 && <br />}
                </React.Fragment>
              )) : '-'
            }
          </div>
        );
      },
      valueGetter: (params: GridCellParams) => params.row.site_id.length > 0 ? params.row.site_id : null
    },
    { field: 'role_type', headerName: `${t('Role Type')}`, width: 220, valueGetter: (params: GridCellParams) => params.row.role_type },
    {
      field: 'is_active',
      headerName: `${t('Status')}`,
      width: 100,
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridCellParams) =>
        params.row.is_active === true ? (
          <Chip variant="outlined" color="success" label={`${t('Active')}`} size="small" />
        ) : (
          <Chip variant="outlined" color="error" label={`${t('Inactive')}`} size="small" />
        ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
          {t('RolesPageTitle')}
        </Typography>
        {
          userRoleType === RoleType.Super_Admin && (
            <Button
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: theme.palette.button.main,
                color: 'white',
                '&:hover': {
                  backgroundColor: '#0288d1',
                },
                minWidth: 'fit-content',
                minHeight: '42px',
              }}
              component={Link}
              to="/admin/masterdata/roles/create"
            >
              {t("Create New Role")}
            </Button>
          )
        }
      </Box>

      <Box>
        <Grid container spacing={3} sx={{ marginTop: 1, paddingX: 3 }}>
          <Grid item xs={12} sm={4}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label={t("Search")}
                placeholder={t("Search by Role name")!}
                value={searchQuery}
                onChange={((event) => setSearchQuery(event.target.value))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon fontSize="medium">search</Icon>
                    </InputAdornment>
                  ),
                  endAdornment: searchQuery.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton size="small" edge="end" onClick={() => setSearchQuery('')}>
                        <Icon>clear</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                onChange={(event, newValue: Site | null) => {
                  setSelectedSites(newValue);
                }}
                limitTags={1}
                id="site"
                options={
                  siteData
                }
                getOptionLabel={(option) => option.site_name}
                value={selectedSites}
                renderOption={(props, option, { inputValue, selected }) => {
                  const matches = match(option.site_name, inputValue, { insideWords: true });
                  const parts = parse(option.site_name, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part: { text: string, highlight: boolean }, index: number) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >

                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t("Site")}
                    placeholder={t("Site")!}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={2}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                onChange={(event, newValue: FilterOption) => {
                  setSelectedRoleType(newValue);
                }}
                limitTags={2}
                options={[{ "label": `${t("All")}`, "value": "All" }, { "label": `${t("Member")}`, "value": "Member" }, { "label": `${t(RoleType.HR_Recruiter)}`, "value": RoleType.HR_Recruiter }, { "label": `${t(RoleType.Super_Admin)}`, "value": RoleType.Super_Admin }]}
                isOptionEqualToValue={(option: FilterOption, value: FilterOption) => option.value === value.value}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.label, inputValue, { insideWords: true });
                  const parts = parse(option.label, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part: { text: string, highlight: boolean }, index: number) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                value={selectedRoleType}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label={t("Role Type")} placeholder={t("Role Type")!} />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={2}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                onChange={(event, newValue: FilterOption) => {
                  setSelectedStatus(newValue);
                }}
                limitTags={2}
                options={[{ "label": `${t("All")}`, "value": null }, { "label": `${t("Active")}`, "value": true }, { "label": `${t("Inactive")}`, "value": false }]}
                isOptionEqualToValue={(option: FilterOption, value: FilterOption) => option.value === value.value}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.label, inputValue, { insideWords: true });
                  const parts = parse(option.label, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part: { text: string, highlight: boolean }, index: number) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                value={selectedStatus}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label={t("Status")} placeholder={t("Status")!} />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginTop: 3, paddingX: 3 }}>
        {
          !allSuccessLoading ? <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box> : (
            filterRoleData.length === 0 ? (
              <Typography align="center">{t('No data')}</Typography>

            ) : (<>
              <Box display='inline-grid' sx={{ width: '100%' }}>
                <DataGridPro
                  getRowId={(row) => row.role_id}
                  rows={filterRoleData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pagination
                  pageSizeOptions={[10, 20, 30, 40, 50]}
                  getRowHeight={() => 'auto'}
                  disableRowSelectionOnClick
                  sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                      outline: 'none !important',
                    },
                    fontSize: 13,
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  slotProps={{
                    filterPanel: {
                      filterFormProps: {
                        logicOperatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                        },
                        columnInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        valueInputProps: {
                          InputComponentProps: {
                            variant: 'outlined',
                            size: 'small',
                          },
                        },
                        deleteIconProps: {
                          sx: {
                            '& .MuiSvgIcon-root': { color: '#d32f2f' },
                          },
                        },
                      },
                      sx: {
                        '& .MuiDataGrid-filterForm': { p: 2 },
                        '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                          backgroundColor: () =>
                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                        },
                        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                      },
                    },
                  }}
                  localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                  unstable_headerFilters
                />
              </Box>
            </>)
          )
        }
      </Box>
    </>
  );
};

export default Roles;