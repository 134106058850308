// React
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// MUI Components
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {
    DataGrid,
    GridCellParams,
    GridColDef
} from '@mui/x-data-grid';

import { enUS } from '@mui/x-data-grid-pro';

// Utils
import { useTranslation } from 'react-i18next';

// Types
import { Status, thTHGrid } from '../../../@types';

// Services
import { getAllStatus } from '../../../services/StatusService';

export default function StatusDescription() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()

    const [statusData, setStatusData] = useState<Status[]>([])

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await getAllStatus();

                if (response.data?.success) {
                    setStatusData(response.data?.status);
                }

            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        fetchStatus();
    }, [navigate]);

    const columns: GridColDef[] = [
        {
            field: 'action',
            headerName: '',
            width: 50,
        },
        {
            field: 'statusName',
            headerName: `${t('Status name')}`,
            width: 200,
            renderCell: (params: GridCellParams) => {
                let statusName = [params.row.status_name];

                return (
                    <span style={{ fontSize: 'small' }}>{statusName}</span>
                )
            }

        },
        {
            field: 'statusNameHR',
            headerName: `${t('Show on HR')}`,
            width: 270,
            renderCell: (params: GridCellParams) => {
                let statusHR = [params.row.show_on_hr]
                let statusColor = [params.row.color]

                return (
                    <Chip
                        variant="outlined"
                        sx={{
                            bgcolor: statusColor,
                            borderColor: statusColor,
                            color: 'white',
                            my: 1.5
                        }}
                        size='medium'
                        label={statusHR}
                    />
                )
            }
        },
        {
            field: 'statusNameApplicant',
            headerName: `${t('Show on Applicant')}`,
            width: 270,
            renderCell: (params: GridCellParams) => {
                let statusAPC = [params.row.show_on_applicant]
                let statusColor = [params.row.color]

                return (
                    <Chip
                        variant="outlined"
                        sx={{
                            bgcolor: statusColor,
                            borderColor: statusColor,
                            fontWeights: 'normal',
                            color: 'white',
                            my: 1.5
                        }}
                        size='medium'
                        label={statusAPC}
                    />
                )
            }
        },
    ];

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("Status Description")}
                </Typography>
                <Button
                    variant="outlined"
                    disableElevation
                    sx={{
                        mb: 2,
                        mt: 2,
                        maxWidth: 80,
                        width: '100%',
                        '&:hover': {
                            backgroundColor: '#49AF4D',
                            borderColor: '#49AF4D',
                            color: 'white'
                        },
                    }}
                    component={Link}
                    to="/admin/application-form"
                >
                    {t('Back')}
                </Button>
            </Box>

            <Box sx={{ width: '100%', marginTop: 3, paddingX: 3 }}>
                <Box display='inline-grid' sx={{ width: '100%' }}>
                    <DataGrid
                        rows={statusData}
                        columns={columns}
                        pagination
                        pageSizeOptions={[100]}
                        getRowHeight={() => 'auto'}
                        disableRowSelectionOnClick
                        sx={{
                            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                outline: 'none !important',
                            },
                            fontSize: 13,
                        }}
                        localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                    />
                </Box>
            </Box>
        </>
    );
}