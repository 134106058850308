import * as React from "react";
import PublicAppBar from "../../../components/appbar/PublicAppBar";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type InternalServerErrorProps = {
    //
};

const InternalServerError: React.FC<any> = () => {
    const { t } = useTranslation();
    
    return (
        <Container maxWidth='lg'>
            <PublicAppBar />
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography variant="h1">500</Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: "40px", marginBottom: 2 }}>{t("Oops! Internal Server Error")}</Typography>
                <Typography variant="body1">{t("Something went wrong on our server. Please try again later.")}</Typography>
            </div>
        </Container>
    );
};

export default InternalServerError;
