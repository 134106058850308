// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Hooks and Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { editStartDateOfWork } from "../../../../redux/slices/jobAppSlice";

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { DataGrid, GridColDef, GridValueGetterParams, enUS } from "@mui/x-data-grid";

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';

// MUI Icons
import CancelIcon from '@mui/icons-material/Cancel';

// Utils
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { apiUrl } from "../../../../Constants";
import { thTHGrid } from "../../../../@types/DataGridLocaleText";
import Swal from 'sweetalert2';
import newAdapter from "../../../../utils/newAdapter";

type Job = {
    id: string;
    job_id: string;
    job_name: string;
    num_openings: number;
    site: {
        site_id: string;
        site_name: string;
    };
    company: string;
    start_date: Date;
    end_date: Date;
    is_active: boolean;
}

const JobsPositionInfo: React.FC<any> = ({ errors }) => {
    const { t, i18n } = useTranslation();
    const { jobInfo } = useAppSelector(state => state.jobapp);
    let newSelectedPosition = []
    const dispatch = useDispatch();
    const [dataErrorState, setDataErrorState] = useState<any>(errors)

    useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    const [loading, setLoading] = useState<boolean>(true);
    const [selectedPosition, setSelectedPosition] = useState<Job[]>([]);
    // var   [newSelectedPosition, setNewSelectedPosition] = useState<any[]>([]);
    const selectedPositionLength = selectedPosition.length;
    const [startDateOfWork, setStartDateOfWork] = useState<any>(null);

    useEffect(() => {
        if (jobInfo) {
            setStartDateOfWork(jobInfo.start_date_of_work ? dayjs(jobInfo.start_date_of_work) : null);
        }
    }, [jobInfo]);

    useEffect(() => {
        const position = JSON.parse(sessionStorage.getItem('selected_position') || '[]');

        const url = `${apiUrl}/api/jobs/getmanyjobs`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ jobs: position })
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setSelectedPosition(data.jobs);

                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            });
    }, []);

    function CanclePosition(id: any) {
        Swal.fire({
            icon: 'warning',
            title: `<h5 style="margin: 0px">${t('ต้องการยกเลิกการสมัครใช่หรือไม่?')}</h5>`,
            html: `<p>${t('โปรดตรวจสอบความถูกต้องของข้อมูลก่อนยืนยันการยกเลิกการสมัคร')}</p>`,
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`
        }).then((result) => {
            if (result.isConfirmed) {
                newSelectedPosition = [...selectedPosition]

                let data: any = newSelectedPosition.filter(item => item.id !== id)
                setSelectedPosition(data)

                let selected_id: any = []
                data?.map((job: any) => {
                    return selected_id.push(job.id)
                })

                let formsAnswer = JSON.parse(sessionStorage.getItem('selected_position') ?? '[]');
                formsAnswer = selected_id
                sessionStorage.setItem('selected_position', JSON.stringify(formsAnswer));
                let totalSelectedJobs = JSON.parse(sessionStorage.getItem('all_selected') ?? '[]');
                totalSelectedJobs = data
                sessionStorage.setItem('all_selected', JSON.stringify(totalSelectedJobs));

                Swal.fire({
                    icon: 'success',
                    title: `<h5 style="margin: 0px">${t('ยกเลิกการสมัครสำเร็จ!')}</h5>`,
                    showCancelButton: false,
                    confirmButtonColor: '#43A047',
                    confirmButtonText: `${t('Confirm')}`
                })
            }
        })
    }

    const columns: GridColDef[] = [
        {
            field: 'delete',
            headerName: '',
            width: 180,
            renderCell: (params) => {
                return (
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<CancelIcon color="error" />}
                        sx={{ fontSize: 12 }}
                        onClick={() => {
                            const id = params.row.id
                            CanclePosition(id)
                        }}
                    >
                        {t('Cancle')}
                    </Button>
                )
            },
        },
        {
            field: 'position',
            headerName: `${t('Position')}`,
            width: 180,
            valueGetter: (params: GridValueGetterParams) => params.row.job_name
        },
        {
            field: 'site',
            headerName: `${t('Workplace')}`,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.Site.site_name
        },
        {
            field: 'company',
            headerName: `${t('Company')}`,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.company
        },
        {
            field: 'num_openings',
            headerName: `${t('Job opening')}`,
            width: 120,
        },
    ];

    return <div>
        <Typography sx={{ mt: 6, mb: 3 }}>{t("Your job positions")}</Typography>

        {
            loading ?
                <>
                    <Box display='flex' justifyContent='center'><CircularProgress color="success" size={20} sx={{ mr: 1 }} /><Typography align='center'>{t("Loading")}...</Typography>
                    </Box>
                </> :
                selectedPositionLength === 0 &&

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ textAlign: 'center', mb: 2, color: 'error.main' }}>{t("The selected job position was not found. Please selection job position.")}</Typography>
                    <Button component={Link} variant="contained" to="/jobs">
                        {t("Click here to select a job position")}
                    </Button>
                </Box>
        }

        {
            !loading && selectedPositionLength > 0 &&
            <Box display='inline-grid' sx={{ width: '100%' }}>
                <DataGrid
                    getRowId={(row) => row.id}
                    rows={selectedPosition}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 30, 40, 50]}
                    getRowHeight={() => 50}
                    disableRowSelectionOnClick
                    sx={{
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none !important',
                        },
                        fontSize: 13,
                    }}
                    localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                />
            </Box>
        }

        <Typography sx={{ mt: 6, mb: 3 }}>{t("Please select date when you can start work")}</Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th'>
                    <MobileDatePicker
                        closeOnSelect
                        sx={{ width: '100%' }}
                        label={t("Start date of work") + " *"}
                        value={startDateOfWork}
                        localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
                        dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
                        disablePast
                        onChange={(newValue) => {
                            try {
                                if (newValue !== null) {
                                    setStartDateOfWork(newValue.toISOString());
                                    dispatch(editStartDateOfWork(newValue.toISOString()));

                                    if (dataErrorState.start_date_of_work) {
                                        setDataErrorState({ ...dataErrorState, start_date_of_work: null })
                                    }
                                }
                            } catch (error) { }
                        }}
                        slotProps={{
                            textField: {
                                error: dataErrorState.start_date_of_work ? true : false,
                            },
                        }}
                    />
                    <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                        {t(dataErrorState.start_date_of_work?.message)}
                    </Typography>
                </LocalizationProvider>
            </Grid>
        </Grid>
    </div>;
};

export default JobsPositionInfo;
