export function setItem(keyName: string, value: string): void {
    localStorage.setItem(keyName, value);
}

export function getItem(keyName: string): string | null {
    return localStorage.getItem(keyName);
}

export function deleteItem(keyName: string): void {
    localStorage.removeItem(keyName);
}