import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import PublicAppBar from "../appbar/PublicAppBar";

const PublicLayout = () => {
    return (
        <>
            <Container maxWidth='lg'>
                <PublicAppBar />
                <Outlet />
            </Container>
        </>
    )
}

export default PublicLayout;
