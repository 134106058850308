import { Route, Routes } from 'react-router-dom';
import React from 'react';

import './styles/App.scss';

// Layouts
import AdminLayout from './components/layouts/AdminLayout';
import UserJobsLayout from './pages/users/JobsList/UserJobsLayout';
import PublicLayout from './components/layouts/PublicLayout';

// Authentucation
import LoginRedirectPage from './pages/auth/sign_in/LoginRedirectPage';
import ProtectedRoute from './components/common/ProtectedRoute';
import SignIn from './pages/auth/sign_in/SignIn';
import SignUp from './pages/auth/sign_up/SignUp';
import ForgotPassword from './pages/auth/forgot-password/ForgotPassword';

// Error Page
import NotFound from './pages/ErrorPage/NotFound/NotFound';
import UnauthorizedError from './pages/ErrorPage/NotAuthorized/UnauthorizedError';
import ForbiddenError from './pages/ErrorPage/Forbidden/ForbiddenError';
import InternalServerError from './pages/ErrorPage/InternalServerError/InternalServerError';

// Member - Application Form
import AppFormPage from './pages/users/AppFormPage/AppFormPage';

// Member - Detail Open Job
import UserJobDetail from './pages/users/JobsList/UserJobDetail';

// Admin - Master Data: Users
import Users from './pages/admin/master-user/Users';
import CreateUser from './pages/admin/master-user/CreateUser';
import EditUser from './pages/admin/master-user/EditUser';

// Admin - Master Data: Roles
import Roles from './pages/admin/master-role/Roles';
import CreateRole from './pages/admin/master-role/CreateRole';
import EditRole from './pages/admin/master-role/EditRole';

// Admin - Master Data: Status
import Status from './pages/admin/master-status/Status';
import EditStatus from './pages/admin/master-status/EditStatus';

// Admin - Master Data: Sites
import Sites from './pages/admin/master-site/Sites';
import EditSite from './pages/admin/master-site/EditSite';
import CreateSite from './pages/admin/master-site/CreateSite';

// HR - Jobs Hiring
import JobCreate from './pages/admin/job-hiring/JobCreate';
import JobLists from './pages/admin/job-hiring/JobLists';
import JobDetail from './pages/admin/job-hiring/JobDetail';
import JobEdit from './pages/admin/job-hiring/JobEdit';
import JobHistory from './pages/admin/job-hiring/JobHistory';

// HR - All Submitted Application Form
import SubmittedForm from './pages/admin/submitted-form/SubmittedForm';
import JobAppDetail from './pages/admin/submitted-form/JobAppDetail';
import StatusDescription from './pages/admin/submitted-form/StatusDes';

// HR - Interview Appointment
import AppointmentList from './pages/admin/interview/AppointmentList';

import Dashboard from './pages/admin/dashboard/Dashboard';

import JobStatus from './pages/users/JobsStatus/JobsStatus';
import AppformDetail from './pages/users/JobsStatus/AppformDetail';
import DataToEC from './pages/admin/ec-data/DataToEC';

enum MENU_ID {
  DASHBOARD = '101',
  JOBS_LIST = '201',
  JOBS_CREATE = '202',
  JOBS_HISTORY = '203',
  SUBMITTED_FORM = '301',
  APPOINTMENT = '401',
  DATA_TO_EC = '501',
  USERS = '601',
  ROLES = '602',
  SITES = '603',
  STATUS = '604',
}

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<PublicLayout />}>
          <Route index element={<UserJobsLayout />} />
          <Route path='/login/:id?' element={<SignIn />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />

          <Route path="jobs">
            <Route index element={<UserJobsLayout />} />
            <Route path='detail/:id' element={<UserJobDetail />} />
          </Route>
          <Route path='/appform' element={<AppFormPage />} />
          <Route path="jobs_status">
            <Route index element={<JobStatus />} />
            <Route path='appformdetail/:id' element={<AppformDetail />} />
          </Route>
        </Route>

        <Route path="/admin" element={
          <ProtectedRoute allowedRoles={["HR Recruiter", "Super Admin"]}>
            <AdminLayout />
          </ProtectedRoute>
        }>
          <Route index element={
            <ProtectedRoute requiredMenus="101">
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="dashboard" element={
            <ProtectedRoute requiredMenus="101">
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="jobs">
            <Route index element={
              <ProtectedRoute requiredMenus={MENU_ID.JOBS_LIST}>
                <JobLists />
              </ProtectedRoute>
            } />
            <Route path='detail/:id' element={
              <ProtectedRoute requiredMenus={MENU_ID.JOBS_LIST}>
                <JobDetail />
              </ProtectedRoute>
            } />
            <Route path="create" element={
              <ProtectedRoute requiredMenus={MENU_ID.JOBS_CREATE}>
                <JobCreate />
              </ProtectedRoute>
            } />
            <Route path="edit/:id" element={
              <ProtectedRoute requiredMenus={MENU_ID.JOBS_LIST}>
                <JobEdit />
              </ProtectedRoute>
            } />
            <Route path="history" element={
              <ProtectedRoute requiredMenus={MENU_ID.JOBS_HISTORY}>
                <JobHistory />
              </ProtectedRoute>
            } />
          </Route>

          <Route path="interview">
            {/* <Route index element={
              <ProtectedRoute requiredMenus={MENU_ID.APPOINTMENT}>
                <AppointmentList />
              </ProtectedRoute>
            } /> */}
            <Route path="schedule" element={
              <ProtectedRoute requiredMenus={MENU_ID.APPOINTMENT}>
                <AppointmentList />
              </ProtectedRoute>
            } />
          </Route>

          <Route path="ec/data">
            <Route index element={
              <ProtectedRoute requiredMenus={MENU_ID.DATA_TO_EC}>
                <DataToEC />
              </ProtectedRoute>
            } />

            <Route path="detail/:id" element={
              <ProtectedRoute requiredMenus={MENU_ID.DATA_TO_EC}>
                <DataToEC />
              </ProtectedRoute>
            } />
          </Route>

          <Route path="masterdata">
            <Route path="users">
              <Route index element={
                <ProtectedRoute requiredMenus={MENU_ID.USERS}>
                  <Users />
                </ProtectedRoute>
              } />
              <Route path="edit/:id" element={
                <ProtectedRoute requiredMenus={MENU_ID.USERS}>
                  <EditUser />
                </ProtectedRoute>
              } />
              <Route path="create" element={
                <ProtectedRoute requiredMenus={MENU_ID.USERS}>
                  <CreateUser />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="roles">
              <Route index element={
                <ProtectedRoute requiredMenus={MENU_ID.ROLES}>
                  <Roles />
                </ProtectedRoute>
              } />
              <Route path="edit/:id" element={
                <ProtectedRoute requiredMenus={MENU_ID.ROLES}>
                  <EditRole />
                </ProtectedRoute>
              } />
              <Route path="create" element={
                <ProtectedRoute requiredMenus={MENU_ID.ROLES}>
                  <CreateRole />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="status">
              <Route index element={
                <ProtectedRoute requiredMenus={MENU_ID.STATUS}>
                  <Status />
                </ProtectedRoute>
              } />
              <Route path="edit/:id" element={
                <ProtectedRoute requiredMenus={MENU_ID.STATUS}>
                  <EditStatus />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="sites">
              <Route index element={
                <ProtectedRoute requiredMenus={MENU_ID.SITES}>
                  <Sites />
                </ProtectedRoute>
              } />
              <Route path="edit/:id" element={
                <ProtectedRoute requiredMenus={MENU_ID.SITES}>
                  <EditSite />
                </ProtectedRoute>
              } />
              <Route path="create" element={
                <ProtectedRoute requiredMenus={MENU_ID.SITES}>
                  <CreateSite />
                </ProtectedRoute>
              } />
            </Route>
          </Route>

          <Route path="application-form">
            <Route index element={
              <ProtectedRoute requiredMenus={MENU_ID.SUBMITTED_FORM}>
                <SubmittedForm />
              </ProtectedRoute>
            } />
            <Route path="status-description" element={<StatusDescription />} />
            <Route path="detail/:id" element={
              <ProtectedRoute requiredMenus={MENU_ID.SUBMITTED_FORM}>
                <JobAppDetail />
              </ProtectedRoute>} />
          </Route>
        </Route>

        <Route path='/login/AADRedirect' element={<LoginRedirectPage />} />

        <Route path="/forbidden" element={<ForbiddenError />} />
        <Route path="/unauthorized" element={<UnauthorizedError />} />
        <Route path="/servererror" element={<InternalServerError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;