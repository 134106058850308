// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import { ThemeProvider } from "@mui/material";

// Utils
import { useTranslation } from 'react-i18next';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as Yup from 'yup';

// Styles
import theme from "../../../styles/theme";

// Services
import { resetPassword } from '../../../services/AuthService';

interface dataProps {
  idCardNumber: string;
}

const CreateNewPassword: React.FC<dataProps> = ({ idCardNumber }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setComfirmPassword] = useState<string>('');

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(`${t("Password is required")}`)
      .min(8, `${t("Password must be at least 8 characters")}`)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,}/,
        `${t("Password must include a number and letter")}`
      ),
    confirmPassword: Yup.string()
      .required(`${t("Confirm Password is required")}`)
      .oneOf([Yup.ref('password')], `${t("Passwords don't match")}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema)
  });

  const onSubmit = (passwordValue: any) => {

    const resetpassword = async () => {
      try {
        const data = {
          username: idCardNumber,
          new_password: passwordValue.password
        }

        const response = await resetPassword(data);

        if (response.data?.success) {
          let timeleft = 3;
          let downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
              clearInterval(downloadTimer);
              window.location.assign('/login');
            }
            timeleft -= 1;
          }, 1000);

          Swal.fire({
            icon: 'success',
            title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: `${t("OK!")}`,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.assign('/login');
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
            confirmButtonColor: '#3085d6',
            confirmButtonText: `${t("OK!")}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.assign('/login');
            }
          })
        }
      } catch (error) {
        navigate('/server', { replace: true });
      }
    }
    resetpassword();
  }

  return <div>
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box marginY={7} sx={{ display: 'flex', flexDirection: 'column', width: '400px', }}>
          <Typography component="h1" variant="h6" align="left" sx={{ fontWeight: 'bold', letterSpacing: 0.5 }}>
            {t("Reset your password")}
          </Typography>
          <Typography align="left" sx={{ fontSize: 15, letterSpacing: 0.5 }} my={1} >
            {t("Please enter your new password")}
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              fullWidth
              color='info'
              id="password"
              label={t("New Password")}
              size="medium"
              margin="normal"
              type='password'
              {...register('password')}
              error={errors.password ? true : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(event.target.value)
                errors.password = undefined
              }}
            />
            <Typography variant="inherit" color="error" align="left" pt={1} fontSize={14} fontFamily='Sarabun'>
              {errors.password?.message}
            </Typography>
            <FormHelperText id="password-helper-text" sx={{ color: 'gray', fontSize: 12 }}>
              {t("Password length must be greater than 8 characters. Including a number and letter.")} <br />
              {t("Can't use special characters.")}
            </FormHelperText>
            <TextField
              fullWidth
              color='info'
              id="comfirmpassword"
              label={t("Confirm New Password")}
              size="medium"
              margin="normal"
              type='password'
              error={errors.confirmPassword ? true : false}
              {...register('confirmPassword')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComfirmPassword(event.target.value)
                errors.confirmPassword = undefined
              }}
            />
            <Typography variant="inherit" color="error" align="left" pt={1} fontSize={14} fontFamily='Sarabun'>
              {errors.confirmPassword?.message}
            </Typography>
          </Box>
          <Box>
            <Button
              fullWidth
              size="medium"
              variant="contained"
              sx={{
                mt: 6,
                minWidth: 170,
              }}
              onClick={handleSubmit(onSubmit)}
            >
              {t("Submit")}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  </div>;
};

export default CreateNewPassword;
