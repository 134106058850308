import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/sites';

export function getAllSites() {
    return api.get(`${BASE_URL}`);
}

export function querySites(query: string) {
    return api.get(`${BASE_URL}/query?${query}`);
}

export function getSiteById(id: string) {
    return api.get(`${BASE_URL}/${id}`);
}

export function createSite(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}`, data, config);
}

export function editSite(id: string, data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.put(`${BASE_URL}/${id}`, data, config);
}