import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import GreatBritainFlag from "../../assets/images/Great-Britain-Flag.png"
import ThailandFlag from "../../assets/images/Thailand-Flag.png"
import MenuIcon from "@mui/icons-material/Menu";
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListItemButton from '@mui/material/ListItemButton';
import WorkIcon from '@mui/icons-material/Work';
import Icon from '@mui/material/Icon';

import { useEffect, useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from "react-i18next";


import Grid from '@mui/material/Grid';
import { Link as ReactRouterLink, useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { eraseCookie } from '../../cookie';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { editIsLoggedIn } from '../../redux/slices/userSlice';
import { apiUrl } from '../../Constants';
import Swal from 'sweetalert2';

const drawerWidth = 240;

const PublicAppBar: React.FC = () => {
    const location = useLocation();
    const path = location.pathname.replace(/\/$/, '');
    const pathParts = path.split("/");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { fNameTH, lNameTH, fNameEN, lNameEN } = useAppSelector((state) => state.user);

    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    const [parsedToken, setParsedToken] = useState<any | null>(null);
    useEffect(() => {
        try {
            if (localStorage.getItem('access_token') !== null) {
                setParsedToken(jwtDecode(localStorage.access_token));
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        } catch (error) {
            setIsLoggedIn(false);
        }
    }, [])


    const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
    const open = Boolean(showMenu);
    const handleClickLang = (event: React.MouseEvent<HTMLElement>) => {
        setShowMenu(event.currentTarget);
    };

    const handleClose = () => {
        setShowMenu(null);
    };

    const [openUserMenu, setOpenUserMenu] = useState<null | HTMLElement>(null);
    const isUserMenuOpen = Boolean(openUserMenu);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenUserMenu(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setOpenUserMenu(null);
    };

    const handleLogout = () => {
        Swal.fire({
            title: `<h5 style="margin: 0px">${t('Log out')}</h5>`,
            html: `<p>${t('Do you want to log out?')}</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: `${t('Yes, log out.')}`,
            cancelButtonText: `${t('Cancel')}`,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const logout = async () => {
                    eraseCookie();
                    sessionStorage.removeItem('get_latest_form');
                    sessionStorage.removeItem('selected_position');
                    sessionStorage.removeItem('all_selected');
                    sessionStorage.removeItem('id_card_number');
                    dispatch(editIsLoggedIn(false));
                    window.location.assign('/login');
                };

                logout();
            }
        })
    }


    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const { t, i18n } = useTranslation();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Box sx={{ overflow: 'auto', paddingX: 2 }}>
                <List>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '210px' }}>
                        <Link component={ReactRouterLink} to="/jobs" color="inherit" underline="none">
                            {pathParts[1] === 'jobs' || pathParts[1] === undefined ? (
                                <ListItemButton sx={{
                                    pl: 2, marginBottom: 0.5, backgroundColor: theme.palette.primary.main, borderRadius: 1, width: 210,
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                }}>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <WorkIcon sx={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <Typography sx={{ pl: 1, fontSize: 16, color: 'white' }}>
                                        {t("All Open Jobs")}
                                    </Typography>
                                </ListItemButton>
                            ) : (
                                <ListItemButton sx={{ pl: 2, marginBottom: 0.5 }}>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <WorkIcon />
                                    </ListItemIcon>
                                    <Typography sx={{ pl: 1, fontSize: 16 }}>
                                        {t("All Open Jobs")}
                                    </Typography>
                                </ListItemButton>
                            )}
                        </Link>
                    </Box>

                    {
                        isLoggedIn && <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '210px' }}>
                                <Link component={ReactRouterLink} to="/jobs_status" color="black" underline="none">
                                    {pathParts[1] === 'jobs_status' || pathParts[1] === undefined ? (
                                        <ListItemButton sx={{
                                            pl: 2, marginBottom: 0.5, backgroundColor: theme.palette.primary.main, borderRadius: 1, width: 210,
                                            "&:hover": {
                                                backgroundColor: theme.palette.primary.main,
                                            },
                                        }}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <AssignmentIcon sx={{ color: 'white' }} />
                                            </ListItemIcon>
                                            <Typography sx={{ pl: 1, fontSize: 16, color: 'white' }}>
                                                {t("Job Application Status")}
                                            </Typography>
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton sx={{ pl: 2, marginBottom: 0.5 }}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <AssignmentIcon />
                                            </ListItemIcon>
                                            <Typography sx={{ pl: 1, fontSize: 16 }}>
                                                {t("Job Application Status")}
                                            </Typography>
                                        </ListItemButton>
                                    )}
                                </Link>
                            </Box>
                        </>
                    }
                </List>
            </Box>
        </div>
    )

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language') === null ? (
            localStorage.setItem('language', 'th')!
        ) : localStorage.getItem('language')!)
    }, [])

    const onClickLanguageChangeENtoTH = () => {
        i18n.changeLanguage("en");
        localStorage.setItem('language', 'en')
    };
    const onClickLanguageChangeTHtoEN = () => {
        i18n.changeLanguage("th");
        localStorage.setItem('language', 'th')
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item>
                            <Box display='flex' alignItems="center">
                                <>
                                    <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' }, mr: 1 }}>
                                        <Link component={ReactRouterLink} to="/" underline="none">
                                            <img src='https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png' alt='Betagro Logo' width='80px' />
                                        </Link>
                                    </Box>
                                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleDrawerToggle}
                                            sx={{ mr: 2, display: { sm: "flex" } }}
                                        >
                                            <MenuIcon sx={{ color: 'gray' }} />
                                        </IconButton>
                                        <Drawer
                                            variant="temporary"
                                            open={mobileOpen}
                                            onClose={handleDrawerToggle}
                                            ModalProps={{
                                                keepMounted: true
                                            }}
                                            sx={{
                                                display: { xs: "block", sm: "block" },
                                                "& .MuiDrawer-paper": {
                                                    boxSizing: "border-box",
                                                    width: drawerWidth
                                                }
                                            }}
                                        >
                                            <Box sx={{ pl: 4, pt: 3 }}>
                                                <Link component={ReactRouterLink} to="/" underline="none">
                                                    <img src='https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png' alt='Betagro Logo' width='80px' />
                                                </Link>
                                            </Box>
                                            {drawer}
                                        </Drawer>
                                    </Box>

                                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                        <Box display='flex' gap={6} marginLeft={4}>
                                            <Box>
                                                <Link component={ReactRouterLink} to="/jobs" color="black" underline="none">
                                                    <Typography sx={{
                                                        pl: 1, fontSize: 16, color: pathParts[1] === 'jobs' ? theme.palette.primary.main : 'black', fontWeight: pathParts[1] === 'jobs' ? 'bold' : 'normal', '&:hover': {
                                                            fontWeight: 'bold',
                                                            color: theme.palette.primary.main
                                                        }
                                                    }}>
                                                        {t("All Open Jobs")}
                                                    </Typography>
                                                </Link>
                                            </Box>
                                            {
                                                isLoggedIn && (
                                                    <>
                                                        <Box>
                                                            <Link component={ReactRouterLink} to="/jobs_status" color="black" underline="none">
                                                                <Typography sx={{
                                                                    pl: 1, fontSize: 16, color: pathParts[1] === 'jobs_status' ? theme.palette.primary.main : 'black', fontWeight: pathParts[1] === 'jobs_status' ? 'bold' : 'normal', '&:hover': {
                                                                        fontWeight: 'bold',
                                                                        color: theme.palette.primary.main
                                                                    }
                                                                }}>
                                                                    {t("Job Application Status")}
                                                                </Typography>
                                                            </Link>
                                                        </Box>
                                                    </>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </>
                            </Box>
                        </Grid>

                        <Grid item >
                            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                                <IconButton
                                    id="language-button"
                                    aria-controls={open ? "lang-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClickLang}
                                    sx={{ width: '40px', height: '40px' }}
                                >
                                    <LanguageIcon />
                                    {
                                        i18n.language === 'en' ? <img src={GreatBritainFlag} alt='Great Britain flag' width='24px' style={{ position: 'absolute', bottom: -2, right: -2 }} /> :
                                            <img src={ThailandFlag} alt='Thailand flag' width='24px' style={{ position: 'absolute', bottom: -2, right: -2 }} />
                                    }
                                </IconButton>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Menu
                                        id="lang-menu"
                                        anchorEl={showMenu}
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "language-button"
                                        }}
                                    >
                                        <MenuItem onClick={onClickLanguageChangeTHtoEN}><img src={ThailandFlag} alt='thailand flag' width='24px' style={{ marginRight: '8px' }} />ไทย</MenuItem>
                                        <MenuItem onClick={onClickLanguageChangeENtoTH}><img src={GreatBritainFlag} alt='great britain flag' width='24px' style={{ marginRight: '8px' }} />English</MenuItem>
                                    </Menu>

                                    {
                                        !isLoggedIn && (
                                            <Box display='flex'>
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    size="small"
                                                    variant="outlined"
                                                    sx={{ color: theme.palette.primary.main, borderColor: theme.palette.primary.main, mr: 2, ml: 2, minWidth: 'max-content', py: 1, px: 2 }}
                                                    onClick={() => { window.location.assign('/login') }}
                                                >
                                                    {t("Sign in")}
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    size="small"
                                                    variant="contained"
                                                    sx={{ backgroundColor: theme.palette.primary.main, color: 'white', minWidth: 'max-content', py: 1, px: 2 }}
                                                    onClick={() => { window.location.assign('/register') }}
                                                >
                                                    {t("Sign Up")}
                                                </Button>
                                            </Box>
                                        )
                                    }

                                    {isLoggedIn && (
                                        <>
                                            <IconButton
                                                onClick={handleOpenUserMenu}
                                                size="medium"
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'unset'
                                                    }, '&:active': {
                                                        backgroundColor: 'unset'
                                                    }
                                                }}
                                                aria-controls={isUserMenuOpen ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={isUserMenuOpen ? 'true' : undefined}
                                            >
                                                <Avatar sx={{
                                                    backgroundColor: '#e1e8eb', color: 'blue', '&hover': {
                                                        backgroundColor: 'none'
                                                    }
                                                }} {...stringAvatar(`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`)} />
                                            </IconButton>
                                            <Typography onClick={handleOpenUserMenu} sx={{ color: 'black', marginLeft: 1, display: { xs: 'none', lg: 'flex' }, alignItems: 'center', '&:hover': { cursor: 'pointer' } }}>{`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`} <ArrowDropDownIcon /></Typography>
                                            <Menu
                                                anchorEl={openUserMenu}
                                                id="account-menu"
                                                open={isUserMenuOpen}
                                                onClose={handleCloseUserMenu}
                                                onClick={handleCloseUserMenu}
                                            >
                                                <Box px={4} pt={1} pb={2} display='flex' gap={2} alignItems='center'>
                                                    <Avatar sx={{ backgroundColor: '#e1e8eb', color: 'blue' }} {...stringAvatar(`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`)} />
                                                    <Box>
                                                        <Typography sx={{ fontWeight: 600 }}>{`${fNameTH !== null ? fNameTH : fNameEN} ${lNameTH !== null ? lNameTH : lNameEN}`}</Typography>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                                <MenuItem
                                                    onClick={handleLogout}
                                                    sx={{
                                                        py: 1.2, px: 4
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <Icon fontSize="medium">logout</Icon>
                                                    </ListItemIcon>
                                                    {t("Logout")}
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </Box>
                            </Stack>
                        </Grid>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default PublicAppBar;