// React
import React, { useState, useEffect } from 'react'
import {
    useParams,
    Link as ReactRouterLink,
    useNavigate,
    useSearchParams
} from 'react-router-dom';

// MUI Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Link from '@mui/material/Link';

// MUI Icons
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HistoryIcon from '@mui/icons-material/History';
import PeopleIcon from '@mui/icons-material/People';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';

// Utils
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'

// Styles
import theme from '../../../styles/theme';

// Types
import { Job } from '../../../@types';

// Services
import { deleteJobById, getJobById } from '../../../services/JobService';

const JobDetail = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [loadingJob, setLoadingJob] = useState<boolean>(true);
    const allSuccessLoading = !loadingJob;
    const [hovered, setHovered] = useState<{ [key: string]: boolean }>({});
    const [jobData, setJobData] = useState<Job>({
        job_name: "",
        num_openings: 0,
        Site: {
            site_id: '',
            site_name: ''
        },
        site_id: "",
        company: "",
        contract_signer: "",
        start_date: new Date(),
        end_date: new Date(),
        hr_name: "",
        hr_email: "",
        hr_phone: "",
        is_active: true,
        id: "",
        job_description: [{ job_title: "", job_detail: "" }],
    })
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [moreOpen, setMoreOpen] = useState(false);
    const [placement, setPlacement] = useState<PopperPlacementType>();

    const handleMouseEnter = (hoverName: string) => {
        setHovered((prevHover) => ({
            ...prevHover,
            [hoverName]: true,
        }));
    };

    const handleMouseLeave = (hoverName: string) => {
        setHovered((prevHover) => ({
            ...prevHover,
            [hoverName]: false,
        }));
    };

    useEffect(() => {
        const fetchJobById = async () => {
            try {
                const response = await getJobById(id);

                if (!response.data?.success) {
                    Swal.fire({
                        title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#43A047',
                        confirmButtonText: 'OK!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/admin/jobs', { replace: true });
                        }
                    })

                    return;
                }

                const jobData = response.data?.job;
                setJobData(jobData);

                const jobDescription = JSON.parse(jobData.job_description)
                setJobData({
                    ...jobData,
                    job_description: jobDescription
                })

                setLoadingJob(false);
            } catch (error: any) {
                console.log(error);

                navigate('/servererror', { replace: true, state: { error: error.message } });
            }
        };

        fetchJobById();
    }, []);

    const monthTH: string[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    const monthEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const format_startdate = (): string => {
        let date_of_format: string = '';
        const date: string = new Date(jobData.start_date).getDate().toString();
        const month: number = new Date(jobData.start_date).getMonth();
        const year: number = new Date(jobData.start_date).getFullYear();
        let date_of_birth: string = "";
        i18n.language === 'th' ?
            date_of_birth = date + ' ' + monthTH[month] + ' ' + (year + 543) :
            date_of_birth = date + ' ' + monthEN[month] + ' ' + year
        date_of_format = date_of_birth
        return date_of_format
    }

    const format_enddate = (): string => {
        let date_of_format: string = '';
        const date: string = new Date(jobData.end_date).getDate().toString();
        const month: number = new Date(jobData.end_date).getMonth();
        const year: number = new Date(jobData.end_date).getFullYear();
        let date_of_birth: string = "";
        i18n.language === 'th' ?
            date_of_birth = date + ' ' + monthTH[month] + ' ' + (year + 543) :
            date_of_birth = date + ' ' + monthEN[month] + ' ' + year
        date_of_format = date_of_birth
        return date_of_format
    }

    let allJobTopic: string[] = [];
    if (jobData.id !== "") {
        for (let i = 0; i < jobData.job_description.length; i++) {
            Object.values(jobData.job_description[i]).forEach((_, index) => {
                allJobTopic.push(Object.values(jobData.job_description[i])[index])
            })
        }
    }

    const handleMoreOpenClick =
        (newPlacement: PopperPlacementType) =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
                setMoreOpen((prev) => placement !== newPlacement || !prev);
                setPlacement(newPlacement);
            };

    const handleClickAway = () => {
        setMoreOpen(false);
    };

    return <div>
        {
            allSuccessLoading ? <>
                <Box display='flex' gap={3} sx={{ mb: 3, mt: 2 }}>
                    <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                        textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                            color: '#1976d2',
                        }
                    }}>
                        <ChevronLeftIcon fontSize='small' />
                        <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                    </Box>
                    
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            component={ReactRouterLink}
                            to="/admin"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {t("Dashboard")}
                        </Link>
                        {
                            searchParams.get('content_type') === 'history' ? <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                component={ReactRouterLink}
                                to="/admin/jobs/history"
                            >
                                <HistoryIcon sx={{ mr: 0.5 }} fontSize="small" />
                                {t("History")}
                            </Link> :
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    component={ReactRouterLink}
                                    to="/admin/jobs"
                                >
                                    <DescriptionIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    {t("JobsList")}
                                </Link>
                        }
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            <WorkIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {jobData.job_name}
                        </Typography>
                    </Breadcrumbs>
                </Box>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography gutterBottom variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
                        {jobData.job_name}
                    </Typography>
                    <Box>
                        <Button onClick={handleMoreOpenClick('bottom-end')}>
                            <Icon>more_horiz</Icon>
                        </Button>
                        

                        <Popper open={moreOpen} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Fade {...TransitionProps} timeout={50}>
                                        <Paper sx={{ width: 200, maxWidth: '100%' }}>
                                            <MenuList sx={{ p: 0.5, borderRadius: 1 }}>
                                                {
                                                    jobData.is_active === false ? null :
                                                        <MenuItem onClick={() => {
                                                            setMoreOpen(false);
                                                            navigate(`/admin/jobs/edit/${jobData.id}`);
                                                        }}>
                                                            <ListItemIcon>
                                                                <EditIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <Typography variant="body1">
                                                                {t("Edit")}
                                                            </Typography>
                                                        </MenuItem>
                                                }

                                                <MenuItem onClick={() => {
                                                    setMoreOpen(false);
                                                    searchParams.has('content_type') ? 
                                                    navigate(`/admin/jobs/create?copy=${jobData.id}&${searchParams.toString()}`) :
                                                    navigate(`/admin/jobs/create?copy=${jobData.id}`)
                                                }}>
                                                    <ListItemIcon>
                                                        <ContentCopyIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="body1">
                                                        {t("Create a copy")}
                                                    </Typography>
                                                </MenuItem>

                                                <MenuItem
                                                    sx={{
                                                        ':hover': {
                                                            backgroundColor: '#d32f2f',
                                                        }, borderRadius: 1
                                                    }}
                                                    onMouseEnter={() => handleMouseEnter('delete')}
                                                    onMouseLeave={() => handleMouseLeave('delete')}
                                                    onClick={() => {
                                                        setMoreOpen(false);
                                                        Swal.fire({
                                                            title: `<h5 style="margin: 0px">${t("Are you sure?")}</h5>`,
                                                            html: `<p>${t("Do you want to delete this job?")}</p>`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#d33',
                                                            confirmButtonText: `${t("Delete")}`,
                                                            cancelButtonText: `${t("Cancel")}`,
                                                            reverseButtons: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                const deleteJob = async () => {
                                                                    try {
                                                                        const response = await deleteJobById(id);

                                                                        if (response.data?.success) {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                title: `<h5 style="margin: 0px">${t("Deleted!")}</h5>`,
                                                                                html: `<p>${t("Job has been deleted.")}</p>`,
                                                                                confirmButtonText: `${t("OK!")}`,
                                                                                confirmButtonColor: theme.palette.success.main,
                                                                            }).then(() => {
                                                                                if (searchParams.get('content_type') === 'history') {
                                                                                    searchParams.get('display') === 'table' ? 
                                                                                    navigate('/admin/jobs/history?display=table', { replace: true }) :
                                                                                    navigate('/admin/jobs/history?display=grid', { replace: true })
                                                                                }
                                                                                else {
                                                                                    navigate('/admin/jobs', { replace: true });
                                                                                }
                                                                            })
                                                                        } else {
                                                                            Swal.fire({
                                                                                icon: 'error',
                                                                                title: `<h5 style="margin: 0px">${t("Error!")}</h5>`,
                                                                                html: `<p>${t("This job position already has applicants. Deletion is not possible.")}</p>` + 
                                                                                    `<p>${t("Please close the application instead of deleting.")}</p>`,
                                                                                confirmButtonText: `${t("OK!")}`,
                                                                                confirmButtonColor: theme.palette.success.main,
                                                                            })
                                                                        }
                                                                    } catch (error: any) {
                                                                        Swal.fire({
                                                                            title: `${t("Error!")}`,
                                                                            html: `${t("Something went wrong.")}`,
                                                                            icon: 'error',
                                                                            confirmButtonText: `${t("ok")}`,
                                                                            confirmButtonColor: theme.palette.success.main,
                                                                        })
                                                                    }
                                                                };

                                                                deleteJob();
                                                            }
                                                        })

                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <DeleteIcon sx={{ color: hovered['delete'] ? 'white' : '#d32f2f' }} fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ color: hovered['delete'] ? 'white' : '#d32f2f' }}>
                                                        {t("Delete")}
                                                    </Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </Paper>
                                    </Fade>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </Box>
                </Grid>
                <Grid container sx={{ my: 2 }}>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ mr: 4, mb: 2 }}
                        >
                            <DomainRoundedIcon fontSize='medium' sx={{ color: '#FF9824' }} />
                            <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                {jobData.company}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ mr: 4, mb: 2 }}
                        >
                            <LocationOnRoundedIcon fontSize='medium' sx={{ color: '#FF9824' }} />
                            <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                {jobData.Site?.site_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ mr: 4, mb: 2 }}
                        >
                            <InsertInvitationIcon fontSize='medium' sx={{ color: '#4CAF50' }} />
                            <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                {format_startdate()} - {format_enddate()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ mr: 4 }}
                        >
                            <PeopleIcon fontSize='medium' sx={{ color: '#04A9F4' }} />
                            <Typography variant="body1" component="div" sx={{ ml: 1 }}>
                                {jobData.num_openings} {t("Openings")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Typography>
                    {t("Workplace") + " : "} {jobData.Site?.site_name}
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    {t("Company") + " : "} {jobData.company}
                </Typography>

                {allJobTopic.map((data, index) => {
                    let dataDetails: string[] = [];
                    if ((index % 2) !== 0) {
                        dataDetails = data.split('\n')
                    }
                    return <div key={index}>
                        {dataDetails.length !== 0 ? (
                            <>
                                {dataDetails.map((detail, i) => {
                                    return (
                                        <Typography key={i} variant="body1" component="div">
                                            {detail}
                                        </Typography>
                                    );
                                })}
                            </>
                        ) :
                            (
                                <Typography gutterBottom variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                                    {data}
                                </Typography>
                            )}
                    </div>;

                })}

                <Grid container direction="column" sx={{ my: 3 }}>
                    <Typography gutterBottom variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                        {t("Contract HR Recruiter")}
                    </Typography>
                    <Typography variant="body1" component="div" >
                        {jobData.hr_name}
                    </Typography>
                    <Typography variant="body1" component="div" >
                        {t("Email") + " :"} {jobData.hr_email}
                    </Typography>
                    <Typography variant="body1" component="div" >
                        {t("Tel") + " :"} {jobData.hr_phone}
                    </Typography>
                </Grid>
            </> : <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                    <CircularProgress />
                </Box>
            </>
        }
    </div >;
}

export default JobDetail