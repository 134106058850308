// React
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material';
import { useParams} from 'react-router-dom';

// Utils
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { setCookie } from "../../../cookie";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Styles
import theme from "../../../styles/theme";
import 'react-toastify/dist/ReactToastify.css';
import { green } from "@mui/material/colors";

// Services
import { localLogin } from "../../../services/AuthService";
import { checkAllDupJobApply } from "../../../services/JobService";
// Images
import BetagroIcon from "../../../assets/images/betagro-icon.png"

const SignIn = () => {
    const defaultTheme = createTheme({
        typography: {
            fontFamily: 'Sarabun'
        }
    });

    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [sessionExpired, setSessionExpired] = useState<boolean>(false);
    const [tokenError, setTokenError] = useState<boolean>(false);
    const [localLoginLoading, setLocalLoginLoading] = useState<boolean>(false);
    const [adfsLoginLoading, setAdfsLoginLoading] = useState<boolean>(false);

    const SignInSchema = Yup.object().shape({
        id_card_number: Yup.string()
            .required(`${t("ID card number is required")}`),
        password: Yup.string()
            .required(`${t("Password is required")}`)
            .min(8, `${t("Password must be at least 8 characters")}`)
            .matches(
                /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                `${t("Password must include a number and letter")}`
            ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SignInSchema)
    });

    const onSubmit = (user: any) => {
        try {
            setLocalLoginLoading(true);

            const login = async () => {
                const data = {
                    username: user.id_card_number,
                    password: user.password
                }

                const response = await localLogin(data);

                if (response.data?.success) {
                    setLocalLoginLoading(false);

                    const parsedToken = jwtDecode(response.data?.access_token) as { exp: number, UserId:string }
                    const tokenExpiryTime = new Date(parsedToken.exp * 1000)
                    setCookie(response.data?.access_token, tokenExpiryTime)

                    let timeleft = 3;
                    let downloadTimer = setInterval(async function () {
                        if (timeleft <= 0) {
                            clearInterval(downloadTimer);
                            if (searchParams.get("continue") !== null) {
                                window.location.assign(searchParams.get("continue") as string);
                            } else {
                                const checkDup = await onCheckDupJob(parsedToken?.UserId)
                                const stepAppform = sessionStorage.getItem('nextStep')
                                if(checkDup && stepAppform === 'appform'){
                                    sessionStorage.removeItem('nextStep');
                                    window.location.assign('/appform');
                                }else{
                                    sessionStorage.removeItem('nextStep');
                                    window.location.assign('/jobs');
                                }
                            }
                        }
                        timeleft -= 1;
                    }, 1000);

                    Swal.fire({
                        icon: 'success',
                        title: `${t("Login Success")}`,
                        text: `${t("You will be redirected in")} ${timeleft} ${t("seconds")}`,
                        showConfirmButton: true,
                        confirmButtonText: `${t("Login Success")}`,
                        confirmButtonColor: theme.palette.primary.main,
                        timer: 3000,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            if (searchParams.get("continue") !== null) {
                                window.location.assign(searchParams.get("continue") as string);
                            } else {
                                const checkDup = await onCheckDupJob(parsedToken?.UserId)
                                const stepAppform = sessionStorage.getItem('nextStep')
                                if(checkDup && stepAppform === 'appform'){
                                    sessionStorage.removeItem('nextStep');
                                    window.location.assign('/appform');
                                }else{
                                    sessionStorage.removeItem('nextStep');
                                    window.location.assign('/jobs');
                                }
                            }
                        }
                    })
                } else {
                    setLocalLoginLoading(false);
                    toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }

            login();

        } catch (error) {
            navigate('/servererror', { replace: true });
            setLocalLoginLoading(false);
        }
    };

    const onCheckDupJob = async (userid:string) =>{
        const position = JSON.parse(sessionStorage.getItem('selected_position') || '[]');
        let totalSelectedJobs = JSON.parse(sessionStorage.getItem('all_selected') ?? '[]');
        var filtered = [];

        const jobList = await checkAllDupJobApply({
            jobs_id:position,
            user_id:userid
        })

        let JobSelete = jobList?.data?.data.length > 0 ? jobList?.data?.data : [] 
        if( JobSelete){

            for(let job in totalSelectedJobs){
                for(let item in JobSelete){
                  if(JobSelete[item] === totalSelectedJobs[job].id){
                    filtered.push(totalSelectedJobs[job])
                  }
                }
              }
        }
        sessionStorage.setItem('selected_position', JSON.stringify(JobSelete));
        sessionStorage.setItem('all_selected', JSON.stringify(filtered));
        return true
    }

    useEffect(() => {
        if (searchParams.get("token_expired") === "true") {
            sessionStorage.removeItem('forms_answers');
            sessionStorage.removeItem('selected_position');
            sessionStorage.removeItem('all_selected');
            localStorage.removeItem('access_token');

            setSessionExpired(true)
            navigate('/login', { replace: true });
        } else if (searchParams.get("token_invalid") === "true") {
            sessionStorage.removeItem('forms_answers');
            sessionStorage.removeItem('selected_position');
            sessionStorage.removeItem('all_selected');
            localStorage.removeItem('access_token');

            setTokenError(true)
            navigate('/login', { replace: true });
        }
    }, [navigate, searchParams])

    useEffect(() => {
        const token = localStorage.access_token;

        if (token) {
            try {
                const user: any = jwtDecode(token);

                const adminAndHRRolesType = ['Super Admin', 'HR Recruiter'];

                if (adminAndHRRolesType.includes(user.RoleType)) {
                    navigate('/admin');
                } else {
                    navigate('/jobs');
                }
            } catch (error) {
                navigate('/login');
                localStorage.removeItem('access_token')
            }
        }

    }, [navigate])

    return <div>
        <ToastContainer style={{ width: "400px" }} />

        <ThemeProvider theme={defaultTheme}>
            <Container component="main" sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: 3, sm: 0 } }}>
                <Box
                    position="static"
                    sx={{
                        marginTop: { xs: 4, sm: 6 },
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '400px',
                        width: '100%'
                    }}
                >
                    <Box sx={{ display: { sm: 'none' }, alignSelf: 'center' }}>
                        <Link component={ReactRouterLink} to="/" underline="none">
                            <img src='https://upload.wikimedia.org/wikipedia/commons/3/38/Logo-BTG-COP-440x440_px.png' alt='Betagro Logo' width='70px' />
                        </Link>
                    </Box>
                    <Typography component="h1" variant="h5" sx={{ alignSelf: { xs: 'center', sm: 'flex-start' } }}>
                        {t("Sign in")}
                    </Typography>
                    {
                        sessionExpired && (
                            <Box display='flex' alignItems='center' mt={2} mb={-1} gap={1}>
                                <Icon fontSize="medium" sx={{ color: 'error.main' }}>error_outline</Icon>
                                <Typography color="error.main" align="left" fontSize={14}>
                                    {t("Your session has expired. Please login again.")}
                                </Typography>
                            </Box>
                        )
                    }
                    {
                        tokenError && (
                            <Box display='flex' alignItems='center' mt={2} mb={-1} gap={1}>
                                <Icon fontSize="medium" sx={{ color: 'error.main' }}>error_outline</Icon>
                                <Typography color="error.main" align="left" fontSize={14}>
                                    {t("Your token is invalid. Please login again.")}
                                </Typography>
                            </Box>
                        )
                    }
                    <Box component="form" sx={{ mt: 1 }}>
                        <TextField
                            sx={{ width: '100%' }}
                            fullWidth
                            required
                            margin="normal"
                            size="small"
                            id="id_card_number"
                            label={t("ID card number")}
                            autoComplete="id_card_number"
                            autoFocus
                            {...register('id_card_number')}
                            error={errors.id_card_number ? true : false}
                            placeholder={t("ID card number")!}
                            defaultValue={id}
                        />
                        <Typography color="error" align="left" fontSize={14}>
                            {errors.id_card_number?.message}
                        </Typography>

                        <TextField
                            sx={{ width: '100%' }}
                            fullWidth
                            required
                            margin="normal"
                            size="small"
                            label={t("Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            {...register('password')}
                            error={errors.password ? true : false}
                            placeholder={t("Password")!}
                        />
                        <Typography color="error" align="left" marginBottom={1} fontSize={14}>
                            {errors.password?.message}
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography marginBottom={1} fontSize={14}>
                                <Link component={ReactRouterLink} to="/forgot-password" variant="body2" underline='none' sx={{ color: '#1565c0' }}>
                                    {t("Forgot Password?")}
                                </Link>
                            </Typography>
                            <Typography marginBottom={1} fontSize={14}>
                                <Link component={ReactRouterLink} to="/register" variant="body2" underline='none' sx={{ color: '#1565c0' }}>
                                    {t("Don't have an account? Sign up.")}
                                </Link>
                            </Typography>
                        </Box>

                        <ThemeProvider theme={theme}>
                            <Box sx={{ marginTop: 3, position: 'relative' }}>
                                <Button
                                    disabled={localLoginLoading}
                                    fullWidth
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    sx={{
                                        mt: 1,
                                        minWidth: 170,
                                        mb: 1,
                                        color: localLoginLoading ? 'lightgray' : 'white',
                                    }}
                                    onClick={
                                        handleSubmit(onSubmit)
                                    }
                                >
                                    {t("Sign in")}
                                </Button>
                                {localLoginLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '30%',
                                            left: '47%',
                                            zIndex: 999,
                                        }}
                                    />
                                )}
                            </Box>
                        </ThemeProvider>
                        <div
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px' }}
                        >
                            <div style={{ flex: 1, height: '1px', backgroundColor: 'black' }} />
                            <div>
                                <Typography my={2} style={{ width: '70px', textAlign: 'center' }}>{t("OR")}</Typography>
                            </div>
                            <div style={{ flex: 1, height: '1px', backgroundColor: 'black' }} />
                        </div>

                        <Stack direction="column" spacing={1} alignItems={'center'}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    maxWidth: 400,
                                    width: '100%'
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color='success'
                                    startIcon={<img src={BetagroIcon} alt="Betagro Icon" width={22} style={{ opacity: adfsLoginLoading ? 0.5 : 1 }} />}
                                    sx={{
                                        fontSize: 12,
                                        height: 37,
                                        justifyContent: 'center'
                                    }}
                                    disabled={adfsLoginLoading}
                                    href={window.location.origin + '/v1/authentication/aad?continue=' + window.location.origin + '/login/AADRedirect'}
                                >

                                    <Box sx={{ position: 'relative' }}>
                                        <Button
                                            fullWidth
                                            sx={{
                                                color: adfsLoginLoading ? 'lightgray' : green[500],
                                                fontSize: 12,
                                                minWidth: 100,
                                                height: 37,
                                                pt: 1,
                                                "&:hover": {
                                                    backgroundColor: 'transparent'
                                                },
                                            }}
                                            onClick={() => setAdfsLoginLoading(true)}
                                        >
                                            {t("Continue with Betagro Account")}
                                        </Button>
                                        {adfsLoginLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: green[500],
                                                    position: 'absolute',
                                                    top: '15%',
                                                    left: i18n.language === 'th' ? '35%' : '38%',
                                                    zIndex: 999,
                                                }}
                                            />
                                        )}
                                    </Box>

                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    </div>;
};

export default SignIn;
