// React
import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

// Components
import Appointment from "./Appointment";
import PastAppointment from "./PastAppoitment"

// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Utils
import { useTranslation } from "react-i18next";

// Styles
import theme from "../../../styles/theme";
import '../../../styles/SubmitForm.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AppointmentList = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [tabValue, setTabValue] = useState<number>(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        if (searchParams.get('type') === 'overdue-appointment') {
          setTabValue(1)
        } else {
          setTabValue(0)
        }

      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    fetchAppointment()
  }, [navigate]);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
          {t('Interview Appointment')}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mt: 1 }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="interview tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            }
          }}
          sx={{
            ".Mui-selected": {
              color: theme.palette.primary.main,
              fontWeight: "bold"
            },
          }}
        >
          <Tab
            label={t("Interview schedule")}
            {...a11yProps(0)}
            sx={{ fontSize: { xs: 14, sm: 16 } }}
            component={Link} to='/admin/interview/schedule'
          />
          <Tab
            label={t("Overdue interview schedule")}
            {...a11yProps(1)}
            sx={{ fontSize: { xs: 14, sm: 16 } }}
            component={Link} to='/admin/interview/schedule?type=overdue-appointment'
          />
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Appointment />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <PastAppointment />
        </CustomTabPanel>
      </Box>
    </div>
  )
}

export default AppointmentList