// React
import React, { useEffect, useState } from "react";
import { Link as ReactRouter, useNavigate } from "react-router-dom";

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";
import useDebounce from "../../../hooks/useDebounce";

// MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  GridCellParams,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams
} from "@mui/x-data-grid";

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersShortcutsItem } from "@mui/x-date-pickers";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";
import { DataGridPro, enUS } from '@mui/x-data-grid-pro';
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";

// MUI Icons
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Utils
import { useTranslation } from "react-i18next";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Swal from "sweetalert2";
import dayjs, { Dayjs } from 'dayjs';
import jwtDecode from "jwt-decode";
import { newAdapter } from "../../../utils";

// Styles
import '../../../styles/SubmitForm.scss';
import theme from "../../../styles/theme";

// Types
import { thTHGrid } from "../../../@types/DataGridLocaleText";

// Services
import { getAllStatus } from "../../../services/StatusService";
import { getAllSites } from "../../../services/SiteService";
import {
  getAllAppFormData,
  updateAppFormStatusByMultipleIds
} from "../../../services/AppFormService";
import {
  AppFormStatus,
  Site,
  Status,
  TokenPayload
} from "../../../@types";

const PastAppointment = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { rolesite } = useAppSelector((state) => state.user);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [appFormData, setAppFormData] = useState<AppFormStatus[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
  const [selectedSites, setSelectedSites] = useState<Site | null>(null);
  const [rowsSelected, setRowsSelected] = useState<any[]>([]);
  const [siteData, setSiteData] = useState<Site[]>([]);
  const [statusData, setStatusData] = useState<Status[]>([]);
  const [statusLoading, setStatusLoading] = useState<boolean>(true);
  const [appFormLoading, setAppFormLoading] = useState<boolean>(true);
  const [siteLoading, setSiteLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [countFilterSelected, setCountFilterSelected] = useState<number>(0);

  const [interviewDateRange, setInterviewDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [submittedDateRange, setSubmittedDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const allSuccessLoading = !appFormLoading && !siteLoading && !statusLoading;
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const date = new Date().toJSON()

  useEffect(() => {
    const fetchSites = async () => {
      const response = await getAllSites();

      if (response.data?.success) {
        const data = response.data?.sites;
        let dataActive = data.filter((site: Site) => site.is_active === true)
        let dataRole = dataActive.filter((site: Site) => rolesite.includes(site.site_id))
        setSiteData(dataRole);
        setSiteLoading(false);
      }
    };

    const fetchStatus = async () => {
      const response = await getAllStatus();

      if (response.data?.success) {
        setStatusData(response.data?.status);
        setStatusLoading(false);
      }
    };

    fetchSites();
    fetchStatus();
  }, []);

  useEffect(() => {
    const fetchAppForm = async () => {
      try {
        setAppFormLoading(true);

        const data = {
          rolesite: rolesite,
          submittedDateRange: submittedDateRange,
          interviewDateRange: interviewDateRange,
          status: selectedStatus?.id,
          site: selectedSites?.site_id ? [selectedSites?.site_id] : null,
          query: searchQuery
        }

        const response = await getAllAppFormData(data);

        if (response.data?.success) {
          const data = response.data?.appForms;

          const interviewProcess: any = data.filter((appform: any) => appform.Status.id === 3);
          const pastData: any = []
          interviewProcess.map((item: any) => {
            if (item?.Interview[0]?.interview_date < date && item.is_active === true) {
              pastData.push(item)
            }
            return true
          })
          setAppFormData(pastData);
        }

      } catch (error) {
        navigate('/servererror', { replace: true });
      } finally {
        setTimeout(() => {
          setAppFormLoading(false);
          setIsSearching(false);
        }, 500);
      }
    };

    if (debouncedSearchQuery !== '') {
      setIsSearching(true);
      fetchAppForm();
    } else {
      setSearchQuery('');
      fetchAppForm();
    }
  }, [selectedSites, selectedStatus, debouncedSearchQuery, submittedDateRange, interviewDateRange]);

  useEffect(() => {
    if (searchQuery !== '') {
      setIsSearching(true);
      setAppFormData([]);
    }
  }, [searchQuery])

  useEffect(() => {
    let count = 0;
    if (selectedSites) count++;
    if (selectedStatus) count++;
    if (submittedDateRange[0] || submittedDateRange[1]) count++;
    if (interviewDateRange[0] || interviewDateRange[1]) count++;
    setCountFilterSelected(count);
  }, [selectedSites, selectedStatus, submittedDateRange, interviewDateRange])

  const handleFilterOpenClick =
    (newPlacement: PopperPlacementType) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
      };

  const handleClickAway = () => {
    setFilterOpen(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: `${t('Today')}`,
      getValue: () => {
        const start_today = dayjs().startOf('day');
        const end_today = dayjs().endOf('day');
        return [start_today, end_today];
      },
    },
    {
      label: `${t('Yesterday')}`,
      getValue: () => {
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');
        return [yesterday.startOf('day'), yesterday.endOf('day')];
      },
    },
    {
      label: `${t('This Week')}`,
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: `${t('Last Week')}`,
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: `${t('Last 7 Days')}`,
      getValue: () => {
        const start_today = dayjs().startOf('day');
        const end_today = dayjs().endOf('day');
        const prevWeek = start_today.subtract(7, 'day');
        return [prevWeek, end_today];
      },
    },
    {
      label: `${t('Last 30 Days')}`,
      getValue: () => {
        const start_today = dayjs().startOf('day');
        const end_today = dayjs().endOf('day');
        const prevMonth = start_today.subtract(30, 'day');
        return [prevMonth, end_today];
      },
    },
    {
      label: `${t('This Month')}`,
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: `${t('Last Month')}`,
      getValue: () => {
        const today = dayjs();
        const prevMonth = today.subtract(1, 'month');
        return [prevMonth.startOf('month'), prevMonth.endOf('month')];
      },
    },
  ];

  const shortcutsItemsInterview: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: `${t('Today')}`,
      getValue: () => {
        const start_today = dayjs().startOf('day');
        const end_today = dayjs().endOf('day');
        return [start_today, end_today];
      },
    },
    {
      label: `${t('Yesterday')}`,
      getValue: () => {
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');
        return [yesterday.startOf('day'), yesterday.endOf('day')];
      },
    },
    {
      label: `${t('This Week')}`,
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: `${t('Last Week')}`,
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: `${t('Last 7 Days')}`,
      getValue: () => {
        const start_today = dayjs().startOf('day');
        const end_today = dayjs().endOf('day');
        const prevWeek = start_today.subtract(7, 'day');
        return [prevWeek, end_today];
      },
    },
    {
      label: `${t('Last 30 Days')}`,
      getValue: () => {
        const start_today = dayjs().startOf('day');
        const end_today = dayjs().endOf('day');
        const prevMonth = start_today.subtract(30, 'day');
        return [prevMonth, end_today];
      },
    },
    {
      label: `${t('This Month')}`,
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: `${t('Last Month')}`,
      getValue: () => {
        const today = dayjs();
        const prevMonth = today.subtract(1, 'month');
        return [prevMonth.startOf('month'), prevMonth.endOf('month')];
      },
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'view',
      headerName: `${t('View')}`,
      headerAlign: 'center',
      width: 80,
      align: 'center',
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link component={ReactRouter} to={`/admin/application-form/detail/${params.row.id}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
            <Tooltip title={t("View more details")}>
              <Icon fontSize="medium">visibility</Icon>
            </Tooltip>
          </Link>
        );
      }
    },
    {
      field: 'interview_date', headerName: `${t("Date")}`, width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        const date = new Date(params.row.Interview[0].interview_date).toLocaleString(i18n.language).split(' ')[0]
        return date
      },
      renderCell: (params: GridCellParams) => {
        const date = new Date(params.row.Interview[0].interview_date).toLocaleString(i18n.language).split(' ')[0]
        return (
          <Chip
            sx={{
              bgcolor: '#DFDFDF',
              borderColor: '#DFDFDF',
              color: '#666666',
              my: 1.5,
              borderRadius: 1,
              width: '110px'
            }}
            size='medium'
            label={date}
          />
        )
      },
    },
    {
      field: 'interview_time', headerName: `${t("Time")}`, width: 150, type: 'string',
      valueGetter: (params: GridValueGetterParams) => {
        const time = new Date(params.row.Interview[0].interview_date).toLocaleString(i18n.language).split(' ')[1].split(':')[0] + ':' + new Date(params.row.Interview[0].interview_date).toLocaleString(i18n.language).split(' ')[1].split(':')[1]
        return time
      },
      renderCell: (params: GridCellParams) => {
        const time = new Date(params.row.Interview[0].interview_date).toLocaleString(i18n.language).split(' ')[1].split(':')[0] + ':' + new Date(params.row.Interview[0].interview_date).toLocaleString(i18n.language).split(' ')[1].split(':')[1]

        return (
          <Chip
            variant="outlined"
            sx={{
              borderColor: '#DFDFDF',
              color: '#666666',
              my: 1.5,
              borderRadius: 1,
              width: '90px'
            }}
            size='medium'
            label={time}
          />
        )
      }
    },
    {
      field: 'position',
      headerName: `${t('Position')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => {
        let position = params.row.Job.job_name;
        return position;
      }
    },
   
    {
      field: 'appForm.full_name',
      headerName: `${t('Full name')}`,
      width: 230,
      valueGetter: (params: GridCellParams) => {
        let fullName = params.row.AppFormData.first_name_th + ' ' + params.row.AppFormData.last_name_th;
        return fullName;
      }
    },
    {
      field: 'telephone',
      headerName: `${t('Phone Number')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => {
        let telephone = params.row.AppFormData.telephone;
        return telephone;
      }
    },
    {
      field: 'modified_date', headerName: `${t("Last Modified Date")}`, width: 150, type: 'string',
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.modified_date) {
          const date = dayjs(params.row.modified_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
          const time = dayjs(params.row.modified_date).format('HH:mm')
          return date + ' ' + time;
        } else {
          return null;
        }
      },
      renderCell: (params: GridCellParams) => {
        if (params.row.modified_date) {
          const date = dayjs(params.row.modified_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
          const time = dayjs(params.row.modified_date).format('HH:mm')
          return date + ' ' + time;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'site',
      headerName: `${t('Site')}`,
      width: 180,
      valueGetter: (params: GridCellParams) => {
        let site = params.row.Job.Site.site_name;
        return site;
      }
    },
    {
      field: 'company',
      headerName: `${t('Job Company')}`,
      width: 200,
      renderCell: (params: GridCellParams) => {
        let company = [params.row.Job.company]
        return company
      }
    },
   
  ];

  function changeStatus() {
    const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);
    Swal.fire({
      title: `${t('Select action steps')}`,
      html:
        `<label class="changegreen-container" ><input id="pass" type="checkbox" name="check" /><span class="green">${statusData[3].show_on_hr}</span></label>` +
        `<label class="changered-container" ><input id="fail" type="checkbox" name="check" /><span class="red">${statusData[4].show_on_hr}</span></label>` +
        '<label class="changered-container" ><input type="checkbox" name="check" /></label>',
      showCancelButton: true,
      confirmButtonColor: '#43A047',
      cancelButtonText: `${t('Cancel')}`,
      confirmButtonText: `${t('Confirm')}`,
      didOpen: () => {
        var pass = document.getElementById('pass') as HTMLInputElement
        var fail = document.getElementById('fail') as HTMLInputElement
        var groupCheck = Array.from(document.getElementsByName('check'))

        pass.addEventListener("input", function () {
          Swal.resetValidationMessage()
          if (pass.checked) {
            groupCheck.forEach(element => {
              pass.checked = true;
              (element as HTMLInputElement).checked = false;
            })
          }
        })

        fail.addEventListener("input", function () {
          Swal.resetValidationMessage()
          if (fail.checked) {
            groupCheck.forEach(element => {
              fail.checked = true;
              (element as HTMLInputElement).checked = false;
            })
          }
        })
      },
      preConfirm: () => {
        var checkPass = (document.getElementById('pass') as HTMLInputElement).checked
        var checkFail = (document.getElementById('fail') as HTMLInputElement).checked

        if (!checkFail || !checkPass) {
          Swal.showValidationMessage(`${t('Status is required')}`)
        }

        if (checkPass === true) {
          Swal.fire({
            icon: 'warning',
            title: `${t('Are you sure?')}`,
            html: `${t('The status will be changed to')}` + `&nbsp&nbsp` + `<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-jtl3cc-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[3].show_on_hr}</span></div> ` + `<br>` + `${t('Please check the information before change status')}`,
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const changeStatusFunction = async () => {
                try {
                  const data = {
                    ids: rowsSelected,
                    status_id: statusData[3].id,
                    modified_by: parsedToken.Username
                  }

                  const response = await updateAppFormStatusByMultipleIds(data);

                  if (response.data?.success) {
                    Swal.fire({
                      icon: 'success',
                      title: `${t('Update status successful!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      window.location.reload();
                    })
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: `${t('Update status failed!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      changeStatus();
                    })
                  }
                } catch (error) {
                  navigate('/servererror', { replace: true });
                }
              }

              changeStatusFunction();
            } else {
              changeStatus();
            }
          })
        }

        if (checkFail === true) {
          Swal.fire({
            icon: 'warning',
            title: `${t('Are you sure?')}`,
            html: `${t('The status will be changed to')}` + `&nbsp&nbsp` + `<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-1i5jzc9-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[4].show_on_hr}</span></div> ` + `<br>` + `${t('Please check the information before change status')}`,
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const changeStatusFunction = async () => {
                try {
                  const data = {
                    ids: rowsSelected,
                    status_id: statusData[4].id,
                    modified_by: parsedToken.Username
                  }

                  const response = await updateAppFormStatusByMultipleIds(data);

                  if (response.data?.success) {
                    Swal.fire({
                      icon: 'success',
                      title: `${t('Update status successful!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      window.location.reload();
                    })
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: `${t('Update status failed!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      changeStatus();
                    })
                  }
                } catch (error) {
                  navigate('/servererror', { replace: true });
                }
              }

              changeStatusFunction();
            } else {
              changeStatus();
            }
          })
        }
      }
    })
  }

  return (
    <div>
      <Box>
        <Grid container sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label={t("Search")}
                placeholder={t("Search by Fullname, id card number, phone number, email, job position, site")!}
                value={searchQuery}
                onChange={((event) => setSearchQuery(event.target.value))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ width: '100%', marginTop: 3 }}>

          <Box sx={{
            display: 'grid',
            gridTemplateColumns: { sm: 'fit-content(100%) 2fr' },
            gridTemplateRows: { xs: 'fit-content(100%) repeat(1, 1fr)' },
            marginBottom: 2,
          }}>
            <Box sx={{ gridRow: 1, gridColumn: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
              <Button
                disabled={rowsSelected.length === 0 ? true : false}
                variant="contained"
                sx={{
                  borderRadius: 1, backgroundColor: theme.palette.button.main,
                  "&:hover": {
                    backgroundColor: '#0057FF',
                  },
                  fontSize: { xs: 12, sm: 13 }
                }}
                onClick={() => changeStatus()}
              >
                {t('Select action steps')}
              </Button>
              <Typography variant="subtitle2" sx={{ display: 'inline-flex', fontSize: { xs: 12, sm: 14 } }}>
                <CheckCircleOutlineIcon sx={{ color: '#4CAF50', mr: 1, fontSize: { xs: 20, sm: 24 } }} />
                {rowsSelected.length} {t('selected')}
              </Typography>
            </Box>

            <Box sx={{ gridRow: { xs: 2, sm: 1 }, gridColumn: { sm: 3 } }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '50% 50%', sm: 'repeat(1, 1fr)' } }}>
                <Box sx={{
                  py: 1, display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end' }, '&:hover': {
                    cursor: 'pointer',
                  }, gridColumn: 1
                }}>
                  <Button onClick={handleFilterOpenClick('bottom-end')}>
                    <Badge badgeContent={countFilterSelected} color="primary">
                      <Icon >filter_list</Icon>
                    </Badge>
                    <Typography sx={{ userSelect: 'none' }}>
                      {t("Filter")}
                    </Typography>
                  </Button>

                  <Popper open={filterOpen} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Fade {...TransitionProps} timeout={50}>
                          <Paper sx={{ width: '400px', display: 'flex', flexDirection: 'column', py: 1, px: 3, border: '1px solid #e0e0e0' }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <LocalizationProvider dateAdapter={newAdapter} adapterLocale={i18n.language === 'en' ? 'en' : 'th'}>
                                <DemoContainer components={['SingleInputDateRangeField']}>
                                  <DemoItem label={t("FilterByInterviewDate")} component="DateRangePicker">
                                    <MobileDateRangePicker
                                      slotProps={{
                                        shortcuts: {
                                          items: shortcutsItemsInterview,
                                        },
                                        actionBar: { actions: [] },
                                        textField: { size: 'small' },
                                      }}
                                      localeText={{
                                        start: `${t('StartDateRange')}`,
                                        end: `${t('EndDateRange')}`,
                                      }}
                                      // calendars={1}
                                      onChange={(date: [Dayjs | null, Dayjs | null]) => {
                                        setInterviewDateRange([
                                          date[0] ? dayjs(date[0]).startOf('day') : null,
                                          date[1] ? dayjs(date[1]).endOf('day') : null,
                                        ]);
                                      }}
                                      dayOfWeekFormatter={(_day, weekday) => i18n.language === 'en' ? `${weekday.format('ddd')}` : `${weekday.format('dd')}`}
                                      defaultValue={interviewDateRange}
                                      showDaysOutsideCurrentMonth
                                      format="DD/MM/YYYY"
                                      value={interviewDateRange}
                                    />
                                  </DemoItem>
                                </DemoContainer>
                              </LocalizationProvider>
                              <Tooltip title={t("Clear")}>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  sx={{ height: 'fit-content', ml: 1 }}
                                  onClick={() => {
                                    setInterviewDateRange([null, null]);
                                  }}
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <LocalizationProvider dateAdapter={newAdapter} adapterLocale={i18n.language === 'en' ? 'en' : 'th'}>
                                <DemoContainer components={['SingleInputDateRangeField']}>
                                  <DemoItem label={t("FilterBySubmittedDate")} component="DateRangePicker">
                                    <MobileDateRangePicker
                                      slotProps={{
                                        shortcuts: {
                                          items: shortcutsItems,
                                        },
                                        actionBar: { actions: [] },
                                        textField: { size: 'small' },
                                      }}
                                      localeText={{
                                        start: `${t('StartDateRange')}`,
                                        end: `${t('EndDateRange')}`,
                                      }}
                                      // calendars={1}
                                      onChange={(date: [Dayjs | null, Dayjs | null]) => {
                                        setSubmittedDateRange([
                                          date[0] ? dayjs(date[0]).startOf('day') : null,
                                          date[1] ? dayjs(date[1]).endOf('day') : null,
                                        ]);
                                      }}
                                      dayOfWeekFormatter={(_day, weekday) => i18n.language === 'en' ? `${weekday.format('ddd')}` : `${weekday.format('dd')}`}
                                      defaultValue={submittedDateRange}
                                      showDaysOutsideCurrentMonth
                                      format="DD/MM/YYYY"
                                      value={submittedDateRange}
                                    />
                                  </DemoItem>
                                </DemoContainer>
                              </LocalizationProvider>
                              <Tooltip title={t("Clear")}>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  sx={{ height: 'fit-content', ml: 1 }}
                                  onClick={() => {
                                    setSubmittedDateRange([null, null]);
                                  }}
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <Box sx={{ width: '100%' }}>
                                <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterBySite")}</Typography>
                                <FormControl sx={{ width: '100%' }}>
                                  <Autocomplete
                                    onChange={(_, newValue: Site | null) => {
                                      setSelectedSites(newValue);
                                    }}
                                    clearIcon={null}
                                    size="small"
                                    limitTags={1}
                                    id="site"
                                    options={siteData}
                                    getOptionLabel={(option) => option.site_name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={selectedSites}
                                    renderOption={(props, option, { inputValue }) => {
                                      const matches = match(option.site_name, inputValue, { insideWords: true });
                                      const parts = parse(option.site_name, matches);

                                      return (
                                        <li {...props}>
                                          <div>
                                            {parts.map((part: { highlight: boolean; text: string }, index: number) => (
                                              <span
                                                key={index}
                                                style={{
                                                  fontWeight: part.highlight ? 700 : 400,
                                                }}
                                              >

                                                {part.text}
                                              </span>
                                            ))}
                                          </div>
                                        </li>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params}
                                        label={t("Site")}
                                        placeholder={t("Site")!}
                                      />
                                    )}
                                    loading={siteLoading}
                                    loadingText={`${t('Loading')}...`}
                                  />
                                </FormControl>
                              </Box>
                              <Tooltip title={t("Clear")}>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  sx={{ height: 'fit-content', ml: 1 }}
                                  onClick={() => {
                                    setSelectedSites(null);
                                  }}
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                              <Button
                                sx={{
                                  fontSize: 13,
                                }}
                                onClick={() => {
                                  setInterviewDateRange([null, null]);
                                  setSubmittedDateRange([null, null]);
                                  setSelectedSites(null);
                                  setSelectedStatus(null);
                                  setFilterOpen(false);
                                }}
                              >
                                {t('RemoveAllFilters')}
                              </Button>
                            </Box>
                          </Paper>
                        </Fade>
                      </ClickAwayListener>
                    )}
                  </Popper>
                </Box>
              </Box>
            </Box>
          </Box>

          {isSearching && allSuccessLoading && <Typography align='center'>{t("Searching")}...</Typography>}
          {!allSuccessLoading ? <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box> : (
            appFormData.length === 0 ? (
              !isSearching ? (
                <Typography align='center'>{t("No data")}</Typography>
              ) : null
            ) : (<>
              <Box display='inline-grid' sx={{ width: '100%' }}>
                <DataGridPro
                  getRowId={(row) => row.id}
                  rows={appFormData}
                  columns={columns}
                  pagination
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 20, 30, 40, 50]}
                  disableRowSelectionOnClick={selectedStatus ? false : true}
                  checkboxSelection
                  onRowSelectionModelChange={(params) => {
                    setRowsSelected(params);
                  }}
                  sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                      outline: 'none !important',
                    },
                    fontSize: 13,
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  slotProps={{
                    filterPanel: {
                      filterFormProps: {
                        logicOperatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                        },
                        columnInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        valueInputProps: {
                          InputComponentProps: {
                            variant: 'outlined',
                            size: 'small',
                          },
                        },
                        deleteIconProps: {
                          sx: {
                            '& .MuiSvgIcon-root': { color: '#d32f2f' },
                          },
                        },
                      },
                      sx: {
                        '& .MuiDataGrid-filterForm': { p: 2 },
                        '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                          backgroundColor: () =>
                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                        },
                        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                      },
                    },
                  }}
                  localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                  unstable_headerFilters
                />
              </Box>
            </>)
          )
          }
        </Box>
      </Box>
    </div>
  )
}

export default PastAppointment