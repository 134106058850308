// React
import * as React from "react";

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    editEmail,
    editLineID,
    editEmergencyPerson,
    editPhoneNumber,
    editEmergencyPhone,
    editRelationship
} from "../../../../redux/slices/jobAppSlice";

// MUI Components
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Utils
import { useTranslation } from "react-i18next";

const ContactInfo: React.FC<any> = ({ errors }) => {
    const { t } = useTranslation();
    const [dataErrorState, setDataErrorState] = React.useState<any>(errors)

    React.useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    const { isLoggedIn } = useAppSelector(state => state.user);
    const { contactInfo } = useAppSelector(state => state.jobapp);
    const dispatch = useAppDispatch();

    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [lineId, setLineId] = React.useState('');
    const [emergencyPerson, setEmergencyPerson] = React.useState('');
    const [emergencyPhone, setEmergencyPhone] = React.useState('');
    const [relationship, setRelationship] = React.useState('');

    React.useEffect(() => {
        if (contactInfo) {
            setPhoneNumber(contactInfo.telephone);
            setEmail(contactInfo.email);
            setLineId(contactInfo.line_id);
            setEmergencyPerson(contactInfo.emergency_name);
            setEmergencyPhone(contactInfo.emergency_phone);
            setRelationship(contactInfo.emergency_relation);

        }
    }, [contactInfo]);

    return <div>
        <Typography sx={{ mt: 6, mb: 3 }}>{t("Contact Information")}</Typography>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <TextField
                    error={dataErrorState.telephone ? true : false}
                    required
                    sx={{
                        backgroundColor: isLoggedIn ? 'rgba(0, 0, 0, 0.05)' : '', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                            WebkitTextFillColor: isLoggedIn ? 'rgba(0, 0, 0, 0.7)' : '',
                        }
                    }}
                    disabled={isLoggedIn ? true : false}
                    fullWidth
                    label={t("Phone Number")}
                    placeholder={t("Phone Number")!}
                    type="tel"
                    id="phoneNumber"
                    autoComplete="tel"
                    inputProps={{ maxLength: 10 }}
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        dispatch(editPhoneNumber(e.target.value));

                        if (dataErrorState.telephone) {
                            setDataErrorState((prevState: any) => ({
                                ...prevState,
                                telephone: null
                            }))
                        }
                    }}
                />
                {
                    dataErrorState.telephone ?
                        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
                            {t(dataErrorState.telephone?.message)}
                        </Typography> : null
                }
                <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 0812345678`}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    sx={{ width: '100%' }}
                    id="email"
                    label={`${t("Email Address")}`}
                    placeholder={`${t("Email Address")}`}
                    autoComplete="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        dispatch(editEmail(e.target.value));
                    }}
                />
                <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Such as")} email@mail.com`}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    sx={{ width: '100%' }}
                    id="lineid"
                    label={`${t("Line ID")}`}
                    placeholder={`${t("Line ID")}`}
                    value={lineId}
                    onChange={(e) => {
                        setLineId(e.target.value)
                        dispatch(editLineID(e.target.value));
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    sx={{ width: '100%' }}
                    id="personemergency"
                    label={t("Person to notify in case of emergency")}
                    placeholder={t("Person to notify in case of emergency")!}
                    value={emergencyPerson}
                    onChange={(e) => {
                        setEmergencyPerson(e.target.value);
                        dispatch(editEmergencyPerson(e.target.value));
                    }}
                />
                <FormHelperText sx={{ marginLeft: 1 }}>{t("เช่น นายใจดี งดงาม")}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    sx={{ width: '100%' }}
                    id="phonepersonemergency"
                    label={t("Phone number of person to call in case of emergency")}
                    placeholder={t("Phone number of person to call in case of emergency")!}
                    inputProps={{ maxLength: 10 }}
                    value={emergencyPhone}
                    onChange={(e) => {
                        setEmergencyPhone(e.target.value);
                        dispatch(editEmergencyPhone(e.target.value));
                    }}
                />
                <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 0812345678`}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    sx={{ width: '100%' }}
                    id="Relationshippersonemergency"
                    label={t("Relationship")}
                    placeholder={t("Relationship")!}
                    value={relationship}
                    onChange={(e) => {
                        setRelationship(e.target.value);
                        dispatch(editRelationship(e.target.value));
                    }}
                />
            </Grid>
        </Grid>
    </div>;
};

export default ContactInfo;
