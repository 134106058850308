import { green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: green[600],
    },
    secondary: {
      main: '#0900b4',
    },
    button: {
      main: '#007AFF',
    },
  },
  typography: {
    fontFamily: 'Sarabun'
  },
});

theme.typography.h4 = {
  fontSize: '1.3rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.1rem',
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    button: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    button?: PaletteOptions['primary'];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    blueStatus: true;
    orangeStatus: true;
    greenStatus: true;
    redStatus: true;
  }
}

export default theme;