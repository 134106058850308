// React
import * as React from "react";

// Utils
import jwtDecode from "jwt-decode";
import { setCookie } from "../../../cookie";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const LoginRedirectPage = () => {
  const { t } = useTranslation();
  const queryParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries())
  const token = queryParams['token']
  const continueParam = queryParams['continue']

  if (token === undefined || token === "" || token === null) {
    let timeleft = 3;
    let downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        window.location.assign('/login');
      }
      timeleft -= 1;
    }, 1000);
    Swal.fire({
      icon: 'error',
      title: `${t("You do not have permission!")}`,
      html: `<p>${t("Your account may no longer be active or in use")}</p>`,
      showConfirmButton: true,
      confirmButtonText: "OK",
      confirmButtonColor: '#4caf50',
      timer: 3000,
      timerProgressBar: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.assign('/login?key=401')
      }
    });
    return;
  }


  const parsedToken = jwtDecode(token) as { exp: number }
  if (!parsedToken) {
    window.location.assign('/login?key=500')
  }

  const tokenExpiryTime = new Date(parsedToken.exp * 1000)

  setCookie(token, tokenExpiryTime)

  // navigate('/home')
  if (continueParam) {
    window.location.assign(continueParam)
  } else {
    window.location.assign('/admin/dashboard')
  }

  return <div>

  </div>;
};

export default LoginRedirectPage;
