// React
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

// MUI Components
import { Autocomplete, Badge, Box, Button, Card, Checkbox, Chip, CircularProgress, ClickAwayListener, Fade, FormControl, Grid, Icon, IconButton, Paper, Popper, PopperPlacementType, TextField, Tooltip, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

// @MUI Icons
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// Types
import { Job, Site } from '../../../@types';

// Utils
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useTranslation } from 'react-i18next';
import { newAdapter } from "../../../utils";
import dayjs, { Dayjs } from 'dayjs';

// Services
import { getInterviewsDashboardData } from '../../../services/DashboardService';
import { getAllSites } from '../../../services/SiteService';
import { getAllJobs } from '../../../services/JobService';

const InterviewDashboard = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [siteData, setSiteData] = useState<Site[]>([]);
    const [jobsData, setJobsData] = useState<Job[]>([]);

    const [interviewDashboardData, setInterviewDashboardData] = useState<any>(null);
    const [selectedSites, setSelectedSites] = useState<Site[]>([]);
    const [selectedJobs, setSelectedJobs] = useState<Job[]>([]);
    const [loadingSites, setLoadingSites] = useState<boolean>(true);
    const [loadingJobs, setLoadingJobs] = useState<boolean>(true);
    const [loadingInterviewDashboard, setLoadingInterviewDashboard] = useState<boolean>(true);
    const [interviewDateRange, setInterviewDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [jobApplyDateRange, setJobApplyDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [countFilterInterviewDashboardSelected, setCountFilterInterviewDashboardSelected] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [placement, setPlacement] = useState<PopperPlacementType>();
    const [filterInterviewDashboardOpen, setFilterInterviewDashboardOpen] = useState<boolean>(false);

    const handleFilterInterviewDashboardOpenClick =
        (newPlacement: PopperPlacementType) =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
                setFilterInterviewDashboardOpen((prev) => placement !== newPlacement || !prev);
                setPlacement(newPlacement);
            };

    useEffect(() => {
        const fetchInterviewDashboard = async () => {
            setLoadingInterviewDashboard(true);

            const response = await getInterviewsDashboardData({
                site_ids: selectedSites?.map((site: Site) => site.site_id).length !== 0 ? selectedSites?.map((site: Site) => site.site_id) : undefined,
                interview_date_range: interviewDateRange[0] || interviewDateRange[1] ? interviewDateRange : undefined,
                job_ids: selectedJobs?.map((job: Job) => job.id).length !== 0 ? selectedJobs?.map((job: Job) => job.id) : undefined,
                job_apply_date_range: jobApplyDateRange[0] || jobApplyDateRange[1] ? jobApplyDateRange : undefined,
            });

            if (response.data?.success) {
                setLoadingInterviewDashboard(false);
                setInterviewDashboardData(response.data?.data);
            }
        };

        fetchInterviewDashboard();
    }, [selectedSites, interviewDateRange, selectedJobs, jobApplyDateRange]);

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data?.success) {
                    setSiteData(response.data?.sites);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        const siteSorting = async () => {
            try {
                siteData.sort((a, b) => a.site_id.localeCompare(b.site_id));
                setTimeout(() => {
                    setLoadingSites(false);
                }, 500);
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        fetchSites();
        siteSorting();

    }, []);

    useEffect(() => {
        const fetchJobs = async () => {
            setLoadingJobs(true);

            const response = await getAllJobs();

            if (response.data?.success) {
                setLoadingJobs(false);
                setJobsData(response.data?.jobs);
            }
        };

        fetchJobs();
    }, []);

    useEffect(() => {
        let count = 0;
        if (selectedSites && selectedSites.length > 0) count++;
        if (interviewDateRange[0] || interviewDateRange[1]) count++;
        if (jobApplyDateRange[0] || jobApplyDateRange[1]) count++;
        if (selectedJobs && selectedJobs.length > 0) count++;
        setCountFilterInterviewDashboardSelected(count);
    }, [selectedSites, interviewDateRange, selectedJobs, jobApplyDateRange]);

    const handleClickAwayInterviewDashboardFilter = () => {
        setFilterInterviewDashboardOpen(false);
    };

    const styleCard = {
        borderRadius: '0.5rem', height: '100%', p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1, marginTop: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("Information of job applications and interviews")}
                </Typography>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: 'fit-content(100%) 2fr' },
                    gridTemplateRows: { xs: 'fit-content(100%) repeat(1, 1fr)' },
                }}>
                    <Box sx={{ gridRow: { xs: 2, sm: 1 }, gridColumn: { sm: 3 } }}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '50% 50%', sm: 'repeat(1, 1fr)' } }}>
                            <Box sx={{
                                py: 1, display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end' }, '&:hover': {
                                    cursor: 'pointer',
                                }, gridColumn: 1
                            }}>
                                <Button onClick={handleFilterInterviewDashboardOpenClick('bottom-end')}>
                                    <Badge badgeContent={countFilterInterviewDashboardSelected} color="primary">
                                        <Icon >filter_list</Icon>
                                    </Badge>
                                    <Typography sx={{ userSelect: 'none' }}>
                                        {t("Filter")}
                                    </Typography>
                                </Button>

                                <Popper open={filterInterviewDashboardOpen} anchorEl={anchorEl} placement={placement} transition>
                                    {({ TransitionProps }) => (
                                        <ClickAwayListener onClickAway={handleClickAwayInterviewDashboardFilter}>
                                            <Fade {...TransitionProps} timeout={50}>
                                                <Paper sx={{ width: '400px', display: 'flex', flexDirection: 'column', py: 1, px: 3, border: '1px solid #e0e0e0' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <LocalizationProvider dateAdapter={newAdapter} adapterLocale={i18n.language === 'en' ? 'en' : 'th'}>
                                                            <DemoContainer sx={{ mt: 1 }} components={['SingleInputDateRangeField']}>
                                                                <DemoItem label={t("FilterByJobApplyDate")} component="DateRangePicker">
                                                                    <MobileDateRangePicker
                                                                        slotProps={{
                                                                            // shortcuts: {
                                                                            //     items: shortcutsItems,
                                                                            // },
                                                                            actionBar: { actions: [] },
                                                                            textField: { size: 'small' },
                                                                        }}
                                                                        localeText={{
                                                                            start: `${t('StartDateRange')}`,
                                                                            end: `${t('EndDateRange')}`,
                                                                        }}
                                                                        dayOfWeekFormatter={(_day, weekday) => i18n.language === 'en' ? `${weekday.format('ddd')}` : `${weekday.format('dd')}`}
                                                                        // calendars={1}
                                                                        onChange={(date: any) => {
                                                                            setJobApplyDateRange([
                                                                                date[0] ? dayjs(date[0]).startOf('day') : null,
                                                                                date[1] ? dayjs(date[1]).endOf('day') : null,
                                                                            ]);
                                                                        }}
                                                                        defaultValue={jobApplyDateRange}
                                                                        showDaysOutsideCurrentMonth
                                                                        format="DD/MM/YYYY"
                                                                        value={jobApplyDateRange}
                                                                    />
                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                        <Tooltip title={t("Clear")}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ height: 'fit-content', ml: 1 }}
                                                                onClick={() => {
                                                                    setJobApplyDateRange([null, null]);
                                                                }}
                                                            >
                                                                <Icon>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <LocalizationProvider dateAdapter={newAdapter} adapterLocale={i18n.language === 'en' ? 'en' : 'th'}>
                                                            <DemoContainer sx={{ mt: 1 }} components={['SingleInputDateRangeField']}>
                                                                <DemoItem label={t("FilterByInterviewDate")} component="DateRangePicker">
                                                                    <MobileDateRangePicker
                                                                        slotProps={{
                                                                            // shortcuts: {
                                                                            //     items: shortcutsItems,
                                                                            // },
                                                                            actionBar: { actions: [] },
                                                                            textField: { size: 'small' },
                                                                        }}
                                                                        localeText={{
                                                                            start: `${t('StartDateRange')}`,
                                                                            end: `${t('EndDateRange')}`,
                                                                        }}
                                                                        dayOfWeekFormatter={(_day, weekday) => i18n.language === 'en' ? `${weekday.format('ddd')}` : `${weekday.format('dd')}`}
                                                                        // calendars={1}
                                                                        onChange={(date: any) => {
                                                                            setInterviewDateRange([
                                                                                date[0] ? dayjs(date[0]).startOf('day') : null,
                                                                                date[1] ? dayjs(date[1]).endOf('day') : null,
                                                                            ]);
                                                                        }}
                                                                        defaultValue={interviewDateRange}
                                                                        showDaysOutsideCurrentMonth
                                                                        format="DD/MM/YYYY"
                                                                        value={interviewDateRange}
                                                                    />
                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                        <Tooltip title={t("Clear")}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ height: 'fit-content', ml: 1 }}
                                                                onClick={() => {
                                                                    setInterviewDateRange([null, null]);
                                                                }}
                                                            >
                                                                <Icon>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Box sx={{ width: '100%' }}>
                                                            <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterBySite")}</Typography>
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    onChange={(event, newValue: Site[]) => {
                                                                        setSelectedSites(newValue);
                                                                    }}
                                                                    multiple
                                                                    disableCloseOnSelect
                                                                    limitTags={1}
                                                                    id="site"
                                                                    options={
                                                                        siteData
                                                                    }
                                                                    size="small"
                                                                    loading={loadingSites}
                                                                    loadingText={`${t("Loading")}...`}
                                                                    getOptionLabel={(option) => option.site_name}
                                                                    value={selectedSites}
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox
                                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option.site_name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label={loadingSites ? `${t("Loading")}...` : t("Site")}
                                                                            placeholder={t("Site")!}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {loadingSites ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }} />
                                                                    )}
                                                                    renderTags={(value: any, getTagProps) =>
                                                                        value.map((option: Site, index: number) => (
                                                                            <Chip
                                                                                variant="outlined"
                                                                                label={t(option.site_name)}
                                                                                {...getTagProps({ index })}
                                                                            />
                                                                        ))
                                                                    }
                                                                    noOptionsText={t("No options found")}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Tooltip title={t("Clear")}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ height: 'fit-content', ml: 1 }}
                                                                onClick={() => {
                                                                    setSelectedSites([]);
                                                                }}
                                                            >
                                                                <Icon>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Box sx={{ width: '100%' }}>
                                                            <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterByJob")}</Typography>
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    onChange={(event, newValue: Job[]) => {
                                                                        setSelectedJobs(newValue);
                                                                    }}
                                                                    multiple
                                                                    disableCloseOnSelect
                                                                    limitTags={3}
                                                                    id="site"
                                                                    options={
                                                                        jobsData
                                                                    }
                                                                    size="small"
                                                                    loading={loadingJobs}
                                                                    loadingText={`${t("Loading")}...`}
                                                                    getOptionLabel={(option) => option.job_name}
                                                                    value={selectedJobs}
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props} key={option.id}>
                                                                            <Checkbox
                                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option.job_name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label={loadingJobs ? `${t("Loading")}...` : t("Job position's name")}
                                                                            placeholder={t("Job position's name")!}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {loadingJobs ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }} />
                                                                    )}
                                                                    renderTags={(value: any, getTagProps) =>
                                                                        value.map((option: Job, index: number) => (
                                                                            <Chip
                                                                                variant="outlined"
                                                                                label={t(option.job_name)}
                                                                                {...getTagProps({ index })}
                                                                            />
                                                                        ))
                                                                    }
                                                                    noOptionsText={t("No options found")}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Tooltip title={t("Clear")}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ height: 'fit-content', ml: 1 }}
                                                                onClick={() => {
                                                                    setSelectedJobs([]);
                                                                }}
                                                            >
                                                                <Icon>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                                        <Button
                                                            sx={{
                                                                fontSize: 13,
                                                            }}
                                                            onClick={() => {
                                                                setJobApplyDateRange([null, null]);
                                                                setInterviewDateRange([null, null]);
                                                                setSelectedSites([]);
                                                                setSelectedJobs([]);
                                                                setFilterInterviewDashboardOpen(false);
                                                            }}
                                                        >
                                                            {t('RemoveAllFilters')}
                                                        </Button>
                                                    </Box>
                                                </Paper>
                                            </Fade>
                                        </ClickAwayListener>
                                    )}
                                </Popper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <PeopleAltIcon sx={{ color: '#42A5F5', fontSize: '2rem', backgroundColor: '#E3F2FD', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Numbers of all application forms')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingInterviewDashboard ? '...' : interviewDashboardData?.apply_job_count}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('items')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <CalendarMonthIcon sx={{ color: '#42A5F5', fontSize: '2rem', backgroundColor: '#E3F2FD', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('All interviews')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingInterviewDashboard ? '...' : interviewDashboardData?.all_interview_count}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('peoples')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <CalendarMonthIcon sx={{ color: '#42A5F5', fontSize: '2rem', backgroundColor: '#E3F2FD', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Interview now')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingInterviewDashboard ? '...' : interviewDashboardData?.current_interview_count}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('peoples')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <CalendarMonthIcon sx={{ color: '#8C8C8C', fontSize: '2rem', backgroundColor: '#ededed', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Overdule interview')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingInterviewDashboard ? '...' : interviewDashboardData?.past_interview_count}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('peoples')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <VerifiedIcon sx={{ color: '#3EE845', fontSize: '2rem', backgroundColor: '#e4f2e4', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t('Passed the interview')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingInterviewDashboard ? '...' : interviewDashboardData?.passed_interview_count}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('peoples')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={6} lg={3} xl={2}>
                    <Card variant="outlined" sx={styleCard}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <CancelIcon sx={{ color: '#FF4F40', fontSize: '2rem', backgroundColor: '#f5eeed', borderRadius: '50%', p: 1 }} />
                            <Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
                                {t("Didn't pass the interview")}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>{loadingInterviewDashboard ? '...' : interviewDashboardData?.rejected_interview_count}</Typography>
                            <Typography sx={{ fontSize: '16px' }} gutterBottom>
                                {t('peoples')}
                            </Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default InterviewDashboard