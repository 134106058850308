import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { apiUrl } from "../../Constants";
import api from "../../services/ApiService";

export const fetchRole = createAsyncThunk('user/fetchRole', async () => {
    try {
        if (localStorage.getItem('access_token')) {
            const response = await api.get(`${apiUrl}/api/roles/${jwtDecode<any>(localStorage.getItem('access_token')!).RoleId}`);

            if (response.data?.success === true) {
                return response.data.role;
            }
        }
    } catch (error) {
        throw new Error('Failed to fetch role data');
    }
});

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    try {
        if (localStorage.getItem('access_token')) {
            const response = await api.get(`${apiUrl}/api/users/${jwtDecode<any>(localStorage.getItem('access_token')!).UserId}`);

            if (response.status === 200) {
                return response.data.user;
            } else {
                return undefined;
            }
        }
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
});

type loadingState = {
    role: 'idle' | 'pending' | 'fulfilled' | 'rejected';
};

interface userState {
    userid: string
    prefix: string
    fNameTH: string
    lNameTH: string
    fNameEN: string
    lNameEN: string
    roleid: string
    rolename: string
    roletype: string
    email: string
    idcardnumber: string
    token: string | undefined
    isLoggedIn?: boolean
    menu: string[]
    loading: loadingState
    rolesite: string[]
}

const storedToken = localStorage.getItem('access_token');
let initialToken: string | undefined = undefined;

if (storedToken) {
    try {
        jwtDecode(storedToken);
        initialToken = storedToken;
    } catch (error) {
        localStorage.removeItem('access_token');
        window.location.href = '/login?token_invalid=true&continue=/';
    }
}

const initialState: userState = {
    userid: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).UserId : null,
    prefix: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).Prefix : null,
    fNameTH: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).FNameTH : null,
    lNameTH: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).LNameTH : null,
    fNameEN: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).FNameEN : null,
    lNameEN: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).LNameEN : null,
    roleid: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).RoleId : null,
    rolename: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).RoleName : null,
    roletype: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).RoleType : null,
    email: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).Email : null,
    idcardnumber: initialToken ? jwtDecode<any>(localStorage.getItem('access_token')!).IDCardNumber : null,
    token: initialToken || undefined,
    isLoggedIn: initialToken ? true : false,
    menu: [],
    loading: {
        role: 'idle',
    },
    rolesite: []
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        editFName: (state, action) => {
            state.fNameTH = action.payload
        },
        editLName: (state, action) => {
            state.lNameTH = action.payload
        },
        editRole: (state, action) => {
            state.roleid = action.payload
        },
        editRoleType: (state, action) => {
            state.roletype = action.payload
        },
        editIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRole.pending, (state) => {
                state.loading.role = 'pending';
            })
            .addCase(fetchRole.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.loading.role = 'fulfilled';
                    state.menu = action.payload.menu;
                    state.rolesite = action.payload.site_id;
                } else if (action.payload === undefined) {
                    state.loading.role = 'rejected';
                }
            })
            .addCase(fetchRole.rejected, (state, action) => {
                state.loading.role = 'rejected';
            })
            .addCase(fetchUser.pending, (state) => {
                state.loading.role = 'pending';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.loading.role = 'fulfilled';
                    state.userid = action.payload.id || null;
                    state.prefix = action.payload.prefix || null;
                    state.fNameTH = action.payload.first_name_th || null;
                    state.lNameTH = action.payload.last_name_th || null;
                    state.fNameEN = action.payload.first_name_en || null;
                    state.lNameEN = action.payload.last_name_en || null;
                    state.email = action.payload.email || null;
                    state.idcardnumber = action.payload.id_card_number || null;
                    state.roleid = action.payload.role_id || null;
                } else if (action.payload === undefined) {
                    state.loading.role = 'rejected';
                }
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.loading.role = 'rejected';
            })
    },
});

export const { editFName, editLName, editRole, editRoleType, editIsLoggedIn } = userSlice.actions;
export default userSlice;