// React
import { useEffect, useState } from "react";

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { editMilitaryStatus, editWorkExperience } from "../../../../redux/slices/jobAppSlice";

// MUI Components
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

// Utils
import { useTranslation } from "react-i18next";

const TextareaAutosize = styled(BaseTextareaAutosize)(
    () => `
    font-family: 'Sarabun';
    font-size: 1rem;
    line-height: 1.5;
    padding: 10px 12px;
    border-radius: 5px;
    color: #000;
    background: '#fff';
    border: 1px solid grey;
    box-shadow: 0px 2px 2px #f3f6f9;
    
    &:hover {
        border-color: #000;
    }
    
    &:focus {
        border-color: #138808;
        border-width: 2px;
    }
    
    // firefox
    &:focus-visible {
        outline: 0;
    }
    `,
);


const MilitaryInfo: React.FC<any> = ({ errors }) => {
    const { t } = useTranslation();
    const { personalInfo } = useAppSelector(state => state.jobapp);
    const dispatch = useAppDispatch();

    const [value, setValue] = useState('');

    const [dataErrorState, setDataErrorState] = useState<any>(errors)

    useEffect(() => {
        setDataErrorState(errors)
    }, [errors]);

    useEffect(() => {
        setValue(personalInfo.military_status);
    }, [personalInfo]);

    const handleMilitaryStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        dispatch(editMilitaryStatus((event.target as HTMLInputElement).value));

        setDataErrorState({ ...dataErrorState, military_status: null })
    };

    return <div>
        <Typography sx={{ mt: 6, mb: { xs: 1, sm: 2 } }}>{t("Military Status")} *</Typography>

        <FormControl>
            <RadioGroup
                id="military-status"
                aria-labelledby="military-status"
                name="controlled-military-status"
                value={value}
                onChange={handleMilitaryStatusChange}
                sx={{ gap: { sm: 2, md: 3 }, flexDirection: { sm: 'column', md: 'row' }, ml: 2 }}
            >
                <FormControlLabel value="เกณฑ์ทหารแล้ว" control={<Radio />} label={t("Conscripted")} />
                <FormControlLabel value="รด." control={<Radio />} label={t("Reservist")} />
                <FormControlLabel value="ยังไม่เกณฑ์ทหาร" control={<Radio />} label={t("No Military Service")} />
                <FormControlLabel value="เพศหญิง" control={<Radio />} label={t("No Military Service (Female)")} />
            </RadioGroup>
        </FormControl>

        <Typography variant="inherit" color="error" align="left" ml={1} pt={1} fontSize={13}>
            {t(dataErrorState.military_status?.message)}
        </Typography>

        <Typography sx={{ mt: 6, mb: { xs: 1, sm: 2 } }}>{t("Work Experience")!}</Typography>
        <TextareaAutosize
            aria-label="empty textarea"
            placeholder={t("Fill out your work experience here...")!}
            sx={{ maxWidth: { xs: '92%', sm: '100%' }, width: { xs: '92%', sm: '100%' }, minWidth: { xs: '92%', sm: '100%' }, minHeight: '10%', }}
            onChange={(e) => dispatch(editWorkExperience(e.target.value))}
            value={personalInfo.work_experience}
        />
    </div>;
};

export default MilitaryInfo;
