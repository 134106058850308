// React
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

// MUI Components
import { Autocomplete, Badge, Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, Fade, FormControl, Icon, IconButton, Paper, Popper, PopperPlacementType, TextField, Tooltip, Typography } from '@mui/material';

// @MUI Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// Types
import { Site } from '../../../@types';

// Utils
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useTranslation } from 'react-i18next';

// Services
import { getNewApplyDailyGraphDashboardData } from '../../../services/DashboardService';
import { getAllSites } from '../../../services/SiteService';
import { BarChart } from '@mui/x-charts';

// Custom Chart Color
const colorChart: { [key: string]: string[] } = {
    newApplyColor: [
      '#007AFF',
    ],
};

const NewApplyDailyGraphDashboard = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [siteData, setSiteData] = useState<Site[]>([]);

    const [newDailyGraphDashboardData, setNewApplyDailyGraphDashboardData] = useState<any>(null);
    const [selectedSites, setSelectedSites] = useState<Site[]>([]);
    const [loadingSites, setLoadingSites] = useState<boolean>(true);
    const [loadingNewApplyDailyGraphDashboard, setLoadingNewApplyDailyGraphDashboard] = useState<boolean>(true);
    const [countFilterNewApplyDailyGraphDashboardSelected, setCountFilterNewApplyDailyGraphDashboardSelected] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [placement, setPlacement] = useState<PopperPlacementType>();
    const [filterNewApplyDailyGraphDashboardOpen, setFilterNewApplyDailyGraphDashboardOpen] = useState<boolean>(false);
    const [colorScheme, setColorScheme] = useState('newApplyColor');

    const handleFilterNewApplyDailyGraphDashboardOpenClick =
        (newPlacement: PopperPlacementType) =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
                setFilterNewApplyDailyGraphDashboardOpen((prev) => placement !== newPlacement || !prev);
                setPlacement(newPlacement);
            };

    useEffect(() => {
        const fetchNewApplyDailyGraphDashboard = async () => {
            setLoadingNewApplyDailyGraphDashboard(true);

            const response = await getNewApplyDailyGraphDashboardData({
                site_ids: selectedSites?.map((site: Site) => site.site_id).length !== 0 ? selectedSites?.map((site: Site) => site.site_id) : undefined,
            });

            if (response.data?.success) {
                setLoadingNewApplyDailyGraphDashboard(false);
                setNewApplyDailyGraphDashboardData(response.data?.data);
            }
        };

        fetchNewApplyDailyGraphDashboard();
    }, [selectedSites]);

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data?.success) {
                    setSiteData(response.data?.sites);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        const siteSorting = async () => {
            try {
                siteData.sort((a, b) => a.site_id.localeCompare(b.site_id));
                setTimeout(() => {
                    setLoadingSites(false);
                }, 500);
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        fetchSites();
        siteSorting();

    }, []);

    useEffect(() => {
        let count = 0;
        if (selectedSites && selectedSites.length > 0) count++;
        setCountFilterNewApplyDailyGraphDashboardSelected(count);
    }, [selectedSites])

    const handleClickAwayNewApplyDailyGraphDashboardFilter = () => {
        setFilterNewApplyDailyGraphDashboardOpen(false);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("The chart shows the numbers of new applicants today")}
                </Typography>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: 'fit-content(100%) 2fr' },
                    gridTemplateRows: { xs: 'fit-content(100%) repeat(1, 1fr)' },
                }}>
                    <Box sx={{ gridRow: { xs: 2, sm: 1 }, gridColumn: { sm: 3 } }}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '50% 50%', sm: 'repeat(1, 1fr)' } }}>
                            <Box sx={{
                                py: 1, display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end' }, '&:hover': {
                                    cursor: 'pointer',
                                }, gridColumn: 1
                            }}>
                                <Button onClick={handleFilterNewApplyDailyGraphDashboardOpenClick('bottom-end')}>
                                    <Badge badgeContent={countFilterNewApplyDailyGraphDashboardSelected} color="primary">
                                        <Icon >filter_list</Icon>
                                    </Badge>
                                    <Typography sx={{ userSelect: 'none' }}>
                                        {t("Filter")}
                                    </Typography>
                                </Button>

                                <Popper open={filterNewApplyDailyGraphDashboardOpen} anchorEl={anchorEl} placement={placement} transition>
                                    {({ TransitionProps }) => (
                                        <ClickAwayListener onClickAway={handleClickAwayNewApplyDailyGraphDashboardFilter}>
                                            <Fade {...TransitionProps} timeout={50}>
                                                <Paper sx={{ width: '400px', display: 'flex', flexDirection: 'column', py: 1, px: 3, border: '1px solid #e0e0e0' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Box sx={{ width: '100%' }}>
                                                            <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterBySite")}</Typography>
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    onChange={(event, newValue: Site[]) => {
                                                                        setSelectedSites(newValue);
                                                                    }}
                                                                    multiple
                                                                    disableCloseOnSelect
                                                                    limitTags={1}
                                                                    id="site"
                                                                    options={
                                                                        siteData
                                                                    }
                                                                    size="small"
                                                                    loading={loadingSites}
                                                                    loadingText={`${t("Loading")}...`}
                                                                    getOptionLabel={(option) => option.site_name}
                                                                    value={selectedSites}
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox
                                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option.site_name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label={loadingSites ? `${t("Loading")}...` : t("Site")}
                                                                            placeholder={t("Site")!}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {loadingSites ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </React.Fragment>
                                                                                ),
                                                                            }} />
                                                                    )}
                                                                    renderTags={(value: any, getTagProps) =>
                                                                        value.map((option: Site, index: number) => (
                                                                            <Chip
                                                                                variant="outlined"
                                                                                label={t(option.site_name)}
                                                                                {...getTagProps({ index })}
                                                                            />
                                                                        ))
                                                                    }
                                                                    noOptionsText={t("No options found")}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Tooltip title={t("Clear")}>
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                sx={{ height: 'fit-content', ml: 1 }}
                                                                onClick={() => {
                                                                    setSelectedSites([]);
                                                                }}
                                                            >
                                                                <Icon>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                                        <Button
                                                            sx={{
                                                                fontSize: 13,
                                                            }}
                                                            onClick={() => {
                                                                setSelectedSites([]);
                                                                setFilterNewApplyDailyGraphDashboardOpen(false);
                                                            }}
                                                        >
                                                            {t('RemoveAllFilters')}
                                                        </Button>
                                                    </Box>
                                                </Paper>
                                            </Fade>
                                        </ClickAwayListener>
                                    )}
                                </Popper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {
                    !loadingNewApplyDailyGraphDashboard &&
                    <BarChart
                        width={900}
                        height={500}
                        series={[
                            { data: newDailyGraphDashboardData?.count_of_new_apply_per_day_of_each_site.display.values_array, label: newDailyGraphDashboardData?.count_of_new_apply_per_day_of_each_site.display.label, id: 'pvId' },
                        ]}
                        xAxis={[{ data: newDailyGraphDashboardData?.count_of_new_apply_per_day_of_each_site.display.xLabels, scaleType: 'band' }]}
                        colors={colorChart[colorScheme]}
                    />
                }
            </Box>
        </div>
    )
}

export default NewApplyDailyGraphDashboard