// React
import React, { useEffect, useState } from 'react'
import {
    Link as ReactRouterLink,
    useNavigate,
    useParams
} from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from '../../../redux/hooks';

// MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// MUI Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// Utils
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';

// Styles
import { green } from '@mui/material/colors';

// Types
import RoleType from '../../../@types/RoleType';

// Services
import { getAllSites } from '../../../services/SiteService';
import { editRole, getRoleById } from '../../../services/RoleService';
import { getAllMenus } from '../../../services/MenuService';
import {
    Menu,
    Role,
    Site,
    TokenPayload
} from '../../../@types';

const EditRole = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const userRoleType = useAppSelector((state) => state.user.roletype);

    const havePermission = userRoleType === 'Super Admin' ? true : false;

    if (!havePermission) {
        navigate('/forbidden', { replace: true });
    }

    const [roleName, setRoleName] = useState<string>('Role Name')
    const [roleType, setRoleType] = useState<string>('Role Type')
    const [active, setActive] = useState<boolean>(true)
    const [siteData, setSiteData] = useState<Site[]>([]);
    const [roleSite, setRoleSite] = useState<string[]>([]);
    const [roleMenus, setRoleMenus] = useState<string[] | undefined>([]);
    const [menusData, setMenusData] = useState<Menu[]>([]);
    const [filteredMenuData, setFilteredMenuData] = useState<Menu[]>([]);
    const [filteredSiteData, setFilteredSiteData] = useState<Site[]>([]);

    const [loadingMenus, setLoadingMenus] = useState<boolean>(true);
    const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
    const [editLoading, setEditLoading] = useState<boolean>(false);

    const allSuccessLoading = !loadingRoles && !loadingMenus;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.checked);
    };

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data?.success) {
                    const sitesData = response.data.sites;
                    const dataSort = sitesData.sort((a: Site, b: Site) => a.site_id.localeCompare(b.site_id))
                    setSiteData(dataSort.filter((site: Site) => site.is_active === true));
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };
        fetchSites();
    }, [navigate]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const role_id = id as string;
                const response = await getRoleById(role_id);

                if (response.data?.success === false) {
                    Swal.fire({
                        title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#43A047',
                        confirmButtonText: 'OK!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign('/admin/masterdata/roles');
                        }
                    });
                    return;
                }

                const rolesData: Role = response.data?.role;
                if (rolesData) {
                    setRoleName(rolesData.role_name);
                    setRoleType(rolesData.role_type);
                    setRoleSite(rolesData.site_id ?? []);
                    setActive(rolesData.is_active ?? true);
                    setRoleMenus(rolesData.menu?.filter((menu: string) => parseInt(menu) % 100 !== 0 && menu !== '101'));
                    setLoadingRoles(false)
                }
            } catch (error) {
                console.error(error);

                navigate('/servererror', { replace: true });
            }
        };
        fetchRoles();

    }, [navigate, id])

    useEffect(() => {
        try {
            if (siteData && siteData.length > 0 && roleSite && roleSite.length > 0) {
                const mappedSiteData = siteData.filter((site: Site) => roleSite.includes(site.site_id));
                setFilteredSiteData(mappedSiteData)
            }
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [navigate, siteData, roleSite]);

    useEffect(() => {
        try {
            siteData.sort((a: Site, b: Site) => a.site_id.localeCompare(b.site_id))
        } catch (error) {
            navigate('/servererror', { replace: true });
        }

    }, [navigate, siteData]);

    useEffect(() => {
        try {
            filteredSiteData.sort((a: Site, b: Site) => a.site_id.localeCompare(b.site_id))
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [navigate, filteredSiteData]);

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const response = await getAllMenus();

                if (response.data?.success) {
                    const menusData = response.data?.menus;
                    setMenusData(menusData.sort((a: Menu, b: Menu) => a.menu_id.localeCompare(b.menu_id)));
                    setMenusData((previous: Menu[]) => previous.filter((menu: Menu) => menu.menu_id !== '101'));
                    if (roleType === RoleType.HR_Recruiter) {
                        setMenusData((previous: Menu[]) => previous.filter((menu: Menu) => menu.parent_id !== '600'));
                    }
                    setLoadingMenus(false);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };
        fetchMenus();
    }, [navigate, roleType]);

    useEffect(() => {
        try {
            if (menusData && menusData.length > 0 && roleMenus && roleMenus.length > 0) {
                const mappedMenusData = menusData.filter((menu: Menu) => roleMenus.includes(menu.menu_id));
                setFilteredMenuData(mappedMenusData)
            }
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [navigate, menusData, roleMenus]);

    // const formValidationSchema = yup.object().shape({
    //     rolename: yup.string().required(`${t("Role name is required")}`),
    // });

    const {
        // register,
        handleSubmit,
        // formState: { errors }
    } = useForm({
        // resolver: yupResolver(formValidationSchema)
    });

    const onSubmit = () => {

        const postData = async () => {
            try {
                const updatedData = {
                    role_name: roleName,
                    is_active: active
                }

                const sites = (filteredSiteData as Site[]).map((site) => site.site_id);
                const menus = (filteredMenuData as Menu[]).map((menu) => menu.menu_id);

                const parentMenus = filteredMenuData.map((menu: Menu) => menu.parent_id);
                const distinctParentMenus = Array.from(new Set(parentMenus));

                const selectedMenus = [...menus, ...distinctParentMenus]
                selectedMenus.push('100')
                selectedMenus.push('101')

                selectedMenus.sort((a: string, b: string) => a.localeCompare(b))

                setEditLoading(true);

                const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);
                const role_id = id as string;
                const data = {
                    role_id: role_id,
                    role_name: updatedData.role_name,
                    is_active: updatedData.is_active,
                    site_id: sites,
                    menu: selectedMenus,
                    modified_by: parsedToken.Username
                }
                const response = await editRole(role_id, data);

                if (response) {
                    setEditLoading(false);
                    const responseData = response.data;
                    if (!response.data?.success) {
                        toast.error(i18n.language === 'th' ? responseData.message_th : responseData.message_en, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else if (response.data?.success) {
                        Swal.fire({
                            title: i18n.language === 'th' ? responseData.message_th : responseData.message_en,
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#43A047',
                            confirmButtonText: 'OK!',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.assign('/admin/masterdata/roles');
                            }
                        })
                    }
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        postData();

    };

    return (
        <>
            {
                havePermission ? <>
                    {
                        allSuccessLoading ? <>
                            <ToastContainer style={{ width: "400px" }} />

                            <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                                <Box display='flex' gap={3} sx={{ mb: 3 }}>
                                    <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                                        textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                            color: '#1976d2',
                                        }
                                    }}>
                                        <ChevronLeftIcon fontSize='small' />
                                        <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                                    </Box>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            component={ReactRouterLink}
                                            to="/admin"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {t("Dashboard")}
                                        </Link>
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            component={ReactRouterLink}
                                            to={'/admin/masterdata/roles'}
                                        >
                                            <AssignmentIndIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {t("RoleMenu")}
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {t("Edit Role")}
                                        </Typography>
                                    </Breadcrumbs>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                                    {t("Edit Role")}
                                </Typography>
                            </Box>
                            <Box component="form" noValidate sx={{ mt: 3 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                            fullWidth
                                            sx={{
                                                backgroundColor: 'rgba(0, 0, 0, 0.05)', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                                                }
                                            }}
                                            disabled
                                            id="roleName"
                                            label={t("Role Name")}
                                            value={roleName}
                                        />
                                        {/* <TextField
                                            required
                                            fullWidth
                                            id="roleName"
                                            label={t("Role Name")}
                                            {...register('rolename')}
                                            error={errors.rolename ? true : false}
                                            value={roleName}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setRoleName(event.target.value);
                                                errors.rolename = undefined;
                                            }}
                                            disabled
                                        />
                                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                            {errors.rolename?.message}
                                        </Typography> */}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            sx={{
                                                backgroundColor: 'rgba(0, 0, 0, 0.05)', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                                                }
                                            }}
                                            disabled
                                            id="roleType"
                                            label={t("Role Type")}
                                            value={roleType}
                                        />
                                    </Grid>
                                    {
                                        roleType === 'Member' ? null :
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Autocomplete
                                                        multiple
                                                        onChange={(_, newValue: Site[]) => {
                                                            setFilteredSiteData(newValue);
                                                        }}
                                                        disableCloseOnSelect
                                                        isOptionEqualToValue={(option: Site, value: Site) => option.site_id === value.site_id}
                                                        id="site"
                                                        options={siteData}
                                                        value={filteredSiteData}
                                                        getOptionLabel={(option) => option.site_name}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.site_name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={t("Site")} placeholder={t("Site")!} />
                                                        )}
                                                        renderTags={(value: Site[], getTagProps) =>
                                                            value.map((option: Site, index: number) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    label={t(option.site_name)}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))
                                                        }
                                                        noOptionsText={t("No options found")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Autocomplete
                                                        multiple
                                                        onChange={(_, newValue: Menu[]) => {
                                                            setFilteredMenuData(newValue);
                                                        }}
                                                        disableCloseOnSelect
                                                        isOptionEqualToValue={(option: Menu, value: Menu) => option.menu_id === value.menu_id}
                                                        id="menu"
                                                        options={menusData.filter((menu: Menu) => menu.parent_id !== null)}
                                                        groupBy={(option: Menu) => {
                                                            return option.parent_id;
                                                        }}
                                                        renderGroup={(params) => (
                                                            menusData.map((menu: Menu) => {
                                                                if (menu.menu_id === params.group) {
                                                                    return (
                                                                        <div key={menu.menu_id}>
                                                                            <Typography variant="inherit" color="primary" align="left" pl={2} pt={1} fontSize={13}>
                                                                                {t(menu.menu_name)}
                                                                            </Typography>
                                                                            {params.children}
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        )}
                                                        value={filteredMenuData}
                                                        getOptionLabel={(option: Menu) => t(option.menu_name)}
                                                        renderOption={(props, option: Menu, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {t(option.menu_name)}
                                                            </li>
                                                        )}
                                                        renderTags={(value: Menu[], getTagProps) =>
                                                            value.map((option: Menu, index: number) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    icon={<Icon fontSize='small'>{option.icon}</Icon>}
                                                                    label={t(option.menu_name)}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={loadingMenus ? `${t("Loading")}...` : t("Menu")}
                                                                placeholder={t("Menu")!}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {loadingMenus ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                        loading={loadingMenus}
                                                        loadingText={`${t("Loading")}...`}
                                                        noOptionsText={t("No options found")}
                                                    />
                                                </Grid>
                                            </>
                                    }
                                    <Grid item xs={6} sm={12}>
                                        <FormControlLabel
                                            label={t("Active")}
                                            control={
                                                <Checkbox
                                                    checked={active}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'Active' }}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 5, mb: 2 }}
                                            onClick={handleSubmit(onSubmit)}
                                            disabled={editLoading}
                                        >
                                            {t("Edit")}
                                            {editLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: green[500],
                                                        position: 'absolute',
                                                        zIndex: 999,
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 5, mb: 2 }}
                                            component={ReactRouterLink}
                                            to="/admin/masterdata/roles"
                                        >
                                            {t("Cancel")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </> : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                            <CircularProgress />
                        </Box>
                    }
                </> : null
            }
        </>
    )
}

export default EditRole