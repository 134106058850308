import * as React from "react";
import PublicAppBar from "../../../components/appbar/PublicAppBar";
import { Container, Typography } from "@mui/material";

type UnauthorizedErrorProps = {
  //
};

const UnauthorizedError: React.FC<any> = () => {
  return (
    <Container maxWidth='lg'>
      <PublicAppBar />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h1">401</Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: "40px", marginBottom: 2 }}>Unauthorized</Typography>
        <Typography variant="body1">Oops! You are not authorized to access this page.</Typography>
      </div>
    </Container>
  );
};

export default UnauthorizedError;
