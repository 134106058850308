import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/appforms';

interface getAllAppFormDataByUserIdType {
    user_id: string;
}

interface iJobTransferData {
    app_form_status_id: string;
    jobId? : string;
    transferStatus: boolean;
    modified_by: string;
}

export function getAllAppFormData(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}/all-app-form`, data, config);
}

export function getAllAppFormDataByUserId(data: getAllAppFormDataByUserIdType) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}/all-app-form/user`, data, config);
}

export function getAppFormById(id: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.get(`${BASE_URL}/app-form/${id}`, config);
}

export function createAppForm(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/create-app-form`, data, config);
}

export function getLatestSubmittedAppForms(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/latest-submitted-form`, data, config);
}

export function updateAppFormStatusById(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.put(`${BASE_URL}/status/update-status`, data, config);
}

export function updateAppFormStatusByMultipleIds(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.put(`${BASE_URL}/status/update-multiple-status`, data, config);
}

export function updateAppFormCommentById(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.put(`${BASE_URL}/comment/update-comment`, data, config);
}

export function updateAppFormCommentByMultipleIds(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.put(`${BASE_URL}/comment/update-multiple-comment`, data, config);
}

export function updateJobStatusTransfer(data: iJobTransferData) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}/transfer-job-status`, data, config);
}

