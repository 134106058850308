// React
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI Components
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

import {
    DataGrid,
    GridCellParams,
    GridColDef
} from '@mui/x-data-grid';

import { enUS } from '@mui/x-data-grid-pro';

// Utils
import { useTranslation } from 'react-i18next';

// Styles
import theme from '../../../styles/theme';

// Types
import { Status as StatusType, thTHGrid } from '../../../@types';

// Services
import { getAllStatus } from '../../../services/StatusService';

const Status = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate()

    const [statusData, setStatusData] = useState<StatusType[]>([])
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true)
    const allSuccessLoading = !loadingStatus;

    const userRoleType = useAppSelector(state => state.user.roletype);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await getAllStatus();

                if (response.data?.success) {
                    const statusData = response.data?.status;
                    setStatusData(statusData);
                    setLoadingStatus(false);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        fetchStatus();
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'edit',
            headerName: `${t('Edit')}`,
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            width: userRoleType === 'Super Admin' ? 80 : 0,
            renderCell: (params) => {
                if (userRoleType === 'Super Admin') {
                    return (
                        <Link
                            to={`/admin/masterdata/status/edit/${params.row.id}`}
                            style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}
                        >
                            <Tooltip title={t("Edit")}>
                                <Icon fontSize="medium">edit</Icon>
                            </Tooltip>
                        </Link>
                    );
                }
            },
        },
        {
            field: 'status_name',
            headerName: `${t('Status name')}`,
            width: 200,
            valueGetter: (params: GridCellParams) => `${params.row.status_name}`,
        },
        {
            field: 'show_on_applicant',
            headerName: `${t('Show on Applicant')}`,
            width: 270,
            renderCell: (params: GridCellParams) => {
                let statusAPC = [params.row.show_on_applicant]
                let statusColor = [params.row.color]

                return (
                    <Chip
                        variant="outlined"
                        sx={{
                            bgcolor: statusColor,
                            borderColor: statusColor,
                            fontWeights: 'normal',
                            color: 'white',
                            my: 1.5
                        }}
                        size='medium'
                        label={statusAPC}
                    />
                )
            }
        },
        {
            field: 'show_on_hr',
            headerName: `${t('Show on HR')}`,
            width: 270,
            renderCell: (params: GridCellParams) => {
                let statusHR = [params.row.show_on_hr]
                let statusColor = [params.row.color]

                return (
                    <Chip
                        variant="outlined"
                        sx={{
                            bgcolor: statusColor,
                            borderColor: statusColor,
                            color: 'white',
                            my: 1.5
                        }}
                        size='medium'
                        label={statusHR}
                    />
                )
            }
        },
        {
            field: 'is_active',
            headerName: `${t('Status')}`,
            width: 90,
            renderCell: (params: GridCellParams) =>
                params.row.is_active === true ? (
                    <Chip variant="outlined" color="success" label={t("Active")} size="small" />
                ) : (
                    <Chip variant="outlined" color="error" label={t("Inactive")} size="small" />
                ),
        },
    ];

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {t("StatusPageTitle")}
                </Typography>
            </Box>

            <Box sx={{ width: '100%', marginTop: 3, paddingX: 3 }}>
                <Box display='inline-grid' sx={{ width: '100%' }}>
                    {
                        allSuccessLoading ? <>
                            {
                                statusData.length > 0 ? <>
                                    <DataGrid
                                        rows={statusData}
                                        columns={columns}
                                        pagination
                                        pageSizeOptions={[100]}
                                        getRowHeight={() => 'auto'}
                                        disableRowSelectionOnClick
                                        sx={{
                                            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                                outline: 'none !important',
                                            },
                                            fontSize: 13,
                                        }}
                                        localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                                    />
                                </> : <Typography align='center'>{t("No data")}</Typography>
                            }
                        </> : <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box>
                    }
                </Box>
            </Box>
        </>
    );
};

export default Status;