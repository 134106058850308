import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/jobs';

export function getAllJobs() {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.get(`${BASE_URL}`, config);
}

export function getJobById(id: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.get(`${BASE_URL}/${id}`, config);
}

export function getJobsByMultipleIds(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/getmanyjobs`, data, config);
}

export function getLatestJobCreateByUsername(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/latestcreate`, data, config);
}

export function createJob(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}`, data, config);
}

export function editJobById(id: any, data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.put(`${BASE_URL}/${id}`, data, config);
}

export function deleteJobById(id: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.delete(`${BASE_URL}/${id}`, config);
}

export function queryJobs(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/query`, data, config);
}

export function checkDupJobApply(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/checkdupjobapply`, data, config);
}

export function checkAllDupJobApply(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };

    return api.post(`${BASE_URL}/checkalldupjob`, data, config);
}