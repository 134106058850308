// @React
import React, { useEffect, useState } from 'react';

// @MUI Components
import { Box, IconButton, Typography, Button, Chip, Grid, } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, enUS } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

// @MUI Icons
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

// @Utils
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { thTHGrid } from "../../../@types/DataGridLocaleText";

// @React-Router-Dom
import { Link as ReactRouterLink } from 'react-router-dom';
import { getAllStatus } from '../../../services/StatusService';
import { updateAppFormStatusById, updateAppFormStatusByMultipleIds } from '../../../services/AppFormService';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

interface statusTableProps {
  index: number;
  value: number;
  data: any[];
}

interface Status {
  id: string;
  status_name: string;
  show_on_applicant: string;
  show_on_hr: string;
  color: string;
  is_active: boolean;
}

const JobStatusTable: React.FC<statusTableProps> = (props) => {
  const { index, value, data, ...other } = props;
  const { t, i18n } = useTranslation();

  const [selectionCheck, setselectionCheck] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<Status[]>([]);
  const [multiAppform, setMultiAppform] = useState<any[]>([]);

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await getAllStatus();

      if (response.data?.success) {
        setStatusData(response.data?.status);
      }
    };
    fetchStatus();
  }, []);

  const handleCancelAppform = (appformId: any) => {
    Swal.fire({
      icon: 'warning',
      title: `<h5 style="margin: 0px">${t('Are you sure you want to cancel your application?')}</h5>`,
      html: `<p>${t('If you cancel your application, your application will not be considered.')}</p>`,
      showCancelButton: true,
      confirmButtonColor: '#43A047',
      cancelButtonText: `${t('Cancel')}`,
      confirmButtonText: `${t('Confirm')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const updateData = async () => {
          const parsedToken: any = jwtDecode(localStorage.access_token);

          const data = {
            app_form_status_id: appformId,
            status_id: statusData[5].id,
            modified_by: parsedToken.Username,
          }
          const response = await updateAppFormStatusById(data);

          if (response.data?.success) {
            Swal.fire({
              icon: 'success',
              title: `${t('Cancellation successful!')}`,
              confirmButtonColor: '#43A047',
              confirmButtonText: 'OK!',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(() => {
              window.location.assign('/jobs_status?content_type=completedTable');
            })
          }
        }

        updateData();
      }
    })

  }

  const columns: GridColDef[] = [
    {
      field: 'viewmore',
      headerName: '',
      width: 50,
      renderCell: (params) => {
        return (
          <>
            {selectionCheck ? (
              <IconButton disabled sx={{ borderRadius: 2 }}>
                <VisibilityOffRoundedIcon color='disabled' />
              </IconButton>
            ) : (
              <Tooltip title={t("View more details")}>
                <IconButton color='info' sx={{ borderRadius: 2 }}
                  component={ReactRouterLink}
                  to={`/jobs_status/appformdetail/${params.row.id}?content_type=inprogressTable`}
                >
                  <VisibilityRoundedIcon />
                </IconButton>
              </Tooltip>
            )
            }
          </>
        );
      }
    },
    {
      field: 'cancel',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        return (
          <>
            {!selectionCheck && (
              <Tooltip title={t("Cancel job application")}>
                <IconButton color='error' sx={{ borderRadius: 2, }}
                  onClick={() => handleCancelAppform(params.row.id)}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      }
    },
    {
      field: 'position_name',
      headerName: `${t("Position")}`,
      width: 230,
      renderCell: (params: GridCellParams) => {
        let jobPosition = params.row.Job.job_name
        

        return (
          <Typography sx={{ fontSize: 14 }}>
            {jobPosition}
          </Typography>
        );
      }
    },
    {
      field: 'Site',
      headerName: `${t("Site")}`,
      width: 210,
      renderCell: (params: GridCellParams) => {
        let site = params.row.Job.Site.site_name;

        return (
          <Typography sx={{ fontSize: 14, }}>
            {site}
          </Typography>
        );
      }
    },
    {
      field: 'status_name',
      headerName: `${t("Status")}`,
      width: 180,
      renderCell: (params: GridCellParams) => {
        let status_applicant = params.row.Status.show_on_applicant
        let statusColor = params.row.Status.color

        return (
          <Chip
            variant="outlined"
            sx={{
              bgcolor: statusColor,
              borderColor: statusColor,
              fontWeights: 'normal',
              color: 'white',
              my: 2,
            }}
            size='medium'
            label={status_applicant}
          />
        );
      }
    },
    {
      field: 'created_date',
      headerName: `${t("Apply Date")}`,
      width: 150,
      renderCell: (params: GridCellParams) => {
        let created_date = dayjs(params.row.created_date).format(i18n.language === 'en' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/BBBB HH:mm')

        return (
          <Typography sx={{ fontSize: 14, }}>
            {created_date}
          </Typography>
        );
      }
    },
    {
      field: 'modified_date',
      headerName: `${t("Last Modified Date")}`,
      width: 150,
      renderCell: (params: GridCellParams) => {
        if (params.row.modified_date) {
          let modified_date = dayjs(params.row.modified_date).format(i18n.language === 'en' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/BBBB HH:mm') ?? '-'

          return (
            <Typography sx={{ fontSize: 14 }}>
              {modified_date}
            </Typography>
          );
        } else {
          return (
            <Typography sx={{ fontSize: 14 }}>-</Typography>
          )
        }
      }
    },
  ];

  const handleCancelMultiAppform = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h5 style="margin: 0px">${t('Are you sure you want to cancel your application?')}</h5>`,
      html: `<p>${t('If you cancel your application, your application will not be considered.')}</p>`,
      showCancelButton: true,
      confirmButtonColor: '#43A047',
      cancelButtonText: `${t('Cancel')}`,
      confirmButtonText: `${t('Confirm')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const updateData = async () => {
          const parsedToken: any = jwtDecode(localStorage.access_token);

          const data = {
            ids: multiAppform,
            status_id: statusData[5].id,
            modified_by: parsedToken.Username,
          }
          const response = await updateAppFormStatusByMultipleIds(data);

          if (response.data?.success) {
            Swal.fire({
              icon: 'success',
              title: `<h5 style="margin: 0px">${t('Cancellation successful!')}</h5>`,
              confirmButtonColor: '#43A047',
              confirmButtonText: 'OK!',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(() => {
              window.location.assign('/jobs_status?content_type=completedTable');
            })
          }
        }

        updateData();
      }
    })
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {data.length ? (<>
        {selectionCheck && (
          <Grid container display='flex' sx={{ mb: 2, justifyContent: 'center' }}>
            <Grid item display='flex' alignSelf='center'>
              <Button
                variant="outlined"
                color='error'
                size='medium'
                startIcon={<DeleteRoundedIcon />}
                onClick={handleCancelMultiAppform}
                sx={{ py: 1, px: 2, }}
              >
                {t("Cancel your job")} {`${multiAppform.length}`} {t("selected")}
              </Button>
            </Grid>
          </Grid>
        )}
        <DataGrid
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 4, }
            },
          }}
          pageSizeOptions={[4]}
          rows={data}
          columns={columns}
          disableColumnMenu
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(e: any) => {
            setMultiAppform(e);
            e.length > 1 ? setselectionCheck(true) : setselectionCheck(false);
          }}
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none !important',
            },
            fontSize: 15,
          }}
          localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
        />
      </>) : (
        <Box display='flex' flexDirection='column' justifyContent='center' marginY={7} gap={2}>
          <Typography align='center' sx={{ fontSize: { xs: '14px', sm: '16px' }, letterSpacing: 0.5 }}>
            {t("No job positions currently in progress")}
          </Typography>
          <Button
            variant="contained"
            sx={{ fontSize: { xs: 14, sm: 16 }, maxWidth: 'fit-content', alignSelf: 'center' }}
            component={ReactRouterLink}
            to="/jobs"
          >
            {t("Click to apply for a job")}
          </Button>
        </Box>
      )}


    </div>
  );
}

export default JobStatusTable