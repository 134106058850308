// React
import React, { useEffect, useState } from 'react';
import {
    useParams,
    useSearchParams,
    Link as ReactRouterLink,
    useNavigate
} from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

// MUI Icons
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';

// Utils
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

// Styles
import { green } from '@mui/material/colors';

// Types
import RoleType from '../../../@types/RoleType';
import {
    Role,
    TokenPayload,
    User
} from '../../../@types';

// Services
import { getAllRoles } from '../../../services/RoleService';
import { editUserById, getUserById } from '../../../services/UserService';

const EditUser = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const { id } = useParams();

    const userRoleType = useAppSelector((state) => state.user.roletype);
    const havePermission = userRoleType === 'Super Admin';

    const [editUserType, setEditUserType] = useState('');
    const [userData, setUserData] = useState({
        prefix: '',
        first_name_th: '',
        last_name_th: '',
        first_name_en: '',
        last_name_en: '',
        email: '',
        is_active: true,
        id_card_number: '',
        date_of_birth: null,
        telephone: '',
        Role: {
            role_name: '',
            role_id: '',
            role_type: '',
        }
    });
    const [roleData, setRoleData] = useState<Role[]>([]);
    const [isNormalUser, setIsNormalUser] = useState<boolean>(true);
    const [userLoading, setUserLoading] = useState<boolean>(true);
    const [roleLoading, setRoleLoading] = useState<boolean>(true);
    const [filterLoading, setFilterLoading] = useState<boolean>(true);
    const [selectedRole, setSelectedRole] = useState<Role>({
        role_id: '',
        role_name: '',
        role_type: 'Member',
        is_active: true,
    });
    const [editLoading, setEditLoading] = useState<boolean>(false);
    const allSuccessLoading = !userLoading && !roleLoading && !filterLoading;

    if (!havePermission) {
        navigate('/forbidden', { replace: true });
    }

    useEffect(() => {
        try {
            if (searchParams.has('type')) {
                const type = searchParams.get('type');
                if (type === 'hr') {
                    setEditUserType('HR Recruiter');
                } else if (type === 'superadmin') {
                    setEditUserType('Super Admin');
                } else if (type === 'member') {
                    setEditUserType('Member');
                } else {
                    navigate('/admin/masterdata/users?type=member', { replace: true });
                }
            }

        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    }, [searchParams, navigate]);

    useEffect(() => {
        try {
            if (selectedRole?.role_type === RoleType.HR_Recruiter) {
                setIsNormalUser(false);
            } else if (selectedRole?.role_type === RoleType.Super_Admin) {
                setIsNormalUser(false);
            } else {
                setIsNormalUser(true);
            }
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    }, [selectedRole, navigate]);

    const handlePrefixChange = (event: SelectChangeEvent) => {
        try {
            setUserData((prevData) => ({
                ...prevData,
                prefix: event.target.value as string,
            }));
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setUserData((prevData) => ({
                ...prevData,
                is_active: event.target.checked,
            }));
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    };

    const sortOptionsByGroup = (options: Role[]): Role[] => {
        try {
            const groupedOptions: { [key: string]: Role[] } = {};

            options.forEach((option) => {
                if (!groupedOptions[option.role_type]) {
                    groupedOptions[option.role_type] = [];
                }
                groupedOptions[option.role_type].push(option);
            });

            const sortedOptions: Role[] = [];
            Object.keys(groupedOptions)
                .sort()
                .forEach((group) => {
                    sortedOptions.push(...groupedOptions[group].sort((a, b) => a.role_name.localeCompare(b.role_name)));
                });

            return sortedOptions;
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
            return []
        }
    };

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await getAllRoles();

                if (response.data?.success) {
                    const rolesData = response.data?.roles;
                    const activeRole = rolesData.filter((role: Role) => role.is_active === true && role.role_type !== 'Member');
                    setRoleData(activeRole);
                }

            } catch (error) {
                console.log(error);
                navigate('/servererror', { replace: true });
            } finally {
                setRoleLoading(false);
            }
        };

        fetchRoles();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user_id = id as string;
                const response = await getUserById(user_id);

                const userDataResponse = response.data?.user;

                if (!response.data?.success) {
                    Swal.fire({
                        title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#43A047',
                        confirmButtonText: 'OK!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign('/admin/masterdata/users?type=member');
                        }
                    });
                    return;
                }

                if (userDataResponse.email) {
                    const email_part: string[] = userDataResponse.email.split('@');
                    if (email_part[1] === 'betagro.com') {
                        setIsNormalUser(false);
                    } else {
                        setIsNormalUser(true);
                    }
                }

                setUserData((prevUserData) => ({
                    ...prevUserData,
                    prefix: userDataResponse.prefix || '',
                    first_name_th: userDataResponse.first_name_th || '',
                    last_name_th: userDataResponse.last_name_th || '',
                    first_name_en: userDataResponse.first_name_en || '',
                    last_name_en: userDataResponse.last_name_en || '',
                    email: userDataResponse.email || '',
                    is_active: userDataResponse.is_active,
                    id_card_number: userDataResponse.id_card_number || '',
                    date_of_birth: userDataResponse.date_of_birth || '',
                    telephone: userDataResponse.telephone || '',
                    Role: {
                        role_name: userDataResponse.Role.role_name,
                        role_id: userDataResponse.Role.role_id,
                        role_type: userDataResponse.Role.role_type,
                    }
                }));

                setIsNormalUser(userDataResponse.Role.role_type === 'Member' ? true : false);

            } catch (error) {
                console.log(error);
                navigate('/servererror', { replace: true });
            } finally {
                setUserLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    useEffect(() => {
        try {
            if (roleData && roleData.length > 0 && userData.Role.role_id.length > 0) {
                const mappedRoleData = roleData.filter((role) => userData.Role.role_id.includes(role.role_id));

                setSelectedRole(mappedRoleData[0]);
                setFilterLoading(false);
            }
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    }, [roleData, userData.Role.role_id]);

    const formValidationSchema = yup.object().shape({
        prefix: yup.string(),
        first_name_th: isNormalUser
            ? yup.string().required(`${t("กรุณาใส่ ชื่อภาษาไทย")}`)
            : yup.string(),
        last_name_th: isNormalUser
            ? yup.string().required(`${t("กรุณาใส่ นามสกุลภาษาไทย")}`)
            : yup.string(),
        first_name_en: yup.string().required(`${t("กรุณาใส่ ชื่อภาษาอังกฤษ")}`),
        last_name_en: yup.string().required(`${t("กรุณาใส่ นามสกุลภาษาอังกฤษ")}`),
        email: yup.string().email(`${t('Please enter Valid email')}`),
        telephone: isNormalUser
            ? yup.string().required(`${t('Phone number is required')}`)
            : yup.string(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formValidationSchema)
    });

    const onSubmit = () => {
        const postData = async () => {
            try {
                const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);

                const data = {
                    prefix: userData.prefix.length > 0 ? userData.prefix : null,
                    first_name_th: userData.first_name_th.length > 0 ? userData.first_name_th : null,
                    last_name_th: userData.last_name_th.length > 0 ? userData.last_name_th : null,
                    first_name_en: userData.first_name_en.length > 0 ? userData.first_name_en : null,
                    last_name_en: userData.last_name_en.length > 0 ? userData.last_name_en : null,
                    email: userData.email.length > 0 ? userData.email : null,
                    is_active: userData.is_active,
                    role_id: selectedRole?.role_id,
                    modified_by: parsedToken.Username || null,
                    role_type: selectedRole?.role_type ? selectedRole?.role_type : userData.Role.role_type,
                    id_card_number: userData.id_card_number.length > 0 ? userData.id_card_number : null,
                    date_of_birth: userData.date_of_birth ? userData.date_of_birth : null,
                    telephone: userData.telephone.length > 0 ? userData.telephone : null,
                }

                setEditLoading(true);
                const response = await editUserById(id as string, data);

                if (response) {
                    setEditLoading(false);
                    if (response.data?.success) {
                        Swal.fire({
                            title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#43A047',
                            confirmButtonText: 'OK!',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.assign(`/admin/masterdata/users?type=${(selectedRole?.role_type === undefined ? 'member' : selectedRole?.role_type === 'HR Recruiter' ? 'hr' : 'superadmin')}`);
                            }
                        });
                    } else {
                        toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
                            position: 'top-center',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                    }
                }

            } catch (error) {
                console.log(error);
                navigate('/servererror', { replace: true });
            }
        };

        postData();
    };

    return (
        <>
            <ToastContainer style={{ width: "400px" }} />

            {
                allSuccessLoading ? <>
                    {
                        havePermission ? <>
                            <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                                <Box display='flex' gap={3} sx={{ mb: 3 }}>
                                    <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                                        textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                            color: '#1976d2',
                                        }
                                    }}>
                                        <ChevronLeftIcon fontSize='small' />
                                        <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                                    </Box>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            component={ReactRouterLink}
                                            to="/admin"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {t("Dashboard")}
                                        </Link>
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            component={ReactRouterLink}
                                            to={'/admin/masterdata/users?type=' + (editUserType === 'Member' ? 'member' : editUserType === 'HR Recruiter' ? 'hr' : 'superadmin')}
                                        >
                                            <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {t("UserAccount")}
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {t("Edit Data")}
                                        </Typography>
                                    </Breadcrumbs>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                                    {t('Edit Data')}
                                </Typography>
                            </Box>
                            <Box component="form" noValidate sx={{ mt: 3 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="prefix">{t('Prefix')} {isNormalUser && '*'}</InputLabel>
                                            <Select labelId="prefix" id="prefix" value={userData.prefix ? userData.prefix : undefined} required={isNormalUser ? true : false} label={t('Prefix')} onChange={handlePrefixChange}>
                                                <MenuItem value="Mr">{t('Mr')}</MenuItem>
                                                <MenuItem value="Mrs">{t('Mrs')}</MenuItem>
                                                <MenuItem value="Miss">{t('Miss')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            required={isNormalUser ? true : false}
                                            fullWidth
                                            id="firstName"
                                            label={t('Thai name')}
                                            placeholder={t('Thai name')!}
                                            {...register('first_name_th')}
                                            error={errors.first_name_th ? true : false}
                                            value={userData.first_name_th}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setUserData((prevData) => ({ ...prevData, first_name_th: event.target.value }));
                                                errors.first_name_th = undefined
                                            }}
                                        />
                                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                            {errors.first_name_th?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            required={isNormalUser ? true : false}
                                            fullWidth
                                            id="lastName"
                                            label={t('Thai last name')}
                                            placeholder={t('Thai last name')!}
                                            {...register('last_name_th')}
                                            error={errors.last_name_th ? true : false}
                                            value={userData.last_name_th}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setUserData((prevData) => ({ ...prevData, last_name_th: event.target.value }));
                                                errors.last_name_th = undefined
                                            }}
                                        />
                                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                            {errors.last_name_th?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <TextField
                                            fullWidth
                                            required
                                            id="firstNameEN"
                                            label={t('English name')}
                                            placeholder={t('English name')!}
                                            {...register('first_name_en')}
                                            error={errors.first_name_en ? true : false}
                                            value={userData.first_name_en}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setUserData((prevData) => ({ ...prevData, first_name_en: event.target.value }));
                                                errors.first_name_en = undefined
                                            }}
                                        />
                                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                            {errors.first_name_en?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="lastNameEN"
                                            label={t('English last name')}
                                            placeholder={t('English last name')!}
                                            {...register('last_name_en')}
                                            error={errors.last_name_en ? true : false}
                                            value={userData.last_name_en}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setUserData((prevData) => ({ ...prevData, last_name_en: event.target.value }));
                                                errors.last_name_en = undefined
                                            }}
                                        />
                                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                            {errors.last_name_en?.message}
                                        </Typography>
                                    </Grid>
                                    {isNormalUser ? <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                sx={{
                                                    backgroundColor: 'rgba(0, 0, 0, 0.05)', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                                                    }
                                                }}
                                                disabled
                                                fullWidth
                                                id="id_card_number"
                                                label={t('ID card number')}
                                                placeholder={t('ID card number')!}
                                                value={userData.id_card_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                sx={{
                                                    backgroundColor: 'rgba(0, 0, 0, 0.05)', '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                                                    }
                                                }}
                                                disabled
                                                fullWidth
                                                id="date_of_birth"
                                                label={t('Date of birth')}
                                                placeholder={t('Date of birth')!}
                                                value={dayjs(userData.date_of_birth).format(i18n.language === 'th' ? 'DD/MM/BBBB' : 'MM/DD/YYYY') === 'Invalid Date' ? '' : dayjs(userData.date_of_birth).format(i18n.language === 'th' ? 'DD/MM/BBBB' : 'MM/DD/YYYY')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                error={errors.telephone ? true : false}
                                                required={isNormalUser ? true : false}
                                                id="telephone"
                                                label={t('Phone Number')}
                                                {...register('telephone')}
                                                placeholder={t('Phone Number')!}
                                                value={userData.telephone}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setUserData((prevData) => ({ ...prevData, telephone: event.target.value }));
                                                }}
                                            />
                                            <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                                {errors.telephone?.message}
                                            </Typography>
                                        </Grid>
                                    </> : null}
                                    <Grid item xs={12} sm={isNormalUser ? 6 : 12}>
                                        <TextField
                                            sx={{ backgroundColor: !isNormalUser ? 'rgba(0, 0, 0, 0.05)' : '' }}
                                            disabled={!isNormalUser}
                                            fullWidth
                                            label={isNormalUser ? t('Email') : t('Betagro Email')}
                                            placeholder={isNormalUser ? t('Email')! : t('Betagro Email')!}
                                            id="email"
                                            {...register('email')}
                                            error={errors.email ? true : false}
                                            value={userData.email === null ? '' : userData.email}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setUserData((prevData) => ({ ...prevData, email: event.target.value }));
                                                errors.email = undefined
                                            }}
                                        />
                                        <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                            {errors.email?.message}
                                        </Typography>
                                    </Grid>
                                    {
                                        userData.Role.role_type !== 'Member' ? <>
                                            <Grid item xs={12} sm={4}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        onChange={(event, newValue: Role | undefined) => {
                                                            setSelectedRole(newValue as Role);
                                                            if (selectedRole?.role_type === 'Member') {
                                                                setIsNormalUser(true);
                                                            } else {
                                                                setIsNormalUser(false);
                                                            }
                                                        }}
                                                        id="role"
                                                        options={sortOptionsByGroup(roleData)}
                                                        getOptionLabel={(option) => option.role_name}
                                                        value={selectedRole}
                                                        isOptionEqualToValue={(option: Role, value: Role) => option.role_id === value.role_id}
                                                        groupBy={(option) => option.role_type}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>{option.role_name}</li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={t('Role Name') + ' *'} placeholder={t('Role Name') + ' *'} />
                                                        )}
                                                        disableClearable
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </> : null
                                    }
                                    <Grid item xs={8}>
                                        <FormControlLabel
                                            sx={{ paddingTop: 1 }}
                                            label={t('Active')}
                                            control={
                                                <Checkbox
                                                    checked={userData.is_active}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'Active' }}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            disabled={editLoading}
                                            sx={{ mt: 5, mb: 2 }}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {t('Edit')}
                                            {editLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: green[500],
                                                        position: 'absolute',
                                                        zIndex: 999,
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 5, mb: 2 }}
                                            component={ReactRouterLink}
                                            to={`/admin/masterdata/users?type=${editUserType === 'Member' ? 'member' : editUserType === 'HR Recruiter' ? 'hr' : 'superadmin'}`}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </> : null
                    } </> : <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                        <CircularProgress />
                    </Box>
                </>
            }
        </>
    );
};

export default EditUser;