import { apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/master-data';

export function getAllActiveCountry() {
    return api.get(`${BASE_URL}/country`);
}

export function getAllActiveReligion() {
    return api.get(`${BASE_URL}/religion`);
}

export function getAllActiveMaritalStatus() {
    return api.get(`${BASE_URL}/marital-status`);
}

export function getAllActiveCompany() {
    return api.get(`${BASE_URL}/company`);
}

export function getAllActivePayGrade() {
    return api.get(`${BASE_URL}/pay-grade`);
}

export function getAllActiveIncentiveGroup() {
    return api.get(`${BASE_URL}/incentive-group`);
}

export function getAllActiveWorkSchedule() {
    return api.get(`${BASE_URL}/work-schedule`);
}

export function getAllActiveHolidaySchedule() {
    return api.get(`${BASE_URL}/holiday-schedule`);
}

export function getAllActiveWorkGroup() {
    return api.get(`${BASE_URL}/work-group`);
}

export function getAllActiveBank() {
    return api.get(`${BASE_URL}/bank`);
}

export function getAllActivePayGroup() {
    return api.get(`${BASE_URL}/pay-group`);
}

export function getAllActivePrefix() {
    return api.get(`${BASE_URL}/prefix`);
}