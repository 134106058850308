import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { apiUrl } from "../../Constants";
import api from "../../services/ApiService";

// interface idocument {
//     id?: string;
//     userId?: string;
//     name?: string;
//     type?: string;
//     dmsFileId?: string;
//     createdDate?: string;
// }

// interface idocument {
//     document:  idocument[];
// }


const initialState: any =  {
    document: [],
    status:''
}

export const fetchDocumentByUser = createAsyncThunk('user/documentsDMS', async () => {
    try {

        if (localStorage.getItem('access_token')) {

            const response = await api.get(`${apiUrl}/api/users/documents/user/${jwtDecode<any>(localStorage.getItem('access_token')!).UserId}`);
            if (response.data?.success === true) {
                return response.data;
            }
        }
    } catch (error) {
        throw new Error('Failed to fetch documents data');
    }
});

const documentsSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateDocuments: (state, action) => {
            state.document = action.payload.document
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocumentByUser.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchDocumentByUser.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload.success === true) {
                    state.status = 'fulfilled';
                    state.document = action.payload.document;
                } else if (action.payload === undefined) {
                    state.status = 'rejected';
                }
            })
            .addCase(fetchDocumentByUser.rejected, (state, action) => {
                state.status = 'rejected';
            })
            
    },
});

export const {
    updateDocuments
    
} = documentsSlice.actions;
export default documentsSlice;