// React
import React, { useEffect, useState,useCallback  } from "react";
import { Link as ReactRouterLink, useNavigate, useParams } from "react-router-dom";

// Components
import InputDataToDC from "../../../components/admin/InputDataToEC";

// MUI Components
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import '../../../styles/index.scss'

import {
    MobileDatePicker,
    TimeField,
    LocalizationProvider
} from "@mui/x-date-pickers";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// MUI Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentTurnInIcon from "@mui/icons-material/AssignmentTurnedIn";
import InfoIcon from '@mui/icons-material/Info';
import CommentIcon from '@mui/icons-material/Comment';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';

// Utils
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import { newAdapter } from "../../../utils";

// Types
import { Status } from "../../../@types";
import { AppFormStatus } from "../../../@types";

// Services
import {
    getAppFormById,
    updateAppFormCommentById,
    updateAppFormStatusById
} from "../../../services/AppFormService";
import { getAllStatus } from "../../../services/StatusService";
import { checkUserEC } from '../../../services/ECService';
import { getAllSites } from '../../../services/SiteService';
import { getAllActiveCompany, getAllActivePrefix } from "../../../services/MasterDataService";
import { UpdateStatusEC } from '../../../services/ECService';
import { getAllDocuments, getDocumentsfile } from "../../../services/UserService";
import { TokenPayload} from "../../../@types";
import { apiUrl } from '../../../Constants';
import ProvinceJSON from '../../../services/REGION_THA/Province.json'
// import SubDistrictJSON from '../../../services/REGION_THA/SubDistrict.json'
import {
    Site,
    CompanyInter
  } from '../../../@types';
  import { fetchSubDistricts } from "../../users/AppFormPage/AddressInfo/fetchGeoData";


type ProvinceType = {
    // external_code: number;
    // label_th: string;
    // label_en: string;
    // address_province: number;
    external_code: number;
    province_code: number;
    label_th: string;
    label_en: string;
    is_active: boolean;
}

type DistrictType = {
    id: number;
    name_th: string;
    name_en: string;
    province_id: number;
    address_district: number;
}

type SubDistrictType = {
    id: number;
    zip_code: number;
    name_th: string;
    name_en: string;
    amphure_id: number;
    // address_sub_district: number;
}
//     id: number;
//     zip_code: number;
//     name_th: string;
//     name_en: string;
//     amphure_id: number;
//     created_at?: string;
//     updated_at?: string;
//     deleted_at?: string;
// }

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
};

interface MaritalStatus {
    [key: string]: string;
}

const MARITAL_STATUS: MaritalStatus = {
    D: 'Divorced',
    E: 'Separated',
    M: 'Married',
    S: 'Single',
    W: 'Widowed',
};

interface Gender {
    [key: string]: string;
}

const GENDER: Gender = {
    M: 'Male',
    F: 'Female',
};

interface Religion {
    [key: string]: string;
}

const RELIGION: Religion = {
    BDH: 'Buddhism',
    CHR: 'Christ',
    HND: 'Hindu',
    ISL: 'Islamic',
    NON: 'None Specify',
    SIK: 'Sikh',
};

interface Nationality {
    [key: string]: string;
}

const NATIONALITY: Nationality = {
    KHM: 'Cambodia',
    LAO: "Lao People's Democratic Republic",
    MMR: 'Myanmar',
    THA: 'Thailand',
};

type ifileBase = {
    dataPath: string;
    type: string;
    name: string;
}

interface Prefix {
    [key: string]: string;
  }
  
  const  PREFIX: Prefix = {
    Miss: 'Miss',
    Mr: 'Mr.',
    Mrs: 'Mrs.',
  }

const JobAppDetail: React.FC<any> = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem('access_token') || ''
    const decoded: TokenPayload = jwtDecode(token)
    const [open, setOpen] = useState<boolean>(false);
    const [checkInterview, setCheckInterview] = useState<boolean>(false)
    const [dateErrorState, setDateErrorState] = useState<boolean>(false)
    const [timeErrorState, setTimeErrorState] = useState<boolean>(false)
    const handleClose = () => {
        setOpen(false)
        setDateData(null)
        setTimeData(null)
        setDateErrorState(false)
        setTimeErrorState(false)
    };
    const [openPass, setOpenPass] = useState<boolean>(false);

    const [appFormData, setAppFormData] = useState<AppFormStatus | null>(null);
    const [documentsRefs, setDocumentsRefs] = useState<[]>([]);
    const [loadingAppFormData, setLoadingAppFormData] = useState<boolean>(true);
    const [address, setAddress] = useState({
        legal_address_data: {
            house_number: '',
            moo: '',
            lane: '',
            street: '',
            province: '',
            district: '',
            sub_district: '',
            postal_code: ''
        },
        mailing_address_data: {
            house_number: '',
            moo: '',
            lane: '',
            street: '',
            province: '',
            district: '',
            sub_district: '',
            postal_code: ''
        }
    });
    const [province, setProvince] = useState<{
        external_code: number;
        province_code: number;
        label_th: string;
        label_en: string;
        is_active: boolean;
    }[]>([])
    // const [province, setProvince] = useState<ProvinceType[]>([]);
    const [district, setDistrict] = useState<DistrictType[]>([]);
    const [subDistricts, setSubDistricts] = useState<{
        id: number;
        zip_code: number;
        name_th: string;
        name_en: string;
        amphure_id: number;
    }[]>([])
    const [statusData, setStatusData] = useState<Status[]>([]);
    const [comment, setComment] = useState<string | undefined>('');
    const [interviewDate, setInterviewDate] = useState<Date | null>(null);
    const [editCommentClicked, setEditCommentClicked] = useState<boolean>(false);
    const [companyList, setCompanyList] = useState<{ company_code: string, company_name: string }[]>([]);
    const [sitesData, setSitesData] = useState<Site[]>([]);
    const [filesBaseData, setFilesBaseData] = useState<ifileBase[]>([]);
    const allSuccessLoading = !loadingAppFormData;

    var [dateData, setDateData] = useState<any>(null);
    var [timeData, setTimeData] = useState<any>(null);
    const [monthAge, setMonthAge] = useState<number>(0);
    const [age, setAge] = useState<number>(0);
    const [prefixList, setPrefixList] = useState<any>([]);

   

    const {
        register,
        formState: { errors }
    } = useForm();

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await getAllSites();

        if (response.data?.success) {
          const sitesData = response.data?.sites;

          setSitesData(sitesData)
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    const fetchAllActiveCompany = async () => {
      try {
          const response = await getAllActiveCompany();
          if (response.data?.success) {

              setCompanyList(response.data.companies);
          }
      } catch (error) {
          console.log(error);
          navigate('/servererror', { replace: true });
      }
  }
  const fetchActivePrefix = async () => {
    try {
        const response = await getAllActivePrefix();

        if (response.data?.success) {
            setPrefixList(response.data?.marital_statuses);
        }
    } catch (error) {
        console.log(error);
    }
}

    fetchActivePrefix();
    fetchSites();
    fetchAllActiveCompany();
  }, [navigate]);


  useEffect(() => {
    const getDocumentsRef = async () => {
    try {
    if(appFormData && appFormData?.AppFormData?.user_id){
    const newDocuments = []
    const response = await getAllDocuments(appFormData?.AppFormData?.user_id,'');

         if (response.data?.success) {
            const dataDocuments = response.data.document
            setDocumentsRefs(dataDocuments);
            for(let item in dataDocuments){
              const base64Data =  await getDocumentsfile(dataDocuments[item].dmsFileId)
              if(base64Data.status===200){
              newDocuments.push({
                    dataPath :base64Data?.data,
                    name: dataDocuments[item].name,
                    type: dataDocuments[item].type
              })  
            }
            }
            setFilesBaseData(newDocuments)
        }
        }
            return true
    
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    }
getDocumentsRef();
}, [ navigate, appFormData]);


    useEffect(() => {
        const fetchAppFormData = async () => {
            const response = await getAppFormById(id);

            if (response) {
                if (!response.data?.success) {
                    Swal.fire({
                        title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#43A047',
                        confirmButtonText: 'OK!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign('/admin/application-form');
                        }
                    });
                    return;
                }

                const data = response.data?.AppFormStatusData;

                if (!data) {
                    Swal.fire({
                        title: i18n.language === 'th' ? 'ไม่พบข้อมูล' : 'Not found',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#43A047',
                        confirmButtonText: 'OK!',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign('/admin/application-form');
                        }
                    });
                    return;
                }

                setAppFormData(data);

                if (data.Interview.length > 0) {
                    setInterviewDate(new Date(data.Interview[0].interview_date));
                } else {
                    setInterviewDate(null);
                }

                setComment(data.comment ?? '');

                setTimeout(() => {
                    setLoadingAppFormData(false);
                }, 500);
            }
        }

        const fetchAddressData = async () => {
            // const provinceResponse = await fetch(`https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json`);
            const districtResponse = await fetch(`https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json`);
            // const subDistrictResponse = await fetch(`https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json`);

            // if (provinceResponse) {
            //     const provinceData = await provinceResponse.json()
            //     setProvince(provinceResponse);
            // }
            if(ProvinceJSON){
                setProvince(ProvinceJSON)
            }

            if (districtResponse) {
                const districtData = await districtResponse.json()
                setDistrict(districtData);
            }

            // if (SubDistrictJSON) {
            //     const subDistrictsData = await fetchSubDistricts()
            //     setSubDistricts(subDistrictsData)
            //     // setSubDistrict(SubDistrictJSON);

            //     // const subDistrictData = await subDistrictResponse.json()
            //     // setSubDistrict(subDistrictData);
            // }
        }
        const fetchSubDistrictsData = async () => {
            try {
                const subDistrictsData = await fetchSubDistricts()
                setSubDistricts(subDistrictsData)
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }


        fetchSubDistrictsData()
        fetchAppFormData();
        fetchAddressData();

    }, [navigate, id, i18n.language])

    useEffect(() => {
        if (appFormData ) {
            if (appFormData.AppFormData.AppFormAddress ) {
                const legal_address = appFormData.AppFormData.AppFormAddress.find((item: any) => item.address_type === "LEGAL");

                const mailing_address = appFormData.AppFormData.AppFormAddress.find((item: any) => item.address_type === "MAILING");
           
                if (legal_address) {
                    const legal_province = province && province.find((item: ProvinceType) => item.external_code === parseInt(legal_address?.address_province));
                    const legal_district = district.find((item: DistrictType) => item.name_th === legal_address?.address_district);
                    const legal_sub_district = subDistricts.find((item: SubDistrictType) => item.name_th === legal_address?.address_sub_district);

                    const legal_address_data = {
                        house_number: legal_address?.address_house_number,
                        moo: legal_address?.address_moo,
                        lane: legal_address?.address_lane,
                        street: legal_address?.address_street,
                        province: i18n.language === 'th' ? legal_province?.label_th : legal_province?.label_th,
                        district: i18n.language === 'th' ? legal_district?.name_th : legal_district?.name_en,
                        sub_district: i18n.language === 'th' ? legal_sub_district?.name_th : legal_sub_district?.name_en,
                        postal_code: legal_address?.address_postal_code
                    }

                    setAddress((prevState: any) => {
                        return {
                            ...prevState,
                            legal_address_data: legal_address_data,
                        }
                    })
                }
               
                if (mailing_address) {
                    
                    const mailing_province = province && province.find((item: ProvinceType) => item.external_code === parseInt(mailing_address?.address_province));

                    const mailing_district = district.find((item: DistrictType) => item.name_th === mailing_address?.address_district);
                    const mailing_sub_district = subDistricts.find((item: SubDistrictType) => item.name_th === mailing_address?.address_sub_district);

                    const mailing_address_data = {
                        house_number: mailing_address?.address_house_number,
                        moo: mailing_address?.address_moo,
                        lane: mailing_address?.address_lane,
                        street: mailing_address?.address_street,
                        province: i18n.language === 'th' ? mailing_province?.label_th : mailing_province?.label_en,
                        district: i18n.language === 'th' ? mailing_district?.name_th : mailing_district?.name_en,
                        sub_district: i18n.language === 'th' ? mailing_sub_district?.name_th : mailing_sub_district?.name_en,
                        postal_code: mailing_address?.address_postal_code
                    }

                    setAddress((prevState: any) => {
                        return {
                            ...prevState,
                            mailing_address_data: mailing_address_data
                        }
                    })
                }
            }
        }

    }, [appFormData, province, district, subDistricts, i18n.language]);

    const mapNameCompany = (companyCode: string)=> {

        const data: CompanyInter =  companyList.find((item:CompanyInter) =>  companyCode === item.company_code )!;
        const newCompanyFilter: string = data ? `${data?.company_name}`: companyCode
        return (newCompanyFilter)
    }
    const mapSites = (site_id: string) => {
      const data: any =  sitesData.find((item:any) =>  site_id === item.site_id )!;
      const siteText: string = data ? `${data?.site_name}`: site_id
      return (siteText)
  }

  const debugBase64 = (base64URL: string)=> {
    let win = window.open();
    win?.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

    const disableBtn = (idStatus:number)=> {
    let checkStatus :boolean =false
    if(appFormData?.Status?.id === 1){
        checkStatus = idStatus === 7 || idStatus === 8 ? true :false
    }else if(appFormData?.Status?.id === 2){
        checkStatus = idStatus === 3 || idStatus === 4 || idStatus === 5 ? false : true
    }else if(appFormData?.Status?.id === 3){
        checkStatus = idStatus === 2 || idStatus === 5 || idStatus === 4 ? false : true
    }else if(appFormData?.Status?.id === 4){
        checkStatus = idStatus === 7 || idStatus === 8 ? false : true
    }else if(appFormData?.Status?.id === 5){
        checkStatus = idStatus === 2 || idStatus === 3 || idStatus === 4 ? false : true
    }
    else if(appFormData?.Status?.id === 7){ 
        checkStatus = idStatus === 8 ? false : true  
    }
    return checkStatus
}

const mapPrefix = (prefix: string, lang:string) => {
        const data: any = prefix && prefixList.find((item:any) =>  prefix === item.external_code )!;
        const defaultPrefix = data && lang === 'TH' ? 
        data.label
        : prefix === 'Mr' || prefix === 'Miss' || prefix === 'Mrs' ?
        `${PREFIX[prefix!]} ` 
        : ''
    return defaultPrefix
}


    useEffect(() => {
        const fetchStatus = async () => {
            const response = await getAllStatus();

            if (response.data?.success) {
                setStatusData(response.data?.status);
            }
        };

        fetchStatus();
    }, []);

    useEffect(() => {
        const calculateAge = async (date_of_birth:Date) => {
            const birth = new Date(date_of_birth);
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            let age = now.getFullYear() - birth.getFullYear();
            const monthDiff = now.getMonth() - birth.getMonth();
            const dateDiff = now.getDate() - birth.getDate();
            let monthCalcul = monthDiff;

        // If the current date hasn't occurred yet this year, subtract 1 from the age.
        if (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)) {
            age--;
        }

        if (isNaN(age)) {
            return '-';
        }

        if (monthDiff < 0 ) {
            monthCalcul = 12 + monthDiff
        }

        setMonthAge(monthCalcul)
        setAge(age)

        };

        calculateAge(appFormData?.AppFormData.date_of_birth!);
    }, [appFormData]);

    function changeStatus() {
        const parsedToken: any = jwtDecode(localStorage.access_token);
        // `expires=${date.toUTCString()};`
        Swal.fire({
            title: `${t('Select Status')}`,
            html:
                `<label class="changeorange-container status-action1 " ><input id="consider" type="checkbox" name="check" ${disableBtn(statusData[1]?.id || 0) === true ? 'disabled' : ''} /><span class="orange ${disableBtn(statusData[1]?.id || 0) === true ? ' disabled-btn' : ''}">${statusData[1].show_on_hr }</span></label>` +
                `<label class="changeorange-container status-action1" ><input id="interview" type="checkbox" name="check" ${disableBtn(statusData[2]?.id || 0) === true ? 'disabled' :' '} /><span class="orange ${disableBtn(statusData[2]?.id || 0) === true ? ' disabled-btn' : ''}">${statusData[2].show_on_hr}</span></label>` +
                `<label class="changered-container" ><input id="fail" type="checkbox" name="check"  ${disableBtn(statusData[4]?.id || 0) === true ? 'disabled' :' '} /><span class="red ${disableBtn(statusData[4]?.id || 0) === true ? ' disabled-btn' : ''}" >${statusData[4].show_on_hr}</span></label>` +
                `<label class="changegreen-container" ><input id="pass" type="checkbox" name="check" ${disableBtn(statusData[3]?.id || 0) === true ? 'disabled' :' '} /><span class="green ${disableBtn(statusData[3]?.id || 0) === true ? ' disabled-btn' : ''}">${statusData[3].show_on_hr}</span></label>` +
                `<label class="changeorange-container" ><input id="worked" type="checkbox" name="check" ${disableBtn(statusData[6]?.id || 0) === true ? 'disabled' :' '} /><span class="orange ${disableBtn(statusData[6]?.id || 0) === true ? ' disabled-btn' : ''}">${statusData[6].show_on_hr}</span></label>` +
                `<label class="changeorange-container" ><input id="deleted" type="checkbox" name="check" ${disableBtn(statusData[7]?.id || 0) === true ? 'disabled' :' '} /><span class="red ${disableBtn(statusData[7]?.id || 0) === true ? ' disabled-btn' : ''}">${statusData[7].show_on_hr}</span></label>` +
                '<label class="changered-container" ><input type="checkbox" name="check" /></label>',
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`,
            didOpen: () => {
                var consider = document.getElementById('consider') as HTMLInputElement
                var interview = document.getElementById('interview') as HTMLInputElement
                var worked  = document.getElementById('worked') as HTMLInputElement
                var pass = document.getElementById('pass') as HTMLInputElement
                var fail = document.getElementById('fail') as HTMLInputElement
                var deleted = document.getElementById('deleted') as HTMLInputElement
                var groupCheck = Array.from(document.getElementsByName('check'))

                consider.addEventListener("input", function () {
                    Swal.resetValidationMessage()
                    if (consider.checked) {
                        groupCheck.forEach(element => {
                            consider.checked = true;
                            (element as HTMLInputElement).checked = false;
                        })
                    }
                })

                interview.addEventListener("input", function () {
                    Swal.resetValidationMessage()
                    if (interview.checked) {
                        groupCheck.forEach(element => {
                            interview.checked = true;
                            (element as HTMLInputElement).checked = false;
                        })
                    }
                })

                worked.addEventListener("input", function () {
                    Swal.resetValidationMessage()
                    if (worked.checked) {
                        groupCheck.forEach(element => {
                            worked.checked = true;
                            (element as HTMLInputElement).checked = false;
                        })
                    }
                })

                pass.addEventListener("input", async () => {
                    let id = appFormData && appFormData?.id  ? appFormData.id :''
                    const response = await checkUserEC(id)
                      if (response?.data?.success) {
                        const company = response?.data && response?.data?.data[0]
                        ? mapNameCompany(response?.data?.data[0].company) :''

                        const site = response?.data && response?.data?.data[0] 
                        ? mapSites(response?.data?.data[0].site_id) :''

                            Swal.resetValidationMessage()
                            Swal.fire({
                                title: "ไม่สามารถแก้ไขสถานะ!",
                                text: `ผู้สมัครยังเป็นพนักงาน  ${company} ${site}`,
                                icon: "error"
                              });
                    }else{
                        Swal.resetValidationMessage()
                        if (pass.checked) {
                            groupCheck.forEach(element => {
                            pass.checked = true;
                            (element as HTMLInputElement).checked = false;
                        })
                    }
                    }
                });

                fail.addEventListener("input", function () {
                    Swal.resetValidationMessage()
                    if (fail.checked) {
                        groupCheck.forEach(element => {
                            fail.checked = true;
                            (element as HTMLInputElement).checked = false;
                        })
                    }
                })

                deleted.addEventListener("input", function () {
                    Swal.resetValidationMessage()
                    if (deleted.checked) {
                        groupCheck.forEach(element => {
                            deleted.checked = true;
                            (element as HTMLInputElement).checked = false;
                        })
                    }
                })
            },
            preConfirm: () => {
                var checkConsider = (document.getElementById('consider') as HTMLInputElement).checked
                var checkInterview = (document.getElementById('interview') as HTMLInputElement).checked
                var checkWorked = (document.getElementById('worked') as HTMLInputElement).checked
                var checkPass = (document.getElementById('pass') as HTMLInputElement).checked
                var checkFail = (document.getElementById('fail') as HTMLInputElement).checked
                var checkDeleteUser = (document.getElementById('deleted') as HTMLInputElement).checked

                if (!checkConsider && !checkInterview && !checkFail && !checkPass && !checkWorked && !checkDeleteUser) {
                    Swal.showValidationMessage(`${t('Status is required')}`)
                }

                if (checkConsider === true) {
                    Swal.fire({
                        icon: 'warning',
                        title: `${t('Are you sure?')}`,
                        html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-bd1fyz-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[1].show_on_hr}</span></div> <br>${t('Please check the information before change status')}`,
                        showCancelButton: true,
                        confirmButtonColor: '#43A047',
                        cancelButtonText: `${t('Cancel')}`,
                        confirmButtonText: `${t('Confirm')}`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const changeStatusFunction = async () => {
                                try {
                                    const data = {
                                        app_form_status_id: id,
                                        status_id: 2,
                                        modified_by: parsedToken.Username
                                    }

                                    const response = await updateAppFormStatusById(data);

                                    if (response.data?.success) {
                                        setAppFormData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                Status: {
                                                    id: 2,
                                                    show_on_hr: `${statusData[1].show_on_hr}`,
                                                    color: `${statusData[1].color}`
                                                }
                                            }
                                        })
                                        Swal.fire({
                                            icon: 'success',
                                            title: `${t('Update status successful!')}`,
                                            confirmButtonColor: '#43A047',
                                            confirmButtonText: 'OK!',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                        })
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: `${t('Update status failed!')}`,
                                            confirmButtonColor: '#43A047',
                                            confirmButtonText: 'OK!',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                        }).then(() => {
                                            changeStatus();
                                        })
                                    }
                                } catch (error) {
                                    navigate('/servererror', { replace: true });
                                }
                            }
                            changeStatusFunction();
                        }
                        else {
                            changeStatus();
                        }
                    })
                }

                if (checkInterview === true) {
                    setOpen(true)
                    setCheckInterview(true)
                }

                if (checkPass === true) {
                    setOpenPass(true)
                }

                if(checkWorked === true){
                                Swal.fire({
                                    icon: 'warning',
                                    title: `${t('Are you sure?')}`,
                                    html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-1i5jzc9-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[6].show_on_hr}</span></div> <br>${t('Please check the information before change status')}`,
                                    showCancelButton: true,
                                    confirmButtonColor: '#43A047',
                                    cancelButtonText: `${t('Cancel')}`,
                                    confirmButtonText: `${t('Confirm')}`,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        const changeStatusFunction = async () => {
                                            try {
                                                const data = {
                                                    app_form_status_id: id,
                                                    status_id: 7,
                                                    modified_by: parsedToken.Username
                                                }
            
                                                const response = await updateAppFormStatusById(data);
            
                                                if (response.data?.success) {
                                                    setAppFormData((prevState: any) => {
                                                        return {
                                                            ...prevState,
                                                            Status: {
                                                                id: 7,
                                                                show_on_hr: `${statusData[6].show_on_hr}`,
                                                                color: `${statusData[6].color}`
                                                            }
                                                        }
                                                    })
            
                                                    Swal.fire({
                                                        icon: 'success',
                                                        title: `${t('Update status successful!')}`,
                                                        confirmButtonColor: '#43A047',
                                                        confirmButtonText: 'OK!',
                                                        allowOutsideClick: false,
                                                        allowEscapeKey: false,
                                                    })
                                                } else {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: `${t('Update status failed!')}`,
                                                        confirmButtonColor: '#43A047',
                                                        confirmButtonText: 'OK!',
                                                        allowOutsideClick: false,
                                                        allowEscapeKey: false,
                                                    }).then(() => {
                                                        changeStatus();
                                                    })
                                                }
                                            } catch (error) {
                                                navigate('/servererror', { replace: true });
                                            }
                                        }
            
                                        changeStatusFunction();
                                    } else {
                                        changeStatus();
                                    }
                                })
                }

                if (checkFail === true) {
                    Swal.fire({
                        icon: 'warning',
                        title: `${t('Are you sure?')}`,
                        html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-1i5jzc9-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[4].show_on_hr}</span></div> <br>${t('Please check the information before change status')}`,
                        showCancelButton: true,
                        confirmButtonColor: '#43A047',
                        cancelButtonText: `${t('Cancel')}`,
                        confirmButtonText: `${t('Confirm')}`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const changeStatusFunction = async () => {
                                try {
                                    const data = {
                                        app_form_status_id: id,
                                        status_id: statusData[4].id,
                                        modified_by: parsedToken.Username
                                    }

                                    const response = await updateAppFormStatusById(data);

                                    if (response.data?.success) {
                                        setAppFormData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                Status: {
                                                    id: 5,
                                                    show_on_hr: `${statusData[4].show_on_hr}`,
                                                    color: `${statusData[4].color}`
                                                }
                                            }
                                        })

                                        Swal.fire({
                                            icon: 'success',
                                            title: `${t('Update status successful!')}`,
                                            confirmButtonColor: '#43A047',
                                            confirmButtonText: 'OK!',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                        })
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: `${t('Update status failed!')}`,
                                            confirmButtonColor: '#43A047',
                                            confirmButtonText: 'OK!',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                        }).then(() => {
                                            changeStatus();
                                        })
                                    }
                                } catch (error) {
                                    navigate('/servererror', { replace: true });
                                }
                            }

                            changeStatusFunction();
                        } else {
                            changeStatus();
                        }
                    })
                }

                if(checkDeleteUser === true){
                               
                    Swal.fire({
                        icon: 'warning',
                        title: `${t('Are you sure?')}`,
                        html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-1i5jzc9-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[7].show_on_hr}</span></div> <br>${t('Please check the information before change status')}`,
                        showCancelButton: true,
                        confirmButtonColor: '#43A047',
                        cancelButtonText: `${t('Cancel')}`,
                        confirmButtonText: `${t('Confirm')}`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const changeStatusFunction = async () => {
                                try {
                                    const data = {
                                        app_form_status_id: id,
                                        status_id: 8,
                                        modified_by: parsedToken.Username
                                    }
                                    const Status_id = id ? id?.toString() : ''
                                    const response = await updateAppFormStatusById(data);
                                    const statusResp = await UpdateStatusEC(Status_id)
                                    if (response.data?.success && statusResp.data.success === true) {
                                        setAppFormData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                Status: {
                                                    id: 8,
                                                    show_on_hr: `${statusData[7].show_on_hr}`,
                                                    color: `${statusData[7].color}`
                                                }
                                            }
                                        })

                                        Swal.fire({
                                            icon: 'success',
                                            title: `${t('Update status successful!')}`,
                                            confirmButtonColor: '#43A047',
                                            confirmButtonText: 'OK!',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                        })
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: `${t('Update status failed!')}`,
                                            confirmButtonColor: '#43A047',
                                            confirmButtonText: 'OK!',
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                        }).then(() => {
                                            changeStatus();
                                        })
                                    }
                                } catch (error) {
                                    navigate('/servererror', { replace: true });
                                }
                            }

                            changeStatusFunction();
                        } else {
                            changeStatus();
                        }
                    })
    }
            }
        })
    }

    function handleNewAppointment() {
        const parsedToken: any = jwtDecode(localStorage.access_token);
        setOpen(false)
        setCheckInterview(false)

        Swal.fire({
            icon: 'warning',
            title: `${t('Are you sure?')}`,
            html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-bd1fyz-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[2].show_on_hr}</span></div> <br>${t('The date is on')} ` + dateData.format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB') + `&nbsp${t('InterviewTimeAt')} ` + new Date(timeData).toLocaleString(i18n.language).split(' ')[1].split(':')[0] + ':' + new Date(timeData).toLocaleString(i18n.language).split(' ')[1].split(':')[1] + `<br>${t('Please check the information before change status')}`,
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    const changeStatusFunction = async () => {
                        try {
                            const data = {
                                app_form_status_id: id,
                                status_id: statusData[2].id,
                                modified_by: parsedToken.Username,
                                interview_date: dateData?.format('YYYY-MM-DD') + " " + timeData?.format('HH:mm')
                            }

                            const response = await updateAppFormStatusById(data);

                            if (response.data?.success) {
                                setInterviewDate(new Date(dateData?.format('YYYY-MM-DD') + " " + timeData?.format('HH:mm')))

                                setAppFormData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        Status: {
                                            id: 3,
                                            show_on_hr: `${statusData[2].show_on_hr}`,
                                            color: `${statusData[2].color}`
                                        },
                                        Interview: [
                                            {
                                                interview_date: dateData?.format('YYYY-MM-DD') + " " + timeData?.format('HH:mm'),
                                                interview_by: parsedToken.Username
                                            }
                                        ]
                                    }
                                })

                                Swal.fire({
                                    icon: 'success',
                                    title: `${t('Update status successful!')}`,
                                    confirmButtonColor: '#43A047',
                                    confirmButtonText: 'OK!',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                })
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: `${t('Update status failed!')}`,
                                    confirmButtonColor: '#43A047',
                                    confirmButtonText: 'OK!',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                }).then(() => {
                                    changeStatus();
                                })
                            }
                        } catch (error) {
                            navigate('/servererror', { replace: true });
                        }
                    }
                    changeStatusFunction();
                } else {
                    setOpen(true)
                }
            })

    }

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    }

    const handleSubmitComment = async () => {
        const parsedToken: any = jwtDecode(localStorage.access_token);

        const data = {
            app_form_status_id: id,
            comment: comment,
            modified_by: parsedToken.Username
        }

        const response = await updateAppFormCommentById(data);

        if (response.data?.success) {
            setEditCommentClicked(false);
            setComment(comment);

            Swal.fire({
                icon: 'success',
                title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                confirmButtonColor: '#43A047',
                confirmButtonText: 'OK!',
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
        }
    }

    const handleStatusFile = (type: string) => {
        return   type === 'ID_CARD' 
                 ? "text_types_file_idcard"
                 :type === 'HOUSE_REGISTRATION' 
                 ? "text_types_file_houseRegistration"
                 : type === 'BANK_ACCOUNT'
                 ? "text_types_file_bankAccount"
                 : type === 'FORMAL_PHOTO'
                 ? "text_types_file_formalPhoto"
                 : type === 'EMPLOYMENT_REFERENCE'
                 ? "text_types_file_Ref"
                 : ''
      };

    return <div>
        {
            allSuccessLoading ?
                <>
                    <InputDataToDC
                        app_form_status_id={[id!]}
                        keepMounted
                        open={openPass}
                        onClose={() => setOpenPass(false)}
                    />
                    <Modal
                        open={open}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={{ ...style, width: 400 }}>
                            {(checkInterview) ?
                                (<Box display='flex' justifyContent='center' sx={{ p: 2 }}>
                                    <Typography variant="h5">{t("Interviewing")}</Typography>
                                </Box>)
                                : (<Box display='flex' justifyContent='center' sx={{ p: 2 }}>
                                    <Typography variant="h5">{t("Interview postponed")}</Typography>
                                </Box>)}
                            <Stack spacing={3} direction="row" display='flex' justifyContent='center' alignItems='center' sx={{ p: 1 }}>
                                <Typography>{t("Date")}</Typography>
                                <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th'>
                                    <MobileDatePicker
                                        format="DD/MM/YYYY"
                                        localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
                                        value={dateData}
                                        dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
                                        disablePast
                                        {...register("date", { required: true })}
                                        onChange={(newValue) => {
                                            try {
                                                if (newValue !== null) {
                                                    setDateData(newValue)
                                                    setDateErrorState(false)
                                                }
                                            } catch (error) { }
                                        }}
                                        slotProps={{
                                            textField: () => ({
                                                color: dateErrorState === true ? 'error' : 'info',
                                                focused: dateErrorState === true ? true : false,
                                            }),
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            {(dateErrorState) ?
                                (<Typography variant="inherit" color="error" align="right" ml={1} pb={1} pr={1} fontSize={13}>
                                    {t("Date is requried")}
                                </Typography>)
                                : null
                            }

                            <Stack spacing={2} direction="row" display='flex' justifyContent='center' alignItems='center' sx={{ p: 1 }}>
                                <Typography>{t("Time")}</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimeField
                                        value={timeData}
                                        format="HH:mm"
                                        onChange={(newValue) => {
                                            setTimeData(newValue)
                                            setTimeErrorState(false)
                                        }}
                                        slotProps={{
                                            textField: () => ({
                                                color: timeErrorState === true ? 'error' : 'info',
                                                focused: timeErrorState === true ? true : false,
                                            }),
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            {(timeErrorState) ?
                                (<Typography variant="inherit" color="error" align="right" ml={1} pb={1} pr={1} fontSize={13}>
                                    {t("Time is requried")}
                                </Typography>)
                                : null
                            }

                            <Stack spacing={2} direction="row" display='flex' justifyContent='center' sx={{ p: 1, pt: 3 }}>
                                <Button variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        if (dateData === null && timeData === null) {
                                            setDateErrorState(true)
                                            setTimeErrorState(true)
                                        } else
                                            if (dateData === null) {
                                                setDateErrorState(true)
                                            } else
                                                if (timeData === null) {
                                                    setTimeErrorState(true)
                                                } else {
                                                    handleNewAppointment()
                                                    setDateErrorState(false)
                                                    setTimeErrorState(false)
                                                }
                                    }}
                                >
                                    {t("Confirm")}
                                </Button>
                                <Button variant="contained"
                                    disableElevation
                                    sx={{
                                        backgroundColor: '#797979',
                                        "&:hover": {
                                            backgroundColor: '#585858',
                                            color: 'white',
                                        },
                                    }}
                                    onClick={() => {
                                        if (checkInterview) {
                                            handleClose()
                                            changeStatus()
                                        } else {
                                            handleClose()
                                        }
                                    }}
                                >
                                    {t("Cancel")}
                                </Button>
                            </Stack>
                        </Box>
                    </Modal>

                    <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                        <Box display='flex' gap={3} sx={{ mb: 3 }}>
                            <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                                textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                    color: '#1976d2',
                                }
                            }}>
                                <ChevronLeftIcon fontSize='small' />
                                <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                            </Box>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    component={ReactRouterLink}
                                    to="/admin"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    {t("Dashboard")}
                                </Link>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    component={ReactRouterLink}
                                    to={`/admin/application-form`}
                                >
                                    <AssignmentTurnInIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    {t("SubmittedForm")}
                                </Link>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    {t("JobAppDetail")}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Typography gutterBottom variant="h5" component="h5" sx={{ fontSize: { xs: 18, sm: 25 }, fontWeight: 'bold' }}>
                            {t("JobAppDetail")}
                        </Typography>
                        <Chip
                            variant="outlined"
                            sx={{
                                bgcolor: appFormData?.Status.color,
                                borderColor: appFormData?.Status.color,
                                color: 'white',
                                my: 1.5
                            }}
                            size='medium'
                            label={appFormData?.Status.show_on_hr}
                        />
                    </Box>

                    <Box sx={{ m: 2, mt: 1 }}>
                        {(appFormData?.Status.id === 3 && appFormData.Interview[0].interview_date < new Date()) ?
                            (<Typography variant="subtitle2" sx={{ display: 'inline-flex', alignItems: 'center', mb: 2 }}>
                                <WarningAmberIcon sx={{ color: '#FFA726', mr: 1 }} />
                                {t('This application is past the interview deadline. Please change status to continue.')}
                            </Typography>)
                            : null}
                        <Paper variant="outlined" sx={{ mb: 2 }}>
                            <Grid container rowSpacing={1} paddingY={3} paddingX={5}>
                                <Grid item xs={12} sm={12} display='flex' gap={4}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Apply Date")}</Typography>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{new Date(appFormData?.created_date!).toLocaleString(i18n.language)}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} display='flex' gap={4} mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Start date of work")}</Typography>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{new Date(appFormData?.AppFormData.start_date!).toLocaleString(i18n.language).split(' ')[0]}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} display='flex' gap={4} mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Site")}</Typography>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.Job?.Site?.site_name}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography fontWeight='bold' sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Personal Information")}</Typography>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Full name TH")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>
                                            {mapPrefix(appFormData?.AppFormData.prefix!, 'TH')}{appFormData?.AppFormData.first_name_th || '-'} {appFormData?.AppFormData.last_name_th || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Full name EN")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>
                                            {mapPrefix(appFormData?.AppFormData.prefix!, 'EN')} 
                                            {appFormData?.AppFormData.first_name_en || '-'} {appFormData?.AppFormData.last_name_en || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Nationality")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t(NATIONALITY[appFormData?.AppFormData.nationality!])}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Religion")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t(RELIGION[appFormData?.AppFormData.religion!])}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("MaritalStatus")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t(MARITAL_STATUS[appFormData?.AppFormData.marital_status!])}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Gender")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t(GENDER[appFormData?.AppFormData.gender!])}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Weight")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.weight || '-'} {t('kg')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Height")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.height || '-'} {t('cm')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("text_age")} :</Typography>
                                        <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{`${age} ${t('years old') || '-'}   ${monthAge} ${t('month old') || '-'}`}</Typography>
                                            {/* <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{new Date(appFormData?.AppFormData.date_of_birth!).toLocaleDateString(i18n.language) || '-'}</Typography> */}
                                            {/* <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{calculateAge(appFormData?.AppFormData.date_of_birth!)} {t('years old') || '-'}</Typography>
                                            <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{calculateAge(appFormData?.AppFormData.date_of_birth!)} {t('years old') || '-'}</Typography> */}
                                            {/* <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3, ml: 1 }}>{month} {t('years old') || '-'}</Typography> */}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("ID card number")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.id_card_number || '-'}</Typography>
                                    </Grid>
                                </Box>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Contact Information")}</Typography>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Phone Number")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.telephone || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Email")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.email || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Line ID")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.line_id || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Person to notify in case of emergency")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.emergency_name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Phone number of person to call in case of emergency")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.emergency_phone || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Relationship")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.emergency_relation || '-'}</Typography>
                                    </Grid>
                                </Box>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Education Information")}</Typography>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Education Level")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.education_level || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("School")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.education_school || '-'}</Typography>
                                    </Grid>
                                </Box>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Military Status")}</Typography>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Military Status")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.military_status}</Typography>
                                    </Grid>
                                </Box>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Work Experience")}</Typography>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'coloumn' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("Work Experience")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.AppFormData.work_experience || '-'}</Typography>
                                    </Grid>
                                </Box>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }} fontWeight='bold'>{t("Legal Address")}</Typography>
                                </Grid>
                                <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3, ml: 3, mt: 1 }}>{t("House No.")} {address.legal_address_data.house_number || '-'}, {t("Moo")} {address.legal_address_data.moo || '-'}, {t("Lane")} {address.legal_address_data.lane || '-'}, {t("Street")} {address.legal_address_data.street || '-'}, {t("Sub District")} {address.legal_address_data.sub_district || '-'}, {t("District")} {address.legal_address_data.district || '-'}, {t("Province")} {address.legal_address_data.province || '-'}, {t("Postal Code")} {address.legal_address_data.postal_code || '-'}</Typography>

                                <Grid item xs={12} sm={12} display='flex' mt={1}>
                                    <Typography fontWeight='bold'>{t("Mailing Address")}</Typography>
                                </Grid>
                                {
                                    appFormData?.AppFormData.same_address === false ?
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3, ml: 3, mt: 1 }}>{t("House No.")} {address.mailing_address_data.house_number || '-'}, {t("Moo")} {address.mailing_address_data.moo || '-'}, {t("Lane")} {address.mailing_address_data.lane || '-'}, {t("Street")} {address.mailing_address_data.street || '-'}, {t("Sub District")} {address.mailing_address_data.sub_district || '-'}, {t("District")} {address.mailing_address_data.district || '-'}, {t("Province")} {address.mailing_address_data.province || '-'}, {t("Postal Code")} {address.mailing_address_data.postal_code || '-'}</Typography>
                                        :
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3, ml: 3, mt: 1 }}>{t("Same as legal address")}</Typography>
                                }

                                <Grid item xs={12} sm={12} display='flex' mt={1} sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0.5, sm: 2 } }}>
                                    <Typography fontWeight='bold'>{t("Job Information")}</Typography>
                                    <Link underline="none" href={`/admin/jobs/detail/${appFormData?.Job.id}`} target="_blank" rel="noopener" sx={{ display: 'flex', gap: 1, color: 'blue', ':hover': { cursor: 'pointer', }, alignItems: 'center', ml: 1 }}><Icon fontSize="small" color="secondary" sx={{ ml: 1 }}>launch</Icon> {t("View Job Description")}</Link>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("job position applied")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.Job.job_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("location")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{appFormData?.Job.Site.site_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t("start date")} :</Typography>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{new Date(appFormData?.AppFormData.start_date!).toLocaleDateString(i18n.language)}</Typography>
                                    </Grid>
                                </Box>
                                <Grid item xs={12} sm={12} display='flex' mt={1} sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0.5, sm: 2 } }}>
                                    <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                                        {t("file attached") + " : "}
                                     </Typography>
                                </Grid>
                                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                              
                                {filesBaseData?.length > 0 ? filesBaseData.map((item:any, index) => {
                                    return <div key={index}>
                                           <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                                <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t(`${handleStatusFile(item?.type)}`)} :</Typography>
                                                <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>
                                                <a  onClick={() => { debugBase64(item.dataPath)}}  target="_blank" rel="noreferrer">{item.name}</a>
                                                </Typography>
                                            </Grid>
                                          </div>
                                     }
                                ):
                                (documentsRefs?.length < 1 ? (
                                        <div>{t("text_documents_refs")} </div>
                                    )
                                    :( <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                        <CircularProgress size={20} sx={{ mr: 2 }} />
                                    </Grid>)
                                )
                                  
                                  }
                                
                                </Box>
                                

                            </Grid>
                        </Paper>

                        <Box display='flex' sx={{ alignItems: 'center', gap: 4 }}>
                            <Box display='flex' gap={2} sx={{ alignItems: 'center' }}>
                                <Typography fontWeight='bold'>{t("Status")}</Typography>
                                <Chip variant="outlined" sx={{
                                    bgcolor: appFormData?.Status.color,
                                    borderColor: appFormData?.Status.color,
                                    color: 'white',
                                    my: 1.5
                                }} size='medium' label={appFormData?.Status.show_on_hr} />
                            </Box>
                            <div>
                            {appFormData?.Status.id !== 8 && appFormData?.Status.id !== 6  &&
                                (<Tooltip title={t("Edit Status")} onClick={changeStatus}> 
                                    <Box display='flex' gap={1}>
                                        <Icon fontSize="medium" color="secondary" sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                            }
                                        }}>edit</Icon>
                                    <Typography sx={{ fontSize: { xs: 14, sm: 16 }, color: 'blue', ':hover': { cursor: 'pointer', } }}>{t("Edit Status")}</Typography>
                                    </Box>
                                </Tooltip>
                                )}
                            </div>
                            {/* <Tooltip title={t("Edit Status")} onClick={changeStatus}> 
                                <Box display='flex' gap={1}>
                                    <Icon fontSize="medium" color="secondary" sx={{
                                        ':hover': {
                                            cursor: 'pointer',
                                        }
                                    }}>edit</Icon>
                                    <Typography sx={{ fontSize: { xs: 14, sm: 16 }, color: 'blue', ':hover': { cursor: 'pointer', } }}>{t("Edit Status")}</Typography>
                                </Box>
                            </Tooltip> */}
                        </Box>

                        {(appFormData?.Status.id === 3 && interviewDate) ?
                            (<Box display='flex' sx={{ alignItems: 'center', mb: 3, mt: 1 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={3} md={2} sx={{ mr: 3 }}>
                                        <Typography fontWeight='bold'>{t("Schedule an interview appointment")}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1.8}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 16 }, letterSpacing: 0.3 }}>
                                            {t("date")}  {new Date(interviewDate).toLocaleString(i18n.language).split(' ')[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={2} md={1.2}>
                                        <Typography sx={{ fontSize: { xs: 15, sm: 16 }, letterSpacing: 0.3 }}>
                                            {t("time")}  {new Date(interviewDate).toLocaleString(i18n.language).split(' ')[1].split(':')[0] + ':' + new Date(interviewDate).toLocaleString(i18n.language).split(' ')[1].split(':')[1]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            sx={{ height: 35, fontSize: { xs: 13, sm: 14 } }}
                                            onClick={() => {
                                                setOpen(true)
                                                setCheckInterview(false)
                                            }}
                                        >
                                            {t("Interview postponed")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>)
                            : null}

                        <Box display='flex' sx={{ alignItems: 'center', gap: 3, mt: 1.5 }}>
                            <Box display='flex' gap={1} sx={{ alignItems: 'center' }}>
                                <CommentIcon />
                                <Typography fontWeight='bold'>{t("Comment")}</Typography>
                            </Box>
                            {
                                !editCommentClicked ?
                                    (
                                        <Tooltip title={t("Edit Comment")} onClick={() => setEditCommentClicked(true)}>
                                            <Box display='flex' gap={1}>
                                                <Icon fontSize="medium" color="secondary" sx={{
                                                    ':hover': {
                                                        cursor: 'pointer',
                                                    }
                                                }}>edit</Icon>
                                                <Typography sx={{ fontSize: { xs: 14, sm: 16 }, color: 'blue', ':hover': { cursor: 'pointer', } }}>{t("Edit Comment")}</Typography>
                                            </Box>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            <Tooltip title={t("Save")} onClick={() => {
                                                handleSubmitComment();
                                            }}>
                                                <Box display='flex' gap={1}>
                                                    <Icon fontSize="medium" sx={{
                                                        color: '#43A047',
                                                        ':hover': {
                                                            cursor: 'pointer',
                                                        }
                                                    }}>save</Icon>
                                                    <Typography sx={{ color: '#43A047', ':hover': { cursor: 'pointer', } }}>{t("Save")}</Typography>
                                                </Box>
                                            </Tooltip><Tooltip title={t("Cancel")} onClick={() => setEditCommentClicked(false)}>
                                                <Box display='flex' gap={1}>
                                                    <Icon fontSize="medium" sx={{
                                                        color: '#F44336',
                                                        ':hover': {
                                                            cursor: 'pointer',
                                                        }
                                                    }}>cancel</Icon>
                                                    <Typography sx={{ color: '#F44336', ':hover': { cursor: 'pointer', } }}>{t("Cancel")}</Typography>
                                                </Box>
                                            </Tooltip>
                                        </>
                                    )
                            }
                        </Box>
                        {
                            editCommentClicked ? <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth
                                placeholder={t("Comment")!}
                                sx={{ mt: 1.5 }}
                                value={comment}
                                onChange={handleCommentChange}
                            /> :
                                <Typography sx={{ mt: 1.5, }}>{comment || '-'}</Typography>
                        }
                    </Box>
                </>
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                    <CircularProgress />
                </Box>
        }
    </div>;
};

export default JobAppDetail;
