// React
import React, { useEffect, useState } from 'react'
import {
    Link as ReactRouterLink,
    useNavigate,
    useSearchParams
} from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// MUI Icons
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Utils
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import jwtDecode from 'jwt-decode';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import { green } from '@mui/material/colors';

// Types
import RoleType from '../../../@types/RoleType';
import { Role, TokenPayload } from '../../../@types';

// Services
import { getAllRoles } from '../../../services/RoleService';
import { createUser } from '../../../services/UserService';


const CreateUser = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const userRoleType = useAppSelector((state) => state.user.roletype);

    const havePermission = userRoleType === RoleType.Super_Admin;

    const [prefix, setPrefix] = useState<string>('');
    const [first_name_th, setFirstnameTH] = useState<string>('');
    const [last_name_th, setLastnameTH] = useState<string>('');
    const [first_name_en, setFirstnameEN] = useState<string>('');
    const [last_name_en, setLastnameEN] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [selectedRole, setSelectedRole] = useState<Role | null>(null);
    const [addUserType, setAddUserType] = useState<string>('');
    const [roleData, setRoleData] = useState<Role[]>([]);

    const [createLoading, setCreateLoading] = useState<boolean>(false);
    const [loadingRole, setLoadingRole] = useState<boolean>(true);

    useEffect(() => {
        if (!havePermission) {
            navigate('/forbidden', { replace: true });
            return;
        }

        try {
            if (searchParams.has('type')) {
                const type = searchParams.get('type')
                if (type === 'hr') {
                    setAddUserType(RoleType.HR_Recruiter)
                } else if (type === 'superadmin') {
                    setAddUserType(RoleType.Super_Admin)
                } else {
                    navigate('/admin/masterdata/users?type=member', { replace: true });
                }
            } else {
                navigate('/admin/masterdata/users?type=member', { replace: true });
            }
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }, [searchParams]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await getAllRoles();

                if (response.data?.success) {
                    const rolesData = response.data?.roles;
                    const activeRole = rolesData.filter((role: Role) => role.is_active === true)
                    setRoleData(activeRole);
                    setLoadingRole(false);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        fetchRoles();
    }, []);

    const handlePrefixChange = (event: SelectChangeEvent) => {
        try {
            setPrefix(event.target.value as string);
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setActive(event.target.checked);
        } catch (error) {
            navigate('/servererror', { replace: true });
        }
    };

    const formValidationSchema = yup.object().shape({
        first_name_en: yup.string().required(`${t("First Name is required")}`),
        last_name_en: yup.string().required(`${t("Last Name is required")}`),
        first_name_th: yup.string(),
        last_name_th: yup.string(),
        email: yup.string().required(`${t("Email is required")}`).email(`${t("Please enter Valid email")}`),
        selectedRole: addUserType === RoleType.HR_Recruiter || addUserType === RoleType.Super_Admin ? yup.string().required(`${t("Role is required")}`) : yup.string().notRequired(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formValidationSchema)
    });

    const onSubmit = () => {
        const postData = async () => {
            try {
                const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);

                const newData = {
                    prefix,
                    first_name_en: first_name_en,
                    last_name_en: last_name_en,
                    first_name_th: first_name_th,
                    last_name_th: last_name_th,
                    email,
                    active,
                    selectedRole,
                    createdBy: parsedToken.Username
                }

                setCreateLoading(true);

                const data = {
                    prefix: newData.prefix.length === 0 ? null : newData.prefix,
                    first_name_th: newData.first_name_th.length === 0 ? null : newData.first_name_th,
                    last_name_th: newData.last_name_th.length === 0 ? null : newData.last_name_th,
                    first_name_en: newData.first_name_en.length === 0 ? null : newData.first_name_en,
                    last_name_en: newData.last_name_en.length === 0 ? null : newData.last_name_en,
                    email: newData.email.length === 0 ? null : newData.email,
                    is_active: newData.active,
                    role_id: newData.selectedRole?.role_id,
                    created_by: newData.createdBy.length === 0 ? null : newData.createdBy,
                }

                const response = await createUser(data);

                if (response) {
                    setCreateLoading(false);
                    if (!response.data?.success) {
                        toast.error(i18n.language === 'th' ? response.data?.message_th : response.data?.message_en, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else if (response.data?.success) {
                        Swal.fire({
                            title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#43A047',
                            confirmButtonText: 'OK!',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.assign(`/admin/masterdata/users?type=${(newData.selectedRole?.role_type === RoleType.HR_Recruiter ? 'hr' : 'superadmin')}`);
                            }
                        })
                    }
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        postData();

    };

    return (
        <>
            <ToastContainer style={{ width: "400px" }} />

            {
                havePermission ?
                    <>
                        <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                            <Box display='flex' gap={3} sx={{ mb: 3 }}>
                                <Box display='flex' component={ReactRouterLink} to={'/admin/masterdata/users?type=' + (addUserType === RoleType.HR_Recruiter ? 'hr' : addUserType === RoleType.Super_Admin ? 'superadmin' : 'member')} sx={{
                                    textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                        color: '#1976d2',
                                    }
                                }}>
                                    <ChevronLeftIcon fontSize='small' />
                                    <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                                </Box>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        component={ReactRouterLink}
                                        to="/admin"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        {t("Dashboard")}
                                    </Link>
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        component={ReactRouterLink}
                                        to={'/admin/masterdata/users?type=' + (addUserType === RoleType.HR_Recruiter ? 'hr' : addUserType === RoleType.Super_Admin ? 'superadmin' : 'member')}
                                    >
                                        <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        {t("UserAccount")}
                                    </Link>
                                    <Typography
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="text.primary"
                                    >
                                        <AddCircleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        {t("Create New")} {addUserType}
                                    </Typography>
                                </Breadcrumbs>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                                {t("Create New")} {addUserType}
                            </Typography>
                        </Box>

                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="prefix">{t("Prefix")}</InputLabel>
                                        <Select
                                            labelId="prefix"
                                            label={t("Prefix")}
                                            id="prefix"
                                            value={prefix}
                                            onChange={handlePrefixChange}
                                        >
                                            {/* Fix prefix */}
                                            <MenuItem value='Mr'>{t("Mr")}</MenuItem>
                                            <MenuItem value='Mrs'>{t("Mrs")}</MenuItem>
                                            <MenuItem value='Miss'>{t("Miss")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={5} >
                                    <TextField
                                        fullWidth
                                        required={true}
                                        id="firstNameEN"
                                        label={t('English name')}
                                        placeholder={t('English name')!}
                                        {...register('first_name_en')}
                                        error={errors.first_name_en ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFirstnameEN(event.target.value);
                                            errors.first_name_en = undefined
                                        }}
                                    />
                                    <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                        {errors.first_name_en?.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        fullWidth
                                        required={true}
                                        id="lastNameEN"
                                        label={t('English last name')}
                                        placeholder={t('English last name')!}
                                        {...register('last_name_en')}
                                        error={errors.last_name_en ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLastnameEN(event.target.value);
                                            errors.last_name_en = undefined
                                        }}
                                    />
                                    <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                        {errors.last_name_en?.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        id="firstName"
                                        label={t('Thai name')}
                                        placeholder={t('Thai name')!}
                                        {...register('first_name_th')}
                                        error={errors.first_name_th ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFirstnameTH(event.target.value);
                                            errors.first_name_th = undefined
                                        }}
                                    />
                                    <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                        {errors.first_name_th?.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        id="lastName"
                                        label={t('Thai last name')}
                                        placeholder={t('Thai last name')!}
                                        {...register('last_name_th')}
                                        error={errors.last_name_th ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLastnameTH(event.target.value);
                                            errors.last_name_th = undefined
                                        }}
                                    />
                                    <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                        {errors.last_name_th?.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={t("Betagro Email")}
                                        placeholder={t("Betagro Email")!}
                                        id="email"
                                        {...register('email')}
                                        error={errors.email ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setEmail(event.target.value);
                                            errors.email = undefined
                                        }}
                                    />
                                    <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                        {errors.email?.message}
                                    </Typography>
                                </Grid>
                                {
                                    addUserType === RoleType.HR_Recruiter || addUserType === RoleType.Super_Admin ?
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        onChange={(event, newValue: Role | null) => {
                                                            setSelectedRole(newValue as Role);
                                                            errors.selectedRole = undefined
                                                        }}
                                                        limitTags={2}
                                                        id="role"
                                                        options={roleData.filter(role => role.role_type === addUserType)}
                                                        isOptionEqualToValue={(option: Role, value: Role) => option.role_id === value.role_id}
                                                        getOptionLabel={(option) => option.role_name}
                                                        groupBy={(option) => option.role_type}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option.role_name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label={loadingRole ? `${t("Loading")}...` : t("Role Name")}
                                                                placeholder={t("Role Name")!}
                                                                error={errors.selectedRole ? true : false}
                                                                {...register('selectedRole')}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {loadingRole ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }} />
                                                        )}
                                                        loading={loadingRole}
                                                        loadingText={`${t("Loading")}...`}
                                                    />
                                                </FormControl>
                                                <Typography variant="inherit" color="error" align="left" pt={1} fontSize={13}>
                                                    {errors.selectedRole?.message}
                                                </Typography>
                                            </Grid>
                                        </> : null
                                }
                                <Grid item xs={8}>
                                    <FormControlLabel
                                        sx={{ paddingTop: 1 }}
                                        label={t("Active")}
                                        control={
                                            <Checkbox
                                                checked={active}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'Active' }}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 5, mb: 2, color: 'white' }}
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={createLoading}
                                    >
                                        {t("Create")}
                                        {createLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: green[500],
                                                    position: 'absolute',
                                                    zIndex: 999,
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 5, mb: 2 }}
                                        component={ReactRouterLink}
                                        to={`/admin/masterdata/users?type=${addUserType === RoleType.HR_Recruiter ? 'hr' : 'superadmin'}`}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </> : null
            }
        </>
    )
}

export default CreateUser