// React
import { useState } from "react";
import { useNavigate, } from "react-router-dom";

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { ThemeProvider } from "@mui/material";

// Styles
import theme from "../../../styles/theme";

// Utils
import { useTranslation } from "react-i18next";

export interface ConfirmationDialogPDPAProps {
    id: string;
    keepMounted: boolean;
    open: boolean;
    onClose: (value?: boolean) => void;
    onCancel?: () => void;
}

const styleFormat = {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: { xs: 14, sm: 16 },
    mb: 2
}

export default function ConfirmationDialogPDPA(props: ConfirmationDialogPDPAProps) {
    const { onClose,onCancel, open, ...other } = props;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [pdpaChecked, setPdpaChecked] = useState<boolean>(false);

    const handleAccept = () => {
        onClose(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%', } }}
                maxWidth="md"
                open={open}
                {...other}
            >
                <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 'bold', fontSize: { xs: 16, sm: 18 } }}>
                    ประกาศคำอนุญาตเกี่ยวกับความเป็นส่วนตัวของข้อมูล
                </DialogTitle>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { xs: 14, sm: 16 }, display: 'flex', justifyContent: 'center', my: 3 }}>
                        หนังสือให้ความยินยอมในการเก็บและใช้ข้อมูล
                    </Typography>
                    <Typography sx={styleFormat}>
                        เรียน ผู้สมัครงาน/ผู้สมัครฝึกงาน
                    </Typography>
                    <Typography sx={styleFormat}>
                        เพื่อให้บริษัท เบทาโกร จำกัด (มหาชน) (“บริษัทฯ”) และบริษัทภายในเครือเบทาโกรตามรายชื่อที่ปรากฏในใบสมัครงาน / ใบสมัครฝึกงาน
                        (“บริษัทในเครือ”) ปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 บริษัทฯ
                        ใคร่ขอความยินยอมจากท่านในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่าน สำหรับวัตถุประสงค์ดังต่อไปนี้
                    </Typography>
                    <Typography sx={styleFormat}>
                        1. วัตถุประสงค์สำหรับข้อมูลส่วนบุคคลทั่วไป
                    </Typography>
                    <Typography sx={styleFormat}>
                        เก็บและใช้ข้อมูลส่วนบุคคลที่ปรากฏตามใบสมัครและหลักฐานการสมัครงาน / สมัครฝึกงานของท่านต่อไปอีกเป็นเวลา 3 เดือน
                        เพื่อเสนองานตำแหน่งอื่นที่บริษัทฯ และบริษัทในเครือเห็นว่าอาจเหมาะสมสำหรับคุณสมบัติของท่านต่อไป
                        ในกรณีทีท่านไม่ได้เข้าร่วมงานกับบริษัทฯ และบริษัทในเครือ
                    </Typography>
                    <Typography sx={styleFormat}>
                        2. วัตถุประสงค์สำหรับข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                    </Typography>
                    <Typography sx={styleFormat}>
                        เก็บและใช้ข้อมูลที่มีความอ่อนไหว เช่น ศาสนา เชื้อชาติ ข้อมูลสุขภาพ และประวัติอาชญากรรม
                        ที่ปรากฎตามใบสมัครและหลักฐานการสมัครงาน / สมัครฝึกงานของท่าน เพื่อ
                    </Typography>
                    <Typography sx={styleFormat}>
                        2.1 ตรวจสอบประวัติและพิจารณารับเข้าทำงาน / ฝึกงาน รวมทั้งเพื่อดำเนินการใด ๆ ในการจ้างงาน / รับเข้าฝึกงาน
                        ในกรณีที่บริษัทฯ หรือบริษัทในเครือรับท่านเข้าทำงาน / ฝึกงาน
                    </Typography>
                    <Typography sx={styleFormat}>
                        2.2 เพื่อเสนองานตำแหน่งอื่นที่บริษัทฯ และบริษัทในเครือเห็นว่าอาจเหมาะสมสำหรับคุณสมบัติของท่านต่อไป
                        ในกรณีทีท่านไม่ได้เข้าทำงาน / ฝึกงานกับบริษัทฯ และบริษัทในเครือ
                    </Typography>
                    <Typography sx={styleFormat}>
                        รายละเอียดเพิ่มเติมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ และบริษัทในเครือ ให้เป็นไปตามนโยบายความเป็นส่วนตัว (Privacy Policy)
                        ที่ประกาศในเว็บไซต์บริษัทฯ หรือฝ่ายทรัพยากรบุคคลต้นสังกัด
                        ทั้งนี้ หากท่านประสงค์จะเพิกถอนความยินยอมที่ท่านให้ไว้นี้ ท่านสามารถแจ้งบริษัทฯ หรือบริษัทในเครือที่เกี่ยวข้องกับการจ้างงาน /
                        การรับเข้าฝึกงานของท่านได้ที่ DPOoffice@betagro.com
                    </Typography>
                    <Typography sx={styleFormat}>
                        ข้าพเจ้าได้อ่านและเข้าใจวัตถุประสงค์เกี่ยวกับการเก็บและใช้ข้อมูลส่วนบุคคลข้างต้นแล้ว รวมทั้งนโยบายความเป็นส่วนตัว (Privacy Policy) ตามเอกสารแนบอย่างชัดเจนแล้ว
                        โดยข้าพเจ้ายินยอมให้เก็บและใช้ข้อมูลส่วนบุคคลของข้าพเจ้าภายใต้วัตถุประสงค์ดังกล่าว
                    </Typography>
                    <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                        <Checkbox onChange={() => setPdpaChecked(!pdpaChecked)} />
                        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }}>
                            {t("I agree to the privacy policy") + " *"}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        color="error"
                        onClick={onCancel}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={pdpaChecked ? false : true}
                        onClick={handleAccept}
                    >
                        {t("Confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

