// React
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

// MUI Components
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Switch, { SwitchProps } from '@mui/material/Switch';

// MUI Icons
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import PeopleIcon from '@mui/icons-material/People';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';

// Utils
import jwtDecode from 'jwt-decode';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

// Styles
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

// Types
import { Job, TokenPayload } from '../../../@types';

// Services
import { editJobById } from '../../../services/JobService';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface JobCardProps {
  jobData: Job
}

const JobCard: React.FC<JobCardProps> = ({ jobData }) => {
  //Translation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const path = location.pathname.replace(/\/$/, '');

  const [active, setActive] = useState(jobData.is_active)

  const monthTH: string[] = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  const monthEN: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [delayChecked, setDelayChecked] = React.useState(false);

  const format_startdate = (): string => {
    let date_of_format: string = '';
    const date: string = new Date(jobData.start_date).getDate().toString();
    const month: number = new Date(jobData.start_date).getMonth();
    const year: number = new Date(jobData.start_date).getFullYear();
    let date_of_birth: string = "";
    i18n.language === 'th' ?
      date_of_birth = date + ' ' + monthTH[month] + ' ' + (year + 543) :
      date_of_birth = date + ' ' + monthEN[month] + ' ' + year
    date_of_format = date_of_birth
    return date_of_format
  }

  const format_enddate = (): string => {
    let date_of_format: string = '';
    const date: string = new Date(jobData.end_date).getDate().toString();
    const month: number = new Date(jobData.end_date).getMonth();
    const year: number = new Date(jobData.end_date).getFullYear();
    let date_of_birth: string = "";
    i18n.language === 'th' ?
      date_of_birth = date + ' ' + monthTH[month] + ' ' + (year + 543) :
      date_of_birth = date + ' ' + monthEN[month] + ' ' + year
    date_of_format = date_of_birth
    return date_of_format
  }

  const handleChangeActive = (_: React.ChangeEvent<HTMLInputElement>) => {
    const changeActive = async () => {
      const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);

      if (!delayChecked) {
        const data = {
          job_name: jobData.job_name,
          job_description: jobData.job_description,
          site_id: jobData.Site?.site_id,
          is_active: !active,
          start_date: jobData.start_date,
          end_date: jobData.end_date,
          num_openings: jobData.num_openings,
          modified_by: parsedToken.Username
        }
        const response = await editJobById(jobData.id, data)
        setDelayChecked(true)

        setTimeout(() => {
          setDelayChecked(false)
        }, 3000);

        if (response.data?.success) {
          setActive(!active)
          if (!active) {
            toast.success(t("Job is now active."), {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success(t("Job is now inactive."), {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      } else {
        toast.error(t("Operation too frequent, please wait a moment."), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
    }

    changeActive();
  };

  return (
    <>
      <ThemeProvider
        theme={createTheme({
          breakpoints: {
            values: {
              xs: 320,
              sm: 1280,
              md: 1920,
              lg: 0,
              xl: 3840,
            },
          },
          typography: {
            fontFamily: 'Sarabun, sans-serif',
            // Additional typography styles if needed
          },
        })}
      >
        <Grid item xs={12} sm={4} md={3}>
          <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 1, borderRadius: 3, boxShadow: 4, }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Box display='grid' gridTemplateColumns='auto fit-content(100%)'>
                <Typography gutterBottom variant="h6" component="h6">
                  {jobData.job_name}
                </Typography>
                {
                  path === '/admin/jobs/history' ? null :
                    <IOSSwitch sx={{ m: 1 }} checked={active} onChange={handleChangeActive} />
                }
              </Box>
              <Grid
                display='grid'
                gridTemplateColumns='fit-content(100%) auto'
                alignItems="center"
                sx={{ mt: 1, mb: 1 }}
              >
                <DomainRoundedIcon fontSize='small' sx={{ color: '#FF9824', gridColumn: 1, alignSelf: 'flex-start' }}/>
                <Typography variant="body2" component="div" sx={{ ml: 1, gridColumn: 2 }}>
                  {jobData.company}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ mt: 1, mb: 1 }}
              >
                <LocationOnRoundedIcon fontSize='small' sx={{ color: '#FF9824' }} />
                <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                  {jobData.Site?.site_name}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <InsertInvitationIcon fontSize='small' sx={{ color: '#4CAF50' }} />
                <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                  {format_startdate()} - {format_enddate()}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <PeopleIcon fontSize='small' sx={{ color: '#04A9F4' }} />
                <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                  {jobData.num_openings} {t("Openings")}
                </Typography>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent={path === '/admin/jobs/history' ? "flex-end" : "space-between"}
                alignItems="center"
              >
                {
                  path === '/admin/jobs/history' ?
                    <Button variant="contained"
                      component={Link}
                      to={`/admin/jobs/detail/${jobData.id}?content_type=history&display=grid`}
                      endIcon={<ArrowForwardIosRoundedIcon />}
                    >
                      {t("View More")}
                    </Button>
                    : <>
                      <Button variant="outlined"
                        component={Link}
                        to={`/admin/jobs/edit/${jobData.id}`}
                      >
                        {t("Modify")}
                      </Button>
                      <Button variant="contained"
                        component={Link}
                        to={`/admin/jobs/detail/${jobData.id}`}
                        endIcon={<ArrowForwardIosRoundedIcon />}
                      >
                        {t("View More")}
                      </Button>
                    </>
                }
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </ThemeProvider>
    </>
  )
}

export default JobCard