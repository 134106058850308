import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { Collapse, Link, ListItemButton, ListItemIcon, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as ReactRouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Icon from '@mui/material/Icon';
import { fetchUser } from '../../redux/slices/userSlice';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';
import { getMenuOfRole } from '../../services/MenuService';

const drawerWidth = 260;

interface RootObject {
    menu_id: string;
    menu_name: string;
    parent_id?: any;
    icon?: any;
    children: Child[];
    is_active: boolean;
}

interface Child {
    children_id: string;
    menu_name: string;
    route_name?: string;
    icon: string;
    is_active: boolean;
}

const AdminSidebar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const { t } = useTranslation();
    const path = location.pathname.replace(/\/$/, '');
    const pathParts = path.split("/");
    const dispatch = useAppDispatch();

    const [opened, setOpened] = useState<{ [key: string]: boolean }>({});
    const [hovered, setHovered] = useState<{ [key: string]: boolean }>({});
    const [activeMenu, setActiveMenu] = useState<{ [key: string]: boolean }>({});
    const [menusData, setMenusData] = useState<RootObject[]>([])
    const { roleid } = useAppSelector(state => state.user)

    useEffect(() => {
        if (menusData.length > 0) {
            menusData.forEach((menu) => {
                if (menu.children.length > 0) {
                    menu.children.forEach((child) => {
                        if ('/admin/' + child.route_name?.split('?')[0] === path) {
                            handleActiveMenu(child.menu_name);
                        }
                    });
                }

            });
        }
    }, [menusData, navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await dispatch(fetchUser());

            if (userData.payload) {
                if (userData.payload.role_id !== jwtDecode<any>(localStorage.getItem('access_token')!).RoleId) {

                    Swal.fire({
                        title: `${t('Role Changed')}`,
                        text: `${t('Your role has been changed. Please re-login again')}`,
                        icon: 'warning',
                        confirmButtonText: `${t('ok')}`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showCancelButton: false,
                        showCloseButton: false,
                        showDenyButton: false,
                    }).then((result) => {
                        localStorage.removeItem('access_token');

                        if (result.isConfirmed) {
                            window.location.href = '/login';
                            return;
                        }
                    });
                }
            }
        };

        fetchUserData();
    }, [navigate]);


    const handleMouseEnter = (hoverName: string) => {
        setHovered((prevHover) => ({
            ...prevHover,
            [hoverName]: true,
        }));
    };

    const handleMouseLeave = (hoverName: string) => {
        setHovered((prevHover) => ({
            ...prevHover,
            [hoverName]: false,
        }));
    };

    const toggleOpen = (menuName: string) => {
        setOpened((prevOpened) => ({
            ...prevOpened,
            [menuName]: !prevOpened[menuName],
        }));
    };

    const handleActiveMenu = (menuName: string) => {
        // set all previous active menu to false
        Object.keys(activeMenu).forEach((key) => {
            activeMenu[key] = false;
        });

        // set current menu to true
        setActiveMenu((prevActive) => ({
            ...prevActive,
            [menuName]: true,
        }));
    };

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const data = {
                    roleId: roleid
                }

                const response = await getMenuOfRole(data);

                if (response.data?.success) {
                    setMenusData(response.data?.menus);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        fetchMenus();
    }, [])

    useEffect(() => {
        if (pathParts[2] === 'masterdata') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'SystemAdministration': true,
            }));
        } else if (pathParts[2] === 'jobs') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'JobHiring': true,
            }));
        } else if (pathParts[2] === 'application-form') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'ApplicationForm': true,
            }));
        } else if (pathParts[2] === 'interview') {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'Interview': true,
            }));
        } else if (pathParts[2] === 'dashboard' || pathParts[2] === undefined) {
            setOpened((prevOpened) => ({
                ...prevOpened,
                'Dashboard': true,
            }));

            // set all previous active menu to false
            Object.keys(activeMenu).forEach((key) => {
                activeMenu[key] = false;
            });

            // set active current menu to true
            setActiveMenu((prevActive) => ({
                ...prevActive,
                'Dashboard': true,
            }));
        }

    }, [navigate])

    // set default active menu based on path
    useEffect(() => {
        if (pathParts[3] !== undefined) {
            handleActiveMenu(pathParts[3].toLowerCase());
        }
    }, []);

    return (
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: "none", sm: "block" },

                width: drawerWidth,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                zIndex: '1'
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto', paddingX: 2 }}>
                <List>
                    {
                        menusData.length > 0 && menusData.map((menu, index) => (
                            <div key={index}>
                                <Box onClick={() => toggleOpen(menu.menu_name)} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '220px' }}>
                                    <Typography variant="h5" sx={{ fontSize: '12px', marginLeft: 2, marginTop: 3, marginBottom: 1, fontWeight: 'bold', userSelect: 'none', textTransform: 'uppercase' }} >
                                        {t(menu.menu_name)}
                                    </Typography>
                                    <Box>
                                        {opened[menu.menu_name] ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                                    </Box>
                                </Box>
                                <Collapse in={opened[menu.menu_name]} timeout="auto" unmountOnExit>
                                    {
                                        menu.children.length > 0 ? (
                                            <>
                                                {
                                                    menu.children.map((child, childIndex) => (
                                                        <Link key={childIndex}
                                                            component={ReactRouterLink}
                                                            to={`${child.route_name}`}
                                                            color="inherit"
                                                            underline="none"
                                                            onMouseEnter={() => handleMouseEnter(child.menu_name)}
                                                            onMouseLeave={() => handleMouseLeave(child.menu_name)}
                                                            onClick={() => handleActiveMenu(child.menu_name)}
                                                        >
                                                            <ListItemButton sx={{ pl: 2, marginBottom: 0.5, borderRadius: 1, backgroundColor: activeMenu[child.menu_name] ? theme.palette.primary.main : 'inherit', "&:hover": { backgroundColor: theme.palette.primary.main } }}>
                                                                <ListItemIcon sx={{ minWidth: 0 }}>
                                                                    {
                                                                        <Icon sx={{ color: hovered[child.menu_name] || activeMenu[child.menu_name] ? 'white' : 'inherit' }}>{child.icon}</Icon>
                                                                    }
                                                                </ListItemIcon>
                                                                <Typography sx={{ pl: 1, fontSize: 16, color: hovered[child.menu_name] || activeMenu[child.menu_name] ? 'white' : 'inherit' }}>
                                                                    {t(child.menu_name)}
                                                                </Typography>
                                                            </ListItemButton>
                                                        </Link>
                                                    ))
                                                }
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Collapse>
                            </div>
                        ))
                    }
                </List>
            </Box>
        </Drawer>
    )
}

export default AdminSidebar