import { TOKEN, apiUrl } from "../Constants";
import api from "./ApiService";

const BASE_URL = apiUrl + '/api/status';

export function getAllStatus() {
    return api.get(`${BASE_URL}`);
}

export function getStatusById(id: string) {
    return api.get(`${BASE_URL}/${id}`);
}

export function createStatus(data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.post(`${BASE_URL}`, data, config);
}

export function editStatus(id: string, data: any) {
    const config = {
        headers: { Authorization: `Bearer ${TOKEN}` }
    };
    
    return api.put(`${BASE_URL}/${id}`, data, config);
}