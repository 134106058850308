// React
import React, { useEffect, useState } from "react";
import {
    Link as ReactRouterLink,
    useSearchParams,
    useNavigate
} from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// MUI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HistoryIcon from '@mui/icons-material/History';

import {
    LocalizationProvider,
    TimeField,
    MobileDatePicker
} from "@mui/x-date-pickers";

// Utils
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToastContainer, toast } from 'react-toastify';
import dayjs, { Dayjs } from "dayjs";
import Swal from 'sweetalert2';
import jwtDecode from "jwt-decode";
import newAdapter from "../../../utils/newAdapter";
import 'dayjs/locale/en-gb';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import { green } from "@mui/material/colors";

// Types
import { Site, TokenPayload } from "../../../@types";

// Services
import { getAllSites } from "../../../services/SiteService";
import {
    createJob,
    getJobById,
    getLatestJobCreateByUsername
} from "../../../services/JobService";
import { getAllActiveCompany } from "../../../services/MasterDataService";

interface JobTopic {
    job_title?: string;
    job_detail?: string;
};

interface FormValues {
    job_name: string;
    site: Site | null;
    num_openings: number;
    company: string;
    contract_signer: string;
    hr_name: string;
    hr_email: string;
    hr_phone: string;
    start_date: Dayjs | null;
    start_time: Dayjs | null;
    end_date: Dayjs | null;
    end_time: Dayjs | null;
    job_description?: JobTopic[];
};

const JobCreate: React.FC = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const copy_id = searchParams.get('copy');
    const { rolesite } = useAppSelector((state) => state.user);

    const [siteData, setSiteData] = useState<Site[]>([]);
    const [companyList, setCompanyList] = useState<any[]>([]);
    const [jobTopicfields_check, setJobTopicfields_check] = useState<boolean>(false)
    const [oldJobName, setOldJobName] = useState<string>("");
    const [formData, setFormData] = useState<FormValues>({
        job_name: "",
        site: null,
        num_openings: 0,
        company: "",
        contract_signer: "",
        hr_name: "",
        hr_email: "",
        hr_phone: "",
        start_date: dayjs(),
        start_time: dayjs(),
        end_date: dayjs(),
        end_time: dayjs(),
        job_description: [{ job_title: "", job_detail: "" }]
    });
    const [jobLoading, setJobLoading] = useState<boolean>(true);
    const [siteLoading, setSiteLoading] = useState<boolean>(true);
    const [jobTopicAlert, setJobTopicAlert] = useState<boolean>(false)
    const [createLoading, setCreateLoading] = useState<boolean>(false);
    const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(true);

    const allSuccessLoading = !jobLoading && !siteLoading && !loadingCompanyList;

    let isEndDateViolated = false;
    let isMinTimeViolated = false;
    let isStartDateInPast = false;
    let minTime;
    try {
        minTime = formData.start_date?.toISOString().split("T")[0] === formData.end_date?.toISOString().split("T")[0]
            ? formData.start_time
            : undefined;

        isStartDateInPast = dayjs(formData.start_date).isBefore(dayjs(), 'day');
        isEndDateViolated = dayjs(formData.end_date).isBefore(dayjs(formData.start_date), 'day');
        isMinTimeViolated = minTime !== undefined && dayjs(formData.end_time, 'HH:mm').isBefore(dayjs(minTime, 'HH:mm'));
    } catch (error) { }


    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data?.success) {
                    const siteData = response.data?.sites;
                    const filteredSiteData = siteData.filter((site: Site) => rolesite.includes(site.site_id));
                    setSiteData(filteredSiteData);
                }

            } catch (error) {
                navigate('/servererror', { replace: true });
            } finally {
                setSiteLoading(false);
            }
        };

        const fetchAllActiveCompany = async () => {
            try {
                const response = await getAllActiveCompany();

                if (response.data?.success) {
                    setCompanyList(response.data.companies);
                }
            } catch (error) {
                console.log(error);
                navigate('/servererror', { replace: true });
            } finally {
                setLoadingCompanyList(false);
            }
        }

        fetchSites();
        fetchAllActiveCompany();
    }, []);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await getJobById(copy_id);
                const jobData = response.data?.job;

                if (response) {
                    if (!response.data?.success) {
                        Swal.fire({
                            title: i18n.language === 'th' ? response.data.message_th : response.data.message_en,
                            icon: 'error',
                            confirmButtonColor: '#43A047',
                            confirmButtonText: `${t("OK")}`,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/admin/jobs', { replace: true });
                            }
                        });

                        return;
                    }

                    const job_description = JSON.parse(jobData.job_description).map((job: JobTopic) => {
                        return { job_title: job.job_title, job_detail: job.job_detail }
                    })

                    setFormData({
                        ...formData,
                        job_name: jobData.job_name,
                        site: jobData.Site,
                        num_openings: parseInt(jobData.num_openings),
                        company: jobData.company,
                        contract_signer: jobData.contract_signer,
                        hr_name: jobData.hr_name,
                        hr_email: jobData.hr_email,
                        hr_phone: jobData.hr_phone,
                        start_date: dayjs(jobData.start_date),
                        start_time: dayjs(jobData.start_date),
                        end_date: dayjs(jobData.end_date),
                        end_time: dayjs(jobData.end_date),
                        job_description: job_description
                    });

                    setOldJobName(jobData.job_name);
                    setSuccessLatestJob(true);
                } else {
                    navigate('/admin/jobs', { replace: true });
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            } finally {
                setJobLoading(false);
            }
        };

        if (copy_id) {
            fetchJob();
        } else {
            setFormData({
                job_name: "",
                site: null,
                num_openings: 0,
                company: "",
                contract_signer: "",
                hr_name: "",
                hr_email: "",
                hr_phone: "",
                start_date: dayjs(),
                start_time: dayjs(),
                end_date: dayjs(),
                end_time: dayjs(),
                job_description: [{ job_title: "", job_detail: "" }]
            });

            setJobLoading(false);
        }
    }, [searchParams]);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const jobTopic_validate = (): boolean => {
        let isValid = false;
        formData.job_description?.forEach(data => {
            if (data.job_title?.length === 0 || data.job_detail?.length === 0) {
                setJobTopicfields_check(true);
                isValid = true;
            }
        })
        return isValid
    }

    const onSubmit = () => {
        const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);

        /* validation of site must be NOT null. */
        if (formData.site === null) {
            toast.error(`${t("Site is required")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        /* Checking Alert to add job topic */
        if (formData.job_description?.length === 0) {
            setJobTopicAlert(true);
            toast.error(`${t("Please add a detailed job description")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (jobTopic_validate()) {
            toast.error(`${t("Job description is required. please fill in all fields.")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (formData.start_time === null) {
            toast.error(`${t("Start time is requried")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }
        else if (formData.end_time === null) {
            toast.error(`${t("End time is requried")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (isStartDateInPast) {
            toast.error(`${t("Start date must be at least today")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (isEndDateViolated) {
            toast.error(`${t("End date must be after start date")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        if (isMinTimeViolated) {
            toast.error(`${t("End time must be after start time")}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return null;
        }

        /* Prepare the data correctly for sending */
        const startTimeDate = new Date(formData.start_date?.format('YYYY-MM-DD') + "T" + formData.start_time.format('HH:mm:ss'));
        startTimeDate.setSeconds(0);
        const endTimeDate = new Date(formData.end_date?.format('YYYY-MM-DD') + "T" + formData.end_time.format('HH:mm:ss'));
        endTimeDate.setSeconds(0);
        const factory: string = formData.site?.site_id as string;

        /* Sending data.. */
        setCreateLoading(true);
        const createJobFunc = async () => {
            try {
                const data = {
                    job_name: formData.job_name,
                    num_openings: formData.num_openings,
                    site_id: factory,
                    company: formData.company,
                    contract_signer: formData.contract_signer,
                    start_date: startTimeDate,
                    end_date: endTimeDate,
                    hr_name: formData.hr_name,
                    hr_email: formData.hr_email,
                    hr_phone: formData.hr_phone,
                    job_description: JSON.stringify(formData.job_description),
                    created_by: parsedToken.Username
                }

                const response = await createJob(data);

                if (response.data?.success) {
                    setCreateLoading(false);
                    if (!response.data?.success) {
                        toast.error(i18n.language === 'th' ? response.data.message_th : response.data.message_en, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else if (response.data?.success) {
                        Swal.fire({
                            title: i18n.language === 'th' ? response.data.message_th : response.data.message_en,
                            text: `${t("Do you want to create a new job?")}`,
                            icon: 'success',
                            confirmButtonColor: '#43A047',
                            confirmButtonText: `${t("Yes")}`,
                            showDenyButton: true,
                            denyButtonColor: '#F44336',
                            denyButtonText: `${t("No")}`,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.assign('/admin/jobs/create');
                            } else {
                                navigate('/admin/jobs', { replace: true });
                            }
                        });
                    }
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        createJobFunc();
    };

    const [getLatestJobClick, setGetLatestJobClick] = useState(false);
    const [successLatestJob, setSuccessLatestJob] = useState(false);

    const handleGetLatestJobClick = () => {
        setGetLatestJobClick(true);
    }

    useEffect(() => {
        const onLastestJobClick = async () => {
            try {
                const parsedToken: TokenPayload = jwtDecode(localStorage.access_token);
                const data = {
                    created_by: parsedToken.Username
                }
                const response = await getLatestJobCreateByUsername(data);

                if (response) {
                    if (response.data?.success) {
                        if (response.data?.job) {
                            window.location.assign(`/admin/jobs/create?copy=${response.data.job.id}`);
                        } else {
                            toast.error(`${t("No latest create job data")}`, {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            return;
                        }

                        return;
                    }
                }
            } catch (error) {
                console.log(error);
                navigate('/servererror', { replace: true });
            }
        }

        if (getLatestJobClick) {
            onLastestJobClick();
        }
    }, [getLatestJobClick]);

    return (
        <div>
            <ToastContainer />

            {
                allSuccessLoading ?
                    <>
                        {
                            copy_id ?
                                <>
                                    <Box sx={{ height: 'auto', maxWidth: '100%', mt: 2 }} >
                                        <Box display='flex' gap={3} sx={{ mb: 3 }}>
                                            <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                                                textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                                                    color: '#1976d2',
                                                }
                                            }}>
                                                <ChevronLeftIcon fontSize='small' />
                                                <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
                                            </Box>
                                            <Breadcrumbs aria-label="breadcrumb">
                                                <Link
                                                    underline="hover"
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                    color="inherit"
                                                    component={ReactRouterLink}
                                                    to="/admin"
                                                >
                                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                    {t("Dashboard")}
                                                </Link>
                                                {
                                                    searchParams.get('content_type') === 'history' ? <Link
                                                        underline="hover"
                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                        color="inherit"
                                                        component={ReactRouterLink}
                                                        to="/admin/jobs/history"
                                                    >
                                                        <HistoryIcon sx={{ mr: 0.5 }} fontSize="small" />
                                                        {t("History")}
                                                    </Link> :
                                                        <Link
                                                            underline="hover"
                                                            sx={{ display: 'flex', alignItems: 'center' }}
                                                            color="inherit"
                                                            component={ReactRouterLink}
                                                            to="/admin/jobs"
                                                        >
                                                            <DescriptionIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                            {t("JobsList")}
                                                        </Link>
                                                }
                                                <Link
                                                    underline="hover"
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                    color="inherit"
                                                    component={ReactRouterLink}
                                                    to={`/admin/jobs/detail/${copy_id}`}
                                                >
                                                    <WorkIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                    {oldJobName}
                                                </Link>
                                                <Typography
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                    color="text.primary"
                                                >
                                                    <AddCircleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                    {t("Create a Job")}
                                                </Typography>
                                            </Breadcrumbs>
                                        </Box>
                                    </Box>
                                </> : null
                        }

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                                {t("Create a Job")}
                            </Typography>
                        </Box>

                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            {
                                !successLatestJob ?
                                    <Button variant="contained" color="success" startIcon={<AddCircleOutlineIcon />} onClick={handleGetLatestJobClick}>
                                        {t("Get Latest Create Job Data")}
                                    </Button>
                                    : null
                            }
                            <Typography sx={{ mt: 3, mb: 3, fontWeight: 'bold', fontSize: '17px', letterSpacing: 0.5 }}>
                                {t("Job Information")}
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        fullWidth
                                        id="job_name"
                                        label={t("Position") + " *"}
                                        placeholder={t("Position")!}
                                        {...register('job_name', { required: true })}
                                        error={errors.job_name ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, job_name: event.target.value });
                                            errors.job_name = undefined
                                        }}
                                        value={formData.job_name}
                                    />
                                    {errors.job_name?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("Position is required")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Such as")} คนขับรถส่งของ`}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Autocomplete
                                        disableClearable={copy_id || formData.site !== null ? true : false}
                                        onChange={(event, newValue: Site | null) => {
                                            errors.site = undefined
                                            setFormData({ ...formData, site: newValue });
                                        }}
                                        isOptionEqualToValue={(option, value) => option.site_id === value.site_id}
                                        limitTags={1}
                                        id="site"
                                        options={
                                            siteData
                                        }
                                        getOptionLabel={(option) => option.site_name}
                                        value={formData.site}
                                        renderOption={(props, option, { inputValue, selected }) => {
                                            const matches = match(option.site_name, inputValue, { insideWords: true });
                                            const parts = parse(option.site_name, matches);

                                            return (
                                                <li {...props}>
                                                    <div>
                                                        {parts.map((part: { highlight: boolean; text: string }, index: number) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: part.highlight ? 700 : 400,
                                                                }}
                                                            >

                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                label={t("Site") + " *"}
                                                placeholder={t("Site")!}
                                                error={errors.site ? true : false}
                                                {...register('site', copy_id ? { required: false } : { required: true })}
                                            />
                                        )}
                                    />
                                    {errors.site?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("Site is required")}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        required
                                        id="num_openings"
                                        label={t("Number of Openings")}
                                        type="number"
                                        value={formData.num_openings}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        {...register('num_openings', { required: true, pattern: /^[1-9]\d*$/ })}
                                        error={errors.num_openings ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, num_openings: parseInt(event.target.value) });
                                            errors.num_openings = undefined
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                    {errors.num_openings?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("Number of openings is required")}
                                        </Typography>
                                    }
                                    {errors.num_openings?.type === "pattern" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("Number must be greater than 0")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 4`}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="company" error={errors.company ? true : false}>{t("Company") + ' *'}</InputLabel>
                                        <Select
                                            error={errors.company ? true : false}
                                            required
                                            labelId="company"
                                            id="company"
                                            label={t("Company") + ' *'}
                                            {...register('company', { required: true })}
                                            value={companyList.length > 0 ? formData.company : ''}
                                            onChange={(event: SelectChangeEvent) => {
                                                setFormData({ ...formData, company: event.target.value as string });
                                                errors.company = undefined;
                                            }}
                                            MenuProps={{ sx: { maxHeight: 500 } }}
                                        >
                                            {
                                                companyList.length > 0 && companyList.map((item: { company_code: string, company_name: string }) => {
                                                    return <MenuItem key={item.company_code} value={item.company_name}>{`${item.company_code} - ${item.company_name}`}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    {errors.company?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("กรุณาเลือกบริษัท")}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="contract_signer"
                                        label={t("Contract Signer")}
                                        value={formData.contract_signer}
                                        placeholder={t("Contract Signer")!}
                                        {...register('contract_signer', { required: true })}
                                        error={errors.contract_signer ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, contract_signer: event.target.value });
                                            errors.contract_signer = undefined
                                        }}
                                    />
                                    {errors.contract_signer?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("Contract Signer is required")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Such as")} นางสาวสมใจ งดงาม`}</FormHelperText>
                                </Grid>
                            </Grid>

                            <Typography sx={{ mt: 5, mb: 1, fontWeight: 'bold', fontSize: '17px', letterSpacing: 0.5 }}>
                                {t("Opening Duration")}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item >
                                    <ErrorRoundedIcon fontSize='small' color="warning" />
                                </Grid>
                                <Grid item sx={{ mb: 4 }}>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {t("Verify the date and time are accurate before sending.")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th'>
                                        <MobileDatePicker
                                            sx={{ width: '100%', }}
                                            label={t("Start Date") + " *ddd"}
                                            format="DD/MM/YYYY"
                                            localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
                                            value={formData.start_date}
                                            dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
                                            disablePast
                                            {...register("start_date", { required: true })}
                                            onChange={(newValue) => {
                                                try {
                                                    if (newValue !== null) {
                                                        setFormData({ ...formData, start_date: dayjs(newValue) })
                                                        errors.start_date = undefined
                                                    }
                                                } catch (error) { }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {
                                        isStartDateInPast && (
                                            <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                                {t("Start date must be at least today")}
                                            </Typography>
                                        )
                                    }
                                    {errors.start_date?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("Start Date is requried")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{t("Input as number ( Date / Month / Year B.C. )")}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeField
                                            label={t("Time Start") + " *"}
                                            value={dayjs(formData.start_time)}
                                            onChange={(Stime) => setFormData((prevData) => ({ ...prevData, start_time: Stime }))}
                                            format="HH:mm"
                                            sx={{ width: '100%' }}
                                        />
                                    </LocalizationProvider>
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 12:30`}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th'>
                                        <MobileDatePicker
                                            sx={{ width: '100%', }}
                                            label={t("End Date") + " *"}
                                            format="DD/MM/YYYY"
                                            localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
                                            value={formData.end_date}
                                            dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
                                            disablePast
                                            {...register("end_date", { required: true })}
                                            onChange={(newValue) => {
                                                try {
                                                    if (newValue !== null) {
                                                        setFormData({ ...formData, end_date: dayjs(newValue) })
                                                        errors.end_date = undefined
                                                    }
                                                } catch (error) { }
                                            }}
                                            minDate={dayjs(formData.start_date)}
                                        />
                                    </LocalizationProvider>
                                    {
                                        isEndDateViolated && (
                                            <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                                {t("End date must be after start date")}
                                            </Typography>
                                        )
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{t("Input as number ( Date / Month / Year B.C. )")}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeField
                                            label={t("Time End") + " *"}
                                            value={dayjs(formData.end_time)}
                                            onChange={(Etime) => setFormData((prevData) => ({ ...prevData, end_time: Etime }))}
                                            format="HH:mm"
                                            sx={{ width: '100%' }}
                                            minTime={minTime}
                                        />
                                    </LocalizationProvider>
                                    {isMinTimeViolated && (
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("End time must be after start time")}
                                        </Typography>
                                    )}
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 12:30`}</FormHelperText>
                                </Grid>
                            </Grid>

                            <Box>
                                <Typography sx={{ mt: 5, mb: 0, fontWeight: 'bold', fontSize: '17px', letterSpacing: 0.5 }}>
                                    {t("Job Description")}
                                </Typography>
                                {
                                    jobTopicAlert && formData.job_description?.length === 0 &&
                                    <Alert severity="error" color="error" sx={{ mt: 2, py: 2 }}>
                                        {t("Please add a detailed job description")}
                                    </Alert>
                                }
                                {formData.job_description?.map((job, index) => {
                                    return <div key={index}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} sm={10} sx={{ mt: 2 }}>
                                                <TextField
                                                    fullWidth
                                                    id="jobTitle"
                                                    label={t("Topic") + " *"}
                                                    value={job.job_title}
                                                    sx={{ mt: 2 }}
                                                    error={jobTopicfields_check && job.job_title?.length === 0 ? true : false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFormData({
                                                            ...formData,
                                                            job_description: formData.job_description!.map((job, i) => {
                                                                if (i === index) {
                                                                    return { ...job, job_title: event.target.value };
                                                                } else {
                                                                    return job;
                                                                }
                                                            })
                                                        });
                                                    }}
                                                />
                                                {jobTopicfields_check && job.job_title?.length === 0 &&
                                                    <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                                        {t("Please specify a topic")}
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={2} sm={2}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    height: 80,
                                                    mt: 4,
                                                }}
                                            >
                                                <Button
                                                    color="error"
                                                    startIcon={<DeleteRoundedIcon />}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            job_description: formData.job_description!.filter((_, i) => i !== index)
                                                        });
                                                    }}
                                                    size="large"
                                                    sx={{ p: 2, display: { xs: "none", sm: "flex" } }}
                                                >
                                                    {t("Delete Topic")}
                                                </Button>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            job_description: formData.job_description!.filter((_, i) => i !== index)
                                                        });
                                                    }}
                                                    size="large"
                                                    sx={{ display: { xs: "flex", sm: "none" } }}
                                                >
                                                    <DeleteRoundedIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    id="job_detail"
                                                    multiline
                                                    placeholder={t("Please enter the job description details")! + "\n  1. \n  2."}
                                                    value={job.job_detail}
                                                    style={{ width: '100%' }}
                                                    error={jobTopicfields_check && job.job_detail?.length === 0 ? true : false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFormData({
                                                            ...formData,
                                                            job_description: formData.job_description!.map((job, i) => {
                                                                if (i === index) {
                                                                    return { ...job, job_detail: event.target.value };
                                                                } else {
                                                                    return job;
                                                                }
                                                            })
                                                        });
                                                    }}
                                                />
                                                {jobTopicfields_check && job.job_detail?.length === 0 &&
                                                    <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                                        {t("Please specify the details of the topic")}
                                                    </Typography>}
                                            </Grid>
                                        </Grid>
                                    </div>;
                                })}
                                <Grid item
                                    sx={{ display: "grid", justifyContent: "flex-start", alignItems: "center", my: 3 }}
                                >
                                    <Button
                                        variant="contained"
                                        color='info'
                                        disableElevation
                                        startIcon={<AddCircleOutlineIcon />}
                                        onClick={() => {
                                            setFormData({
                                                ...formData,
                                                job_description: [...formData.job_description!, { job_title: "", job_detail: "" }]
                                            });
                                        }}
                                        size="medium"
                                        sx={{
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#0292e1',
                                            },
                                            minWidth: 'fit-content',
                                            minHeight: '45px',
                                        }}
                                    >
                                        {t("Add Topic")}
                                    </Button>
                                </Grid>
                            </Box>

                            <Typography sx={{ mt: 5, mb: 3, fontWeight: 'bold', fontSize: '17px', letterSpacing: 0.5 }}>
                                {t("HR Recruiter's Contact Information")}
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="hr_name"
                                        label={t("HR Recruiter Name")}
                                        placeholder={t("HR Recruiter Name")!}
                                        {...register('hr_name', { required: true })}
                                        error={errors.hr_name ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, hr_name: event.target.value });
                                            errors.hr_name = undefined
                                        }}
                                        value={formData.hr_name}
                                    />
                                    {errors.hr_name?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("HR recruiter's name is required")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{t("เช่น นายใจดี งดงาม")}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="hr_email"
                                        label={t("HR Recruiter Email")}
                                        placeholder={t("HR Recruiter Email")!}
                                        {...register('hr_email', {
                                            required: true,
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                        })}
                                        error={errors.hr_email ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, hr_email: event.target.value });
                                            errors.hr_email = undefined
                                        }}
                                        value={formData.hr_email}
                                    />
                                    {errors.hr_email?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("HR recruiter's email is required")}
                                        </Typography>
                                    }
                                    {errors.hr_email?.type === "pattern" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("This field is required Email")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Such as")} email@betagro.com`}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="hr_phone"
                                        label={t("HR Recruiter Phone number")}
                                        placeholder={t("HR Recruiter Phone number")!}
                                        {...register('hr_phone', {
                                            required: true,
                                        })}
                                        error={errors.hr_phone ? true : false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, hr_phone: event.target.value });
                                            errors.hr_phone = undefined
                                        }}
                                        value={formData.hr_phone}
                                        inputProps={{ maxLength: 10 }}
                                    />
                                    {errors.hr_phone?.type === "required" &&
                                        <Typography variant="inherit" color="error" align="left" pt={1} ml={1} fontSize={13}>
                                            {t("HR recruiter's phone number is required")}
                                        </Typography>
                                    }
                                    <FormHelperText sx={{ marginLeft: 1 }}>{`${t("Input as number")} ${t("such as")} 0812345678`}</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 5, mb: 2, color: 'white' }}
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={createLoading}
                                    >
                                        {t("Create")}
                                        {createLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: green[500],
                                                    position: 'absolute',
                                                    zIndex: 999,
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    {searchParams.has('content_type') ? (
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 5, mb: 2 }}
                                            component={ReactRouterLink}
                                            to={copy_id ? `/admin/jobs/detail/${copy_id}?${searchParams.toString()}` : '/admin/jobs'}
                                        >
                                            {t("Cancel")}
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 5, mb: 2 }}
                                            component={ReactRouterLink}
                                            to={copy_id ? `/admin/jobs/detail/${copy_id}` : '/admin/jobs'}
                                        >
                                            {t("Cancel")}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </> : <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                            <CircularProgress />
                        </Box>
                    </>
            }
        </div>
    );
};

export default JobCreate;
