import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss';
import App from './App';
import "./i18n/i18n"
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ThemeProvider } from '@emotion/react';
import theme from './styles/theme'
import { datadogMonitoring } from './monitoring';
import { LicenseInfoKey } from './Constants';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(LicenseInfoKey ?? '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

datadogMonitoring();
