// @React
import React, { useEffect, useState } from 'react';

// @MUI Components
import { Box, Grid, ThemeProvider, Typography, Link, Chip, CircularProgress, Breadcrumbs, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/theme";
import dayjs, { Dayjs } from "dayjs";

// @MUI Icons
import AssignmentTurnInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

// @Utils
import { Link as ReactRouterLink, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

import { fetchDistricts, fetchSubDistricts } from "../AppFormPage/AddressInfo/fetchGeoData";
import { getAppFormById } from '../../../services/AppFormService';
import { getAllActivePrefix } from "../../../services/MasterDataService";
import ProvinceJSON from '../../../services/REGION_THA/Province.json';
import { getAllDocuments, getDocumentsfile } from "../../../services/UserService";

type MyJobAppform = {
  created_date: Dayjs | null;
  comment: string;
  AppFormData: {
    user_id:string
    prefix: string;
    first_name_th: string;
    last_name_th: string;
    first_name_en: string;
    last_name_en: string;
    gender: string;
    nationality: string;
    religion: string;
    marital_status: string;
    weight: string;
    height: string;
    date_of_birth: Date | null;
    id_card_number: string;
    telephone: string;
    email: string;
    line_id: string;
    emergency_name: string;
    emergency_phone: string;
    emergency_relation: string;
    education_level: string;
    education_school: string;
    military_status: string;
    AppFormAddress: [
      legal_address: {
        id: string;
        address_type: string;
        address_house_number: string;
        address_moo: string;
        address_lane: string;
        address_street: string;
        address_sub_district: string;
        address_district: string;
        address_province: string;
        address_postal_code: string;
      },
      mailing_address: {
        id: string;
        address_type: string;
        address_house_number: string;
        address_moo: string;
        address_lane: string;
        address_street: string;
        address_sub_district: string;
        address_district: string;
        address_province: string;
        address_postal_code: string;
      }
    ]
    same_address: boolean;
    start_date: Date | null;
    work_experience: string;
  }
  Job: {
    job_name: string;
    company: string;
    Site: {
      site_name: string;
    };
  }
  Status: {
    id: string;
    status_name: string;
    show_on_applicant: string;
    color: string;
  }
}

interface Prefix {
  [key: string]: string;
}

const  PREFIX: Prefix = {
  Miss: 'Miss',
  Mr: 'Mr.',
  Mrs: 'Mrs.',
}

interface CountryNationl {
  [key: string]: string;
}

const  NATIONALITY: CountryNationl = {
  KHM: 'Cambodia',
  LAO: 'Lao People\'s Democratic Republic',
  MMR: 'Myanmar',
  THA: 'Thailand'
}

interface MaritalStatus {
  [key: string]: string;
}

const MARITAL_STATUS: MaritalStatus = {
  D: 'Divorced',
  E: 'Separated',
  M: 'Married',
  S: 'Single',
  W: 'Widowed',
};

interface Gender {
  [key: string]: string;
}

const GENDER: Gender = {
  M: 'Male',
  F: 'Female',
};

interface Religion {
  [key: string]: string;
}

const RELIGION: Religion = {
  BDH: 'Buddhism',
  CHR: 'Christ',
  HND: 'Hindu',
  ISL: 'Islamic',
  NON: 'None Specify',
  SIK: 'Sikh',
};

const bodyStyle = {
  fontSize: { xs: 15, sm: 17 },
  letterSpacing: 0.3
}

const AppformDetail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [appFormData, setAppFromData] = useState<MyJobAppform | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [forwardPath, setForwardPath] = useState<string>('');
  const [prefixList, setPrefixList] = useState<any>([]);

  const [address, setAddress] = useState({
    legal_address: {
      address_house_number: null,
      address_moo: null,
      address_lane: null,
      address_street: null,
      address_sub_district: null,
      address_district: null,
      address_province: null,
      address_postal_code: null,
    },
    mailing_address: {
      address_house_number: null,
      address_moo: null,
      address_lane: null,
      address_street: null,
      address_sub_district: null,
      address_district: null,
      address_province: null,
      address_postal_code: null,
    },
  })
  const [provincesTH, setProvincesTH] = useState<string[]>([]);
  const [districtsTH, setDistrictsTH] = useState<string[]>([]);
  const [subDistrictsTH, setSubDistrictsTH] = useState<string[]>([]);
  const [provincesEN, setProvincesEN] = useState<string[]>([]);
  const [districtsEN, setDistrictsEN] = useState<string[]>([]);
  const [subDistrictsEN, setSubDistrictsEN] = useState<string[]>([]);
  const [filesBaseData, setFilesBaseData] = useState<ifileBase[]>([]);
  const [documentsRefs, setDocumentsRefs] = useState<[]>([]);

  const monthTH: string[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  const monthEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  type ifileBase = {
    dataPath: string;
    type: string;
    name: string;
}

const handleStatusFile = (type: string) => {
  return   type === 'ID_CARD' 
           ? "text_types_file_idcard"
           :type === 'HOUSE_REGISTRATION' 
           ? "text_types_file_houseRegistration"
           : type === 'BANK_ACCOUNT'
           ? "text_types_file_bankAccount"
           : type === 'FORMAL_PHOTO'
           ? "text_types_file_formalPhoto"
           : type === 'EMPLOYMENT_REFERENCE'
           ? "text_types_file_Ref"
           : ''
};

const debugBase64 = (base64URL: string)=> {
  let win = window.open();
  win?.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}


  useEffect(() => {
    const getDocumentsRef = async () => {
    try {
      
    if(appFormData && appFormData?.AppFormData?.user_id ){
    const newDocuments = []
    const response = await getAllDocuments(appFormData?.AppFormData?.user_id,'');

         if (response.data?.success) {
            const dataDocuments = response.data.document
            setDocumentsRefs(dataDocuments);
            for(let item in dataDocuments){
              const base64Data =  await getDocumentsfile(dataDocuments[item].dmsFileId)
              if(base64Data.status===200){
              newDocuments.push({
                    dataPath :base64Data?.data,
                    name: dataDocuments[item].name,
                    type: dataDocuments[item].type
              })  
            }
            }
            setFilesBaseData(newDocuments)
        }
        }
            return true
    
        } catch (error) {
            console.log(error);
            navigate('/servererror', { replace: true });
        }
    }
getDocumentsRef();
}, [ navigate, appFormData]);

  useEffect(() => {
    const fetchAppFormData = async () => {
      const response = await getAppFormById(id);
      
      if (response) {
        if (!response.data?.success) {
          Swal.fire({
            title: i18n.language === 'th' ? response.data?.message_th : response.data?.message_en,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#43A047',
            confirmButtonText: 'OK!',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.assign('/jobs_status');
            }
          });
          return;
        }

        const appFormById = response.data?.AppFormStatusData;
        const fetchGeoData = async () => {
          try {
            const provincesData = ProvinceJSON;
            const districtsData = await fetchDistricts();
            const subDistrictsData = await fetchSubDistricts();

            if (appFormById.AppFormData.AppFormAddress) {
              const legal_address = appFormById.AppFormData.AppFormAddress.find((address: any) => address.address_type === "LEGAL");
              const myProvinces_legal = provincesData.filter((pv: any) => pv.external_code === parseInt(legal_address.address_province));
              const myDistricts_legal = districtsData.filter((dt: any) => dt.name_th === legal_address.address_district);
              const mySubDistricts_legal = subDistrictsData.filter((sd: any) => sd.name_th === legal_address.address_sub_district);

              if (!appFormById.AppFormData.same_address) {
                const mailing_address = appFormById.AppFormData.AppFormAddress.find((address: any) => address.address_type === "MAILING");
                const myProvinces_mailing = provincesData.filter((pv: any) => pv.external_code === parseInt(mailing_address.address_province));
                const myDistricts_mailing = districtsData.filter((dt: any) => dt.name_th === mailing_address.address_district);
                const mySubDistricts_mailing = subDistrictsData.filter((sd: any) => sd.name_th === mailing_address.address_sub_district);
                setProvincesTH([myProvinces_legal[0].label_th, myProvinces_mailing[0].label_th]);
                setProvincesEN([myProvinces_legal[0].label_en, myProvinces_mailing[0].label_en]);
                setDistrictsTH([myDistricts_legal[0].name_th, myDistricts_mailing[0].name_th]);
                setDistrictsEN([myDistricts_legal[0].name_en, myDistricts_mailing[0].name_en]);
                setSubDistrictsTH([mySubDistricts_legal[0].name_th, mySubDistricts_mailing[0].name_th]);
                setSubDistrictsEN([mySubDistricts_legal[0].name_en, mySubDistricts_mailing[0].name_en]);
                setAddress({
                  legal_address: {
                    address_house_number: legal_address.address_house_number,
                    address_moo: legal_address.address_moo,
                    address_lane: legal_address.address_lane,
                    address_street: legal_address.address_street,
                    address_sub_district: legal_address.address_sub_district,
                    address_district: legal_address.address_district,
                    address_province: legal_address.address_province,
                    address_postal_code: legal_address.address_postal_code,
                  },
                  mailing_address: {
                    address_house_number: mailing_address.address_house_number,
                    address_moo: mailing_address.address_moo,
                    address_lane: mailing_address.address_lane,
                    address_street: mailing_address.address_street,
                    address_sub_district: mailing_address.address_sub_district,
                    address_district: mailing_address.address_district,
                    address_province: mailing_address.address_province,
                    address_postal_code: mailing_address.address_postal_code,
                  },
                });
              } else {
                setProvincesTH([myProvinces_legal[0].label_th]);
                setProvincesEN([myProvinces_legal[0].label_en]);
                setDistrictsTH([myDistricts_legal[0].label_th]);
                setDistrictsEN([myDistricts_legal[0].name_en]);
                setSubDistrictsTH([mySubDistricts_legal[0].name_th]);
                setSubDistrictsEN([mySubDistricts_legal[0].name_en]);
                setAddress({
                  legal_address: {
                    address_house_number: legal_address.address_house_number,
                    address_moo: legal_address.address_moo,
                    address_lane: legal_address.address_lane,
                    address_street: legal_address.address_street,
                    address_sub_district: legal_address.address_sub_district,
                    address_district: legal_address.address_district,
                    address_province: legal_address.address_province,
                    address_postal_code: legal_address.address_postal_code,
                  },
                  mailing_address: {
                    address_house_number: null,
                    address_moo: null,
                    address_lane: null,
                    address_street: null,
                    address_sub_district: null,
                    address_district: null,
                    address_province: null,
                    address_postal_code: null,

                  },
                });
              }


            }
          } catch (error) {
            navigate('/servererror', { replace: true });
            console.log(error);
          }
        }

        fetchGeoData();

        setForwardPath(searchParams.get('content_type') as string);
        setAppFromData(appFormById);

        setTimeout(() => {
          setLoadingData(false);
        }, 500);
      }
    }
    const fetchActivePrefix = async () => {
      try {
          const response = await getAllActivePrefix();
  
          if (response.data?.success) {
              setPrefixList(response.data?.marital_statuses);
          }
      } catch (error) {
          console.log(error);
      }
  }
  
      fetchActivePrefix();
    fetchAppFormData();
  }, []);

  const formatDate = (value: number): string => {
    let date_of_format: string = '';
    if (value === 1) {
      const newDate: string = dayjs(appFormData?.created_date).toISOString().split("T")[0]
      const date_token: string[] = newDate.split("-")
      let year: string = "";
      i18n.language === 'th' ? year = (parseInt(date_token[0]) + 543).toString() : year = date_token[0] + ","
      const date_of_send: string = date_token[2] + '/' + date_token[1] + '/' + year
      date_of_format = date_of_send
    }
    else if (value === 2) {
      const date: string = new Date(appFormData?.AppFormData.date_of_birth!).getDate().toString();
      const month: number = new Date(appFormData?.AppFormData.date_of_birth!).getMonth();
      const year: number = new Date(appFormData?.AppFormData.date_of_birth!).getFullYear();
      let date_of_birth: string = "";
      i18n.language === 'th' ?
        date_of_birth = date + ' ' + monthTH[month] + ' ' + (year + 543) :
        date_of_birth = date + ' ' + monthEN[month] + ' ' + year
      date_of_format = date_of_birth
    }
    else if (value === 3) {
      const date: string = new Date(appFormData?.AppFormData.start_date!).getDate().toString();
      const month: number = new Date(appFormData?.AppFormData.start_date!).getMonth();
      const year: number = new Date(appFormData?.AppFormData.start_date!).getFullYear();
      let date_of_work: string = "";
      i18n.language === 'th' ?
        date_of_work = date + ' ' + monthTH[month] + ' ' + (year + 543) :
        date_of_work = date + ' ' + monthEN[month] + ' ' + year
      date_of_format = date_of_work
    }
    return date_of_format;
  }

  const mapPrefix = (prefix: string, lang:string) => {

    const data: any = prefix && prefixList.find((item:any) =>  prefix === item.external_code )!;
    const defaultPrefix = data && lang === 'TH' ? 
    data.label
    : prefix === 'Mr' || prefix === 'Miss' || prefix === 'Mrs' ?
    `${PREFIX[prefix!]} ` 
    : ''

  return defaultPrefix
}

  return <div>
    <ThemeProvider theme={theme}>
      {!loadingData ? (
        <>
          <Box sx={{ height: 'auto', maxWidth: '100%', mx: { xs: 1, sm: 8 }, mt: { xs: 2, sm: 5 } }} >
            <Box display='flex' gap={3} sx={{ mb: 3 }}>
              <Box display='flex' component={ReactRouterLink} onClick={() => window.history.back()} sx={{
                textDecoration: 'none', color: '#0d47a1', alignItems: 'center', ':hover': {
                  color: '#1976d2',
                }
              }}>
                <ChevronLeftIcon fontSize='small' />
                <Typography sx={{ fontWeight: 'bold' }}>{t("Back")}</Typography>
              </Box>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  component={ReactRouterLink}
                  to={`/jobs_status?content_type=${forwardPath}`}
                >
                  <AssignmentTurnInIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {t("Job Application Status")}
                </Link>
                <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
                  <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {t("JobAppDetail")}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Box>
          <Box sx={{ mx: { xs: 1, sm: 15 }, my: { xs: 2, sm: 5 } }}>
            <Grid container display='flex' sx={{ gap: { xs: 2, sm: 7 }, mb: 2 }}>
              <Grid item display='flex' justifyContent='center' alignItems='center'>
                <Typography sx={{ fontWeight: 'bold', fontSize: { xs: 18, sm: 25 }, letterSpacing: 0.5 }}>
                  {t("Appilcation form")}
                </Typography>
              </Grid>
              <Grid item display='flex' justifyContent='center' alignItems='center'>
                <Chip
                  variant="outlined"
                  sx={{
                    bgcolor: `${appFormData?.Status.color}`,
                    borderColor: `${appFormData?.Status.color}`,
                    fontWeights: 'normal',
                    letterSpacing: 0.3,
                    color: 'white',
                  }}
                  size='medium'
                  label={appFormData?.Status.show_on_applicant}
                />
              </Grid>
            </Grid>

            <Box display='flex' flexDirection='column' gap={3} sx={{ ml: { sm: 4 } }}>
              <Grid item>
                <Typography sx={{ fontSize: { xs: 15, sm: 17 }, mt: { xs: 1, sm: 2 }, letterSpacing: 0.5 }}>
                  {t("Application date") + " "} {formatDate(1)} {t("time")} {dayjs(appFormData?.created_date).format("HH:mm")}
                </Typography>
              </Grid>
              {appFormData?.comment && (
                <>
                  <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                    <ErrorOutlineRoundedIcon fontSize='medium' color='info' />
                    <Typography sx={{ fontWeight: 'bold', fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.5 }}>
                      {t("Comment")}
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.5 }}>
                    {appFormData?.comment}
                  </Typography>
                </>
              )}
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Personal Information")}
                  </Typography>
                </Grid>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={1}>
                    <Typography sx={bodyStyle}>
                      {t("Full name TH")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {/* {PREFIX[appFormData?.AppFormData.prefix!]} {appFormData?.AppFormData.first_name_th || '-'} {appFormData?.AppFormData.last_name_th || '-'} */}
                      {mapPrefix(appFormData?.AppFormData.prefix!, 'TH')} {appFormData?.AppFormData.first_name_th || '-'} {appFormData?.AppFormData.last_name_th || '-'}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={1}>
                    <Typography sx={bodyStyle}>
                      {t("Full name EN")} :
                    </Typography>
                    {/* <Typography sx={bodyStyle}>{appFormData?.AppFormData.prefix} {appFormData?.AppFormData.first_name_en || '-'} {appFormData?.AppFormData.last_name_en || '-'}</Typography> */}
                    <Typography sx={bodyStyle}> {mapPrefix(appFormData?.AppFormData.prefix!, 'EN')} {appFormData?.AppFormData.first_name_en || '-'} {appFormData?.AppFormData.last_name_en || '-'}</Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Gender")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t(GENDER[appFormData?.AppFormData.gender!])}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Weight")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.weight} {t('kg')}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Height")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.height} {t('cm')}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Nationality")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t(NATIONALITY[appFormData?.AppFormData.nationality!])}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Religion")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t(RELIGION[appFormData?.AppFormData.religion!])}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("MaritalStatus")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t(MARITAL_STATUS[appFormData?.AppFormData.marital_status!])}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Date of birth")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {formatDate(2)}
                    </Typography>
                  </Grid>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: { xs: 'none', sm: 'column' }, ml: { sm: 2 } }}>
                  <Grid item sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("ID card number")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.id_card_number}
                    </Typography>
                  </Grid>
                </Box>
                <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: { xs: 'column', sm: 'none' } }} gap={1}>
                  <Grid item xs={12}>
                    <Typography sx={bodyStyle}>
                      {t("ID card number")} :
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.id_card_number}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Contact Information")}
                  </Typography>
                </Grid>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Phone Number")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.telephone}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Email")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.email ? appFormData?.AppFormData.email : '-'}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Line ID")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.line_id ? appFormData?.AppFormData.line_id : '-'}
                    </Typography>
                  </Grid>
                </Box>
                {appFormData?.AppFormData.emergency_name ? <>
                  <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: { xs: 'none', sm: 'column' }, ml: { sm: 2 } }}>
                    <Grid item sm={12} display='flex' gap={2}>
                      <Typography sx={bodyStyle}>
                        {t("Person to notify in case of emergency")} :
                      </Typography>
                      <Typography sx={bodyStyle}>
                        {appFormData?.AppFormData.emergency_name}
                      </Typography>
                    </Grid>
                  </Box>
                  <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: { xs: 'column', sm: 'none' } }} gap={1}>
                    <Grid item xs={12}>
                      <Typography sx={bodyStyle}>
                        {t("Person to notify in case of emergency")} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={bodyStyle}>
                        {appFormData?.AppFormData.emergency_name}
                      </Typography>
                    </Grid>
                  </Box>
                </> : (
                  <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                    <Grid item sm={12} display='flex' gap={2}>
                      <Typography sx={bodyStyle}>
                        {t("Person to notify in case of emergency")} :
                      </Typography>
                      <Typography sx={bodyStyle}>-</Typography>
                    </Grid>
                  </Box>
                )}
                {appFormData?.AppFormData.emergency_phone ? <>
                  <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: { xs: 'none', sm: 'column' }, ml: { sm: 2 } }}>
                    <Grid item sm={12} display='flex' gap={2}>
                      <Typography sx={{ fontSize: { sm: 17 }, letterSpacing: 0.3 }}>
                        {t("Phone number of person to call in case of emergency")} :
                      </Typography>
                      <Typography sx={bodyStyle}>
                        {appFormData?.AppFormData.emergency_phone}
                      </Typography>
                    </Grid>
                  </Box>
                  <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: { xs: 'column', sm: 'none' } }} gap={1}>
                    <Grid item xs={12}>
                      <Typography sx={bodyStyle}>
                        {t("Phone number of person to call in case of emergency")} :
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={bodyStyle}>
                        {appFormData?.AppFormData.emergency_phone}
                      </Typography>
                    </Grid>
                  </Box>
                </> : (
                  <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                    <Grid item xs={12} sm={12} display='flex' gap={2}>
                      <Typography sx={bodyStyle}>
                        {t("Phone number of person to call in case of emergency")} :
                      </Typography>
                      <Typography sx={bodyStyle}>-</Typography>
                    </Grid>
                  </Box>
                )}
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Relationship")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.emergency_relation ? appFormData?.AppFormData.emergency_relation : '-'}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Education Information")}
                  </Typography>
                </Grid>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Education Level")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.education_level ? appFormData?.AppFormData.education_level : '-'}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("School")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.education_school ? appFormData?.AppFormData.education_school : '-'}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Military Status")}
                  </Typography>
                </Grid>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Military Status")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.military_status}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Work Experience")}
                  </Typography>
                </Grid>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {appFormData?.AppFormData.work_experience}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Legal Address")}
                  </Typography>
                </Grid>
                <Grid container direction='row' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Typography sx={bodyStyle}>
                    {t("House No.") + " "} {address.legal_address.address_house_number ? address.legal_address.address_house_number : '-'}
                  </Typography>
                  <Typography sx={bodyStyle}>
                    {t("Moo") + " "} {address.legal_address.address_moo ? address.legal_address.address_moo : '-'}
                  </Typography>
                  <Typography sx={bodyStyle}>
                    {t("Lane") + " "} {address.legal_address.address_lane ? address.legal_address.address_lane : '-'}
                  </Typography>
                  {i18n.language === 'th' ? (
                    <Typography sx={bodyStyle}>
                      {"ถนน"} {address.legal_address.address_street ? address.legal_address.address_street : '-'}
                    </Typography>
                  ) : (
                    <Typography sx={bodyStyle}>
                      {address.legal_address.address_street ? address.legal_address.address_street : '-'} Street,
                    </Typography>
                  )}
                </Grid>
                <Grid container direction='row' gap={1} sx={{ ml: { sm: 2 } }}>
                  {i18n.language === 'th' ? <>
                    <Typography sx={bodyStyle}>
                      {t("Sub District")} {subDistrictsTH[0]}
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t("District")} {districtsTH[0]}
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t("Province")} {provincesTH[0]}
                    </Typography>
                  </> : <>
                    <Typography sx={bodyStyle}>
                      {subDistrictsEN[0]} {"Sub-District"},
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {districtsEN[0]} {t("District")},
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {provincesEN[0]},
                    </Typography>
                  </>}
                  <Typography sx={bodyStyle}>
                    {address.legal_address.address_postal_code}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Mailing Address")}
                  </Typography>
                </Grid>
                {!appFormData?.AppFormData.same_address ? (
                  <><Grid container direction='row' gap={1} sx={{ ml: { sm: 2 } }}>
                    <Typography sx={bodyStyle}>
                      {t("House No.") + " "} {address.mailing_address?.address_house_number ? address.mailing_address?.address_house_number : '-'}
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t("Moo") + " "} {address.mailing_address?.address_moo ? address.mailing_address?.address_moo : '-'}
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {t("Lane") + " "} {address.mailing_address?.address_lane ? address.mailing_address?.address_lane : '-'}
                    </Typography>
                    {i18n.language === 'th' ? (
                      <Typography sx={bodyStyle}>
                        {"ถนน"} {address.mailing_address?.address_street ? address.mailing_address?.address_street : '-'}
                      </Typography>
                    ) : (
                      <Typography sx={bodyStyle}>
                        {address.mailing_address?.address_street ? address.mailing_address?.address_street : '-'} Street,
                      </Typography>
                    )}
                  </Grid>
                    <Grid container direction='row' gap={1} sx={{ ml: { sm: 2 } }}>
                      {i18n.language === 'th' ? <>
                        <Typography sx={bodyStyle}>
                          {t("Sub District")} {subDistrictsTH[1]}
                        </Typography>
                        <Typography sx={bodyStyle}>
                          {t("District")} {districtsTH[1]}
                        </Typography>
                        <Typography sx={bodyStyle}>
                          {t("Province")} {provincesTH[1]}
                        </Typography>
                      </> : <>
                        <Typography sx={bodyStyle}>
                          {subDistrictsEN[1]} {"Sub-District"},
                        </Typography>
                        <Typography sx={bodyStyle}>
                          {districtsEN[1]} {t("District")},
                        </Typography>
                        <Typography sx={bodyStyle}>
                          {provincesEN[1]},
                        </Typography>
                      </>}
                      <Typography sx={bodyStyle}>
                        {address.mailing_address?.address_postal_code}
                      </Typography>
                    </Grid></>
                ) : (
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, ml: { sm: 2 }, letterSpacing: 0.3 }}>
                    {t("Same as legal address")}
                  </Typography>
                )}

              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("Job Information")}
                  </Typography>
                </Grid>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={1}>
                    <Typography sx={bodyStyle}>
                      {t("Job position applied")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.Job.job_name}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Workplace")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.Job.Site.site_name}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Company")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {appFormData?.Job.company}
                    </Typography>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: { sm: 2 } }}>
                  <Grid item xs={12} sm={12} display='flex' gap={2}>
                    <Typography sx={bodyStyle}>
                      {t("Start date of work")} :
                    </Typography>
                    <Typography sx={bodyStyle}>
                      {formatDate(3)}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction='column' gap={2}>
                <Grid container direction='row'>
                  <Typography sx={{ fontSize: { xs: 15, sm: 17 }, fontWeight: 'bold', letterSpacing: 0.5 }}>
                    {t("file attached") + " : "}
                  </Typography>
                  
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                <Box display='flex' flexDirection='column' gap={1} sx={{ ml: 3, mt: 1.5 }}>
                                {filesBaseData?.length > 0 ? filesBaseData.map((item:any, index) => {
                                    return <div key={index}>
                                           <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                                <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>{t(`${handleStatusFile(item?.type)}`)} :</Typography>
                                                <Typography sx={{ fontSize: { xs: 15, sm: 17 }, letterSpacing: 0.3 }}>
                                                <a  onClick={() => { debugBase64(item.dataPath)}}  target="_blank" rel="noreferrer">{item.name}</a>
                                                </Typography>
                                            </Grid>
                                          </div>
                                     }
                                )
                                :(documentsRefs?.length < 1 ? (
                                    <div>{t("text_documents_refs")} </div>
                                   )
                                   :( <Grid item xs={12} sm={12} display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 0, sm: 2 } }}>
                                       <CircularProgress size={20} sx={{ mr: 2 }} />
                                   </Grid>)
                               )
                              }
                                </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' marginY={3}>
              <Button variant='contained' sx={{ width: 180, fontSize: 15, letterSpacing: 0.5 }} component={ReactRouterLink} to={`/jobs_status?content_type=${forwardPath}`}>
                {t("turn")}{t("Back")}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <CircularProgress size={20} sx={{ mr: 2 }} />
          <Typography align='center' sx={{ fontSize: { xs: 15, sm: 17 } }}>{t("Loading")}...</Typography>
        </Box>
      )}
    </ThemeProvider>
  </div>;
};

export default AppformDetail;
