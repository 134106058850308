import * as React from "react";
import PublicAppBar from "../../../components/appbar/PublicAppBar";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type NotFoundProps = {
  //
};

const NotFound: React.FC<any> = () => {

  const { t } = useTranslation();

  return <Container maxWidth='lg'>
    <PublicAppBar />
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Typography variant="h1">404</Typography>
      <Typography sx={{ fontWeight: 'bold', fontSize: "40px", marginBottom: 2 }}>{t("Oops! Page Not Found")}</Typography>
      <Typography variant="body1">{t("The page you are looking for might have been removed or is temporarily unavailable.")}</Typography>
    </div>
  </Container>;
};

export default NotFound;
