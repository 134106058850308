// React
import React, { useEffect, useState } from "react";
import {
    Link,
    useNavigate,
    useSearchParams
} from "react-router-dom";

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";
import useDebounce from "../../../hooks/useDebounce";

// MUI Components
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
    DataGridPro,
    GridCellParams,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueGetterParams,
    enUS,
} from "@mui/x-data-grid-pro";

// Utils
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import dayjs from "dayjs";

// Styles
import theme from "../../../styles/theme";

// Types
import {
    FilterOption,
    Site,
    TokenPayload,
    User,
    thTHGrid
} from "../../../@types";
import RoleType from "../../../@types/RoleType";

// Services
import { getAllSites } from "../../../services/SiteService";
import { queryUsers } from "../../../services/UserService";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Users = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const [siteData, setSiteData] = useState<Site[]>([]);
    const [selectedSites, setSelectedSites] = useState<Site | null>(null);
    const [filterUserTable, setFilterUserTable] = useState<User[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<FilterOption>({ "label": `${t("All")}`, "value": null });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [loadingSite, setLoadingSite] = useState<boolean>(true);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);

    const userRoleType = useAppSelector(state => state.user.roletype);
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const allSuccessLoading = !loadingUser && !loadingSite;

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (searchParams.has('type')) {
            const type = searchParams.get('type')
            if (type === RoleType.Member.toLowerCase()) {
                setTabValue(0);
            } else if (type === 'hr') {
                setTabValue(1)
            } else if (type === 'superadmin') {
                try {
                    const token = localStorage.getItem('access_token') || ''
                    const decoded: TokenPayload = jwtDecode(token)

                    if (decoded.RoleType === RoleType.Super_Admin) {
                        setTabValue(2)
                    } else {
                        navigate('/admin/masterdata/users?type=member', { replace: true })
                    }
                } catch (error) {
                    navigate('/servererror', { replace: true });
                }
            } else {
                navigate('/admin/masterdata/users?type=member', { replace: true });
            }
        }

        if (!searchParams.has('type')) {
            searchParams.append('type', RoleType.Member.toLowerCase());
        }
    }, [navigate, searchParams])

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoadingUser(true);

                const data = {
                    search: debouncedSearchQuery || '',
                    type: searchParams.get('type'),
                    status: selectedStatus.value,
                    site: selectedSites?.site_id
                }

                const response = await queryUsers(data);

                if (response.data?.success) {
                    setFilterUserTable(response.data?.users);
                }

            } catch (error) {
                navigate('/servererror', { replace: true });
            } finally {
                setTimeout(() => {
                    setLoadingUser(false);
                    setIsSearching(false);
                }, 500);
            }
        };

        if (debouncedSearchQuery !== '') {
            setIsSearching(true);
            fetchUsers();
        } else {
            setSearchQuery('');
            fetchUsers();
        }
    }, [debouncedSearchQuery, navigate, searchParams, selectedStatus, selectedSites]);

    useEffect(() => {
        if (searchQuery !== '') {
            setIsSearching(true);
            setFilterUserTable([]);
        }
    }, [searchQuery])

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await getAllSites();

                if (response.data.success) {
                    const sitesData = response.data.sites;
                    setSiteData(sitesData);
                }
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        };

        const siteSorting = async () => {
            try {
                siteData.sort((a, b) => a.site_id.localeCompare(b.site_id));
                setTimeout(() => {
                    setLoadingSite(false);
                }, 500);
            } catch (error) {
                navigate('/servererror', { replace: true });
            }
        }

        fetchSites();
        siteSorting();

    }, [navigate]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    const memberColumns: GridColDef[] = [
        {
            field: 'edit',
            headerName: `${t('Edit')}`,
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            width: userRoleType === RoleType.Super_Admin ? 80 : 0,
            renderCell: (params) => {
                if (userRoleType === RoleType.Super_Admin) {
                    return (
                        <Link to={`/admin/masterdata/users/edit/${params.row.id}?type=${searchParams.get('type')}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
                            <Tooltip title={t("Edit")}>
                                <Icon fontSize="medium">edit</Icon>
                            </Tooltip>
                        </Link>
                    );
                }
            },
        },
        {
            field: 'roleName', headerName: `${t("Role Name")}`, width: 100, hideSortIcons: true, filterable: false, sortable: false, disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => params.row.Role.role_name
        },
        {
            field: 'fullname',
            headerName: `${t("Full name")}`,
            width: 250,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.first_name_th || ''} ${params.row.last_name_th || ''}`,
        },
        {
            field: 'email',
            headerName: `${t("Email")}`,
            width: 250,
            valueGetter: (params: GridValueGetterParams) => params.row.email,
            renderCell: (params: GridCellParams) => {
                return (
                    <>{params.row.email ? params.row.email : '-'}</>
                );
            },
        },
        {
            field: 'telephone',
            headerName: `${t("Phone Number")}`,
            width: 180,
            valueGetter: (params: GridValueGetterParams) => params.row.telephone,
            renderCell: (params: GridCellParams) => {
                return (
                    <>{params.row.telephone ? params.row.telephone : '-'}</>
                );
            },
        },
        {
            field: 'last_active', headerName: `${t("Last Login")}`, width: 200,
            renderCell: (params: GridCellParams) => {
                return (
                    <>{params.row.last_active ? dayjs(params.row.last_active).format(i18n.language === 'en' ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/BBBB HH:mm:ss') : '-'}</>
                );
            },
            valueGetter: (params: GridValueGetterParams) => dayjs(params.row.last_active).format(i18n.language === 'en' ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/BBBB HH:mm:ss'),
        },
        {
            field: 'is_active',
            headerName: `${t("Status")}`,
            width: 100,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                params.row.is_active === true ? (
                    <Chip variant="outlined" color="success" label={t("Active")} size="small" />
                ) : (
                    <Chip variant="outlined" color="error" label={t("Inactive")} size="small" />
                )
        }
    ];

    const hrColumns: GridColDef[] = [
        {
            field: 'edit',
            headerName: `${t('Edit')}`,
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            width: userRoleType === RoleType.Super_Admin ? 80 : 0,
            renderCell: (params) => {
                if (userRoleType === RoleType.Super_Admin) {
                    return (
                        <Link to={`/admin/masterdata/users/edit/${params.row.id}?type=${searchParams.get('type')}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
                            <Tooltip title={t("Edit")}>
                                <Icon fontSize="medium">edit</Icon>
                            </Tooltip>
                        </Link>
                    );
                }
            },
        },
        {
            field: 'roleName', headerName: `${t("Role Name")}`, width: 150, hideSortIcons: true, filterable: false, sortable: false, disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => params.row.Role.role_name
        },
        {
            field: 'fullname',
            headerName: `${t("Full name")}`,
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.first_name_en || ''} ${params.row.last_name_en || ''}`,
        },
        { field: 'email', headerName: `${t("Email")}`, width: 250, },
        {
            field: 'site', headerName: `${t("Site")}`, width: 180,
            renderCell: (params: GridCellParams) => {
                const siteData = params.row.Site;
                siteData?.sort((a: string, b: string) => a.localeCompare(b));

                return (
                    <div style={{ paddingBottom: 16, paddingTop: 16, lineHeight: 1.5 }}>
                        {
                            siteData.map((site: string, index: number) => (
                                <React.Fragment key={site}>
                                    {site}
                                    {index !== siteData.length - 1 && <br />}
                                </React.Fragment>
                            ))}
                    </div>
                );
            }
        },
        {
            field: 'active',
            headerName: `${t("Status")}`,
            width: 100,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                params.row.is_active === true ? (
                    <Chip variant="outlined" color="success" label={t("Active")} size="small" />
                ) : (
                    <Chip variant="outlined" color="error" label={t("Inactive")} size="small" />
                )
        }
    ];

    const superAdminColumns: GridColDef[] = [
        {
            field: 'edit',
            headerName: `${t('Edit')}`,
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            width: userRoleType === RoleType.Super_Admin ? 80 : 0,
            renderCell: (params) => {
                if (userRoleType === RoleType.Super_Admin) {
                    return (
                        <Link to={`/admin/masterdata/users/edit/${params.row.id}?type=${searchParams.get('type')}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
                            <Tooltip title={t("Edit")}>
                                <Icon fontSize="medium">edit</Icon>
                            </Tooltip>
                        </Link>
                    );
                }
            },
        },
        {
            field: 'fullname',
            headerName: `${t("Full name")}`,
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.first_name_en || ''} ${params.row.last_name_en || ''}`,
        },
        { field: 'email', headerName: `${t("Email")}`, width: 250, },
        {
            field: 'active',
            headerName: `${t("Status")}`,
            width: 100,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) =>
                params.row.is_active === true ? (
                    <Chip variant="outlined" color="success" label={t("Active")} size="small" />
                ) : (
                    <Chip variant="outlined" color="error" label={t("Inactive")} size="small" />
                )
        }
    ];

    return <div>
        <ToastContainer />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                {`${t("UsersPageTitle")} (${tabValue === 0 ? RoleType.Member : tabValue === 1 ? RoleType.HR_Recruiter : RoleType.Super_Admin})`}
            </Typography>
            {
                tabValue === 1 && userRoleType === RoleType.Super_Admin ? (<Button
                    variant="contained"
                    disableElevation
                    sx={{
                        backgroundColor: theme.palette.button.main,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#0288d1',
                        },
                        minWidth: 'fit-content',
                        minHeight: '42px'
                    }}
                    component={Link}
                    to="/admin/masterdata/users/create?type=hr"
                >
                    {t("Create New HR Recruiter")}
                </Button>)
                    : tabValue === 2 && userRoleType === RoleType.Super_Admin ? (<Button
                        variant="contained"
                        disableElevation
                        sx={{
                            backgroundColor: theme.palette.button.main,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#0288d1',
                            },
                            minWidth: 'fit-content',
                            minHeight: '42px'
                        }}
                        component={Link}
                        to="/admin/masterdata/users/create?type=superadmin"
                    >
                        {t("Create New Super Admin")}
                    </Button>) : null
            }
        </Box>

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: theme.palette.primary.main,
                        }
                    }}
                    sx={{
                        ".Mui-selected": {
                            color: theme.palette.primary.main,
                            fontWeight: "bold"
                        },
                    }}>
                    <Tab label="Member" {...a11yProps(0)} component={Link} to="/admin/masterdata/users?type=member" />
                    <Tab label={RoleType.HR_Recruiter} {...a11yProps(1)} component={Link} to="/admin/masterdata/users?type=hr" />
                    {
                        localStorage.getItem('access_token') ? (
                            jwtDecode<TokenPayload>(localStorage.getItem('access_token')!).RoleType === RoleType.Super_Admin ? (<Tab label={RoleType.Super_Admin} {...a11yProps(2)} component={Link} to="/admin/masterdata/users?type=superadmin" />) : null
                        ) : null
                    }
                </Tabs>
            </Box>

            <Box>
                <Grid container spacing={3} sx={{ marginTop: 1, paddingX: 3 }}>
                    <Grid item xs={12} sm={searchParams.get('type') === RoleType.Member.toLowerCase() ? 9 : 5}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label={t("Search")}
                                placeholder={t("Search by First name, Last name, Email, or Phone Number")!}
                                value={searchQuery}
                                onChange={((event) => {
                                    setSearchQuery(event.target.value);
                                })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon fontSize="medium">search</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchQuery.length > 0 && (
                                        <InputAdornment position="end">
                                            <IconButton size="small" edge="end" onClick={() => setSearchQuery('')}>
                                                <Icon>clear</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {
                        searchParams.get('type') !== RoleType.Member.toLowerCase() && (
                            <Grid item xs={12} sm={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                        onChange={(event, newValue: Site | null) => {
                                            setSelectedSites(newValue);
                                        }}
                                        limitTags={1}
                                        id="site"
                                        options={
                                            siteData
                                        }
                                        getOptionLabel={(option) => option.site_name}
                                        value={selectedSites}
                                        renderOption={(props, option, { inputValue, selected }) => {
                                            const matches = match(option.site_name, inputValue, { insideWords: true });
                                            const parts = parse(option.site_name, matches);

                                            return (
                                                <li {...props}>
                                                    <div>
                                                        {parts.map((part: { text: string, highlight: boolean }, index: number) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: part.highlight ? 700 : 400,
                                                                }}
                                                            >

                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                label={t("Site")}
                                                placeholder={t("Site")!}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>)
                    }
                    <Grid item xs={12} sm={3}>
                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                                onChange={(_, newValue: FilterOption) => {
                                    setSelectedStatus(newValue);
                                }}
                                limitTags={2}
                                id="role"
                                options={[{ "label": `${t("All")}`, "value": null }, { "label": `${t("Active")}`, "value": true }, { "label": `${t("Inactive")}`, "value": false }]}
                                isOptionEqualToValue={(option: FilterOption, value: FilterOption) => option.value === value.value}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.label, inputValue, { insideWords: true });
                                    const parts = parse(option.label, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part: { text: string, highlight: boolean }, index: number) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                                value={selectedStatus}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField {...params} label={t("Status")} placeholder={t("Status")!} />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ width: '100%', paddingX: 3, mt: 3 }}>
                {isSearching && allSuccessLoading && <Typography align='center'>{t("Searching")}...</Typography>}
                {!allSuccessLoading ? (
                    <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box>
                ) : filterUserTable.length !== 0 ? (
                    <Box display='inline-grid' sx={{ width: '100%' }}>
                        <DataGridPro
                            rows={filterUserTable}
                            columns={searchParams.get('type') === RoleType.Member.toLowerCase() ? memberColumns : searchParams.get('type') === 'hr' ? hrColumns : superAdminColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pagination
                            pageSizeOptions={[10, 20, 30, 40, 50]}
                            getRowHeight={() => tabValue === 0 || tabValue === 2 ? 50 : 'auto'}
                            disableRowSelectionOnClick
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                    outline: "none !important",
                                },
                                fontSize: 13,
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            slotProps={{
                                filterPanel: {
                                    filterFormProps: {
                                        logicOperatorInputProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                        },
                                        columnInputProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                            sx: { mt: 'auto' },
                                        },
                                        operatorInputProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                            sx: { mt: 'auto' },
                                        },
                                        valueInputProps: {
                                            InputComponentProps: {
                                                variant: 'outlined',
                                                size: 'small',
                                            },
                                        },
                                        deleteIconProps: {
                                            sx: {
                                                '& .MuiSvgIcon-root': { color: '#d32f2f' },
                                            },
                                        },
                                    },
                                    sx: {
                                        '& .MuiDataGrid-filterForm': { p: 2 },
                                        '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                                            backgroundColor: () =>
                                                theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                                        },
                                        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                                    },
                                },
                            }}
                            localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                            unstable_headerFilters
                        />
                    </Box>
                ) : !isSearching ? (
                    <Typography align='center'>{t("No data")}</Typography>
                ) : null}
            </Box>

        </Box>
    </div>;
};

export default Users;
