import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import jobAppSlice from "./slices/jobAppSlice";
import documentsSlice from "./slices/documentsSlice";


export const store = configureStore({
    reducer:{
        user: userSlice.reducer,
        jobapp: jobAppSlice.reducer,
        documentDMS:documentsSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch